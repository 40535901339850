var render = function render(){var _vm=this,_c=_vm._self._c;return _c('perfect-scrollbar',[_c('div',{staticClass:"sidenav-pkg bg-grey"},[_c('div',{staticClass:"sidebar text-left p-0",attrs:{"id":"lgmenu"}},[_c('div',{staticClass:"bar-details text-center px-3 pt-3"},[_c('img',{staticClass:"mb-3 w-100 mw-120 h-120 rounded-circle o-cover",attrs:{"src":this.$store.state.user_detail.profile_photo,"width":"30px"}}),_c('p',{staticClass:"name_title bold_font"},[_vm._v(" "+_vm._s(this.$store.state.user_detail.full_name)+" ")])]),_c('div',{staticClass:"bar-menus"},[_c('ul',{staticClass:"m-0 p-2 text-left"},[_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/"}},[_vm._v("Projects ")])],1),(
              (_vm.$store.state.user_detail.user_role == 'admin' ||
                _vm.$store.state.user_detail.user_role == 'super_admin') &&
              _vm.$store.state.user_detail.user_role != 'client'
            )?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/alltasks"}},[_vm._v("All Tasks ")])],1):_vm._e(),_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/mytasks"}},[_vm._v("My Tasks")])],1),(
              _vm.$store.state.user_detail.user_role == 'admin' ||
              _vm.$store.state.user_detail.user_role == 'super_admin'
            )?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/userlist"}},[_vm._v("Users ")])],1):_vm._e(),(_vm.isScrumSideBarTabVisible)?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/scrum"}},[_vm._v("Scrum")])],1):_vm._e(),_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/sprint"}},[_vm._v("Sprint")])],1),(
              _vm.$store.state.user_detail.user_role == 'admin' ||
              _vm.$store.state.user_detail.user_role == 'super_admin'
            )?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/backlogs"}},[_vm._v("Back Logs ")])],1):_vm._e(),(
              _vm.$store.state.user_detail.user_role == 'admin' ||
              _vm.$store.state.user_detail.user_role == 'super_admin'
            )?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/holidays"}},[_vm._v("Holidays ")])],1):_vm._e(),(_vm.$store.state.user_detail.user_role != 'client')?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":{
                name: 'AddUser',
                params: _vm.$store.state.user_detail,
              }}},[_vm._v("Edit Profile ")])],1):_vm._e(),_c('li',{staticClass:"sidemenu position-relative",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-flex align-items-center p-2",attrs:{"to":'/notification'}},[_vm._v("Notifications"),(_vm.notifications_count > 0)?_c('b-badge',{staticClass:"z-depth-1 badge ml-2 d-flex align-items-center rounded-circle img-too-small justify-content-center",attrs:{"variant":"light"}},[_vm._v(_vm._s(_vm.notifications_count))]):_vm._e()],1)],1),(
              _vm.$store.state.user_detail.user_role == 'admin' ||
              _vm.$store.state.user_detail.user_role == 'super_admin'
            )?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":"/leavelist"}},[_vm._v("Leave Records ")])],1):_vm._e(),(_vm.$store.state.user_detail.user_role != 'client')?_c('li',{staticClass:"sidemenu",on:{"click":function($event){return _vm.changebin()}}},[_c('router-link',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"to":'/calendar'}},[_vm._v("Calendar")])],1):_vm._e(),_c('li',{staticClass:"sidemenu"},[_c('a',{staticClass:"sidebarmenu grey-text text-decoration-none d-block p-2",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.clearsession}},[_vm._v("Logout")])])])]),((_vm.$store.state.user_detail.user_role != 'client'))?_c('div',{staticClass:"chat-menus"},[_c('ul',{staticClass:"p-2 m-0 list-type-none"},[_c('li',{staticClass:"sidemenu logout_button",on:{"click":function($event){return _vm.changebin();}}},[_c('router-link',{staticClass:"sidebarmenu chat_icon grey-text text-decoration-none d-block p-2",attrs:{"to":'/group/waioz'}},[_vm._v(" #waioz ")])],1)])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }