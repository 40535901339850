<template>
    <div class="fullpage">
        <div class="tasklist row w-100 mx-0">
            <div class="taskform col-12" id="no-more-tables">
                <div v-if="notifications.length == 0" class="no_alerts">
                    <h3> You are all caught up!!! </h3>
                </div>
                <button v-if="notifications.length != 0" id="buton" class="btn primary-bg py-2 px-3 d-flex ml-auto text-white"  @click="open_all_notification()">Read All</button>
                <table v-if="notifications.length != 0" class="table table-striped text-left table_design">
                <thead class="table_header">
                    <tr class="p-0">
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic notification_type">Type</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic notification">Notification</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic notification_time">Time</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic notification_open">Action</th>
                    </tr>
                </thead>
                <tbody class="table_body">
                    <tr class="p-0 single_alert" v-for="item in notifications" :key="item._id">
                        <!-- <td data-title="Task" class="task_name">{{ item.title }}</td> -->
                        <td data-title="Type" class="notification_type">
                            <span class="notification_icon" v-if="item.type == 'task'">
                                <font-awesome-icon icon="tasks" size="lg"></font-awesome-icon>
                            </span>
                            <span class="notification_icon" v-if="item.type == 'group'">
                                <font-awesome-icon icon="comment" size="lg"></font-awesome-icon>
                            </span>
                            <span class="notification_icon" v-if="item.type == 'message'">
                                <font-awesome-icon icon="comment" size="lg"></font-awesome-icon>
                            </span>
                            <span v-if="item.type == 'project'">
                                <font-awesome-icon icon="clipboard-check" size="lg"></font-awesome-icon>
                            </span>
                            {{item.type}}
                        </td>
                        <td data-title="Notification" class="notification">{{(item.message)}}
                        <div v-if="item.type == 'group' || 'mesage'" v-html="item.last_message">
                        </div>
                        </td>
                        <td data-title="Time" class="notification_time">{{format_date(item.updated_at)}}</td>
                        <td data-title="Action" class="notification_open">
                            <button id="buton" class="notification_button"  @click="open_notification(item)">View</button>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
    name: 'Notification',
    components: {
        moment
    },
    data () {
        return {
            signed_in_user: '',
            notifications: []
        }
    },
    created () {
        this.$store.commit("change_page", "notification-page");
        this.signed_in_user = this.$store.state.user_detail
        this.$socket.emit('all_notifications',{user_id: this.signed_in_user._id})
    },
    mounted () {
        this.$store.commit("change_page", "notification-page");
    },
    methods: {
        format_date(value){
            if (value) {
                return moment(String(value)).format('lll')
            }
        },
        open_all_notification: function() {
            let params = {
                user_id: this.signed_in_user._id,
                type: 'all'
            }
            this.$socket.emit('read_all_notifications',params)
        },
        open_notification: function(data){
            console.log(data)
            if(data.type == 'project'){
                let params = {
                    _id: data._id,
                    user_id: data.user_id,
                    isread: '0'
                }
                console.log(data)
                this.$socket.emit('read_notification',params)
                this.$router.push('/project/' + data.project_id )                 
            }
            else if(data.type == 'task'){
                let params = {
                    _id: data._id,
                    user_id: data.user_id,
                    isread: '0'
                }
                this.$socket.emit('read_notification',params)
                this.$router.push('/viewtask/' + data.task_id)
            }
            else if(data.type == 'message'){
                let params = {
                    _id: data._id,
                    user_id: data.user_id,
                    isread: '0'
                }
                this.$socket.emit('read_notification',params)
                this.$router.push('/chat/'+ data.room_id)
            }
            else if(data.type == 'group'){
                let params = {
                    _id : data._id,
                    user_id: data.user_id,
                    isread: '0'
                }
                this.$socket.emit('read_notification', params)
                this.$router.push('/group/'+ data.room_id)
            }
            else if(data.type == 'leave'){
                let params = {
                    _id : data._id,
                    user_id: data.user_id,
                    isread: '0'
                }
                this.$socket.emit('read_notification', params)
                this.$router.push('/leavelist')
            }
        }
    },
    sockets: {
        connect: function(){
            this.$socket.emit('notification_room',this.signed_in_user._id)
            this.$socket.emit('all_notifications',{user_id: this.signed_in_user._id})
        },
        get_notification : function(values){
            this.$socket.emit('all_notifications',{user_id: this.signed_in_user._id})
        },
        get_all_notifications : function(values){
            this.notifications = values
        },
        get_all_notifications_count: function(value) {
            if(value == 0) {
                this.notifications = []
            }
        }
    }
}
</script>

<style scoped>


.notification_button{
    border: solid 1px rgb(179, 177, 177);
    background-color: transparent;
    color: rgb(114, 112, 112);
    border-radius: 3px;
}

.notification_button:hover{
    background-color: rgb(227, 227, 227);
    color: rgb(80, 79, 79);
}

.notification_icon{
    text-align: right;
}

.single_alert{
    cursor: pointer;
}

@media (max-width: 700px) {

    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td:not(.mf-footer),
    #no-more-tables tr {
        display: block;
    }
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    #no-more-tables tr {
        border: 1px solid #ccc;
        height: auto;
    }
    #no-more-tables td:not(.mf-footer) {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
        white-space: normal;
        text-align: left;
    }
    #no-more-tables td:before {
        position: absolute;
        top: 8px;
        left: 5%;
        width: 45%;
        padding-right: 10px;
        white-space: normal;
        text-align: left;
        /* font-family: 'Poppins-Regular'; */
    }
    .data_table_design tfoot tr {
        display: flex;
        justify-content: center;
    }
    .data_table_design tfoot tr {
        display: block;
    }
    #no-more-tables td:before {
        content: attr(data-title);
    }
}

@media (min-width: 700px){

    .notification{
        width: 60%;
    }
    .notification_type{
        width: 12%;
        text-transform: capitalize;
    }
}

.tasklist{
    margin: 20px 10px;
    display: block;
    width: fit-content;
    overflow-y: auto
}

table tr td {
  /* text-align: center; */
}

</style>