<template>
  <div>
    <div class="d-flex">
      <div style="width: 250px">
        <h6 class="semi_bold_font" style="margin-left: 32px">Date</h6>
        <div class="input-group mb-3 justify-content-center">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><font-awesome-icon
                @click="getDates(current_week_no - 1)"
                class="ml-2"
                icon="chevron-left"
                size="15x"
              ></font-awesome-icon
            ></span>
          </div>
          <v-date-picker
            class="form-control date-selector"
            :masks="masks"
            v-model="date"
            :is-range="false"
            :columns="1"
          >
            <template v-slot="{ togglePopover }">
              <CalendarInput
                :isClearable="false"
                :value="getDateValue(date)"
                @clickInput="togglePopover()"
                @clear="getDateValue"
              />
            </template>
          </v-date-picker>
          <div class="input-group-append">
            <span class="input-group-text">
              <font-awesome-icon
                @click="getDates(current_week_no + 1)"
                class="ml-2"
                icon="chevron-right"
                size="15x"
              ></font-awesome-icon
            ></span>
          </div>
        </div>
      </div>
      <!-- <div class="ml-2" style="width: 250px">
        <h6 class="semi_bold_font">Assignee</h6>
        <v-select
          @input="apply_assignee_filter(filtered_users)"
          class="v-select-design w-100 multiple filter_select"
          :searchable="true"
          :multiple="true"
          v-model="filtered_users"
          :options="users"
          placeholder="Team members"
          label="full_name"
        >
        </v-select>
      </div> -->
    </div>

    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>User</th>
          <th v-for="(day, index) in current_week_days" :key="index">
            {{ day }}
          </th>
        </tr>
      </thead>
      <tbody v-if="users.length < 1">
        <tr>
          <td colspan="8">No Records here!</td>
        </tr>
      </tbody>
      <tbody
        v-if="users.length > 0"
        v-for="(user, index) in usersToDisplay"
        :key="index"
      >
        <tr>
          <td style="background-color: #ececec">
            {{ user.full_name }}
          </td>
          <td
            v-for="(day, index) in current_week_days"
            :key="index"
            @click="open_scrum_modal(user, day)"
          >
            <div class="scrum_content">
              <div v-html="getTaskDetail(user._id, 'worked_on', day)"></div>
            </div>
          </td>
        </tr>
        <!-- <tr>
          <td>Today</td>
          <td
            v-for="(day, index) in current_week_days"
            :key="index"
            @click="open_scrum_modal(user, day)"
          >
            <div class="scrum_content">
              {{ getTaskDetail(user._id, "today", day) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Blockers</td>
          <td
            v-for="(day, index) in current_week_days"
            :key="index"
            @click="open_scrum_modal(user, day)"
          >
            <div class="scrum_content">
              {{ getTaskDetail(user._id, "blockers", day) }}
            </div>
          </td>
        </tr> -->
      </tbody>
    </table>
    <b-modal
      size="lg"
      :title="`${selected_user.full_name}'s Tasks on ${scrum_modal_data.task_date}`"
      v-model="scrum_modal"
      @ok="updateScrumData()"
    >
      <div class="mt-2">
        <h4>Worked On</h4>
        <textarea
          class="form-control"
          id="exampleTextarea"
          rows="5"
          v-model="scrum_modal_data.worked_on"
          value="scrum_modal_data.worked_on"
        ></textarea>
      </div>
      <div class="mt-2">
        <h4>Today</h4>
        <textarea
          class="form-control"
          id="exampleTextarea"
          rows="5"
          v-model="scrum_modal_data.today"
          value="scrum_modal_data.today"
        ></textarea>
      </div>
      <div class="mt-2">
        <h4>Blockers</h4>
        <textarea
          class="form-control"
          id="exampleTextarea"
          rows="5"
          v-model="scrum_modal_data.blockers"
          value="scrum_modal_data.blockers"
        ></textarea>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import CalendarInput from "../CalendarInput.vue";
import VCalendar from "v-calendar";
import moment from "moment";
Vue.use(VCalendar);
export default {
  name: "ScrumDetails",
  components: {
    moment,
    CalendarInput,
  },
  data() {
    return {
      date: new Date(),
      users: [],
      scrumgroupid: null,
      masks: {
        title: "MMMM YYYY",
        weekdays: "WW",
        navMonths: "MMM",
        input: ["L", "DD.MM.YYY", "YYYY/MM/DD"],
        dayPopover: "WWW, MMM D, YYYY",
        data: ["L", "DD.MM.YYY", "YYYY/MM/DD"],
      },
      maxDate: new Date(),
      scrum_data: [],
      selected_user: {},
      scrum_modal: false,
      scrum_modal_data: {},
      current_week: new Date(),
      current_week_no: moment().week(),
      current_week_days: [],
      filter_by_user: [],
      filtered_users: [],
      member_options: [],
    };
  },
  computed: {
    usersToDisplay() {
      if (!this.filtered_users || this.filtered_users.length < 1) {
        return this.users;
      } else {
        return this.filtered_users;
      }
    },
  },
  mounted() {
    this.getUsers(this.$route.params.id);
    this.scrumgroupid =  this.$route.params.id;
    this.getDates(this.current_week_no);
    this.$store.commit("change_page", "scrum");
  },
  methods: {
    open_scrum_modal(user, date) {
      this.selected_user = user;
      let filter_by_user = this.scrum_data.find(
        (assignee) => assignee._id === user._id
      );
      if (filter_by_user) {
        let filter_by_date = filter_by_user.data.find((data) => {
          const task_date = moment(data.task_date);
          const item_date = moment(date, "DD-MM-YYYY");

          const task_date_format = task_date.format("YYYY-MM-DD");
          const item_date_format = item_date.format("YYYY-MM-DD");
          return task_date_format === item_date_format;
        });
        if (filter_by_date) {
          this.scrum_modal_data = {
            assigned_to: user._id,
            task_date: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            blockers: filter_by_date.blockers,
            worked_on: filter_by_date.worked_on,
            today: filter_by_date.today,
            scrumgroupid: this.scrumgroupid
          };
        } else {
          this.scrum_modal_data = {
            assigned_to: user._id,
            task_date: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            blockers: "",
            worked_on: "",
            today: "",
            scrumgroupid: this.scrumgroupid
          };
        }
      } else {
        this.scrum_modal_data = {
          assigned_to: user._id,
          task_date: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
          blockers: "",
          worked_on: "",
          today: "",
          scrumgroupid: this.scrumgroupid
        };
      }
      this.scrum_modal = true;
    },
    getDates(weekNo, year) {
      let startOfWeek;
      if (year)
        startOfWeek = moment()
          .isoWeekYear(year)
          .week(weekNo)
          .startOf("isoWeek");
      else startOfWeek = moment().week(weekNo).startOf("isoWeek");
      const endOfWeek = startOfWeek.clone().endOf("week");
      const currentWeekDates = [];
      let currentDay = startOfWeek.clone();

      while (currentDay.isSameOrBefore(endOfWeek, "day")) {
        currentWeekDates.push(currentDay.format("DD-MM-YYYY"));
        currentDay.add(1, "day");
      }
      this.date = startOfWeek.format("YYYY-MM-DD");
      this.current_week_days = currentWeekDates;
      this.current_week_no = weekNo;
      this.getScrumData(currentWeekDates);
    },
    getDateValue(date) {
      if (date) {
        let picked_date = moment(date);
        this.getDates(picked_date.week(), picked_date.year());
        const startOfWeek = moment(picked_date).startOf("isoWeek");

        const endOfWeek = startOfWeek.clone().endOf("week");

        return `${startOfWeek.format("DD-MM-YYYY")} - ${endOfWeek.format(
          "DD-MM-YYYY"
        )}`;
      } else {
        return `${this.current_week_days[0]} - ${
          this.current_week_days[this.current_week_days.length - 1]
        }`;
      }
    },
    getScrumData(currentWeekDates) {
      const startDate = moment(currentWeekDates[0], "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      const endDate = moment(
        currentWeekDates[currentWeekDates.length - 1],
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");

      const params = {
        startDate: startDate,
        endDate: endDate,
      };
      this.$http
        .get(this.$store.state.api_url + "scrum", { params })
        .then(function (data) {
          var response = data.body;
          this.scrum_data = response.data.map(user => {
            const filteredTasks = user.data.filter(task => task.scrumgroupid == this.$route.params.id || !task.scrumgroupid);
            return {
              ...user,
              data: filteredTasks
            }
          });
        });
    },

    updateScrumData() {
      const params = this.scrum_modal_data;
      this.$http
        .post(this.$store.state.api_url + "scrum", { params })
        .then(function (data) {
          var response = data.body;
          this.scrum_data = response.data;
          this.getScrumData(this.current_week_days);
        });
    },
    getTaskDetail(user, status, date) {
      let filter_by_user = this.scrum_data.find(
        (assignee) => assignee._id === user
      );
      if (filter_by_user) {
        let filter_by_date = filter_by_user.data.find((data) => {
          const task_date = moment(data.task_date);
          const item_date = moment(date, "DD-MM-YYYY");

          const task_date_format = task_date.format("YYYY-MM-DD");
          const item_date_format = item_date.format("YYYY-MM-DD");
          return task_date_format === item_date_format;
        });

        if (filter_by_date)
          return `<div>
          <label class="semi_bold_font" >Worked_on:</label>
          <div>${filter_by_date.worked_on}</div>
          <label class="semi_bold_font" >Today:</label>
          <div>${filter_by_date.today}</div>
          <label class="semi_bold_font" >Blockers:</label>
          <div>${filter_by_date.blockers}</div>
          </div>`;
        else return "";
      } else return "";
    },
    getUsers(scrumgroupid) {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers/" + scrumgroupid)
        .then(function (data) {
          var response = data.body;
          this.users = response.data;
          this.$store.commit("set_group_page_title", response.scrumgroupname);
        });
    },
    apply_assignee_filter(value) {
      this.filter_by_user = [];
      this.filtered_users = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_user.push(element);
          this.filtered_users.push(value[index]);
        }
      } else {
        this.filter_by_user = [];
        this.filtered_users = [];
      }
    },
  },
};
</script>

<style scoped>
.input-block .date-input {
  background: transparent !important;
}

.input-group .form-control {
  line-height: 1.5;
  height: 48px;
  border-radius: 20px;
}
.date-input {
  background-color: "red" !important;
}
.date-selector {
  margin: 0px 10px;
  padding: 0px;
}
.input-group-text {
  padding: 0.25rem 0.2rem;
  font-size: 0.875rem;
  background-color: white;
  border: 0px;
  cursor: pointer;
}
.input-group {
  align-items: center !important;
}
.input-group-append {
  margin-left: -8px;
  z-index: 2;
}

.scrum_content {
  width: 150px;
  max-height: 150px;
  overflow-y: auto;
}
</style>
