<template>
  <div>
    <div class="scrolling-wrapper">
      <div class="row flex-nowrap mt-1 ml-3">
        <TaskList
          v-for="(listItem, index) in lists"
          :key="index"
          :list="listItem"
          :filter_by_assignee="filter_by_assignee"
          :start_date="start_date"
          :end_date="end_date"
          :handleRemoveStatus="handleRemoveStatus"
        ></TaskList>

        <div class="list-column add-width">
          <div class="heading">
            <label class="heading-text text-center">+ Add Status</label>
            <div class="input-group d-flex">
              <v-select
                class="v-select-design filter_select flex-grow-1"
                v-model="selected_status"
                :options="add_filter_status"
                placeholder="Select Status"
                label="text"
              >
              </v-select>
              <div class="input-group-append" v-if="selected_status">
                <button
                  class="btn btn-primary w-100"
                  type="button"
                  @click="handleAddStatus"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TaskList from "@/components/Lists/TaskList";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
import { StatusProps } from "../types/statusTypes";

export default {
  name: "BoardPage",
  props: ["filter_by_assignee", "start_date", "end_date"],
  data() {
    return {
      projectName: "",
      status_props: StatusProps,
      add_filter_status: [], //'new','Completed','rot','pot','fot'
      remove_filter_status: [], // 'todo', 'in_progress', 'dev_completed'
      selected_status: null,
    };
  },
  components: {
    TaskList,
    draggable,
  },
  computed: {
    ...mapGetters({
      boards: "allBoards",
      isLoading: "isLoading",
    }),
    param() {
      return this.$route.params.id;
    },
    lists: {
      get() {
        return this.boards;
      },
      async set(value) {
        await this.reorderTaskLists({
          boardId: this.param,
          lists: value,
        });
      },
    },
  },
  methods: {
    ...mapActions({
      reorderTaskLists: "reorderTaskLists",
    }),
    async intialCreate() {
      let project_Id = this.$route.params.id;
      let local = localStorage.getItem("status_names");
      let namesArray = JSON.parse(local);
      this.remove_filter_status = namesArray;
      let payload = {
        project_Id,
        names: namesArray.map((item) => item.value),
        limit: 50,
      };
      this.$store.dispatch("fetchData", payload);
      const remaining_status = localStorage.getItem("status_remaining");
      let listArray = JSON.parse(remaining_status);
      this.add_filter_status = listArray;
    },
    handleAddStatus() {
      let listnames = localStorage.getItem("status_names");
      let listArray = new Set(JSON.parse(listnames));
      listArray.add(this.selected_status);
      listArray = JSON.stringify(Array.from(listArray));
      localStorage.setItem("status_names", listArray);
      const remaining_status = localStorage.getItem("status_remaining");
      let remaining_status_array = JSON.parse(remaining_status);
      remaining_status_array.filter(
        (item) => item.value !== this.selected_status.value
      );
      remaining_status_array = JSON.stringify(remaining_status_array);
      this.add_filter_status = remaining_status_array;
      // localStorage.setItem("status_remaining", remaining_status_array);
      this.selected_status = null;
      this.intialCreate();
    },
    handleRemoveStatus(status) {
      let listnames = localStorage.getItem("status_remaining");
      let listArray = new Set(JSON.parse(listnames));
      listArray.add({ text: this.status_props[status].name, value: status });
      listArray = JSON.stringify(Array.from(listArray));
      localStorage.setItem("status_remaining", listArray);
      const listed_status = localStorage.getItem("status_names");
      let listed_status_array = JSON.parse(listed_status);
      listed_status_array = listed_status_array.filter(
        (item) => item.value !== status
      );
      listed_status_array = JSON.stringify(listed_status_array);
      this.remove_filter_status = listed_status_array;

      localStorage.setItem("status_names", listed_status_array);
      this.$store.state.boards = [];
      this.intialCreate();
    },
  },
  mounted() {
    this.intialCreate();
  },
};
</script>

<style scoped>
.input-group {
  max-width: 250px;
}
.project-name {
  font-size: 40px;
  padding-left: 2rem;
}

.heading {
  padding: 5px;
  margin-bottom: 30px;
  border: 3px solid;
  border-radius: 8px 8px 0px 0px;
  background-color: #fefefe;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-color: #999797;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.heading-text {
  font-size: 17px;
  padding: 5px;
  font-weight: bold;
}

.is-invalid {
  border-color: red;
}

.add-width {
  width: 250px;
  transition: max-height 0.3s ease;
  margin: 10px;
}
</style>
