<template id="scoreCardForAllTaskReport">
  <div class="row scorecard-container">
    <div
      class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2"
      style="background-color: #ff980e; cursor: default;"
    >
      <h5>Estimated Hours</h5>
      <h2>{{ scoreCard_data.totalEstimatedHours }}</h2>
    </div>

    <div
      class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2"
      style="background-color: #91B35B; cursor: default;"
    >
      <h5>Actual Hours</h5>
      <h2>{{ scoreCard_data.totalActualHours }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreCardForAllTaskReport",

  props: {
  },
  data() {
    return {
        scoreCard_data: {
            totalActualHours: 0,
            totalEstimatedHours: 0
        },
    }
  },
  methods: {
    get_scorecard_data() {

        const filters = JSON.parse(localStorage.getItem("allTasksReport_filters"));
      this.$http
        .get(this.$store.state.api_url + "scorecardfortimes", { params: { sprint: filters.filtered_sprints, project_id: filters.filter_by_project, assigned_to: filters.filter_by_assignee } })
        .then(function (response) {
          this.scoreCard_data = response.body.scoreCard_data;
          if((this.scoreCard_data.totalEstimatedHours && this.scoreCard_data.totalActualHours)) {
            this.scoreCard_data.totalEstimatedHours = parseFloat(this.scoreCard_data.totalEstimatedHours).toFixed(2);
          this.scoreCard_data.totalActualHours = parseFloat(this.scoreCard_data.totalActualHours).toFixed(2);
          }
          if(response.body.scoreCard_data.totalEstimatedHours == 0){
          this.scoreCard_data.totalEstimatedHours = 0;
          } 
          if(response.body.scoreCard_data.totalActualHours == 0) {
          this.scoreCard_data.totalActualHours = 0
          }

        }).catch((err) => {
            console.log(err);
        })
    },
  },
  mounted() {
    this.get_scorecard_data();
    this.$eventHub.$on("refresh_alltask_report_card", this.get_scorecard_data);
  },
  watch: {},
};
</script>

<style scoped>
.scorecard-container.row {
  margin-left: 8px !important;
}
.scorecard {
  display: flex;
  cursor: pointer;
  color: white !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  font-size: 24px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #ebebeb;
}
.score_count {
  font-size: 20px;
}
</style>
