<template>
  <div>
    <div class="col-12 row m-0">
      <button
        v-b-tooltip.hover
        title="Add User"
        class="btn btn primary-bg ml-auto outline-none pointer font-1 img-high-small rounded-circle text-white d-flex align-items-center justify-content-center"
        @click="adduser"
      >
        <font-awesome-icon icon="plus" size="md"></font-awesome-icon>
      </button>
    </div>
    <div class="tasklist row w-100 m-0">
      <div class="taskform col-12" id="no-more-tables">
        <table class="table table-striped user_list_table_design text-left">
          <thead class="table_header">
            <tr class="p-0 m-0 row align-items-center position-relative">
              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic employee_name"
              >
                Employee name
              </th>
              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic employee_email"
              >
                Email ID
              </th>
              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic employee_action"
              >
                Action
              </th>
            </tr>
          </thead>
          <ContentLoader v-if="!is_success">
            <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
            <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
            <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
          </ContentLoader>
          <tbody class="table-body" v-if="is_success">
            <tr
              class="p-0 row position-relative align-items-center rounded m-0"
              v-for="item in employee"
              :key="item._id"
            >
              <td data-title="Name" class="employee_name">
                {{ item.full_name }}
              </td>
              <td data-title="Email ID" class="employee_email">
                {{ item.emailid }}
              </td>
              <td data-title="Action" class="employee_action">
                <div
                  style="display: inline-flex"
                  class="bg-white px-2 py-1 rounded"
                >
                  <router-link
                    class="grey-text d-inline-block text-decoration-none py-0 px-2 fa-sm-custom border-right"
                    v-bind:to="{ name: 'AddUser', params: item }"
                    >Edit</router-link
                  >
                  <a
                    href="javascript:void(0)"
                    class="grey-text d-inline-block text-decoration-none py-0 px-2 fa-sm-custom"
                    @click="del_confirmation(item._id)"
                    >Delete</a
                  >
                </div>
              </td>
            </tr>
            <tr v-if="employee.length == 0">
              <td colspan="3">No Users here!</td>
            </tr>
            <b-modal v-model="del_modal" @ok="del_user()">
              <p class="my-4">Are you sure to delete user?</p>
            </b-modal>
          </tbody>
        </table>
      </div>
      <div class="mt-5 paginator">
        <ul style="display: flex" v-if="pages && pages.length > 1">
          <li class="page-item" v-bind:class="{ disabled: current === 1 }">
            <a class="page-link" @click="get_users(1)">
              <span aria-hidden="true">|&lt; </span>
              <span class="sr-only">Next</span>
            </a>
          </li>
          <li class="page-item" v-bind:class="{ disabled: current === 1 }">
            <a class="page-link" @click="get_users(current - 1)"> &lt; </a>
          </li>
          <li
            class="page-item"
            v-for="item in pages"
            :key="item.id"
            v-bind:class="{ active: current === item.id }"
          >
            <a class="page-link" @click="get_users(item.id)">{{ item.id }}</a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current === totalPages }"
          >
            <a class="page-link" @click="get_users(current + 1)">&gt;</a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current === totalPages }"
          >
            <a class="page-link" @click="get_users(totalPages)">
              <span aria-hidden="true"> &gt;| </span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  components: { ContentLoader },
  name: "Userlist",
  data() {
    return {
      del_modal: false,
      page: 1,
      current: 1,
      totalDocs: 0,
      totalPages: 0,
      startPage: 1,
      endPage: 1,
      pages: [],
      serial_number: "",
      employee: {},
      current_delete_id: "",
      is_success: false,
    };
  },
  methods: {
    del_confirmation: function (id) {
      this.del_modal = !this.del_modal;
      this.current_delete_id = id;
    },
    makeToast(variant = null) {
      this.$root.$bvToast.toast(`New employee added`, {
        title: "Success",
        autoHideDelay: 3000,
      });
    },
    adduser: function () {
      this.$router.push({ name: "AddUser" });
    },
    get_users: function (page) {
      let params = {
        pageno: page,
        size: 10,
        user_role: this.$store.state.user_detail.user_role,
      };
      this.$http
        .get(this.$store.state.api_url + "allusers", { params })
        .then(function (response) {
          this.is_success = true;
          response = response.body;
          this.data = response.data.docs;
          this.serial_number = response.data.pagingCounter;
          this.current = response.data.page;
          this.totalDocs = response.data.totalDocs;
          this.totalPages = response.data.totalPages;
          this.employee = this.data;
          if (this.totalPages <= 5) {
            this.startPage = 1;
            this.endPage = this.totalPages;
          } else {
            if (this.current <= 3) {
              this.startPage = 1;
              this.endPage = 5;
            } else if (this.current + 2 >= this.totalPages) {
              this.startPage = this.totalPages - 4;
              this.endPage = this.totalPages;
            } else {
              this.startPage = this.current - 2;
              this.endPage = this.current + 2;
            }
          }
          this.pages = Array.from(
            Array(this.endPage + 1 - this.startPage).keys()
          ).map((i) => ({
            id: this.startPage + i,
            name: "Item " + (this.startPage + i),
          }));
        });
    },
    del_user: function () {
      this.$http
        .post(this.$store.state.api_url + "delete_user", {
          _id: this.current_delete_id,
        })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.get_users(this.page);
          }
        });
    },
  },
  mounted() {
    this.get_users(this.page);
    this.$store.commit("change_page", "userlist");
    if (
      this.$store.state.user_detail.user_role != "admin" &&
      this.$store.state.user_detail.user_role != "super_admin"
    ) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>

<style>
.table_header {
  font-weight: bold;
}

@media (min-width: 700px) {
  table.user_list_table_design .employee_name {
    width: 40%;
  }

  table.user_list_table_design .employee_email {
    width: 40%;
  }

  table.user_list_table_design .employee_action {
    width: 20%;
  }
}

@media (max-width: 700px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td:not(.mf-footer),
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute !important;
    top: -9999px;
    left: -9999px;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
    height: auto;
  }
  #no-more-tables td:not(.mf-footer) {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 8px;
    left: 5%;
    width: 45%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    /* font-family: 'Poppins-Regular'; */
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

.route {
  color: #484848;
}

.route:hover {
  color: #484848;
  text-decoration: none;
}

.paginator {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.employee {
  display: flex;
  padding-top: 20px;
}

.page-item.active .page-link {
  background-color: rgb(54, 88, 143) !important;
  color: #ffffff;
}

.page-item {
  cursor: pointer;
}

.tasklist {
  display: block;
  width: fit-content;
}

.table-body:hover {
  cursor: default;
}

.router_links {
  color: #484848;
}

.router_links:hover {
  color: #484848;
  text-decoration: none;
}

.edit_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: rgb(114, 112, 112);
}

.del_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: rgb(114, 112, 112);
}

.page-item {
  margin: 0px;
  padding: 0px;
  border: none;
}
</style>
