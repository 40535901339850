<template>
  <div class="edit_page">
    <span
      v-if="!is_success"
      class="position-absolute t-0 r-0 bg-white l-0 mx-auto h-100 row align-items-center justify-content-center z-index-2"
      ><font-awesome-icon icon="spinner" size="lg" spin></font-awesome-icon
    ></span>
    <div class="col-12 row m-0 pt-3" v-if="is_success">
      <div class="col-lg-8">
        <div class="input-group mt-1 mb-4">
          <label for="task_name" class="grey-text p-0 text-left fa-15x"
            >Title</label
          >
          <div class="col-12 p-0">
            <input
              type="text"
              placeholder="Task Name"
              id="task_name"
              v-model="task.title"
              class="form-control px-3 py-3 h-auto rounded m-0"
            />
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="task_name" class="grey-text p-0 text-left fa-15x"
            >Labels</label
          >
          <div class="col-12 p-0">
            <b-form-tags
              v-model="task.labels"
              separator=",;"
              placeholder="Add Labels"
              class="mb-2 style-form"
            >
            </b-form-tags>
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="task_desc" class="grey-text p-0 text-left fa-15x"
            >Description</label
          >
          <div class="col-12 p-0">
            <!-- <input type="text" placeholder="Task Description" id="task_desc" v-model="task.description"  class="form-control"/> -->
            <quill-editor
              class="textarea_design position-relative overflow-hidden pb-1"
              v-model="task.description"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            >
              <div id="toolbar" slot="toolbar">
                <!-- Add a bold button -->
                <button class="ql-bold border rounded mr-1">Bold</button>
                <button class="ql-italic border rounded mr-1">Italic</button>
                <button class="ql-underline border rounded">Underline</button>
              </div>
            </quill-editor>
          </div>
        </div>
        <div class="input-group mt-1">
          <label for="task_desc" class="grey-text p-0 text-left fa-15x w-100"
            >Attach Reference</label
          >
          <label
            class="col-12 col-md-3 col-sm-6 p-0 mt-1"
            v-for="(task_image, index) in task_images"
            :key="task_image._id"
          >
            <img
              :src="task_image.image_url"
              class="images"
              @click="openGallery(index)"
            />
            <div class="circle-icon" @click="delete_image(task_image._id)">
              <font-awesome-icon
                class="overlay"
                icon="trash"
                size="sm"
              ></font-awesome-icon>
            </div>
          </label>
          <!-- <ul>
                        <li v-for="(image, index) in media" :key="index" style="display: inline-block" >
                            <img v-lazy="image.src || image.thumb" style="height: 100px" @click="openGallery(index)">
                            <div class="circle-icon" @click="delete_image(index)">
                                <font-awesome-icon class="overlay" icon="trash" size="sm"></font-awesome-icon>
                            </div>
                        </li>
                    </ul> -->
          <LightBox
            ref="lightbox"
            :showCaption="true"
            :showLightBox="false"
            :media="media"
          ></LightBox>
          <b-modal
            v-model="del_img_modal"
            @ok="del_image()"
            centered
            title="Delete Task Image"
          >
            <p class="my-4">Are you sure to delete this image?</p>
          </b-modal>
          <b-modal size="xl" id="modal-1" class="modal_div" v-model="modalShow">
            <b-carousel
              ref="myCarousel"
              id="carousel-1"
              :interval="0"
              controls
              indicators
              background="#ababab"
              style="text-shadow: 1px 1px 2px #333"
            >
              <b-carousel-slide
                class="popup-image"
                v-for="task_image in task_images"
                :key="task_image._id"
                :img-src="task_image.image_url"
              >
              </b-carousel-slide>
            </b-carousel>
          </b-modal>
          <label class="col-12 col-md-3 col-sm-6 p-1 mt-1">
            <div class="text-center my-4 hide_input m-auto p-0">
              <label for="images" class="p-0 upload text-center"></label>
              <div class="col-12 p-0">
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  multiple
                  id="images"
                  ref="files"
                  @change="uploadimage"
                />
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-group mt-1 mb-4">
          <label for="project" class="grey-text p-0 text-left fa-15x w-100"
            >Project
          </label>
          <div class="col-12 p-0">
            <v-select
              @input="change_project(task.project_name)"
              class="v-select-design"
              :searchable="true"
              :multiple="false"
              v-model="task.project_name"
              :options="project_options"
              placeholder="Project"
              label="name"
            >
            </v-select>
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="assigned_to" class="grey-text p-0 text-left fa-15x w-100"
            >Sprint
          </label>
          <div class="col-12 p-0">
            <v-select
              class="v-select-design"
              :searchable="true"
              :multiple="true"
              v-model="selected_sprint"
              :options="sprint_options"
              label="sprint_name"
              placeholder="Select a sprint"
            >
            </v-select>
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="assigned_to" class="grey-text p-0 text-left fa-15x w-100"
            >Assigned To
          </label>
          <div class="col-12 p-0">
            <!-- <v-selectize :options="options" v-model="task.assigned_to_detail" placeholder="Project team member" key-by="full_name" label="full_name" keys="['full_name', 'emailid']">
                            <template slot="item" slot-scope="{item}">{{item.full_name}} | {{item.emailid}}</template>
                            <template slot="option" slot-scope="{option}">
                                <label class="text-left">{{ option.full_name }}</label> <br />
                                <span class="text-left">{{ option.emailid }}</span>
                            </template>
                        </v-selectize> -->
            <v-select
              class="v-select-design"
              :searchable="true"
              :multiple="false"
              v-model="task.assigned_to_detail"
              :options="member_options"
              placeholder="Project team member"
              label="full_name"
            >
            </v-select>
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="estimated_hours" class="grey-text p-0 text-right fa-15x"
            >Estimated Hours</label
          >
          <div class="col-12 p-0">
            <input
              type="number"
              placeholder="HH.MM"
              id="estimated_hours"
              v-model="task.estimated_hours"
              class="form-control px-3 py-3 h-auto rounded m-0"
            />
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="actual_hours" class="grey-text p-0 text-right fa-15x"
            >Actual Hours</label
          >
          <div class="col-12 p-0">
            <input
              type="number"
              placeholder="HH.MM"
              id="actual_hours"
              v-model="task.actual_hours"
              class="form-control px-3 py-3 h-auto rounded m-0"
            />
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="due_date" class="grey-text p-0 text-left fa-15x w-100"
            >Due Date</label
          >
          <div class="col-12 p-0">
            <Datepicker
              class="datepicker_design"
              :use-utc="true"
              :bootstrap-styling="true"
              id="due_date"
              placeholder="DD MM YYYY"
              v-model="task.due_date"
            />
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="status" class="grey-text p-0 text-left fa-15x w-100"
            >Status</label
          >
          <div class="col-12 p-0">
            <!-- <v-selectize :options="status" v-model="task.status" placeholder="Status of Task"/> -->
            <v-select
              class="v-select-design"
              :searchable="true"
              :multiple="false"
              v-model="task.status"
              :options="status"
              placeholder="Status of Task"
              label="text"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="text-center my-4 col-12 pt-4 z-depth-top">
        <button
          class="primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded-pill text-white bold_font"
          type="submit"
          v-bind:class="{ disabled: is_btn_loading }"
          @click="save_task"
        >
          Save
          <span class="ml-2" v-if="is_btn_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
        </button>
        <button
          style="margin-left: 10px"
          class="btn border rounded-pill py-2 px-4"
          type="submit"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import debounce from "lodash.debounce";
import vSelect from "vue-select";
// import imageCompressor from 'vue-image-compressor'
import Compress from "compress.js";
import LightBox from "vue-image-lightbox";
import { StatusProps } from "../types/statusTypes";

export default {
  name: "AddTask",
  components: {
    Datepicker,
    vSelect,
    // imageCompressor,
    Compress,
    LightBox,
  },
  data() {
    return {
      del_img_modal: false,
      slide: 0,
      text: "",
      selected: null,
      project_options: [],
      member_options: [],
      sprint_options: [],
      task: {},
      employee: {},
      status: [
        { text: StatusProps["new"].name, value: "new" },
        { text: StatusProps["todo"].name, value: "todo" },
        { text: StatusProps["in_progress"].name, value: "in_progress" },
        { text: StatusProps["on_hold"].name, value: "on_hold" },
        { text: StatusProps["dev_completed"].name, value: "dev_completed" },
        { text: StatusProps["rot"].name, value: "rot" },
        { text: StatusProps["pot"].name, value: "pot" },
        { text: StatusProps["fot"].name, value: "fot" },
        { text: StatusProps["completed"].name, value: "completed" },
        { text: StatusProps["released"].name, value: "released" },
        { text: StatusProps["closed"].name, value: "closed" },
      ],
      files: "",
      task_images: "",
      modalShow: false,
      signed_in_user: "",
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      is_btn_loading: false,
      current_delete_id: "",
      is_success: false,
      media: [],
      selected_sprint: null,
    };
  },
  mounted() {
    this.$store.commit("change_page", "project");
    this.fetchtask();
  },
  methods: {
    change_project(value) {
      this.member_options = value.members;
     // this.sprint_options = value.sprint;
      this.task.assigned_to_detail = "";
      //this.selected_sprint = null;
      this.task.project_id = value._id;
      this.task.project_name = value.name;
      this.task.estimated_hours = value.estimated_hours;
      this.task.actual_hours = value.actual_hours;
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    onEditorBlur(editor) {},
    onEditorFocus(editor) {},
    onEditorReady(editor) {},
    delete_image(id) {
      this.current_delete_id = id;
      this.del_img_modal = !this.del_img_modal;
    },
    showmodal: function (index) {
      // this.$refs.setSlide(index);
      this.modalShow = !this.modalShow;
    },
    del_image() {
      let id = this.current_delete_id;
      let obj = this.task_images.find((o) => o.id === id);
      var index = this.task_images.indexOf(obj);
      this.task_images.splice(index, 1);
      this.$http
        .post(this.$store.state.api_url + "delete_image", { _id: id })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.$bvToast.toast(`Image deleted`, {
              title: "Success",
              variant: "success",
              autoHideDelay: 3000,
            });
          }
        });
    },
    uploadimage: function (evt) {
      let selected = evt.target.files;
      let accepted_files = [];
      let declined_files = [];
      for (var i = 0; i < selected.length; i++) {
        let file = selected[i];
        if (
          file.name.split(".").pop() == "jpeg" ||
          file.name.split(".").pop() == "jpg" ||
          file.name.split(".").pop() == "JPG" ||
          file.name.split(".").pop() == "png"
        ) {
          accepted_files.push(file);
        } else {
          declined_files.push(file);
        }
      }
      // if(declined_files.length){
      //     console.log('Came in')
      //     var file_names = ''
      //     for(var i=0;i<declined_files.length;i++){
      //         console.log(declined_files[i].name)
      //         file_names+= declined_files[i].name
      //         if(declined_files.length>1 && declined_files.length > i)
      //         {
      //             file_names+= ", "
      //         }
      //     }
      //     file_names+= " are not of the suitable file format."
      //     console.log(file_names)
      // }
      const compress = new Compress();
      var files = accepted_files;
      compress
        .compress(files, {
          size: 5, // the max size in MB, defaults to 2MB
          quality: 0.75, // the quality of the image, max is 1,
          maxWidth: 720, // the max width of the output image, defaults to 1920px
          maxHeight: 640, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          let compressed_img = data;
          let formData = new FormData();
          var is_img = [];
          formData.append("parent_id", this.task._id);
          formData.append("type", "task");
          for (var i = 0; i < compressed_img.length; i++) {
            let file = compressed_img[i];
            var adding_obj = compressed_img[i].alt;
            formData.append("file", JSON.stringify(compressed_img[i]));
          }
          this.$http
            .post(this.$store.state.api_url + "taskimg", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (data) {
              this.task_images = data.body.data.images;
              this.media.splice(0, this.media.length);
              for (var i = 0; i < this.task_images.length; i++) {
                let img = {};
                img.thumb = this.task_images[i].image_url;
                img.src = this.task_images[i].image_url;
                img.caption = this.task_images[i].image_name;
                this.media.push(img);
              }
            });
        });
    },
    makeToast(variant = null) {
      this.$root.$bvToast.toast(`Task details updated`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    fetchsprints() {
      this.$http.get(this.$store.state.api_url + "activesprints/").then((response) => {
        this.sprint_options = response.body.data
      }).catch((err) => {
        console.log(err);
      })
    },
    fetchtask() {
      var itemId = this.$route.params.id;
      this.$http
        .get(this.$store.state.api_url + "task/" + itemId)
        .then(function (response) {
          this.is_success = true;
          response = response.body.data;
          this.task = response;
          this.selected_sprint = response.sprint;
          this.selected_sprint = this.sprint_options.filter(obj => this.selected_sprint.includes(obj.sprint_name));
          this.$store.commit("set_project_detail", this.task.project_id);
          this.selected = response.assigned_to_detail;
          this.task_images = response.task_images;
          this.media.splice(0, this.media.length);
          for (var i = 0; i < this.task_images.length; i++) {
            let img = {};
            img.thumb = this.task_images[i].image_url;
            img.src = this.task_images[i].image_url;
            img.caption = this.task_images[i].image_name;
            this.media.push(img);
          }
          let params = {
            id: this.$store.state.user_detail._id,
            role: this.$store.state.user_detail.user_role,
          };
          this.$http
            .get(this.$store.state.api_url + "check_manager", { params })
            .then(function (response) {
              if (response.data.data.length > 0) {
                this.project_options = response.data.data;
                if (this.task.project_id == undefined) {
                  this.task.project_id = this.project_options[0]._id;
                  this.task.project_name = this.project_options[0].name;
                  this.member_options = this.project_options[0].members;
                 // this.sprint_options = this.project_options[0].sprint;
                }
              }
            });
          if (response.project_id != undefined) {
            this.task.project_name = this.task.project_id.name;
            this.member_options = response.project_id.members;
           // this.sprint_options = response.project_id.sprint;
            if (
              this.$store.state.user_detail.user_role != "admin" &&
              this.$store.state.user_detail.user_role != "super_admin" &&
              this.$store.state.user_detail._id != this.task.project_id.manager
            ) {
              this.$router.push(this.$router.push({ name: "Home" }));
            }
          }
        });
    },
    save_task: function () {
      this.task.sprint = this.selected_sprint;
      var err = "";
      this.task.estimated_hours = (this.task.estimated_hours || "").trim();
      this.task.actual_hours = (this.task.actual_hours || "").trim();
      if (this.task.title == "" || typeof this.task.title == "undefined") {
        err = "Please fill title!";
      }

      if (this.task.project_name == "" || typeof this.task.project_name == "undefined") {
        err = "Please select project!";
      }

      if (
        this.task.assigned_to_detail == "" ||
        typeof this.task.assigned_to_detail == "undefined" ||
        this.task.assigned_to_detail == null
      ) {
        err = "Please select a team member to assign task";
      }

      if (err == "") {
        if (typeof this.task.status === "object") {
          this.task.status = this.task.status.value;
        }

        this.is_btn_loading = true;
        this.task.assigned_to = this.task.assigned_to_detail._id;
        this.$http
          .post(this.$store.state.api_url + "edittask", this.task)
          .then(function (data) {
            this.is_btn_loading = false;
            var response = data.body;
            if (response.status === true) {
              let details = response.data;
              this.makeToast("success");
              if (
                this.signed_in_user._id == details.project_id.manager &&
                this.signed_in_user._id != this.task.assigned_to
              ) {
                let params = {
                  user_id: this.task.assigned_to,
                  task_id: this.$route.params.id,
                  type: "task",
                  isread: "1",
                  message:
                    'You are assigned to "' +
                    details.title +
                    '" task in "' +
                    details.project_id.name +
                    '" project.',
                };
                this.$socket.emit("new_notification", params);
              } else if (this.signed_in_user.user_role == "admin") {
                let pm_params = {
                  user_id: this.task.assigned_to,
                  task_id: this.$route.params.id,
                  type: "task",
                  isread: "1",
                  message:
                    'You are assigned to "' +
                    details.title +
                    '" task in "' +
                    details.project_id.name +
                    '" project.',
                };
                let admin_params = {
                  user_id: details.project_id.manager,
                  task_id: this.$route.params.id,
                  type: "task",
                  isread: "1",
                  message:
                    this.task.assigned_to_detail.full_name +
                    ' is assigned to "' +
                    details.title +
                    '" task in "' +
                    details.project_id.name +
                    '" project by Admin ' +
                    this.signed_in_user.full_name +
                    ".",
                };
                this.$socket.emit("new_notification", admin_params);
                this.$socket.emit("new_notification", pm_params);
              }
              this.$router.push("/project/list/" + details.project_id._id);
            } else {
              this.$root.$bvToast.toast(`Error while updating task details`, {
                title: "Error",
                variant: danger,
                autoHideDelay: 3000,
              });
            }
          });
      } else {
        this.$bvToast.toast(err, {
          title: "Error",
          autoHideDelay: 1000,
          variant: "danger",
        });
      }
    },
    del_task: function () {
      let id = this.$route.params.id;
      this.$http
        .post(this.$store.state.api_url + "delete_task", { _id: id })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.$router.push("/project/list/" + this.task.project_id._id);
          }
        });
    },
    cancel: function () {
      if (this.task.project_id._id != undefined) {
        this.$router.push("/project/list/" + this.task.project_id._id);
      } else {
        this.$router.push("/");
      }
    },
  },
  created() {
    this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    this.fetchsprints();
    this.fetchtask();
  },
  watch: {
    "$route.params.id": function (id) {
      this.fetchtask();
    },
  },
};
</script>

<style>
.vs__dropdown-menu {
  font-size: 1rem !important;
}

.ql-toolbar.ql-snow {
  padding: 0.375rem 0.75rem !important;
}

.ql-container {
  margin-bottom: 8px !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.popup-image {
  height: 100%;
}

#modal-1 .modal-body {
  height: calc(100vh - 180px) !important;
}

#modal-1 .img-fluid {
  object-fit: contain !important;
  height: 100%;
}

#modal-1 .carousel.slide,
#modal-1 .carousel-inner {
  height: 100% !important;
}

.overlay {
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 9px;
  right: 0;
}

.circle-icon {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 10px;
  right: 0;
  background: #d4d4d4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  vertical-align: middle;
  opacity: 0.4;
}

.circle-icon:hover {
  opacity: 1;
  cursor: pointer;
  color: #000000;
}

.overlay:hover {
  color: black;
}

.images {
  width: 100%;
  object-fit: cover;
  height: 150px;
  padding: 5px;
}

.upload label {
  width: 100%;
  width: fit-content;
}

.upload label:hover {
  cursor: pointer;
}

.upload::before {
  content: "+";
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 40px;
  color: grey;
}

.upload {
  display: flex;
  border: 2px rgb(109, 109, 109) dashed;
  border-radius: 3px;
  height: 141px;
  width: 100%;
}

.upload:hover {
  background-color: #d4d4d4;
  cursor: pointer;
}

/* .selecter input{
    width: 100%;
}

.selectize-dropdown-content{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100px;
    -webkit-overflow-scrolling: touch;
} */

.selectize-dropdown .selectize-dropdown-content .create {
  display: none;
}

.hide_input input {
  display: none;
}
</style>
