export const TaskColumns = {
  project_code: "Project Code",
  labels: "Labels",
  record_status: "Record Status",
  project_id: "Project",
  status: "Status",
  created_at: "Created At",
  updated_at: "Updated At",
  ticket_no: "Ticket No",
  assigned_to: "Assigned To",
  description: "Description",
  due_date: "Due Date",
  sprint: "Sprint",
  title: "Task Name",
  estimated_hours: "Estimated Hours",
  actual_hours: "Actual Hours"
};
