<template>
  <div class="newform col-12 p-0">
    <div class="newsub col-12 p-0">
      <div class="col-12 row mx-0">
        <div class="col-lg-8">
          <div class="input-group col-12 mb-4">
            <label for="scrumgroupname" class="grey-text col-12 p-0 text-left fa-15x">Scrum Group Name</label>
            <div class="col-12 p-0">
                <input type="text" v-model="scrumgroup.name" placeholder="Scrum Group Name" id="scrumgroupname"  class="form-control px-3 py-3 h-auto rounded m-0"/>
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="scrummaster" class="grey-text col-12 p-0 text-left fa-15x">Scrum Master</label>
            <div class="col-12 p-0 mb-2">
              <v-select class="v-select-design" :searchable="true" :multiple="false" v-model="scrumgroup.scrummaster" :options="scrum_master" placeholder="Select Scrum Master" label="full_name" >
              </v-select>
            </div>
          </div>
           <div class="input-group col-12 mb-4">
            <label for="teammembers" class="grey-text col-12 p-0 text-left fa-15x">Team Members</label>
            <div class="col-12 p-0 mb-2">
              <v-select class="v-select-design" :searchable="true" :multiple="true" v-model="scrumgroup.teammembers" :options="team_members" placeholder="Select Team Members" label="full_name" >
              </v-select>
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="status" class="grey-text col-12 p-0 text-left fa-15x">Status</label>
            <div class="col-12 p-0 mb-2">
              <v-select class="v-select-design" :searchable="true" :multiple="false" v-model="scrumgroup.status" :options="['Active', 'In Active']" placeholder="Select Status" label="status" >
              </v-select>
            </div>
          </div>
        </div>
        <div class="text-center my-4 col-12 pt-4 z-depth-top">
          <button class="primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded-pill text-white bold_font" type="submit" v-bind:class="{ disabled: is_btn_loading }"  @click="newscrumgroup">Add Scrum Group
            <span class="ml-2" v-if="is_btn_loading">
              <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
            </span>
          </button>
          <button class="btn border rounded-pill py-2 px-4 ml-3" v-if="edit_page" type="button" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

export default {
    name: 'AddScrumGroup',
    components: {
        Datepicker,
        vSelect
    },
    data () {
        return {
          state: {},
          scrumgroup: {
            name: "",
            teammembers: [],
            scrummaster: [],
            status: ""
              },
          edit_page: false,
          is_btn_loading: false,
          team_members: [],
          scrum_master: [],
          editorOption: {
          modules: {
            toolbar: '#toolbar'
              }
          }
        }
    },
    methods: {
        onEditorBlur(editor) {
            
        },
        onEditorFocus(editor) {
            
        },
        onEditorReady(editor) {
            
        },
        makeToast(variant=null) {
            this.$root.$bvToast.toast(`Scrum group created`, {
                title: 'Success',
                variant: variant,
                autoHideDelay: 3000,
            })
        },
        newscrumgroup:function() {
          var err = ''
          if(this.scrumgroup.name == '' || typeof this.scrumgroup.name == "undefined" ){
              err = 'Please fill scrum group name'
          }else if (this.scrumgroup.teammembers == '' || typeof this.scrumgroup.teammembers == "undefined" || this.scrumgroup.teammembers.length == 0){
              err = 'Please select atleast one team member'
          }else if (this.scrumgroup.scrummaster == '' || typeof this.scrumgroup.scrummaster == "undefined" || this.scrumgroup.scrummaster == null){
              err = 'Please select scrum master'
          }else if (this.scrumgroup.status == '' || typeof this.scrumgroup.status == "undefined" ){
              err = 'Please select scrum group status'
          }
          if(err == ''){
            this.is_btn_loading = true;
            var finalTeamMembersValue = this.scrumgroup.teammembers.map(member => {
                return member.id;
             }); 
             var finalScrumMasterValue = this.scrumgroup.scrummaster;
             this.scrumgroup.teammembers = finalTeamMembersValue;
             this.scrumgroup.scrummaster = finalScrumMasterValue;
            this.scrumgroup.type=this.edit_page
            if(!this.edit_page) {
              this.scrumgroup.teammembers = finalTeamMembersValue;
              this.scrumgroup.scrummaster = finalScrumMasterValue;
            }
            this.$http.post(this.$store.state.api_url+'addscrumgroup',this.scrumgroup).then(function(data){
                this.is_btn_loading = false;
                var response = data.body;
                if(response.status === true){
                    if(!this.edit_page){
                        var details = response.data
                        this.makeToast('success')
                        this.$router.push({name: 'ScrumList'})
                    }else{
                        this.$root.$bvToast.toast(`Scrum group details updated`, {
                            title: 'Success',
                            autoHideDelay: 5000,
                            variant: 'success'
                        })
                        this.$router.push({name: 'ScrumList'})
                    }
                }else{
                  this.scrumgroup.teammembers = [];
                  this.scrumgroup.scrummaster = [];
                    this.$bvToast.toast(`Scrum group exists already`, {
                        title: 'Error',
                        autoHideDelay: 3000,
                        variant: 'danger'
                    })
                }
          })
          }else{
            this.$bvToast.toast(err, {
                  title: 'Error',
                  autoHideDelay: 3000,
                  variant: 'danger'
              })
          }
        },
        cancel:function(){
            this.$router.push({name: 'ScrumList'})
        },
        get_userlist:function(){
            this.$http.post(this.$store.state.api_url+'allprojectusers',{search: ''})
            .then(function(response){
                response = response.body;
                response = response.data
                let data = response;
                this.team_members = data;
                this.scrum_master = data;
            })
        },
    },
    mounted() {
      this.get_userlist();
      this.$store.commit("change_page", "Scrum List")
      if(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin' || this.$store.state.user_detail._id == this.$store.state.project.manager){}
      else {
       // this.$router.push({name:'ScrumList'})
      }
    },
    created(){
        if(typeof this.$route.params._id!="undefined")
        {
            this.edit_page = true
            this.scrumgroup=this.$route.params
        }
    }
}

</script>

<style>

.ql-toolbar.ql-snow{
  padding: 0.375rem 0.75rem !important;
}

.ql-container{
  margin-bottom: 8px !important;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow{
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

/* .cancel{
    background-color: rgb(248, 151, 151) !important;
}

.cancel:hover{
    background-color: rgb(243, 113, 113) !important;
} */

h2{
  margin-bottom: 20px;
  text-align: left;
}

.newform{
  height: 100vh;
}
input{
  font-size: 16px;
  border-radius: 3px;
  display: block;
  margin-bottom: 8px ;
  border: 2px;
  border-style: solid;
  border-color: #e0e6e8;
}

.vs__dropdown-menu{
  max-height: 200px !important;
}

.vs__dropdown-toggle{
  padding: 0px !important;
}

.vs__search{
  font-size: 1rem !important;
  color: #495057
}

.vs__search:focus{
  color: #495057
}

.vs__selected-options{
  font-size: 1rem !important;
}

.vs__selected{
      margin: 2px !important;
}

.vs__actions{
  padding: 2px 6px 0 3px;
}

.newsub{
  height: 100vh;
  margin: 20px 0px;
  float: right;
}

.vs__clear{
  margin-bottom: 4px !important;
}

</style>