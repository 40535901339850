import Vue from "vue";
import Router from "vue-router";
import Home from "../components/Home.vue";
import Signin from "../components/Signin.vue";
import MyTasks from "../components/MyTasks.vue";
import AddProject from "../components/AddProject.vue";
import AddUser from "../components/AddUser.vue";
import ProjectDisplay from "../components/ProjectDisplay.vue";
import AddTask from "../components/AddTask.vue";
import Userlist from "../components/Userlist.vue";
import TaskView from "../components/TaskView.vue";
import NoDataFound from "../components/NoDataFound.vue";
import TaskProxy from "../components/TaskProxy.vue";
import ChatPage from "../components/ChatPage.vue";
import GroupChat from "../components/GroupChat.vue";
import Notification from "../components/Notifications.vue";
import Calendar from "../components/Calendar.vue";
import Holidays from "../components/GovLeaves.vue";
import AddLeave from "../components/AddLeave.vue";
import ApplyLeave from "../components/ApplyLeave.vue";
import LeaveList from "../components/Leavelist.vue";
import AllTasks from "../components/AllTasks.vue";
import AllTasksReport from "../components/AllTasksReport.vue";
import Board from "../components/Board";
import InboxMessage from "../components/InboxMessage";
import ProjectTasksLists from "../components/ProjectTasksLists.vue";
import ProjectTasksBoard from "../components/ProjectTasksBoard.vue";
import ProjectSprints from "../components/ProjectSprints.vue";
import auth from "../middleware/auth";
import ScrumDetails from "../components/Scrum/index.vue";
import ScrumList from "../components/Scrum/ScrumList.vue";
import SprintList from "../components/Sprint/SprintList.vue";
import AddScrumGroup from "../components/Scrum/AddScrumGroup.vue";
import AddSprint from "../components/Sprint/AddSprint.vue";
import BackLogs from "../components/BackLogs.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/signin",
      name: "Signin",
      component: Signin,
    },
    {
      path: "/mytasks",
      name: "MyTasks",
      component: MyTasks,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/addproject",
      name: "AddProject",
      component: AddProject,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/adduser",
      name: "AddUser",
      component: AddUser,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/addleave",
      name: "AddLeave",
      component: AddLeave,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/applyleave",
      name: "ApplyLeave",
      component: ApplyLeave,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/project/:id",
      name: "ProjectDisplay",
      component: ProjectDisplay,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/project/list/:id",
      name: "ProjectTasksLists",
      component: ProjectTasksLists,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/project/board/:id",
      name: "ProjectTasksBoard",
      component: ProjectTasksBoard,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/project/sprints/:id",
      name: "ProjectSprints",
      component: ProjectSprints,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/scrum",
      name: "ScrumList",
      component: ScrumList,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/sprint",
      name: "SprintList",
      component: SprintList,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/sprint/:id",
      name: "AddSprint",
      component: AddSprint,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/addsprint",
      name: "AddSprint",
      component: AddSprint,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/scrum/:id",
      name: "ScrumDetails",
      component: ScrumDetails,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/addscrumgroup",
      name: "ScrumGroup",
      component: AddScrumGroup,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/task/:id",
      name: "AddTask",
      component: AddTask,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/viewtask/:id",
      name: "TaskView",
      component: TaskView,
      meta: {
        middleware: auth,
      }
    },
    {
      path: "/taskproxy/:ticket_no",
      name: "TaskProxy",
      component: TaskProxy,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/userlist",
      name: "Userlist",
      component: Userlist,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/chat/:id",
      name: "InboxMessage",
      component: InboxMessage,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/group/:id",
      name: "GroupChat",
      component: GroupChat,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/notification",
      name: "Notification",
      component: Notification,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        middleware: auth,
      },
      props: true,
    },
    {
      path: "/holidays",
      name: "Holidays",
      component: Holidays,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/leavelist",
      name: "LeaveList",
      component: LeaveList,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/alltasks",
      name: "AllTasks",
      component: AllTasks,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/backlogs",
      name: "BackLogs",
      component: BackLogs,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/alltasksreport",
      name: "AllTasksReport",
      component: AllTasksReport,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/nodatafound",
      name: "NoDataFound",
      component: NoDataFound,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/board/:id",
      name: "Board",
      component: Board,
      meta: {
        middleware: auth,
      },
    },
    {
      path: "/inbox",
      name: "InboxMessage",
      component: InboxMessage,
      meta: {
        middleware: auth,
      },
    },
  ],
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
