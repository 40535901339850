<template id="filterBar-template">
  <div>
    <MyTasksFilter
      key="my_tasks"
      v-if="filterName === 'my_tasks'"
      :closeFilters="closeFilters"
    />
    <AllTasksFilters
      key="all_tasks"
      v-if="filterName === 'all_tasks'"
      :closeFilters="closeFilters"
    />
    <BackLogsFilters
      key="back_logs"
      v-if="filterName === 'back_logs'"
      :closeFilters="closeFilters"
    />
    <AllTasksReportFilters
      key="all_tasks_report"
      v-if="filterName === 'all_tasks_report'"
      :closeFilters="closeFilters"
    />
    <ProjectTasksFilters
      key="project_tasks"
      v-if="filterName === 'project_tasks'"
      :closeFilters="closeFilters"
    />
    <SprintFilters
      key="sprints"
      v-if="filterName === 'sprints'"
      :closeFilters="closeFilters"
    />
  </div>
</template>

<script>
import MyTasksFilter from "./Filters/MyTaskFilters";
import AllTasksFilters from "./Filters/AllTaskFilters";
import ProjectTasksFilters from "./Filters/ProjectTaskFilters.vue";
import SprintFilters from "./Filters/SprintFilters.vue";
import AllTasksReportFilters from "./Filters/AllTasksReportFilters.vue";
import BackLogsFilters from "./Filters/BackLogsFilters.vue";
export default {
  name: "FilterSidebar",
  components: {
    SprintFilters,
    MyTasksFilter,
    AllTasksFilters,
    ProjectTasksFilters,
    AllTasksReportFilters,
    BackLogsFilters
  },
  props: ["closeFilters", "filterName"],
  data() {
    return {};
  },
  methods: {},
  created() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
  },
  mounted() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
  },
  watch: {
    filterName(newValue, oldValue) {
      this.filterName = newValue;
    },
  },
};
</script>
