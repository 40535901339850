<template>
  <div class="col-12 px-0 pt-7">
    <div class="toolbar d-flex flex-column row col-12 m-0 p-0">
      <div
        class="left_children col-12 p-0 px-md-3 col-md-6"
        v-if="
          $store.state.user_detail.user_role == 'admin' ||
          $store.state.user_detail.user_role == 'super_admin' ||
          $store.state.user_detail._id == this.$store.state.project.manager
        "
      ></div>

      <div class="d-flex flex-column">
        <ScoreCard
          :set_status="filter_status"
          :projectId="[projectId]"
          :user_filter="filter_by_assignee"
          :scorecard_filter="scorecard_filter"
        />

        <div class="filtered_items">
          <div
            v-if="searchtask !== '' && typeof searchtask !== 'undefined'"
            class="filter_chip"
          >
            Task Name: <b>{{ searchtask }}</b>
            <font-awesome-icon
              class="ml-2 clear-filter"
              @click="remove_filter('searchtask', searchtask)"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
          <div
            v-if="ticket_no !== '' && typeof ticket_no !== 'undefined'"
            class="filter_chip"
          >
            Ticket Number: <b>{{ ticket_no }}</b>
            <font-awesome-icon
              class="ml-2 clear-filter"
              @click="remove_filter('ticket_no', ticket_no)"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
          <div
            v-if="selected_members.length > 0"
            class="d-flex align-items-center"
          >
            <div v-for="(assignee, index) in selected_members" :key="index">
              <div v-if="isFiltered_assignee(assignee._id)" class="filter_chip">
                assignee:
                <b>{{ assignee.full_name }}</b>
                <font-awesome-icon
                  @click="remove_filter('filter_by_assignee', assignee._id)"
                  class="ml-2 clear-filter"
                  icon="times"
                  size="1x"
                ></font-awesome-icon>
              </div>
            </div>
          </div>
          <div
            v-if="
              selected_status.length > 0 &&
              typeof selected_status !== 'undefined'
            "
            class="d-flex align-items-center"
          >
            <div
              v-for="(status, index) in selected_status"
              :key="index"
              class="filter_chip"
            >
              Status:
              <b>{{
                status.name !== null && typeof status.name !== "undefined"
                  ? status.name
                  : status
              }}</b>
              <font-awesome-icon
                @click="remove_filter('selected_status', status)"
                class="ml-2 clear-filter"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </div>
          </div>

          <div
        v-if="
          filtered_sprints.length > 0 && typeof filtered_sprints !== 'undefined'
        "
        class="d-flex align-items-center"
      >
        <div
          v-for="(sprint, index) in filtered_sprints"
          :key="index"
          class="filter_chip"
        >
          Sprint:
          <b>{{ sprint.sprint_name }}</b>
          <font-awesome-icon
            @click="remove_filter('filtered_sprints', sprint)"
            class="ml-2 clear-filter"
            icon="times"
            size="1x"
          ></font-awesome-icon>
        </div>
      </div>

          <div
            v-if="
              filter_by_labels.length > 0 &&
              typeof filter_by_labels !== 'undefined'
            "
            class="d-flex align-items-center"
          >
            <div class="filter_chip">
              Label:
              <b>{{ filter_by_labels }}</b>
              <font-awesome-icon
                @click="remove_filter('filter_by_labels', filter_by_labels)"
                class="ml-2 clear-filter"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </div>
          </div>

          <div
            v-if="start_date !== '' && typeof start_date !== 'undefined'"
            class="filter_chip"
          >
            Due Date: <b>{{ start_date }} - {{ end_date }}</b>
            <font-awesome-icon
              @click="remove_filter('due_date', start_date)"
              class="ml-2 clear-filter"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="tasklist col-12 row w-100 px-md-3 p-0 mx-0">
      <div class="taskform col-12 px-md-3 p-0" id="no-more-tables">
        <span class="ml-2" v-if="is_loading">
          <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
        </span>
        <table
          v-if="!is_loading"
          class="table table-hover table_design table-striped"
        >
          <thead>
            <tr class="p-0 m-0 position-relative">
              <th
                class="project_tasks_flag text-left flag_status semi_bold_font grey-text font-weight-normal border-0 font-italic"
              >
                <input
                  type="checkbox"
                  :checked="isCheckedAll || checkedRow.length === project_tasks"
                  @click="(e) => onCheck(e, true, '')"
                />
              </th>

              <th
                v-if="visible_columns.includes('ticket_no')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_ticket_number"
              >
                Ticket Number
              </th>
              <th
                v-if="visible_columns.includes('title')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_tasks_name"
              >
                Task name
              </th>
              <th
                v-if="visible_columns.includes('assigned_to')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_assigned_to"
              >
                Assigned to
              </th>

              <th
                v-if="visible_columns.includes('project_id')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_project_name"
              >
                Project
              </th>
              <th
                v-if="visible_columns.includes('status')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_updated_at"
              >
                Status
              </th>
              <th
                v-if="visible_columns.includes('created_at')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_created_at"
              >
                Created Date
              </th>

              <th
                v-if="visible_columns.includes('updated_at')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_updated_at"
              >
                Modified At
              </th>
              <th
                v-if="visible_columns.includes('sprint')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_sprint"
              >
                Sprints
              </th>
              <th
                v-if="visible_columns.includes('labels')"
                class="semi_bold_font text-center grey-text font-weight-normal border-0 font-italic project_labels"
              >
                Labels
              </th>

              <th
                v-if="visible_columns.includes('due_date')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_due_date"
              >
                Due Date
              </th>
              <th
                v-if="visible_columns.includes('project_code')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_project_code"
              >
                Project Code
              </th>

              <th
                v-if="visible_columns.includes('description')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_description"
              >
                Description
              </th>
              <th
                  v-if="visible_columns.includes('estimated_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic estimated_hours"
                >
                  Estimated Hours
                </th>

                <th
                  v-if="visible_columns.includes('actual_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic actual_hours"
                >
                  Actual Hours
                </th>
              <th
                v-if="visible_columns.includes('record_status')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_updated_at"
              >
                Record Status
              </th>
            </tr>
          </thead>
          <tbody v-if="!is_success">
            <td colspan="5">
              <ContentLoader>
                <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
              </ContentLoader>
            </td>
          </tbody>
          <tbody class="table-body" v-if="is_success">
            <tr
              class="p-0 position-relative rounded m-0 table-body-row"
              v-for="item in this.project_tasks"
              :key="item._id"
              @click="goto_task(item.ticket_number)"
            >
              <td class="pl-3 text-left w-auto flag_status project_tasks_flag">
                <input
                  type="checkbox"
                  :checked="isCheckedRow(item._id)"
                  @click="(e) => onCheck(e, false, item._id)"
                />
              </td>

              <td
                v-if="visible_columns.includes('ticket_no')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_ticket_number"
              >
                {{ item.ticket_no }}
              </td>

              <td
                v-if="visible_columns.includes('title')"
                class="project_tasks_name"
                data-title="Task"
              >
                <span class="font-8 semi_bold_font">{{
                  item.ticket_number
                }}</span>
                <br />{{ item.title }}<span v-if="item.title == null"> - </span>
              </td>

              <td
                v-if="visible_columns.includes('assigned_to')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_assigned_to"
              >
                {{
                  item.assigned_to_detail !== null
                    ? item.assigned_to_detail.full_name
                    : "-"
                }}
              </td>
              <td
                v-if="visible_columns.includes('project_id')"
                class="project_project_name"
                data-title="Project"
              >
                {{ item.project_id !== null ? item.project_id.name : "-" }}
              </td>

              <td
                v-if="visible_columns.includes('status')"
                class="project_updated_at"
                data-title="status"
              >
                <span
                  :style="{ background: get_color(item.status.toLowerCase()) }"
                  class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ get_status_name(item.status.toLowerCase()) }}</span
                >
              </td>
              <td
                v-if="visible_columns.includes('created_at')"
                class="project_created_at fa-sm-custom created_at"
                data-title="created At"
              >
                {{ format_date(item.created_at) }}
              </td>
              <td
                v-if="visible_columns.includes('updated_at')"
                class="project_updated_at fa-sm-custom"
                data-title="Updated At"
              >
                {{ format_date(item.updated_at) }}
              </td>
              <td v-if="visible_columns.includes('sprint')"
                    class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_sprint">
                    <span v-if="typeof item.sprint === 'undefined' || item.sprint === null"></span>
                    <span v-else-if="item.sprint.length === 0"></span>
                    <span v-else-if="Array.isArray(item.sprint)">
                        {{ item.sprint.map((data, index) => {
                            return data;
                        }).join(", ") }}
                    </span>
                    <span v-else></span>
                </td>
              <td
                v-if="visible_columns.includes('labels')"
                class="semi_bold_font grey-text d-flex flex-column font-weight-normal border-0 font-italic project_labels"
              >
                <span
                  v-for="(label, index) in item.labels"
                  :key="index"
                  class="status_bg ml-1 text-black mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ label }},</span
                >
              </td>

              <td
                v-if="visible_columns.includes('due_date')"
                class="project_due_date fa-sm-custom due_date"
                data-title="Due Date"
              >
                {{ format_date(item.due_date) }}
              </td>
              <td
                v-if="visible_columns.includes('project_code')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_project_code"
              >
                {{ item.project_code }}
              </td>
              <td
                v-if="visible_columns.includes('description')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_description"
              >
                <span v-html="item.description"></span>
              </td>
              <td
                  v-if="visible_columns.includes('estimated_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic estimated_hours"
                >
                  {{ item.estimated_hours }}
              </td>
              <td
                  v-if="visible_columns.includes('actual_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic actual_hours"
                >
                  {{ item.actual_hours }}
              </td>
              <td
                v-if="visible_columns.includes('record_status')"
                class="project_updated_at"
                data-title="status"
              >
                <span
                  :class="item.record_status === 'active' ? 'pot' : 'fot'"
                  class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ item.record_status }}</span
                >
              </td>
            </tr>

            <tr v-if="project_tasks.length == 0">
              <td colspan="5">No Tasks here!</td>
            </tr>
          </tbody>
          <b-modal
            hide-header-close
            v-model="del_modal"
            @ok="del_task()"
            centered
            title="Delete Task"
          >
            <p class="my-4">Are you sure to delete this task?</p>
          </b-modal>
        </table>
      </div>
    </div>

    <div class="mt-3 row m-0 col-12" v-if="is_success">
      <ul
        class="pagination pagination_design ml-auto d-flex list-type-none"
        v-if="pages && pages.length > 1"
      >
        <li class="page-item" v-bind:class="{ disabled: current === 1 }">
          <a class="page-link" @click="get_tasks(1)">
            <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
          </a>
        </li>
        <li class="page-item" v-bind:class="{ disabled: current === 1 }">
          <a class="page-link" @click="get_tasks(current - 1)">
            <font-awesome-icon icon="angle-left"> </font-awesome-icon>
          </a>
        </li>
        <li
          class="page-item"
          v-for="item in pages"
          :key="item.id"
          v-bind:class="{ active: current === item.id }"
        >
          <a class="page-link" @click="get_tasks(item.id)">{{ item.id }}</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: current === totalPages }"
        >
          <a class="page-link" @click="get_tasks(current + 1)">
            <font-awesome-icon icon="angle-right"> </font-awesome-icon>
          </a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: current === totalPages }"
        >
          <a class="page-link" @click="get_tasks(totalPages)">
            <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import { ContentLoader } from "vue-content-loader";
import BoardPage from "@/components/BoardPage";
import ScoreCard from "@/components/ScoreCard.vue";
import { StatusProps } from "../types/statusTypes";

export default {
  name: "ProjectTasksLists",
  components: {
    vSelect,
    Datepicker,
    moment,
    ContentLoader,
    BoardPage,
    ScoreCard,
  },
  data() {
    return {
      current_delete_id: "",
      del_modal: false,
      sort_task: "",
      sort_date: "",
      status_props: StatusProps,
      filter_status: [
        "new",
        "todo",
        "dev_completed",
        "on_hold",
        "in_progress",
        "completed",
        "rot",
        "pot",
        "fot",
      ],
      start_date: "",
      end_date: "",
      filter_name: "",
      filter_sprint: "", //Filter v-model for sprint... Yet to work on
      filter_by_member: "",
      filter_by_sprint: [], //Variable sent in api
      defaultSelected: [],
      projectId: this.$route.params.id,
      project: "",
      assigned_to: "",
      assignedState: null,
      task: "",
      taskState: null,
      due_date: "",
      due_dateState: null,
      submittedNames: [],
      page: 1,
      current: 1,
      totalDocs: 0,
      totalPages: 0,
      startPage: 1,
      endPage: 1,
      pages: [],
      serial_number: "",
      project_tasks: [],
      sprint_modal: false,
      teammate_modal: false,
      member_options: [],
      selected_members: [],
      teammate: [],
      sprint_options: [],
      selected_status: null,
      filtered_sprints: [],
      is_loading: false,
      is_success: false,
      filter_by_assignee: [],
      showList: false,
      showBoard: false,
      searchtask: "",
      ticket_no: "",
      scoreCard_data: [],
      isCheckedAll: false,
      checkedRow: [],
      filter_by_labels: "",
      visible_columns: ["title", "assigned_to", "created_at", "status"],
    };
  },
  created() {
    this.$eventHub.$on("search_project_task", this.get_tasks);
    this.$eventHub.$on("project_task_table", this.refresh_table);
    this.get_tasks(1);
    this.get_userlist();
    this.fetchproject();
  },
  methods: {
    setActiveTab(tab) {
      localStorage.setItem("activeTab", tab);
      this.showList = tab === "list";
      this.showBoard = tab === "board";
    },
    change_board() {
      this.$router.push("/board/" + this.$route.params.id);
    },
    goto_task(ticket_no) {
      this.$router.push("/taskproxy/" + ticket_no);
    },
    del_confirmation: function (id) {
      this.del_modal = !this.del_modal;
      this.current_delete_id = id;
    },
    isFiltered(id) {
      return this.filter_by_project.includes(id) ? true : false;
    },

    isCheckedRow(id) {
      return this.isCheckedAll || this.checkedRow.includes(id) ? true : false;
    },
    onCheck(e, isAll, id) {
      e.stopPropagation();
      if (isAll) {
        if (this.isCheckedAll) {
          this.checkedRow = [];
        }
        this.isCheckedAll = !this.isCheckedAll;
        // let existingList = new Set(this.checkedRow);
        // existingList.delete(id)
        // this.checkedRow = Array.from(existingList);
      } else {
        let existingList = new Set(this.checkedRow);
        existingList.add(id);
        this.checkedRow = Array.from(existingList);
      }
    },
    isFiltered_assignee(id) {
      return this.filter_by_assignee.includes(id) ? true : false;
    },
    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].color;
      } else return "#000";
    },
    remove_filter(name, value) {
      switch (name) {
        case "searchtask":
          this.searchtask = "";
          this.update_local_storage_filters(name, "");
          this.get_tasks(this.page);
          break;

        case "ticket_no":
          this.ticket_no = "";
          this.update_local_storage_filters(name, "");
          this.get_tasks(this.page);
          break;
        case "due_date":
          this.start_date = "";
          this.end_date = "";
          this.update_local_storage_filters("start_date", "");
          this.update_local_storage_filters("end_date", "");
          this.get_tasks(this.page);
          break;
        case "selected_status":
          let new_status = this.selected_status.filter(
            (status) => status.value !== value.value
          );
          this.selected_status = new_status;
          this.update_local_storage_filters(name, new_status);
          this.get_tasks(this.page);
          break;
        case "filtered_sprints":
          let new_sprint = this.filtered_sprints.filter(
            (sprint) => sprint.sprint_name !== value.sprint_name
          );
          this.filtered_sprints = new_sprint;
          this.update_local_storage_filters(name, new_sprint);
          this.get_tasks(this.taskpage);
          break;
        case "filter_by_labels":
          this.filter_by_label = "";
          this.update_local_storage_filters(name, "");
          this.get_tasks(this.page);
          break;
        default:
          break;
      }
      this.$eventHub.$emit("refresh_projectTask");
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("projectTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "projectTasks_filters",
        JSON.stringify(existing_filters)
      );
    },
    reset_filters() {
      const existing_filters = {
        start_date: this.start_date,
        end_date: this.end_date,
        searchtask: this.searchtask,
        selected_status: this.selected_status,
        filtered_sprints: this.filtered_sprints,
        ticket_no: this.ticket_no,
        filter_by_assignee: this.filter_by_assignee,
        filtered_assignees: this.selected_members,
        filter_by_labels: this.filter_by_labels,
        filter_by_sprint: this.filter_by_sprint,
        projectTask_visible_columns: this.visible_columns,
      };

      localStorage.setItem(
        "projectTasks_filters",
        JSON.stringify(existing_filters)
      );
    },
    scorecard_filter: function (status) {
      this.reset_filters();
      this.selected_status = status;
      this.update_local_storage_filters("selected_status", [
        { name: this.get_status_name(status.toLowerCase()), value: status },
      ]);
      this.get_tasks(this.page);
      this.$eventHub.$emit("refresh_projectTask");
    },

    get_score: function (status) {
      const status_data = this.scoreCard_data.find(
        (item) => item._id === status
      );
      if (typeof status_data !== "undefined") return status_data.count;
      else return 0;
    },
    refresh_table: function () {
      let existing_filters = localStorage.getItem("projectTasks_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          ...existing_filters,
          projectTask_visible_columns: this.visible_columns,
        };
        localStorage.setItem(
          "projectTasks_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.visible_columns =
          existing_filters.projectTask_visible_columns || [];
      }
    },
    format_date_str(dateStr) {
      const parsedDate = moment(dateStr, "DD/MM/YYYY");
      const formattedDate = parsedDate.format("YYYY-MM-DD");

      return moment(formattedDate).isValid() ? formattedDate : "";
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    get_tasks: function (page) {
      this.is_loading = true;
      const filters = JSON.parse(localStorage.getItem("projectTasks_filters"));
      const sort = localStorage.getItem("project_sort");
      let params = {
        pageno: page,
        size: 7,
        projectId: this.projectId,
        user_filter: filters.filter_by_assignee,
        labels_filter: filters.filter_by_labels,
        start_filter:
          filters.start_date !== ""
            ? this.format_date_str(filters.start_date)
            : "",
        end_filter:
          filters.end_date !== "" ? this.format_date_str(filters.end_date) : "",
        task_sort: this.sort_task,
        date_sort: this.sort_date,
      };
      if (filters.selected_status) {
        params = {
          ...params,
          set_status: filters.selected_status
            ? filters.selected_status.length > 0
              ? filters.selected_status.map((status) => status.value)
              : []
            : [],
        };
      }
      if (filters.filtered_sprints) {
        params = {
          ...params,
          set_sprint:
            filters.filtered_sprints.length > 0
              ? filters.filtered_sprints.map((sprint) => sprint.sprint_name)
              : [],
        };
      }
      if (sort) {
        switch (sort) {
          case "Created High to Low":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: 1,
            };
            break;
          case "Created Low to High":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: -1,
            };
            break;
          case "DueDate High to Low":
            params = {
              ...params,
              sortBy: "due_date",
              sortOrder: 1,
            };
            break;
          case "DueDate Low to High":
            params = {
              ...params,
              sortBy: "due_date",
              sortOrder: -1,
            };
            break;
          default:
            break;
        }
      } else {
        params = {
          ...params,
          sortBy: "created_at",
          sortOrder: -1,
        };
      }
      this.$http
        .get(this.$store.state.api_url + "alltasks", { params })
        .then(function (response) {
          response = response.body;
          this.data = response.data.docs;
          this.serial_number = response.data.pagingCounter;
          this.current = response.data.page;
          this.totalDocs = response.data.totalDocs;
          this.totalPages = response.data.totalPages;
          this.scoreCard_data = response.scoreCard_data;
          this.project_tasks = response.data.docs;
          if (this.totalPages <= 5) {
            this.startPage = 1;
            this.endPage = this.totalPages;
          } else {
            if (this.current <= 3) {
              this.startPage = 1;
              this.endPage = 5;
            } else if (this.current + 2 >= this.totalPages) {
              this.startPage = this.totalPages - 4;
              this.endPage = this.totalPages;
            } else {
              this.startPage = this.current - 2;
              this.endPage = this.current + 2;
            }
          }
          this.is_loading = false;
          this.is_success = true;
          this.pages = Array.from(
            Array(this.endPage + 1 - this.startPage).keys()
          ).map((i) => ({
            id: this.startPage + i,
            name: "Item " + (this.startPage + i),
          }));
        });
      this.is_success = true;
      this.is_loading = false;
      this.get_local_storage_data();
    },

    add_teammate: function () {
      this.teammate = [];
      var user_name = [];
      for (var i = 0; i < this.selected_members.length; i++) {
        this.teammate.push(this.selected_members[i]._id);
        user_name[this.selected_members[i]._id] =
          this.selected_members[i].full_name;
      }
      var project_members = [];
      for (var i = 0; i < this.project.members.length; i++) {
        project_members.push(this.project.members[i]._id);
        user_name[this.project.members[i]._id] =
          this.project.members[i].full_name;
      }
      var removed_user = _.difference(project_members, this.teammate);
      var added_user = _.difference(this.teammate, project_members);
      var added_message = "";
      var removed_message = "";
      if (added_user.length) {
        for (var i = 0; i < added_user.length; i++) {
          added_message += user_name[added_user[i]];
          if (added_user.length > 1 && added_user.length >= i) {
            added_message += ", ";
          }
        }
        added_message += " newly added in project '" + this.project.name + "'.";
      }
      if (removed_user.length) {
        for (var i = 0; i < removed_user.length; i++) {
          removed_message += user_name[removed_user[i]];
          if (removed_user.length > 1 && i < removed_user.length - 1) {
            removed_message += ", ";
          }
        }
        removed_message += " removed from project '" + this.project.name + "'.";
      }
      var params = {};
      params["members"] = this.teammate;
      params["_id"] = this.project._id;
      params["type"] = true;
      params["name"] = this.project.name;
      this.$http
        .post(this.$store.state.api_url + "addproject", params)
        .then(function (data) {
          var whole = data.body;
          var data = whole.data;
          this.selected_members = data.members;
          var member_list = data.members;
          this.project.members = member_list;
          if (added_user.length) {
            for (var i = 0; i < added_user.length; i++) {
              let params = {
                user_id: added_user[i],
                project_id: data._id,
                type: "project",
                isread: "1",
                message: "You are added in " + data.name + " project.",
              };
              this.$socket.emit("new_notification", params);
            }
          }
          if (removed_user.length) {
            for (var i = 0; i < removed_user.length; i++) {
              let params = {
                user_id: removed_user[i],
                project_id: data._id,
                type: "project",
                isread: "1",
                message: "You are removed from " + data.name + " project.",
              };
              this.$socket.emit("new_notification", params);
            }
            // removed_message+= " removed from project '" + this.project.name + "'."
          }
          for (var i = 0; i < member_list.length; i++) {
            let params = {
              user_id: member_list[i],
              project_id: data._id,
              type: "project",
              isread: "1",
              message: added_message,
            };

            if (
              added_user.length &&
              added_user.indexOf(member_list[i] == "-1")
            ) {
              this.$socket.emit("new_notification", params);
            }
            params.message = removed_message;
            if (
              removed_user.length &&
              removed_user.indexOf(member_list[i] == "-1")
            ) {
              this.$socket.emit("new_notification", params);
            }
          }
        });
    },
    add_sprint: function () {
      var params = {};
      params["sprint"] = this.project.sprint;
      params["_id"] = this.project._id;
      params["type"] = true;
      params["name"] = this.project.name;
      this.$http
        .post(this.$store.state.api_url + "addproject", params)
        .then(function (res) {});
    },
    select_teammate: function () {
      this.teammate_modal = !this.teammate_modal;
    },
    get_userlist: function () {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers")
        .then(function (response) {
          response = response.body;
          response = response.data;
          let data = response;
          this.member_options = data;
        });
    },
    makeToast(variant = null) {
      this.$root.$bvToast.toast(`New employee added`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    add_task_page: function () {
      let params = {
        project_id: this.project._id,
        status: "in_progress",
        is_global: false,
      };
      this.$http
        .post(this.$store.state.api_url + "addtask/", { params })
        .then(function (response) {
          var result = response.data;
          var task = result.data;
          var task_id = task._id;
          this.$router.push("/task/" + task_id);
        });
    },
    del_project: function (id) {
      this.$http
        .post(this.$store.state.api_url + "delete_project", { _id: id })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.$router.push({ name: "Home" });
          }
        });
    },
    del_task: function () {
      this.$http
        .post(this.$store.state.api_url + "delete_task", {
          _id: this.current_delete_id,
        })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.get_tasks(this.page);
          }
        });
    },
    fetchproject() {
      let user_params = this.$store.state.user_detail._id;
      var itemId = this.$route.params.id;
      this.$http
        .post(this.$store.state.api_url + "project/" + itemId, { user_params })
        .then(async (response) => {
          response = response.body.data;
          this.project = response;
          this.$store.commit("set_project_detail", this.project);
          this.member_options = this.project.members;
          var obj = await this.project.members.find(
            (o) => o.id === this.$store.state.user_detail._id
          );
          if (
            this.$store.state.user_detail.user_role != "admin" &&
            this.$store.state.user_detail.user_role != "super_admin" &&
            this.$store.state.user_detail._id !=
              this.$store.state.project.manager &&
            obj == undefined
          ) {
            this.$router.push({ name: "Home" });
          }
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.assignedState = valid ? "valid" : "invalid";
      this.taskState = valid ? "valid" : "invalid";
      this.due_dateState = valid ? "valid" : "invalid";
      return valid;
    },
    resetModal() {
      this.assigned_to = "";
      this.assignedState = null;
      this.task = "";
      this.taskState = null;
      this.due_date = "";
      this.due_dateState = null;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    get_local_storage_data() {
      let existing_filters = localStorage.getItem("projectTasks_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          start_date: this.start_date,
          end_date: this.end_date,
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filtered_sprints: this.filtered_sprints,
          ticket_no: this.ticket_no,
          filter_by_assignee: this.filter_by_assignee,
          filtered_assignees: this.selected_members,
          filter_by_labels: this.filter_by_labels,
          filter_by_sprint: this.filter_by_sprint,
          projectTask_visible_columns: this.visible_columns,
        };
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.start_date = existing_filters.start_date || "";
        this.end_date = existing_filters.end_date || "";
        this.start_date = existing_filters.start_date || "";
        this.end_date = existing_filters.end_date || "";
        this.searchtask = existing_filters.searchtask || "";
        this.ticket_no = existing_filters.ticket_no || "";
        this.selected_status = existing_filters.selected_status || [];
        this.filtered_sprints = existing_filters.filtered_sprints || [];
        this.filter_by_assignee = existing_filters.filter_by_assignee || [];
        this.selected_members = existing_filters.filtered_assignees || [];
        this.filter_by_labels = existing_filters.filter_by_labels || "";
        this.filter_by_sprint = existing_filters.filter_by_sprint || [];
        this.visible_columns =
          existing_filters.projectTask_visible_columns || this.visible_columns;
        existing_filters = {
          ...existing_filters,
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filtered_sprints: this.filtered_sprints,
          ticket_no: this.ticket_no,
          filter_by_assignee: this.filter_by_assignee,
          filtered_assignees: this.selected_members,
          filter_by_labels: this.filter_by_labels,
          filter_by_sprint: this.filter_by_sprint,
        };
      }

      localStorage.setItem(
        "projectTasks_filters",
        JSON.stringify(existing_filters)
      );
    },
  },
  mounted() {
    // this.fetchproject();
    const activeTab = localStorage.getItem("activeTab");
    if (activeTab === "list") {
      this.showList = true;
    } else if (activeTab === "board") {
      this.showBoard = true;
    }
    this.get_tasks(this.page);
    this.get_local_storage_data();
    this.$store.commit("change_page", "project_show");
    this.$socket.emit("read_project_notifications", {
      project_id: this.$route.params.id,
      type: "project",
    }); //Yet to complete
    this.$socket.emit("read_project_notifications", {
      project_id: this.$route.params.id,
      user_id: this.$store.state.user_detail._id,
      type: "project",
    });
  },
  watch: {
    start_date(newValue, oldValue) {
      this.start_date = newValue;
    },
    end_date(newValue, oldValue) {
      this.end_date = newValue;
    },
    searchtask(newValue, oldValue) {
      this.searchtask = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    filtered_sprints(newValue, oldValue) {
      this.filtered_sprints = newValue;
    },
    selected_members(newValue, oldValue) {
      this.selected_members = newValue;
    },
    filter_by_assignee(newValue, oldValue) {
      this.filter_by_assignee = newValue;
    },
    filter_by_spring(newValue, oldValue) {
      this.filter_by_spring = newValue;
    },
    filter_by_labels(newValue, oldValue) {
      this.filter_by_labels = newValue;
    },
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style>
.board-page {
  margin-top: 1.5rem;
}

.activeTab {
  color: #1e90ff;
}

.list-icon {
  font-size: larger;
  margin-bottom: -5px;
}

.list-icon span {
  padding-right: 10px;
}

.board-icon {
  font-size: larger;
  margin-bottom: -5px;
}

.board-icon span {
  padding-right: 10px;
}

.table_header {
  font-weight: bold;
}

.page-item.active .page-link {
  background-color: rgb(54, 88, 143) !important;
  color: #ffffff;
}

.page-item {
  cursor: pointer;
}

.sort_btn {
  width: 30px;
  border: solid 1px #ffffff;
  background-color: transparent;
  border-radius: 4px;
  color: rgb(114, 112, 112);
}

.filt svg {
  right: -12px;
  position: relative;
  top: 1px;
}

.statuslist .vdp-datepicker {
  padding-bottom: 5px;
}

.statuslist {
  z-index: 10;
  padding: 0px;
  font-weight: normal;
  font-size: 14px;
  position: absolute;
  background-color: white;
  margin-top: 0px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  right: 110px;
  top: 50px;
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
  width: 230px;
}

.filt .dropdown-menu {
  min-width: 9rem;
  font-size: 14px;
}

.filt .dropdown-item {
  padding: 0.5em 1em;
}

.btn-dd {
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
  border-radius: 4px;
}

.btn-dd:focus {
  outline: none !important;
}

.btn-dd:hover {
  background-color: transparent !important;
  border-color: transparent;
}

.btn-dd:active {
  background-color: transparent !important;
  border-color: transparent;
}

.tasklink {
  color: rgb(0, 0, 0);
  margin-left: 0px;
  margin-right: 0px;
}

.tasklink:hover {
  color: black;
  text-decoration: none;
}

.edit_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: rgb(114, 112, 112);
}

.del_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  color: rgb(114, 112, 112);
}

.view_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  border-radius: 4px;
  color: rgb(114, 112, 112);
}

/* .dropdown{
    background-color: transparent;
    color: black;
}

.dropdown:focus{
    outline: none
} */

.toolbar {
  display: flex;
  border-bottom: 1px solid gainsboro;
}

.scorecard-container.row {
  margin-left: 8px !important;
}
.scorecard {
  display: flex;
  cursor: pointer;
  color: white !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  font-size: 24px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #ebebeb;
}
.score_count {
  font-size: 20px;
}
.dev_completed {
  background-color: #a3ff0e !important;
}

.in_progress {
  background-color: #ff980e !important;
}
.new {
  background-color: #007bff !important;
}
.todo {
  background-color: #00d5d9 !important;
}

.rot {
  background-color: #612f12 !important;
}

.pot {
  background-color: #018a01 !important;
}

.completed {
  background-color: #6606bd !important;
}
.fot {
  background-color: #f13636 !important;
}
.released {
  background-color: #ff01f2 !important;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.filtered_items {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.filter_chip {
  display: flex;
  background-color: #e6e6e6;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.close-filter,
.clear-filter {
  cursor: pointer;
}
.left_children {
  margin-right: auto;
  display: flex;
  font-size: 14px;
}

.right_children {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-left: auto !important;
}

#buton:hover {
  background-color: #dae0e5 !important;
}

.sort_filter {
  display: flex;
}

.tasklist {
  margin: 20px 10px;
  display: block;
  width: fit-content;
  overflow-y: auto;
}

.table-body:hover {
  cursor: pointer;
}

.router_links {
  color: #484848;
}

.router_links:hover {
  color: #484848;
  text-decoration: none;
}

.project {
  display: flex;
  padding-top: 20px;
}

#pjt:focus {
  outline: none;
}

#pjt {
  color: white;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

@media (orientation: portrait) {
  .toolbar {
    margin-top: 20px;
  }
}

@media (min-width: 700px) {
  .tasklink {
    margin-left: 1rem;
  }

  .addtask {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .add_teammate {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media (max-width: 700px) {
  #buton {
    margin-bottom: 3px;
  }

  .addtask {
    border-radius: 20px;
  }

  .dd_select {
    border-radius: 20px;
  }

  .add_teammate {
    border-radius: 20px;
  }

  .tasklink {
    margin-left: 1.7rem;
  }

  #right {
    display: inline-flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .filt {
    display: inline-flex;
  }

  .filt svg {
    right: -7px;
    position: relative;
    top: 8px;
  }

  .sorter svg {
    right: 2px;
    position: relative;
    top: 4px;
  }

  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td:not(.mf-footer),
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
    height: auto;
  }
  #no-more-tables td:not(.mf-footer) {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 8px;
    left: 5%;
    width: 45%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    /* font-family: 'Poppins-Regular'; */
  }
  .data_table_design tfoot tr {
    display: flex;
    justify-content: center;
  }
  .data_table_design tfoot tr {
    display: block;
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

@media (min-width: 700px) {
  .table.table_design {
    min-width: 100vw;
    overflow-x: auto;
  }
  .project_assigned_to,
  .project_project_code,
  .project_sprint,
  .estimated_hours,
  .actual_hours,
  .project_labels {
    width: 150px;
    max-width: 200px;
  }

  .project_ticket_number {
    width: 100px !important;
  }

  .project_tasks_name {
    width: 240px !important;
  }
  .project_description {
    width: 560px !important;
    overflow-wrap: break-word;
  }

  /* .project_tasks_flag {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f1f1f1;
  } */

  .project_project_name,
  .project_due_date,
  .project_created_at {
    width: 120px !important;
  }

  .project_updated_at {
    width: 124px !important;
  }
}
.btn-secondary {
  outline: none !important;
  box-shadow: none !important;
}
input.vs__search {
  margin-top: 12px;
}
.statuslist .btn.btn-primary {
  background: #3e49c1;
  border: 0;
  padding: 0.5em 2em;
  margin: 1em 0 0.5em auto !important;
}
</style>
