<template>
<div class="col-12">
    <div class="col-12 row m-0 p-0 px-md-3 align-items-center">
        <div class="p-2 col-md-4 row m-0">
            <label class="semi_bold_font w-100 font-8">Filter by Member</label>
            <v-select @input="member_filter(filter_name)" class="v-select-design w-100 multiple filter_select" :searchable="true" :multiple="true" v-model="filter_name" :options="member_options" placeholder="Select User" label="full_name" >
            </v-select>
        </div>
        <div class="p-2 col-md-4 row m-0">
            <label class="semi_bold_font font-8 w-100">Filter by Leave date <a href="javascript:void(0)" class="bg-danger py-1 px-2 rounded text-white ml-1 text-decoration-none" @click="remove_date_filter()">Clear</a></label>
            <Datepicker @input="date_filter(start_date, end_date)" class="w-50 datepicker_design pr-1 filter_datepicker" :use-utc="true" :format="customFormatter" :bootstrap-styling="true" placeholder="From" v-model="start_date" />
            <Datepicker @input="date_filter(start_date, end_date)" class="w-50 datepicker_design pr-1 ml-auto filter_datepicker" :use-utc="true" :format="customFormatter" :bootstrap-styling="true" placeholder="To"  v-model="end_date" />
        </div>
    </div>
    <div class="tasklist row w-100 mx-0 mt-4">
        <div class="taskform col-12" id="no-more-tables">
            <table class="table table-striped text-left table_design">
                <thead class="table_header">
                    <tr class="p-0">
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic leave_applicant">Applicant</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic leave_reason">Leave Reason</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic leave_applied">Applied On</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic leave_dates">Leave Date</th>
                    </tr>
                </thead>
                <tbody v-if="!is_success">
                    <td colspan="4">
                        <ContentLoader>
                            <rect x="0" y="0" rx="6" ry="6" width="100%" height="23" />
                            <rect x="0" y="30" rx="6" ry="6" width="100%" height="23" />
                            <rect x="0" y="60" rx="6" ry="6" width="100%" height="23" />
                        </ContentLoader>
                    </td>
                </tbody>
                <tbody class="table-body" v-if="is_success">
                    <tr class="p-0" v-for="item in leaves" :key="item._id">
                        <td data-title="Leave reason" class="leave_applicant">{{item.user_detail.full_name}}</td>
                        <td data-title="Leave reason" class="leave_reason">{{item.doc.reason}}</td>
                        <td data-title="Applied On" class="leave_applied">{{item.doc.applied_on}}</td>
                        <td data-title="Leave Dates" class="leave_dates">{{item.doc.display_date}}</td>
                    </tr>
                    <tr v-if="leaves.length == 0">
                        <td colspan="4">No leaves</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="mt-3 row m-0 col-12">
            <ul class="pagination pagination_design ml-auto d-flex list-type-none" v-if="pages && pages.length > 1">
                <li class="page-item" v-bind:class="{disabled:current === 1}">
                    <a class="page-link"  @click="list_leaves(1)">
                        <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
                    </a>
                </li>
                <li class="page-item" v-bind:class="{disabled:current === 1}">
                    <a class="page-link"  @click="list_leaves(current - 1)"> 
                        <font-awesome-icon icon="angle-left"> </font-awesome-icon>
                    </a>
                </li>
                <li class="page-item" v-for="item in pages" :key="item.id" v-bind:class="{active:current === item.id}">
                    <a class="page-link"  @click="list_leaves(item.id)">{{item.id}}</a>
                </li>
                <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                    <a class="page-link"  @click="list_leaves(current + 1)">
                        <font-awesome-icon icon="angle-right"> </font-awesome-icon>
                    </a>
                </li>
                <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                    <a class="page-link"  @click="list_leaves(totalPages)">
                        <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
                    </a>
                </li>
            </ul>
        </div>
</div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment-timezone'
import { ContentLoader } from 'vue-content-loader'

export default {
    name: 'LeaveList',
    components: {
        vSelect,
        Datepicker,
        moment,
        ContentLoader
    },
    data () {
        return {
            page: 1,
            current: 1,
            totalDocs: 0,
            totalPages: 0,
            startPage: 1,
            endPage: 1,
            pages:[],
            serial_number:'',
            leaves:[],
            start_date: '',
            end_date: '',
            filter_name: '',
            member_options: [],
            filter_by_member: [],
            request_for: '',
            is_success: false
        }
    },
    methods : {
        remove_date_filter() {
            this.start_date = '';
            this.end_date = '';
            this.list_leaves(this.page)
        },
        makeToast(variant=null) {
            this.$root.$bvToast.toast(`New employee added`, {
                title: 'Success',
                autoHideDelay: 3000,
            })
        },
        customFormatter(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        member_filter: function(filter_member) {
            this.filter_by_member = [];
            if(filter_member.length > 0){
                for (let index = 0; index < filter_member.length; index++) {
                    const element = filter_member[index]._id;
                    this.filter_by_member.push(element)   
                }
                this.request_for = "user"
            }
            else {
                this.filter_by_member = []
                this.request_for = ""
            }
            this.list_leaves(this.page)
        },
        date_filter:function (start_date, end_date){
            this.start_date = moment.utc(start_date).format('YYYY-MM-DD')
            this.end_date = moment.utc(end_date).format('YYYY-MM-DD')
            this.start_date = this.start_date == 'Invalid date' ? '':this.start_date;
            this.end_date = this.end_date == 'Invalid date' ? '':this.end_date;
            if(this.start_date == '' || this.end_date == '') {
                this.$bvToast.toast(`Choose start and end dates`, {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 500,
                    appendToast: false,
                })
            }
            else {
                this.list_leaves(this.page)
            }
        },
        get_userlist:function(){
            let params = {
                current_user: this.$store.state.user_detail._id
            }
            this.$http.post(this.$store.state.api_url+'allchatusers', params)
            .then(function(response){
                response = response.body;
                response = response.data
                let data = response
                this.member_options = data;
            })
        },
        list_leaves:function(page){
            let params = {
                pageno : page,
                size: 10,
                user_id: this.filter_by_member,
                start_filter: this.start_date,
                end_filter: this.end_date,
                request_for: this.request_for
            }
            this.$http.post(this.$store.state.api_url+'listleaves',params)
            .then(function(data){
                this.is_success = true;
                this.data = data.body.data
                this.leaves = this.data.docs
                this.serial_number = this.data.pagingCounter;
                this.current =this.data.page;
                this.totalDocs=this.data.totalDocs;
                this.totalPages=this.data.totalPages;
                console.log(this.leaves)
                if(this.totalPages <= 5) 
                {
                    this.startPage = 1;
                    this.endPage = this.totalPages;
                } 
                else 
                {
                    if (this.current <= 3) {
                        this.startPage = 1;
                        this.endPage = 5;
                    } else if (this.current + 2 >= this.totalPages) {
                        this.startPage = this.totalPages - 4;
                        this.endPage = this.totalPages;
                    } else {
                        this.startPage = this.current - 2;
                        this.endPage = this.current + 2;
                    }
                }
                this.pages = Array.from(Array((this.endPage + 1) - this.startPage).keys()).map(i => ({ id: (this.startPage+i), name: 'Item ' + (this.startPage+i) }));
            })
            // this.$http.get(this.$store.state.api_url+'allleaves',{params}).then(function(response){
            //     response = response.body;
            //     this.data=response.data.docs;
            //     this.serial_number = response.data.pagingCounter;
            //     this.current =response.data.page;
            //     this.totalDocs=response.data.totalDocs;
            //     this.totalPages=response.data.totalPages;
            //     this.leaves=this.data;
            // })
        },
    },
    mounted() {
        this.$store.commit("change_page", "leavelist");
        if(this.$store.state.user_detail.user_role != 'admin' && this.$store.state.user_detail.user_role != 'super_admin')
        {
            this.$router.push({name:'Home'})
        }
        this.list_leaves(this.page);
        this.get_userlist();
    }
}
</script>

<style scoped>

.leavelist{
  z-index: 10;
  padding: 0px;
  font-weight: normal;
  font-size: 14px;
  position:absolute;
  background-color: white;
  margin-top: 0px;
  box-shadow: 0 0 0 1px rgba(111,119,130,.15), 0 5px 20px 0 rgba(21,27,38,.08);
  left: 65px;
  top: 30px;
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
  width: 200px
}

</style>