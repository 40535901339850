const fs = require("fs");
exports.getBaseUrl = () => {
  return "https://teams.waioz.com"; // https://teams.waioz.com, http://localhost:2404
};
exports.prepareUploadFolder = (path) => {
  const pathExist = fs.existsSync(path);
  if (!pathExist) {
    fs.mkdirSync(path, {
      recursive: true,
    });
  }
};
