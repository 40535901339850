<template>
  <div id="app" class="row w-100 px-0 m-0">
    <div
      class="position-fixed d-none d-md-flex bg-grey mw-60 l-0 col-sm-1 z-index-high row mx-0 h-100 p-0 align-items-end justify-content-center border-right"
      v-if="this.$route.path !== '/signin'"
    >
      <div class="small_text_logo" @click="homeredirect()"></div>
      <div class="mb-3">
        <img
          :src="this.$store.state.user_detail.profile_photo"
          class="mb-3 img-small rounded-circle"
          @click="edit_redirect()"
        />
        <router-link
          class="sidebarmenu grey-text text-decoration-none d-block p-2 position-relative"
          v-bind:to="'/notification'"
          ><font-awesome-icon class="" icon="bell" size="1x"></font-awesome-icon
          ><b-badge
            v-if="notifications_count > 0"
            variant="light"
            class="badge ml-2 d-flex align-items-center z-depth-1 rounded-circle img-too-small justify-content-center position-absolute t-0"
            style="right: -5px"
            >{{ notifications_count }}</b-badge
          ></router-link
        >
        <a
          href="javascript:void(0)"
          @click="add_task_page"
          v-b-tooltip.hover
          title="Add Task"
          class="text-decoration-none d-block p-2 grey-text"
          v-if="
            $store.state.user_detail.user_role == 'admin' ||
            $store.state.user_detail.user_role == 'super_admin' ||
            is_project_manager
          "
        >
          <font-awesome-icon class="" icon="plus" size="1x"></font-awesome-icon>
        </a>
      </div>
    </div>
    <ejs-sidebar
      id="default-sidebar"
      :enableGestures="enableGestures"
      class="bg-grey"
      ref="sidebar"
      width="280"
      v-if="this.$route.path !== '/signin'"
      style=""
    >
      <button
        id="toggle"
        v-on:click="toggleClick"
        class="e-btn rounded-right-imp close-btn rounded rounded-btn d-flex align-items-center p-0 position-absolute r-0 t-0 mt-5 z-index-high primary-bg-imp text-white d-xl-none d-block"
      >
        <font-awesome-icon
          class=""
          icon="angle-double-left"
          size="1x"
        ></font-awesome-icon>
      </button>
      <sidebar id="sidebar" class="" />
    </ejs-sidebar>
    <div
      v-bind:class="{ 'ml-0': this.$route.path === '/signin' }"
      class="col-lg-12 col-md-12 col-sm-12 col-xs-10 p-0 signin_section"
      v-if="this.$route.path === '/signin'"
    >
      <router-view />
    </div>
    <div
      class="w-100 text-left main-section py-1 px-3"
      id="extra_margin"
      v-if="this.$route.path !== '/signin'"
    >
      <button
        id="toggle"
        v-on:click="toggleClick"
        class="e-btn rounded-right-imp close-btn rounded rounded-btn d-flex align-items-center p-0 position-absolute l-0 t-0 mt-5 z-index-high primary-bg-imp text-white d-md-block d-none"
      >
        <font-awesome-icon
          class=""
          :icon="collapse_icon"
          size="1x"
        ></font-awesome-icon>
      </button>
      <div class="row mx-3 mt-4 p-3">
        <div class="col-12 p-0">
          <h5 class="m-0">Waioz</h5>
          <div class="d-flex" v-if="this.$route.path === '/'">
            <p class="header-bar bold_font h1 m-0">
              {{ this.$store.state.page }}
            </p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$route.path === '/alltasks'">
            <p class="header-bar bold_font h1 m-0">All Tasks</p>
            <div class="toggler">
              <div
                ref="allTaskSortMenu"
                v-b-tooltip.hover
                title="All Task Sort"
              >
                <button @click="toggleAllTaskSortMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="sort"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showAllTaskSortMenu" class="menu">
                  <div
                    :class="
                      all_task_sort === 'Created High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_sort('Created High to Low')"
                  >
                    Created High to Low
                  </div>
                  <div
                    :class="
                      all_task_sort === 'Created Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_sort('Created Low to High')"
                  >
                    Created Low to High
                  </div>
                  <div
                    :class="
                      all_task_sort === 'DueDate High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_sort('DueDate High to Low')"
                  >
                    DueDate High to Low
                  </div>
                  <div
                    :class="
                      all_task_sort === 'DueDate Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_sort('DueDate Low to High')"
                  >
                    DueDate Low to High
                  </div>
                </div>
              </div>
              <div ref="columns" v-b-tooltip.hover title="Columns">
                <button @click="toggleShow" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="th-large"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showMenu" class="menu">
                  <div
                    class="menu-item"
                    v-for="(item, index) in task_columns_list"
                    @click="allTasksColumnClicked(item)"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      :checked="isVisibleAllTaskColumn(item)"
                    />{{ get_column_name(item) }}
                  </div>
                </div>
              </div>

              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('all_tasks')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
          </div>
          <div class="d-flex" v-if="this.$route.path === '/backlogs'">
            <p class="header-bar bold_font h1 m-0">Back Logs</p>  
            <div class="toggler">
              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('back_logs')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
          </div>
          <div class="d-flex" v-if="this.$route.path === '/alltasksreport'">
            <p class="header-bar bold_font h1 m-0">Sprint Report</p>
            <div class="toggler">
              <div
                ref="allTaskSortMenu"
                v-b-tooltip.hover
                title="All Task Report Sort"
              >
                <button @click="toggleAllTasksReportSortMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="sort"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showAllTasksReportSortMenu" class="menu">
                  <div
                    :class="
                      all_task_report_sort === 'Created High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_report_sort('Created High to Low')"
                  >
                    Created High to Low
                  </div>
                  <div
                    :class="
                      all_task_report_sort === 'Created Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_report_sort('Created Low to High')"
                  >
                    Created Low to High
                  </div>
                  <div
                    :class="
                      all_task_report_sort === 'DueDate High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_report_sort('DueDate High to Low')"
                  >
                    DueDate High to Low
                  </div>
                  <div
                    :class="
                      all_task_report_sort === 'DueDate Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_task_report_sort('DueDate Low to High')"
                  >
                    DueDate Low to High
                  </div>
                </div>
              </div>
              <div ref="columns" v-b-tooltip.hover title="Columns">
                <button @click="toggleShow" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="th-large"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showMenu" class="menu">
                  <div
                    class="menu-item"
                    v-for="(item, index) in task_columns_list"
                    @click="allTasksReportColumnClicked(item)"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      :checked="isVisibleAllTasksReportColumn(item)"
                    />{{ get_column_name(item) }}
                  </div>
                </div>
              </div>

              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('all_tasks_report')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
          </div>
          <div class="d-flex" v-if="this.$route.path === '/sprint'">
            <p class="header-bar bold_font h1 m-0">All Sprints</p>
            <div class="toggler">
            <div>
              <router-link to="/addsprint" class="btn btn-dark" v-if="
            $store.state.user_detail.user_role == 'admin' ||
            $store.state.user_detail.user_role == 'super_admin'" style="display: inline-block; margin-right: 10px;border-radius: 50%; padding: 5px 8px 3px 8px;"><font-awesome-icon icon="plus" size="md"></font-awesome-icon></router-link>
            </div>
              <div
                ref="allSprintSortMenu"
                v-b-tooltip.hover
                title="All Sprint Sort"
              >
                <button @click="toggleAllSprintSortMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="sort"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showAllSprintSortMenu" class="menu">
                  <div
                    :class="
                      sprint_sort === 'Created High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_sprint_sort('Created High to Low')"
                  >
                    Created High to Low
                  </div>
                  <div
                    :class="
                      sprint_sort === 'Created Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_all_sprint_sort('Created Low to High')"
                  >
                    Created Low to High
                  </div>
                </div>
              </div>
              <div ref="columns" v-b-tooltip.hover title="Columns">
                <button @click="toggleShow" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="th-large"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showMenu" class="menu">
                  <div
                    class="menu-item"
                    v-for="(item, index) in sprint_columns_list"
                    @click="allSprintsColumnClicked(item)"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      :checked="isVisibleAllSprintColumn(item)"
                    />{{ get_column_name_for_sprint(item) }}
                  </div>
                </div>
              </div>

              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('sprints')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
          </div>
          <div class="d-flex" v-if="this.$route.path === '/board'">
            <p class="header-bar bold_font h1 m-0">Board</p>
          </div>
          <div class="d-flex" v-if="this.$route.path === '/inbox'">
            <p class="header-bar bold_font h1 m-0">Inbox</p>
          </div>
          <div
            class="d-flex"
            v-if="this.$store.state.page == 'notification-page'"
          >
            <p class="header-bar bold_font h1 m-0">Notifications</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$route.path === '/mytasks'">
            <p class="header-bar bold_font h1 m-0">
              {{ this.$store.getters.full_name }} Tasks
            </p>
            <div class="toggler">
              <div ref="myTaskSortMenu" v-b-tooltip.hover title="My Task Sort">
                <button @click="toggleMyTaskSortMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="sort"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showMyTaskSortMenu" class="menu">
                  <div
                    :class="
                      my_task_sort === 'Created High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_my_task_sort('Created High to Low')"
                  >
                    Created High to Low
                  </div>
                  <div
                    :class="
                      my_task_sort === 'Created Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_my_task_sort('Created Low to High')"
                  >
                    Created Low to High
                  </div>
                  <div
                    :class="
                      my_task_sort === 'DueDate High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_my_task_sort('DueDate High to Low')"
                  >
                    DueDate High to Low
                  </div>
                  <div
                    :class="
                      my_task_sort === 'DueDate Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_my_task_sort('DueDate Low to High')"
                  >
                    DueDate Low to High
                  </div>
                </div>
              </div>
              <div ref="columns" v-b-tooltip.hover title="Columns">
                <button @click="toggleShow" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="th-large"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showMenu" class="menu">
                  <div
                    class="menu-item"
                    v-for="(item, index) in task_columns_list"
                    @click="myTasksColumnClicked(item)"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      :checked="isVisibleMyTaskColumn(item)"
                    />{{ get_column_name(item) }}
                  </div>
                </div>
              </div>

              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('my_tasks')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
          </div>
          <div class="d-flex" v-if="this.$route.path === '/addproject'">
            <p class="header-bar bold_font h1 m-0">
              {{ this.$store.state.page }}
            </p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$route.path === '/calendar'">
            <p class="header-bar bold_font h1 m-0">
              {{ this.$store.state.page }}
            </p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$store.state.page == 'scrum'">
            <p class="header-bar bold_font h1 m-0">{{ this.$store.state.scrumgrouppagetitle }}</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div v-if="this.$route.path === '/scrum'">
          <p class="header-bar bold_font h1 m-0">Scrums</p>
          <!-- <Profilebutton class="toggler" /> -->
        </div>
          <div class="d-flex" v-if="this.$store.state.page == 'userlist'">
            <p class="header-bar bold_font h1 m-0">Employee</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$store.state.page == 'leavelist'">
            <p class="header-bar bold_font h1 m-0">Leave Records</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$store.state.page == 'adduser'">
            <p class="header-bar bold_font h1 m-0">Employee</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$store.state.page == 'addleave'">
            <p class="header-bar bold_font h1 m-0">Manage Holiday</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$store.state.page == 'apply_leave'">
            <p class="header-bar bold_font h1 m-0">Apply Leave</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$store.state.page == 'project_board'">
            <p class="header-bar bold_font h1">
              {{ this.$store.getters.project_name }}
            </p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div class="d-flex" v-if="this.$route.path.includes('sprints')">
            <p class="header-bar bold_font h1">
              {{ this.$store.getters.project_name }}
            </p>
            <div class="toggler">
              <div ref="projectMenu" v-b-tooltip.hover title="Project menu">
                <button @click="toggleProjectMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    v-bind:icon="projectMenuItem === 'list' ? 'list' : 'th'"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showProjectMenu" class="menu">
                  <router-link
                    class="route"
                    v-bind:to="'/project/list/' + $store.getters.project_id"
                    ><div class="menu-item" @click="setProjectMenu('list')">
                      Lists
                    </div></router-link
                  >
                  <router-link
                    class="route"
                    v-bind:to="'/project/board/' + $store.getters.project_id"
                    ><div class="menu-item" @click="setProjectMenu('board')">
                      Board
                    </div></router-link
                  >
                </div>
              </div>

              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('sprints')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div
            class="d-flex align-items-center"
            v-if="
              this.$route.path.includes('/project/list') ||
              this.$route.path.includes('/project/board')
            "
          >
            <div
              class="header_icon"
              v-bind:style="{
                'background-color': this.$store.getters.project_color,
              }"
            >
              <font-awesome-icon icon="list" size="xs" />
            </div>
            <p class="header-bar bold_font h1">
              {{ this.$store.getters.project_name }}
            </p>
            <div
              class="project_options"
              v-if="
                this.$store.state.user_detail.user_role == 'admin' ||
                this.$store.state.user_detail.user_role == 'super_admin' ||
                this.$store.state.user_detail._id ==
                  this.$store.state.project.manager
              "
            >
              <b-dropdown class="m-md-2 btn-secondary">
                <b-dropdown-item
                  ><router-link
                    class="route"
                    v-bind:to="{
                      name: 'AddProject',
                      params: this.$store.getters.project_details,
                    }"
                    >Edit Project</router-link
                  ></b-dropdown-item
                >
                <b-dropdown-item
                  :disabled="
                    this.$store.state.user_detail.user_role != 'admin' &&
                    this.$store.state.user_detail.user_role != 'super_admin'
                  "
                  @click="del_modal = !del_modal"
                  >Delete Project</b-dropdown-item
                >
              </b-dropdown>
              <b-modal
                hide-header-close
                v-model="del_modal"
                @ok="del_project($store.getters.project_id)"
              >
                <p class="my-4">Are you sure to delete this project?</p>
              </b-modal>
            </div>
            <div class="toggler">
              <div ref="projectSortMenu" v-b-tooltip.hover title="Project Sort">
                <button @click="toggleProjectSortMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="sort"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showProjectSortMenu" class="menu">
                  <div
                    :class="
                      project_sort === 'Created High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_project_sort('Created High to Low')"
                  >
                    Created High to Low
                  </div>
                  <div
                    :class="
                      project_sort === 'Created Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_project_sort('Created Low to High')"
                  >
                    Created Low to High
                  </div>
                  <div
                    :class="
                      project_sort === 'DueDate High to Low'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_project_sort('DueDate High to Low')"
                  >
                    DueDate High to Low
                  </div>
                  <div
                    :class="
                      project_sort === 'DueDate Low to High'
                        ? 'active-menu-item'
                        : 'menu-item'
                    "
                    @click="handle_project_sort('DueDate Low to High')"
                  >
                    DueDate Low to High
                  </div>
                </div>
              </div>
              <div
                ref="projectActionMenu"
                v-b-tooltip.hover
                title="Project Action Menu"
                v-if="
                  $store.state.user_detail.user_role == 'admin' ||
                  $store.state.user_detail.user_role == 'super_admin' ||
                  $store.state.user_detail._id ==
                    this.$store.state.project.manager
                "
              >
                <button @click="toggleProjectActionMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="plus"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showProjectActionMenu" class="menu">
                  <div class="menu-item" @click="add_task_page">Task</div>
                  <div class="menu-item">
                    <router-link
                      class="route"
                      v-bind:to="{
                        name: 'ProjectSprints',
                        params: this.$store.getters.project_details._id,
                      }"
                    >
                      Sprint
                    </router-link>
                  </div>
                  <div class="menu-item" @click="select_teammate">Member</div>
                </div>
              </div>
              <b-modal
                v-model="sprint_modal"
                ref="modal"
                title="Add sprint"
                @show="resetModal"
                no-close-on-esc
                @hidden="resetModal"
                @ok="add_sprint"
              >
                <!-- <v-select :no-drop="true" taggable :searchable="true" :multiple="true" v-model="project.sprint" :options="sprint_options" placeholder="Add Sprint Name" label="full_name" >
                </v-select> -->
                <b-form-tags
                  v-model="project.sprint"
                  separator=",;"
                  placeholder="Add Sprint name"
                  class="mb-2 style-form"
                >
                </b-form-tags>
              </b-modal>
              <b-modal
                v-model="teammate_modal"
                size="lg"
                title="Add Team Member"
                no-close-on-esc
                @ok="add_teammate"
              >
                <v-select
                  :searchable="true"
                  :multiple="true"
                  v-model="selected_members"
                  :options="member_options"
                  placeholder="Project team members"
                  label="full_name"
                  class="style-team-select"
                >
                </v-select>
              </b-modal>
              <div ref="projectMenu" v-b-tooltip.hover title="Project menu">
                <button @click="toggleProjectMenu" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    v-bind:icon="projectMenuItem === 'list' ? 'list' : 'th'"
                    size="15x"
                  ></font-awesome-icon>
                </button>
                <div v-if="showProjectMenu" class="menu">
                  <router-link
                    class="route"
                    v-bind:to="'/project/list/' + $store.getters.project_id"
                    ><div class="menu-item" @click="setProjectMenu('list')">
                      Lists
                    </div></router-link
                  >
                  <router-link
                    class="route"
                    v-bind:to="'/project/board/' + $store.getters.project_id"
                    ><div class="menu-item" @click="setProjectMenu('board')">
                      Board
                    </div></router-link
                  >
                </div>
              </div>

              <div ref="columns" v-b-tooltip.hover title="Columns">
                <button @click="toggleShow" class="anchor">
                  <font-awesome-icon
                    class="header_filter_icon"
                    icon="th-large"
                    size="15x"
                  >
                  </font-awesome-icon>
                </button>
                <div v-if="showMenu" class="menu">
                  <div
                    class="menu-item"
                    v-for="(item, index) in task_columns_list"
                    @click="projectTasksColumnClicked(item)"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      :checked="isVisibleProjectTaskColumn(item)"
                    />{{ get_column_name(item) }}
                  </div>
                </div>
              </div>

              <button
                id="filter-toggle"
                v-b-tooltip.hover
                title="Filters"
                @click="toggleFilterBar('project_tasks')"
                class="toggler ml-4"
              >
                <font-awesome-icon
                  icon="filter"
                  size="15x"
                  class="font-italic grey-text header_filter_icon"
                ></font-awesome-icon>
              </button>
            </div>
            <!-- <div>
                            <b-modal @ok="del_project($store.getters.project_id)" centered modal-title id="modal-1">
                                <p class="my-4">Are you sure to delete project?</p>
                            </b-modal>
                        </div> -->
            <!-- {{this.$store.state.user_detail.emailid}} -->
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          <div
            class="d-flex"
            v-if="
              this.$store.state.page == 'project' &&
              this.$store.getters.project_color != ''
            "
          >
            <div
              class="header_icon my-auto"
              v-bind:style="{
                'background-color': this.$store.getters.project_color,
              }"
            >
              <font-awesome-icon icon="list" size="xs" />
            </div>
            <p
              class="header-bar bold_font h1 m-0"
              v-if="this.$store.getters.project_color != ''"
            >
              {{ this.$store.getters.project_name }}
            </p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
         
          <div class="d-flex" v-if="this.$store.state.page == 'holidays'">
            <p class="header-bar bold_font h1 m-0">Holidays</p>
            <!-- <Profilebutton class="toggler" /> -->
          </div>
          
        </div>
      </div>
      <router-view class="router_links" />
    </div>
    <ejs-sidebar
      id="filter-sidebar"
      :enableGestures="enableGestures"
      :position="position"
      class="bg-grey"
      ref="filterBar"
      width="320"
      style=""
    >
      <FilterSidebar
        id="filterBar"
        :filterName="filterName"
        :closeFilters="toggleFilterBar"
        class=""
      />
    </ejs-sidebar>
  </div>
</template>

<script>
import Vue from "vue";
import Sidebar from "./components/Sidebar";
import FilterSidebar from "./components/FilterSidebar";
import Profilebutton from "./components/ProfileButton";
// import ProjectDisplay from "./components/ProjectDisplay";
import { SidebarPlugin } from "@syncfusion/ej2-vue-navigations";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
// import { enableRipple } from "@syncfusion/ej2-base";
import { TaskColumns } from "./types/taskTypes";
import { SprintColumns } from "./types/sprintTypes";
import { sprintStatusTypes } from "./types/sprintStatusTypes";
import { StatusProps } from "./types/statusTypes";

var commonHelper = require("../node_app/src/commonHelper");

let handleOutsideClick;
Vue.use(SidebarPlugin);
Vue.use(ButtonPlugin);

Vue.directive("closable", {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

export default {
  name: "app",
  components: {
    Sidebar,
    Profilebutton,
    FilterSidebar,
  },
  beforeCreate() {
    let listnames = localStorage.getItem("status_names");
    if (!listnames) {
      listnames = JSON.stringify([
        { text: StatusProps["todo"].name, value: "todo" },
        { text: StatusProps["in_progress"].name, value: "in_progress" },
        { text: StatusProps["dev_completed"].name, value: "dev_completed" },
      ]);
      localStorage.setItem("status_names", listnames);
    }
    let remaining_status = localStorage.getItem("status_remaining");
    if (!remaining_status) {
      let remaining_status = JSON.stringify([
        { text: StatusProps["new"].name, value: "new" },
        { text: StatusProps["completed"].name, value: "Completed" },
        { text: StatusProps["rot"].name, value: "rot" },
        { text: StatusProps["pot"].name, value: "pot" },
        { text: StatusProps["fot"].name, value: "fot" },
        { text: StatusProps["on_hold"].name, value: "on_hold" },
      ]);
      localStorage.setItem("status_remaining", remaining_status);
    }
  },
  data() {
    let icon_name;
    if (document.body.clientWidth >= 767) {
      icon_name = "angle-double-left";
    } else {
      icon_name = "angle-double-right";
    }
    return {
      showdd: false,
      showmenu: false,
      del_modal: false,
      typing: false,
      status: "",
      signing_in: "",
      componentKey: 0,
      type_group: false,
      last_seen: "",
      alert_toast: false,
      toast_link: "",
      showBackdrop: false,
      closeOnDocumentClick: true,
      collapse_icon: icon_name,
      enableGestures: false,
      position: "Right",
      is_project_manager: false,
      notifications_count: 0,
      is_filterBar_open: false,
      showProjectMenu: false,
      showProjectActionMenu: false,
      showProjectSortMenu: false,
      showAllTaskSortMenu: false,
      showAllTasksReportSortMenu: false,
      showAllSprintSortMenu: false,
      showMyTaskSortMenu: false,
      projectMenuItem: "list",
      showMenu: false,
      sprint_modal: false,
      teammate_modal: false,
      member_options: [],
      selected_members: [],
      teammate: [],
      sprint_options: [],
      filterName: "",
      project_sort: "Created Low to High",
      all_task_sort: "Created Low to High",
      all_task_report_sort: "Created Low to High",
      sprint_sort: "Created Low to High",
      my_task_sort: "Created Low to High",
      sprint_filters_default: {
        name_filter: "",
        start_date_filter: "",
        end_date_filter: "",
        selected_status: {
            name: "open",
            value: "Open",
            status_field_name: "sprint_status"
        }
      },
      task_columns: TaskColumns,
      sprint_columns: SprintColumns,
      mytask_visible_columns: [
        "title",
        "assigned_to",
        "created_at",
        "status",
        "project_id",
      ],
      allSprint_visible_columns: ["sprint_name", "start_date", "end_date", "sprint_status", "status"],
      task_columns_list: [
        "ticket_no",
        "title",
        "assigned_to",
        "project_id",
        "status",
        "created_at",
        "updated_at",
        "actual_hours",
        "estimated_hours",
        "sprint",
        "labels",
        "due_date",
      ],
      allTask_visible_columns: [
        "title",
        "assigned_to",
        "created_at",
        "status",
        "project_id",
      ],
      allTask_report_visible_columns: [
        "ticket_no",
        "title",
        "assigned_to",
        "project_id",
        "status",
        "actual_hours",
        "estimated_hours",
      ],
      sprint_columns_list: ["sprint_name", "start_date", "end_date", "sprint_status", "status"],
      projectTask_visible_columns: [
        "title",
        "assigned_to",
        "created_at",
        "status",
      ],
      project: {},
    };
  },
  created() {
    console.log("this.$eventHub:", this.$eventHub);
    // is_project_manager: false,
    // notifications_count: 0,
    this.$eventHub.$on("change-binary", this.changebin);
    this.$eventHub.$on("hide_sidemenu", this.hidemenu);
    if (localStorage.getItem("userinfo")) {
      this.$store.commit(
        "set_user_detail",
        JSON.parse(localStorage.getItem("userinfo"))
      );
    }
    this.$socket.emit("user_connect", {
      user_id: this.$store.state.user_detail._id,
      socket_id: this.$socket.id,
    });
    this.$socket.emit("room_for_alerts", this.$store.state.user_detail._id);

    let params = {
      id: this.$store.state.user_detail._id,
      role: this.$store.state.user_detail.user_role,
    };
    console.log("Component created");
    this.$http
      .get(this.$store.state.api_url + "check_manager", { params })
      .then(function (response) {
        if (response.data.data.length > 0) {
          this.is_project_manager = true;
        }
      });
  },
  mounted() {
    var receiver_details = {};
    this.get_userlist();
    document.addEventListener("click", this.handleOutsideClick);
    this.task_columns = TaskColumns;
    this.sprint_columns = SprintColumns;
    this.project = this.$store.getters.project_details;
    receiver_details.status = "";
    if (this.$store.state.user_detail._id == this.$store.state.chat.sender) {
      receiver_details = this.$store.state.chat.sent_to_info;
    } else {
      receiver_details = this.$store.state.chat.sent_by_info;
    }
    if (receiver_details) {
      this.status = receiver_details.status;
    }
    if (this.$refs.filterBar) {
      console.log("Calling hide() on filterBar");
      this.$refs.filterBar.hide();
    } else {
      console.warn("FilterBar component not found");
    }
    localStorage.setItem("project_sort", this.project_sort);
    localStorage.setItem("all_task_sort", this.all_task_sort);
    localStorage.setItem("all_task_report_sort", this.all_task_report_sort);
    localStorage.setItem("my_task_sort", this.my_task_sort);
    localStorage.setItem("sprint_sort", this.sprint_sort);
    localStorage.setItem("sprint_filters", JSON.stringify(this.sprint_filters_default));
  },
  beforeMount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    this.$eventHub.$off("change-binary");
  },
  updated() {
    var receiver_details = {};
    if (this.$store.state.user_detail._id == this.$store.state.chat.sender) {
      receiver_details = this.$store.state.chat.sent_to_info;
    } else {
      receiver_details = this.$store.state.chat.sent_by_info;
    }
    if (this.$store.state.page == "chat_page") {
      this.status = receiver_details.status;
      this.last_seen = receiver_details.last_seen_time;
    }
  },
  methods: {
    handleOutsideClick(event) {
      // Check if the clicked element is outside the component
      if (!this.$refs.columns.contains(event.target)) {
        if (this.showMenu) {
          this.toggleShow();
        }
      }
      if (!this.$refs.projectMenu.contains(event.target)) {
        if (this.showProjectMenu) {
          this.toggleProjectMenu();
        }
      }
      if (!this.$refs.projectActionMenu.contains(event.target)) {
        if (this.showProjectActionMenu) {
          this.toggleProjectActionMenu();
        }
      }

      if (!this.$refs.projectSortMenu.contains(event.target)) {
        if (this.showProjectSortMenu) {
          this.toggleProjectSortMenu();
        }
      }

      if (!this.$refs.allTaskSortMenu.contains(event.target)) {
        if (this.showAllTaskSortMenu) {
          this.toggleAllTaskSortMenu();
        }
      }

      if (!this.$refs.allTasksReportSortMenu.contains(event.target)) {
        if (this.showAllTasksReportSortMenu) {
          this.toggleAllTasksReportSortMenu();
        }
      }

      if (!this.$refs.sprintSortMenu.contains(event.target)) {
        if (this.showAllSprintSortMenu) {
          this.toggleSprintSortMenu();
        }
      }

      if (!this.$refs.myTaskSortMenu.contains(event.target)) {
        if (this.showMyTaskSortMenu) {
          this.toggleMyTaskSortMenu();
        }
      }
    },
    add_task_page: function () {
      let params = {
        is_global: true,
        status: "new",
        project_id: this.project._id,
      };
      this.$http
        .post(this.$store.state.api_url + "addtask/", { params })
        .then(function (response) {
          var result = response.data;
          var task = result.data;
          var task_id = task._id;
          console.log(task);
          this.$router.push("/task/" + task_id);
        });
    },
    add_teammate: function () {
      this.teammate = [];
      var user_name = [];
      for (var i = 0; i < this.selected_members.length; i++) {
        this.teammate.push(this.selected_members[i]._id);
        user_name[this.selected_members[i]._id] =
          this.selected_members[i].full_name;
      }
      var project_members = [];
      for (var i = 0; i < this.project.members.length; i++) {
        project_members.push(this.project.members[i]._id);
        user_name[this.project.members[i]._id] =
          this.project.members[i].full_name;
      }
      console.log(user_name);
      var removed_user = _.difference(project_members, this.teammate);
      console.log(removed_user);
      var added_user = _.difference(this.teammate, project_members);
      var added_message = "";
      var removed_message = "";
      if (added_user.length) {
        for (var i = 0; i < added_user.length; i++) {
          added_message += user_name[added_user[i]];
          if (added_user.length > 1 && added_user.length >= i) {
            added_message += ", ";
          }
        }
        added_message += " newly added in project '" + this.project.name + "'.";
      }
      if (removed_user.length) {
        for (var i = 0; i < removed_user.length; i++) {
          removed_message += user_name[removed_user[i]];
          if (removed_user.length > 1 && i < removed_user.length - 1) {
            removed_message += ", ";
          }
        }
        removed_message += " removed from project '" + this.project.name + "'.";
        console.log(removed_message);
      }
      var params = {};
      params["members"] = this.teammate;
      params["_id"] = this.project._id;
      params["type"] = true;
      params["name"] = this.project.name;
      this.$http
        .post(this.$store.state.api_url + "addproject", params)
        .then(function (data) {
          var whole = data.body;
          var data = whole.data;
          this.selected_members = data.members;
          var member_list = data.members;
          this.project.members = member_list;
          if (added_user.length) {
            for (var i = 0; i < added_user.length; i++) {
              let params = {
                user_id: added_user[i],
                project_id: data._id,
                type: "project",
                isread: "1",
                message: "You are added in " + data.name + " project.",
              };
              this.$socket.emit("new_notification", params);
            }
          }
          if (removed_user.length) {
            for (var i = 0; i < removed_user.length; i++) {
              let params = {
                user_id: removed_user[i],
                project_id: data._id,
                type: "project",
                isread: "1",
                message: "You are removed from " + data.name + " project.",
              };
              this.$socket.emit("new_notification", params);
            }
            // removed_message+= " removed from project '" + this.project.name + "'."
          }
          for (var i = 0; i < member_list.length; i++) {
            let params = {
              user_id: member_list[i],
              project_id: data._id,
              type: "project",
              isread: "1",
              message: added_message,
            };

            if (
              added_user.length &&
              added_user.indexOf(member_list[i] == "-1")
            ) {
              this.$socket.emit("new_notification", params);
            }
            params.message = removed_message;
            if (
              removed_user.length &&
              removed_user.indexOf(member_list[i] == "-1")
            ) {
              this.$socket.emit("new_notification", params);
            }
          }
        });
    },
    add_sprint: function () {
      var params = {};
      params["sprint"] = this.project.sprint;
      params["_id"] = this.project._id;
      params["type"] = true;
      params["name"] = this.project.name;
      this.$http
        .post(this.$store.state.api_url + "addproject", params)
        .then(function (res) {});
    },

    get_userlist: function () {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers")
        .then(function (response) {
          response = response.body;
          response = response.data;
          let data = response;
          this.member_options = data;
        });
    },
    handle_project_sort: function (value) {
      this.project_sort = value;
      localStorage.setItem("project_sort", value);
      this.$eventHub.$emit("search_project_task");
    },
    handle_all_task_sort: function (value) {
      this.all_task_sort = value;
      localStorage.setItem("all_task_sort", value);
      this.$eventHub.$emit("search_all_task");
    },
    handle_all_task_report_sort: function (value) {
      this.all_task_report_sort = value;
      localStorage.setItem("all_task_report_sort", value);
      this.$eventHub.$emit("search_all_task_report");
    },
    handle_all_sprint_sort: function (value) {
      this.sprint_sort = value;
      localStorage.setItem("sprint_sort", value);
      this.$eventHub.$emit("search_sprint");
    },
    handle_my_task_sort: function (value) {
      this.my_task_sort = value;
      localStorage.setItem("my_task_sort", value);
      this.$eventHub.$emit("search");
    },
    fetchproject() {
      let user_params = this.$store.state.user_detail._id;
      var itemId = this.$route.params.id;
      this.$http
        .post(this.$store.state.api_url + "project/" + itemId, { user_params })
        .then(async (response) => {
          response = response.body.data;
          this.project = response;
          this.$store.commit("set_project_detail", this.project);
          this.selected_members = this.project.members;
          var obj = await this.project.members.find(
            (o) => o.id === this.$store.state.user_detail._id
          );
        });
    },
    resetModal() {
      // this.sprint_modal = !this.sprint_modal;
    },
    homeredirect: function () {
      this.$router.push({ name: "Home" });
    },
    edit_redirect: function () {
      this.$router.push({
        name: "AddUser",
        params: this.$store.state.user_detail,
      });
    },
    del_project: function (id) {
      this.$http
        .post(this.$store.state.api_url + "delete_project", { _id: id })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.$router.push({ name: "Home" });
          }
        });
    },
    changebin(toggle) {
      this.showmenu = toggle;
    },
    onClose() {
      this.showdd = false;
    },
    notify() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
      // this.$notification.show('Hello World', {
      //     body: 'This is an example!'
      // }, {})
      var dts = Math.floor(Date.now());
      var link = commonHelper.getBaseUrl() + this.toast_link; //https://teams.waioz.com
      const notification = {
        title: "PEER",
        options: {
          body: this.toast_message,
          timestamp: dts,
        },
        events: {
          onerror: function () {
            console.log("Custom error event was called");
          },
          onclick: function () {
            window.open(link);
            console.log("Custom click event was called");
          },
          onclose: function () {
            // console.log('Custom close event was called');
          },
          onshow: function () {
            // console.log('Custom show event was called');
          },
        },
      };
      this.$notification.show(
        notification.title,
        notification.options,
        notification.events
      );
    },
    toast() {
      console.log("Toast works");
      console.log(this.toast_link);
      this.$root.$bvToast.toast(this.toast_message, {
        to: this.toast_link,
        title: this.toast_time,
      });
    },
    get_column_name(item) {
      let name = this.task_columns[item];
      if (name) return name;
      else return item;
    },
    get_column_name_for_sprint(item) {
      let name = this.sprint_columns[item];
      if (name) return name;
      else return item;
    },
    isVisibleAllTaskColumn(item) {
      return this.allTask_visible_columns.includes(item);
    },
    isVisibleAllTasksReportColumn(item) {
      return this.allTask_report_visible_columns.includes(item);
    },
    isVisibleAllSprintColumn(item) {
      return this.allSprint_visible_columns.includes(item);
    },
    isVisibleMyTaskColumn(item) {
      return this.mytask_visible_columns.includes(item);
    },
    isVisibleProjectTaskColumn(item) {
      return this.projectTask_visible_columns.includes(item);
    },
    allTasksColumnClicked: function (item) {
      const existing_items = new Set(this.allTask_visible_columns);
      if (!existing_items.has(item)) existing_items.add(item);
      else existing_items.delete(item);
      this.allTask_visible_columns = Array.from(existing_items);
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          allTask_visible_columns: Array.from(existing_items),
        };
      } else {
        existing_filters = {
          ...existing_filters,
          allTask_visible_columns: Array.from(existing_items),
        };
      }
      localStorage.setItem(
        "allTasks_filters",
        JSON.stringify(existing_filters)
      );

      this.$eventHub.$emit("all_task_table");
    },
    allTasksReportColumnClicked: function (item) {
      const existing_items = new Set(this.allTask_report_visible_columns);
      if (!existing_items.has(item)) existing_items.add(item);
      else existing_items.delete(item);
      this.allTask_report_visible_columns = Array.from(existing_items);
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasksReport_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          allTask_report_visible_columns: Array.from(existing_items),
        };
      } else {
        existing_filters = {
          ...existing_filters,
          allTask_report_visible_columns: Array.from(existing_items),
        };
      }
      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );

      this.$eventHub.$emit("all_task_report_table");
    },
    allSprintsColumnClicked: function (item) {
      const existing_items = new Set(this.allSprint_visible_columns);
      if (!existing_items.has(item)) existing_items.add(item);
      else existing_items.delete(item);
      this.allSprint_visible_columns = Array.from(existing_items);
      let existing_filters = JSON.parse(
        localStorage.getItem("allSprint_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          allSprint_visible_columns: Array.from(existing_items),
        };
      } else {
        existing_filters = {
          ...existing_filters,
          allSprint_visible_columns: Array.from(existing_items),
        };
      }
      localStorage.setItem(
        "sprints_filters",
        JSON.stringify(existing_filters)
      );

      this.$eventHub.$emit("all_sprint_table");
    },
    projectTasksColumnClicked: function (item) {
      const existing_items = new Set(this.projectTask_visible_columns);
      if (!existing_items.has(item)) existing_items.add(item);
      else existing_items.delete(item);
      this.projectTask_visible_columns = Array.from(existing_items);
      let existing_filters = JSON.parse(
        localStorage.getItem("projectTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          projectTask_visible_columns: Array.from(existing_items),
        };
      } else {
        existing_filters = {
          ...existing_filters,
          projectTask_visible_columns: Array.from(existing_items),
        };
      }
      localStorage.setItem(
        "projectTasks_filters",
        JSON.stringify(existing_filters)
      );

      this.$eventHub.$emit("project_task_table");
    },
    myTasksColumnClicked: function (item) {
      const existing_items = new Set(this.mytask_visible_columns);
      if (!existing_items.has(item)) existing_items.add(item);
      else existing_items.delete(item);
      this.mytask_visible_columns = Array.from(existing_items);
      let existing_filters = JSON.parse(
        localStorage.getItem("myTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          mytask_visible_columns: Array.from(existing_items),
        };
      } else {
        existing_filters = {
          ...existing_filters,
          mytask_visible_columns: Array.from(existing_items),
        };
      }
      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));

      this.$eventHub.$emit("my_task_table");
    },
    toggleClick: function () {
      this.$refs.sidebar.toggle();
      let mainDiv = document.getElementById("extra_margin").style.marginLeft;
      if (mainDiv == "") mainDiv = "0px";
      if (mainDiv == "0px") {
        this.collapse_icon = "angle-double-right";
      } else {
        this.collapse_icon = "angle-double-left";
      }
      if (document.body.clientWidth >= 767) {
        document.getElementById("extra_margin").style.marginLeft =
          parseInt(mainDiv.split("px")[0]) + 60 + "px";
      }
    },

    toggleFilterBar: function (name) {
      let sidebar = this.$refs.filterBar;
      if (sidebar) {
        sidebar.toggle();
        this.is_filterBar_open = !this.is_filterBar_open;
        this.filterName = name;
      }
    },

    toggleShow: function () {
      this.showMenu = !this.showMenu;
    },

    select_teammate: function () {
      this.teammate_modal = !this.teammate_modal;
    },
    setProjectMenu: function (menu) {
      this.projectMenuItem = menu;
    },
    toggleProjectActionMenu: function () {
      this.showProjectActionMenu = !this.showProjectActionMenu;
    },
    toggleProjectSortMenu: function () {
      this.showProjectSortMenu = !this.showProjectSortMenu;
    },
    toggleAllTaskSortMenu: function () {
      this.showAllTaskSortMenu = !this.showAllTaskSortMenu;
    }, 
    toggleAllTasksReportSortMenu: function () {
      this.showAllTasksReportSortMenu = !this.showAllTasksReportSortMenu;
    }, 
    toggleAllSprintSortMenu: function () {
      this.showAllSprintSortMenu = !this.showAllSprintSortMenu;
    },
    toggleMyTaskSortMenu: function () {
      this.showMyTaskSortMenu = !this.showMyTaskSortMenu;
    },

    toggleProjectMenu: function () {
      if (this.showProjectMenu) this.projectMenuItem = "";
      this.showProjectMenu = !this.showProjectMenu;
    },

    hidemenu: function () {
      if (document.body.clientWidth <= 1200) {
        this.toggleClick();
      }
    },

    hideFilter: function () {
      if (document.body.clientWidth <= 1200) {
        this.toggleFilterBar("");
      }
    },
    hide: function () {
      console.log("DFFDFD");
    },
  },
  sockets: {
    connect: function () {
      this.$socket.emit("online", {
        session_id: this.$socket.id,
        user_id: this.$store.state.user_detail._id,
      });
      this.$socket.emit("all_notifications", {
        user_id: this.$store.state.user_detail._id,
      });
    },
    user_status: function (val) {
      var receiver_id = "";
      if (this.$store.state.user_detail._id == this.$store.state.chat.sender) {
        receiver_id = this.$store.state.chat.receiver;
      } else {
        receiver_id = this.$store.state.chat.sender;
      }
      if (this.$store.state.page == "chat_page" && receiver_id == val.user_id) {
        this.$store.commit("change_page", "chat_page");
        this.status = val.status;
        this.last_seen = val.last_seen_at;
        this.$socket.emit("fetch_chat_details", this.$store.state.chat.chat_id);
      }
    },
    typing: function (data) {
      this.typing = data;
      setTimeout(() => (this.typing = false), 3000);
    },
    group_typing: function (data) {
      this.type_group = data;
      setTimeout(() => (this.type_group = false), 3000);
    },
    // notify () {
    //     // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
    //     this.$notification.show('Hello World', {
    //         body: this.toast_message
    //     }, {})
    // },
    notification_alert: function (data) {
      if (data.type == "project") {
        console.log(data);
        if ((this.$store.state.user_detail._id = data.user_id)) {
          this.alert_toast = true;
          this.toast_message = data.message;
          this.toast_time = data.show_notification_time;
          this.toast_type = data.type;
          this.toast_link = "/project/list/" + data.project_id;
          console.log(this.toast_type);
          this.toast();
          this.notify();
        }
      } else if (data.type == "task") {
        console.log(data);
        this.alert_toast = true;
        this.toast_message = data.message;
        this.toast_time = data.show_notification_time;
        this.toast_type = data.type;
        this.toast_link = "/viewtask/" + data.task_id;
        console.log(this.toast_type);
        this.toast();
        this.notify();
      } else if (data.type == "message") {
        console.log(data);
        this.alert_toast = true;
        this.toast_message = data.message;
        this.toast_time = data.show_notification_time;
        this.toast_type = data.type;
        this.toast_link = "/chat/" + data.room_id;
        console.log(this.toast_type);
        this.toast();
        this.notify();
      } else if (data.type == "group") {
        this.alert_toast = true;
        this.toast_message = data.message;
        this.toast_time = data.show_notification_time;
        this.toast_type = data.type;
        this.toast_link = "/group/" + data.room_id;
        console.log(this.toast_type);
        this.toast();
        this.notify();
      } else if (data.type == "leave") {
        this.alert_toast = true;
        this.toast_message = data.message;
        this.toast_time = data.show_notification_time;
        this.toast_type = data.type;
        this.toast_link = "/leavelist";
        console.log(this.toast_type);
        this.toast();
        this.notify();
      }
      this.notifications_count += 1;
    },
    get_all_notifications_count: function (value) {
      this.notifications_count = value;
    },
    // stopTyping : function(data){
    //     this.typing = false;
    // },
  },
  watch: {
    $route(to, from) {
      this.$refs.filterBar.hide();
      switch (to.name) {
        case "MyTasks":
          this.filterName = "my_tasks";
          break;
        case "AllTasks":
          this.filterName = "all_tasks";
          break;
        case "SprintList":
          this.filterName = "sprints";
          break;
        case "AllTasksReport":
          this.filterName = "all_tasks_report";
          break;
        case "ProjectDisplay":
          this.filterName = "project_tasks";
          break;

        default:
          break;
      }

      if (to.name === "ProjectTasksLists" || to.name === "ProjectTasksBoard") {
        this.fetchproject();
      }

      let existing_my_task_filters = localStorage.getItem("myTasks_filters");
      if (this.$refs.filterBar) {
        this.$refs.filterBar.hide();
      }
      if (
        typeof existing_my_task_filters === "undefined" ||
        !existing_my_task_filters ||
        existing_my_task_filters === null ||
        existing_my_task_filters.trim() === ""
      ) {
        existing_my_task_filters = {
          mytask_visible_columns: this.mytask_visible_columns || [
            "title",
            "assigned_to",
            "created_at",
            "status",
            "project_id",
          ],
        };
        localStorage.setItem(
          "myTasks_filters",
          JSON.stringify(existing_my_task_filters)
        );
      } else {
        existing_my_task_filters = JSON.parse(existing_my_task_filters);
        this.mytask_visible_columns =
          existing_my_task_filters.mytask_visible_columns ||
            this.mytask_visible_columns || [
              "title",
              "assigned_to",
              "created_at",
              "status",
              "project_id",
            ];
        existing_my_task_filters = {
          ...existing_my_task_filters,
          mytask_visible_columns:
            existing_my_task_filters.mytask_visible_columns ||
              this.mytask_visible_columns || [
                "title",
                "assigned_to",
                "created_at",
                "status",
                "project_id",
              ],
        };
        localStorage.setItem(
          "myTasks_filters",
          JSON.stringify(existing_my_task_filters)
        );
      }

      let existing_all_task_filters = localStorage.getItem("allTasks_filters");
      if (
        typeof existing_all_task_filters === "undefined" ||
        !existing_all_task_filters ||
        existing_all_task_filters === null ||
        existing_all_task_filters.trim() === ""
      ) {
        existing_all_task_filters = {
          allTask_visible_columns: this.allTask_visible_columns || [
            "title",
            "assigned_to",
            "created_at",
            "status",
            "project_id",
          ],
        };
        localStorage.setItem(
          "allTasks_filters",
          JSON.stringify(existing_all_task_filters)
        );
      } else {
        existing_all_task_filters = JSON.parse(existing_all_task_filters);
        this.allTask_visible_columns =
          existing_all_task_filters.allTask_visible_columns ||
            this.allTask_visible_columns || [
              "title",
              "assigned_to",
              "created_at",
              "status",
              "project_id",
            ];
        existing_all_task_filters = {
          ...existing_all_task_filters,
          allTask_visible_columns:
            existing_all_task_filters.allTask_visible_columns ||
              this.allTask_visible_columns || [
                "title",
                "assigned_to",
                "created_at",
                "status",
                "project_id",
              ],
        };
        localStorage.setItem(
          "allTasks_filters",
          JSON.stringify(existing_all_task_filters)
        );
      }

      let existing_back_logs_filters = localStorage.getItem("BackLogs_filters");
      if (
        typeof existing_back_logs_filters === "undefined" ||
        !existing_back_logs_filters ||
        existing_back_logs_filters === null ||
        existing_back_logs_filters.trim() === ""
      ) {
        existing_back_logs_filters = {
          backLogs_visible_columns: this.backLogs_visible_columns || [
            "title",
            "assigned_to",
          ],
        };
        localStorage.setItem(
          "BackLogs_filters",
          JSON.stringify(existing_back_logs_filters)
        );
      } else {
        existing_back_logs_filters = JSON.parse(existing_back_logs_filters);
        this.backLogs_visible_columns =
          existing_back_logs_filters.backLogs_visible_columns ||
            this.backLogs_visible_columns || [
              "title",
              "assigned_to",
            ];
        existing_back_logs_filters = {
          ...existing_back_logs_filters,
          backLogs_visible_columns:
            existing_back_logs_filters.backLogs_visible_columns ||
              this.backLogs_visible_columns || [
                "title",
                "assigned_to",
              ],
        };
        localStorage.setItem(
          "BackLogs_filters",
          JSON.stringify(existing_back_logs_filters)
        );
      }

      let existing_all_task_report_filters = localStorage.getItem("allTasksReport_filters");
      if (
        typeof existing_all_task_report_filters === "undefined" ||
        !existing_all_task_report_filters ||
        existing_all_task_report_filters === null ||
        existing_all_task_report_filters.trim() === ""
      ) {
        existing_all_task_report_filters = {
          allTask_report_visible_columns: this.allTask_report_visible_columns || [
            "ticket_no",
        "title",
        "assigned_to",
        "project_id",
        "status",
        "actual_hours",
        "estimated_hours",
          ],
        };
        localStorage.setItem(
          "allTasksReport_filters",
          JSON.stringify(existing_all_task_report_filters)
        );
      } else {
        existing_all_task_report_filters = JSON.parse(existing_all_task_report_filters);
        this.allTask_report_visible_columns =
          existing_all_task_report_filters.allTask_report_visible_columns ||
            this.allTask_report_visible_columns || [
              "ticket_no",
        "title",
        "assigned_to",
        "project_id",
        "status",
        "actual_hours",
        "estimated_hours",
            ];
        existing_all_task_report_filters = {
          ...existing_all_task_report_filters,
          allTask_report_visible_columns:
            existing_all_task_report_filters.allTask_report_visible_columns ||
              this.allTask_report_visible_columns || [
                "ticket_no",
        "title",
        "assigned_to",
        "project_id",
        "status",
        "actual_hours",
        "estimated_hours",
              ],
        };
        localStorage.setItem(
          "allTasksReport_filters",
          JSON.stringify(existing_all_task_report_filters)
        );
      }


      let existing_all_sprint_filters = localStorage.getItem("sprints_filters");
      if (
        typeof existing_all_sprint_filters === "undefined" ||
        !existing_all_sprint_filters ||
        existing_all_sprint_filters === null ||
        existing_all_sprint_filters.trim() === ""
      ) {
        existing_all_sprint_filters = {
          allSprint_visible_columns: this.allSprint_visible_columns || [
            "sprint_name",
            "start_date",
            "end_date",
            "sprint_status",
            "status",
          ],
        };
        localStorage.setItem(
          "sprints_filters",
          JSON.stringify(existing_all_sprint_filters)
        );
      } else {
        existing_all_sprint_filters = JSON.parse(existing_all_sprint_filters);
        this.allSprint_visible_columns =
          existing_all_sprint_filters.allSprint_visible_columns ||
            this.allSprint_visible_columns || [
            "sprint_name",
            "start_date",
            "end_date",
            "sprint_status",
            "status",
            ];
        existing_all_sprint_filters = {
          ...existing_all_sprint_filters,
          allSprint_visible_columns:
            existing_all_sprint_filters.allSprint_visible_columns ||
              this.allSprint_visible_columns || [
                "sprint_name",
                "start_date",
                "end_date",
                "sprint_status",
                "status",
              ],
        };
        localStorage.setItem(
          "sprints_filters",
          JSON.stringify(existing_all_sprint_filters)
        );
      }


      let existing_project_task_filters = localStorage.getItem(
        "projectTasks_filters"
      );
      if (
        typeof existing_project_task_filters === "undefined" ||
        !existing_project_task_filters ||
        existing_project_task_filters === null ||
        existing_project_task_filters.trim() === ""
      ) {
        existing_project_task_filters = {
          projectTask_visible_columns: this.projectTask_visible_columns || [
            "title",
            "assigned_to",
            "created_at",
            "status",
          ],
        };
        localStorage.setItem(
          "projectTasks_filters",
          JSON.stringify(existing_project_task_filters)
        );
      } else {
        existing_project_task_filters = JSON.parse(
          existing_project_task_filters
        );
        this.projectTask_visible_columns =
          existing_project_task_filters.projectTask_visible_columns ||
            this.projectTask_visible_columns || [
              "title",
              "project_id",
              "due_date",
              "status",
            ];
        existing_project_task_filters = {
          ...existing_project_task_filters,
          projectTask_visible_columns:
            existing_project_task_filters.projectTask_visible_columns ||
              this.projectTask_visible_columns || [
                "title",
                "assigned_to",
                "created_at",
                "status",
              ],
        };
        localStorage.setItem(
          "projectTasks_filters",
          JSON.stringify(existing_project_task_filters)
        );
      }
      var receiver_details = {};
      if (this.$store.state.user_detail._id == this.$store.state.chat.sender) {
        receiver_details = this.$store.state.chat.sent_to_info;
        this.status = receiver_details.status;
        this.last_seen = receiver_details.last_seen_at;
      } else {
        receiver_details = this.$store.state.chat.sent_by_info;
        this.status = receiver_details.status;
        this.last_seen = receiver_details.last_seen_at;
      }
    },
  },
};
</script>

<style>
/* @import url('https://fonts.googleapis.com/css?family=Poppins:200,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Sans&display=swap'); */

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
html {
  overflow: hidden;
}

.typing {
  font-size: 13px;
  font-weight: normal !important;
}

.group_typing {
  display: inline-flex;
  font-size: 13px;
  font-weight: normal !important;
}

.dropdowner {
  color: #212529;
}

.project_options {
  display: flex;
}

.btn:focus {
  outline: none !important;
}

.dropdown-item .route {
  color: #212529 !important;
}

.btn:active {
  outline: none !important;
}

.btn-secondary {
  background-color: transparent !important;
  border: none !important;
  color: #484848 !important;
}

.btn-secondary:focus {
  outline: none !important;
}

.btn-secondary:hover {
  background-color: transparent !important;
  border-color: transparent;
}

.btn-secondary:active {
  background-color: transparent !important;
  border-color: transparent;
}

.header_icon {
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.05);
  height: 40px;
  width: 40px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  color: #ffffff;
}

.header-bar {
  margin: 10px 0px;
}

.main-section {
  overflow: auto;
  height: 100vh;
  padding-bottom: 100px;
}

.signin_section {
  overflow: auto;
  height: 100vh;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.toggler {
  display: flex;
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  padding: 0px;
  margin: auto 0px auto auto;
  background-color: transparent;
  border-radius: 50%;
}

.toggler:focus {
  outline: none;
}

.anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: transparent;
}

.anchor::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.28em solid transparent;
  border-bottom: 0;
  border-left: 0.28em solid transparent;
}

.anchor:hover {
  cursor: pointer;
}

.header_filter_icon:hover {
  color: rgba(143, 196, 145, 1) !important;
}

.menu {
  width: 150px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #212529;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  list-style: none;
  margin-left: -56px;
  padding: 16px;
  position: absolute;
  text-align: left;
  z-index: 1;
}

.menu-item {
  display: flex;
  color: #212529;
  gap: 8px;
  align-items: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.menu-item:hover {
  background-color: #f4f6f6;
  cursor: pointer;
}

.active-menu-item {
  background-color: #f4f6f8;
  cursor: pointer;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 20px;
  float: right;
}

.ddlist {
  min-width: 150px;
  font-weight: normal;
  font-size: 16px;
  text-transform: capitalize;
  position: absolute;
  background-color: white;
  margin-top: 15px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
  border-radius: 4px;
  right: 10px;
  top: 50px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

#sidebar {
  border-right: 1px solid #dee2e6;
}

@media (max-width: 575px) {
  #hiddenbar {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }

  #homelink {
    margin: auto 0px;
    height: fit-content;
    color: #ffffff;
    text-decoration: none;
  }

  .hiddenmenu svg {
    color: rgb(240, 255, 255);
    margin-left: 10px;
  }

  .oneline {
    padding-left: 0px;
    margin: 0px;
  }

  .hiddenmenu {
    border: none;
    background-color: transparent;
    margin: 11px 0px;
  }

  .hiddenmenu:focus {
    outline: none;
  }

  .ddlist {
    top: 100px;
  }
}
</style>
