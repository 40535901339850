<template>
  <div class="edit_page">
    <span
      v-if="!is_success"
      class="position-absolute t-0 r-0 bg-white l-0 mx-auto h-100 row align-items-center justify-content-center z-index-2"
      ><font-awesome-icon icon="spinner" size="lg" spin></font-awesome-icon
    ></span>
    <!-- <b-modal hide-header-close v-model="show_delete_confirmation_popup" @ok="delete_sprint" centered
          title="Delete Sprint">
              <p class="my-4">Are you sure to delete this sprint?</p>
            </b-modal>
    <div class="d-flex justify-content-end" v-if="this.$route.params.data && this.$route.params.data.id !== undefined"><font-awesome-icon @click="toggleDeleteConfirmation" icon="trash" title="delete" style="cursor: pointer;margin-right: 3.5%" size="lg"></font-awesome-icon
    ></div> -->
    <div class="col-12 row m-0 pt-3" v-if="is_success">
      <div class="col-lg-8">
        <div class="input-group mt-1 mb-4">
          <label for="sprint_name" class="grey-text p-0 text-left fa-15x"
            >Sprint Name</label
          >.
          <div class="col-12 p-0">
            <input
              type="text"
              placeholder="Sprint Name"
              id="sprint_name"
              v-model="sprint.sprint_name"
              class="form-control px-3 py-3 h-auto rounded m-0"
            />
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="start_date" class="grey-text p-0 text-left fa-15x w-100"
            >Start Date</label
          >
          <div class="col-12 p-0">
            <Datepicker
              class="datepicker_design"
              :use-utc="true"
              :bootstrap-styling="true"
              id="start_date"
              placeholder="DD MM YYYY"
              v-model="sprint.start_date"
            />
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="end_date" class="grey-text p-0 text-left fa-15x w-100"
            >End Date</label
          >
          <div class="col-12 p-0">
            <Datepicker
              class="datepicker_design"
              :use-utc="true"
              :bootstrap-styling="true"
              id="end_date"
              placeholder="DD MM YYYY"
              v-model="sprint.end_date"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-group mt-1 mb-4">
          <label for="sprint_status" class="grey-text p-0 text-left fa-15x w-100"
            >Sprint Status
          </label>
          <div class="col-12 p-0">
            <v-select
              class="v-select-design"
              :searchable="true"
              :multiple="false"
              v-model="selected_sprint_status"
              :options="['Open', 'In Progress', 'Completed']"
              placeholder="Select Sprint Status"
            >
            </v-select>
          </div>
        </div>
        <div class="input-group mt-1 mb-4">
          <label for="status" class="grey-text p-0 text-left fa-15x w-100"
            >Status
          </label>
          <div class="col-12 p-0">
            <v-select
              class="v-select-design"
              :searchable="true"
              :multiple="false"
              v-model="selected_sprint"
              :options="['Active', 'In Active']"
              placeholder="Select status"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="text-center my-4 col-12 pt-4 z-depth-top">
        <button
          class="primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded-pill text-white bold_font"
          type="submit"
          v-bind:class="{ disabled: is_btn_loading }"
          @click="save_sprint"
        >
          Save
          <span class="ml-2" v-if="is_btn_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
        </button>
        <button
          style="margin-left: 10px"
          class="btn border rounded-pill py-2 px-4"
          type="submit"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import debounce from "lodash.debounce";
import vSelect from "vue-select";
import Compress from "compress.js";
import LightBox from "vue-image-lightbox";
import { sprintStatusPropsForForm } from "../../types/sprintStatusTypesForForm.js";

export default {
  name: "AddSprint",
  components: {
    Datepicker,
    vSelect,
    Compress,
    LightBox,
  },
  data() {
    return {
      del_img_modal: false,
      slide: 0,
      text: "",
      show_delete_confirmation_popup: false,
      selected: null,
      sprint_status_options: [],
      status_options: [],
      sprint: {},
      status: [
        { text: sprintStatusPropsForForm["open"].name, value: "open" },
        { text: sprintStatusPropsForForm["in_progress"].name, value: "in_progress" },
        { text: sprintStatusPropsForForm["completed"].name, value: "completed" },
        { text: sprintStatusPropsForForm["active"].name, value: "active" },
        { text: sprintStatusPropsForForm["in_active"].name, value: "in_active" }
      ],
      modalShow: false,
      signed_in_user: "",
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      is_btn_loading: false,
      current_delete_id: "",
      is_success: true,
      selected_sprint: null,
      selected_sprint_status: null,
    };
  },
  mounted() {
    this.$store.commit("change_page", "sprint");
  },
  methods: {
    toggleDeleteConfirmation() {
      this.show_delete_confirmation_popup = !this.show_delete_confirmation_popup;
    },
    change_sprint_status(value) {
      this.sprint_status_options = value.sprint;
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    onEditorBlur(editor) {},
    onEditorFocus(editor) {},
    onEditorReady(editor) {},
    delete_image(id) {
      this.current_delete_id = id;
      this.del_img_modal = !this.del_img_modal;
    },
    showmodal: function (index) {
      this.modalShow = !this.modalShow;
    },
    makeToast(variant = null) {
      this.$root.$bvToast.toast(`Sprint details updated`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    save_sprint: function () {
      this.sprint.status = this.selected_sprint;
      this.sprint.sprint_status = this.selected_sprint_status;
      var err = "";
      if (this.sprint.sprint_name == "" || typeof this.sprint.sprint_name == "undefined") {
        err = "Please fill sprint name";
      } else if (
        this.sprint.start_date == "" ||
        typeof this.sprint.start_date == "undefined"
      ) {
        err = "Please select a start date for sprint";
      } else if (
        this.sprint.end_date == "" ||
        typeof this.sprint.end_date == "undefined"
      ) {
        err = "Please select a end date for sprint";
      } else if (
        this.sprint.sprint_status == "" ||
        typeof this.sprint.sprint_status == "undefined"
      ) {
        err = "Please select sprint status";
      } else if (
        this.sprint.status == "" ||
        typeof this.sprint.status == "undefined"
      ) {
        err = "Please select status";
      }
      if (err == "") {
        this.is_btn_loading = true;
        this.$http
          .post(this.$store.state.api_url + "addsprint", this.sprint)
          .then(function (data) {
            this.is_btn_loading = false;
            var response = data.body;
            if (response.status === true) {
              let details = response.data;
              if(response.message == "Sprint created") {
              this.$root.$bvToast.toast(`Sprint details created!`, {
                title: "Success",
                variant: "success",
                autoHideDelay: 3000,
              });
              } else {
               this.$root.$bvToast.toast(`Sprint details updated!`, {
                title: "Success",
                variant: "success",
                autoHideDelay: 3000,
              });
              }
                this.$router.push("/sprint");
            } else {
              this.$root.$bvToast.toast(`Sprint already exits!`, {
                title: "Error",
                variant: "danger",
                autoHideDelay: 3000,
              });
            }
          });
      } else {
        this.$bvToast.toast(err, {
          title: "Error",
          autoHideDelay: 1000,
          variant: "danger",
        });
      }
    },
    delete_sprint: function () {
      let id = this.$route.params.id;
      this.$http
        .post(this.$store.state.api_url + "delete_sprint", { _id: id })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.$router.push("/sprint");
          }
        });
    },
    cancel: function () {
        this.$router.push("/sprint");
     },
  },
  created() {
    if((JSON.parse(localStorage.getItem("userinfo")).user_role != "admin") && (JSON.parse(localStorage.getItem("userinfo")).user_role != "super_admin")) {
      this.$router.push("/sprint");
    }
    if(this.$route.params.data.status != "" && this.$route.params.data.sprint_name != "") {
this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    if((JSON.parse(localStorage.getItem("userinfo")).user_role == "admin") || (JSON.parse(localStorage.getItem("userinfo")).user_role == "super_admin")) {
      this.sprint.id = this.$route.params.data.id;
      this.sprint.sprint_name = this.$route.params.data.sprint_name;
      this.sprint.start_date = this.$route.params.data.start_date;
      this.sprint.end_date = this.$route.params.data.end_date;
      this.sprint.sprint_status = this.$route.params.data.sprint_status;
      this.selected_sprint_status = this.$route.params.data.sprint_status;
      this.selected_sprint = this.$route.params.data.status;
      this.sprint.type = true;
    } else {
      this.$router.push("/sprint");
    }
    }
  },
  watch: {
    "$route.params.id": function (id) {
    },
  },
};
</script>

<style>
.vs__dropdown-menu {
  font-size: 1rem !important;
}

.ql-toolbar.ql-snow {
  padding: 0.375rem 0.75rem !important;
}

.ql-container {
  margin-bottom: 8px !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}


#modal-1 .modal-body {
  height: calc(100vh - 180px) !important;
}

#modal-1 .img-fluid {
  object-fit: contain !important;
  height: 100%;
}

#modal-1 .carousel.slide,
#modal-1 .carousel-inner {
  height: 100% !important;
}

.selectize-dropdown .selectize-dropdown-content .create {
  display: none;
}

.hide_input input {
  display: none;
}
</style>
