<template id="profilebutton-template">
<div>
    <button ref="button" class="toggler" v-on:click="showdd=!showdd">
        <img class="ml-auto avatar" src="../assets/empty-avatar-png.png" />
    </button>
    <div v-show="showdd" v-closable="{exclude: ['button'],handler: 'onClose'}" v-if="showdd">
        <ul class="ddlist">
            <li class="profile_buttons"> <router-link class="route" v-bind:to="{name:'AddUser', params: edit_user_info}"> Edit Profile </router-link> </li>
            <li class="profile_buttons" @click="clearsession" >Logout</li>
        </ul>
    </div>
</div>
</template>

<script>
import Vue from 'vue'

let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

export default {
    name: 'Profilebutton',
    data () {
        return {
            showdd: false,
            edit_user_info: '',
        }
    },
    created() {
        // this.$eventHub.$on('change-binary', this.changebin);
        this.edit_user_info = JSON.parse(localStorage.getItem('userinfo'));
    },
    beforeDestroy(){
        // this.$eventHub.$off('change-binary');
    },
    methods: {
        changebin(toggle){
            this.showmenu = toggle;
        },
        onClose () {
            this.showdd = false
        },
        clearsession () {
            localStorage.removeItem('userinfo');
            this.$router.push('/signin');
            this.showdd = false
        }
    }
}
</script>

<style>

.route{
  color: #484848;
}

.profile_buttons:hover{
    cursor: pointer;
}

.toggler{
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  padding: 0px;
  margin-left: auto;
  background-color: transparent;
  border-radius: 50%;
}

.toggler:focus{
  outline: none;
}

.avatar{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 20px;
  float:right;
}

.ddlist{
  min-width: 150px;
  font-weight: normal;
  font-size: 16px;
  text-transform: capitalize;
  position:absolute;
  background-color: white;
  margin-top: 15px;
  box-shadow: 0 0 0 1px rgba(111,119,130,.15), 0 5px 20px 0 rgba(21,27,38,.08);
  border-radius: 4px;
  right: 10px;
  top: 50px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

</style>