<template>
  <div
    class="list-column list-width"
    @mouseover="isHovered = true"
    @mouseout="handleMouseOut"
  >
    <div class="heading" :style="{ borderColor: getBackgroundColor }">
      <div class="heading-text text-center ml-2">{{ getHeaderName }}</div>
      <TaskListActions
        :list="list"
        :handleRemoveStatus="handleRemoveStatus"
      ></TaskListActions>
    </div>
    <div class="cards cards-list" :class="{ 'list-hover': isHovered }">
      <draggable v-model="items" v-bind="dragOptions" class="list">
        <TaskListItem
          v-for="item in items"
          :item="item"
          :list="list"
          :key="item.id"
        ></TaskListItem>
      </draggable>
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import TaskListActions from "@/components/Lists/TaskListActions";
import TaskListItem from "@/components/Items/TaskListItem";
import { mapActions } from "vuex";

export default {
  components: {
    TaskListItem,
    Draggable,
    TaskListActions,
  },
  props: [
    "list",
    "filter_by_assignee",
    "start_date",
    "end_date",
    "handleRemoveStatus",
  ],
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: "200",
        ghostClass: "ghost",
        group: "kanban-board-list-items",
        // disabled: false
      };
    },
    items: {
      get() {
        let filtered_data = this.list.items;
        if (this.filter_by_assignee.length) {
          let new_data = [];
          for (let i = 0; i < this.filter_by_assignee.length; i++) {
            for (let j = 0; j < filtered_data.length; j++) {
              if (this.filter_by_assignee[i] == filtered_data[j].assigned_to) {
                new_data.push(filtered_data[j]);
              }
            }
          }
          filtered_data = new_data;
        }

        if (this.start_date && this.end_date) {
          const newArr = filtered_data.filter(
            (item) =>
              item.created_at.slice(0, 10) >= this.start_date &&
              item.due_date.slice(0, 10) <= this.end_date
          );
          filtered_data = newArr;
        }

        return filtered_data;
      },
      set(reorderedListItems) {
        const payload = {
          listName: this.list.name,
          items: reorderedListItems,
        };
        this.reorderTaskListItems(payload);
      },
    },
    getBackgroundColor() {
      switch (this.list.name) {
        case "in_progress":
          return "blue";
        case "todo":
          return "orange";
        case "dev_completed":
          return "green";
        case "new":
          return "#33E9FF";
        case "Completed":
          return "lightgreen";
        case "rot":
          return "yellow";
        case "pot":
          return "green";
        case "fot":
          return "red";
        default:
          return "grey";
      }
    },
    getHeaderName() {
      switch (this.list.name) {
        case "in_progress":
          return "IN PROGRESS";
        case "todo":
          return "TO DO";
        case "dev_completed":
          return "DEV COMPLETED";
        case "new":
          return "NEW";
        case "Completed":
          return "COMPLETED";
        case "rot":
          return "READY ON TEST";
        case "pot":
          return "PASS ON TEST";
        case "fot":
          return "FAIL ON TEST";
        default:
          return this.list.name;
      }
    },
  },
  methods: {
    ...mapActions({
      reorderTaskListItems: "reorderTaskListItems",
    }),
    handleScroll() {
      const scrollbar = document.querySelector(
        ".list-width::-webkit-scrollbar-thumb"
      );
      if (scrollbar) {
        scrollbar.style.backgroundColor = "#888";
      }
    },
    handleMouseOut() {
      // Only handle mouse out if not hovering, to avoid immediate transition
      if (!this.isHovered) {
        const container = document.querySelector(".list-width");
        if (container) {
          container.removeEventListener("scroll", this.handleScroll);
        }
      }
    },
  },
  mounted() {
    const container = document.querySelector(".list-width");
    if (container) {
      container.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeDestroy() {
    const container = document.querySelector(".list-width");
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped>
.dot-icon {
  font-size: medium;
  cursor: pointer;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px;
  margin-bottom: 30px;
  border: 3px solid;
  border-radius: 8px 8px 0px 0px;
  background-color: #fefefe;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-color: #999797;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.list-width {
  width: 300px;
  transition: max-height 0.3s ease;
  margin: 10px;
}

.heading-text {
  font-size: 17px;
  padding: 5px;
  font-weight: bold;
}

.list-hover {
  min-height: 0vh;
  max-height: 90vh;
  overflow-y: auto;
  transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s;
}

.list-hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.list-hover::-webkit-scrollbar {
  width: 7px;
}

.list-hover::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.list-hover::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
