export const sprintStatusPropsForForm = {
    open: {
        name: "Open",
        key: "open",
        color: "#007bff",
        short_name: "Open",
    },
    in_progress: {
        name: "In Progress",
        key: "in_progress",
        color: "#ff980e",
        short_name: "In Progress",
    },
    completed: {
        name: "Completed",
        key: "completed",
        color: "#ff98ee",
        short_name: "Completed",
    },
    active: {
        name: "Active",
        key: "active",
        color: "#612f12",
        short_name: "Active",
    },
    in_active: {
        name: "In Active",
        key: "in_active",
        color: "#91B35B",
        short_name: "In Active",
    }
};
