<template>
<div class="col-12">
    <div class="row align-items-center mx-0">
        <div class="col-12 col-md-4 mb-3">
            <label class="fa-sm-custom light-grey-text">Search by year</label>
            <div class="">
                <div class="dd_year">
                    <v-select class="v-select-design" :no-drop="false" :searchable="true" :multiple="false" v-model="year_filter" @input="filter_year" :options="year_options" placeholder="Choose year" >
                    </v-select>
                </div>
            </div>
        </div>
        <div class="ml-auto text-right col-12 col-md-8">
            <div class="holiday">
                <button v-b-tooltip.hover title="Add Holiday" class="btn primary-bg d-flex align-items-center ml-auto justify-content-center rounded-circle img-high-small text-white" @click="addholiday">
                    <font-awesome-icon icon="plus" size="md" /> 
                </button>
            </div>
        </div>
    </div>
    <div class="tasklist row w-100 mx-0 mt-3">
        <div class="taskform col-12" id="no-more-tables">
            <table class="table table-striped table_design text-left">
            <thead class="table_header">
                <tr class="p-0">
                    <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic employee_name">Holiday name</th>
                    <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic employee_email">Date</th>
                    <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic employee_action">Action</th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr class="p-0" v-for="item in holidays" :key="item._id">
                    <td data-title="Holiday Name" class="employee_name">{{item.leave_name}}</td>
                    <td data-title="Date" class="employee_email">{{item.date_of_leave}}</td>
                    <td data-title="Action" class="employee_action">
                        <div style="display:inline-flex" class="bg-white px-2 py-1 rounded">
                            <router-link class="grey-text d-inline-block text-decoration-none py-0 px-2 fa-sm-custom border-right" v-bind:to="{name:'AddLeave', params: item}">Edit</router-link>
                            <a href="javascript:void(0)" class="grey-text d-inline-block text-decoration-none py-0 px-2 fa-sm-custom" @click="del_leave(item._id)">Delete</a> <!-- "del_modal = !del_modal" -->
                        </div>
                    </td>
                    <!-- <b-modal v-model="del_modal" @ok="del_leave(item)" >
                        <p class="my-4">Are you sure to delete holiday?</p>
                    </b-modal> -->
                </tr>
                <tr v-if="holidays.length == 0">
                    <td colspan="3">No Holidays</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="mt-5 paginator">
            <ul style="display:flex" v-if="pages && pages.length > 1">
                <li class="page-item" v-bind:class="{disabled:current === 1}">
                    <a class="page-link"  @click="get_leaves(1)">
                        <span aria-hidden="true">|&lt; </span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
                <li class="page-item" v-bind:class="{disabled:current === 1}">
                    <a class="page-link"  @click="get_leaves(current - 1)"> &lt;
                    </a>
                </li>
                <li class="page-item" v-for="item in pages" :key="item.id" v-bind:class="{active:current === item.id}">
                    <a class="page-link"  @click="get_leaves(item.id)">{{item.id}}</a>
                </li>
                <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                    <a class="page-link"  @click="get_leaves(current + 1)">&gt;</a>
                </li>
                <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                    <a class="page-link"  @click="get_leaves(totalPages)">
                        <span aria-hidden="true"> &gt;| </span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
    name: 'Holidays',
    components: {
        Datepicker
    },
    data () {
        return {
            year_filter: '',
            del_modal: false,
            page: 1,
            current: 1,
            totalDocs: 0,
            totalPages: 0,
            startPage: 1,
            endPage: 1,
            pages:[],
            serial_number:'',
            holidays: {},
            current_year: '',
            year_options: [],
            filter: '',
            state: {
                disabledDates: {
                    //   to: new Date(), // Disable all dates up to specific date
                    // from: new Date(2020, 18, 2), // Disable all dates after specific date
                    // days: [6, 0], // Disable Saturday's and Sunday's
                    // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
                }
            }
        }
    },
    methods: {
        filter_year: function() {
            if(this.year_filter != null || this.year_filter != ""){
                this.filter = this.year_filter
                this.get_leaves(this.page)
            }
        },
        addholiday: function(){
            this.$router.push({name:'AddLeave'})
        },
        set_year_dd: function(){
            for(var i = this.current_year; i >= 2018; i-- ){
                this.year_options.push(i)
            }
        },
        get_leaves:function(page){
            let params = {
                pageno : page,
                size: 15,
                year: this.filter
            };
            this.$http.get(this.$store.state.api_url+'allholidays',{params}).then(function(response){
                response = response.body;
                this.data=response.data.docs;
                this.serial_number = response.data.pagingCounter;
                this.current =response.data.page;
                this.totalDocs=response.data.totalDocs;
                this.totalPages=response.data.totalPages;
                this.holidays=this.data;
                if(this.totalPages <= 5) 
                {
                    this.startPage = 1;
                    this.endPage = this.totalPages;
                } 
                else 
                {
                    if (this.current <= 3) {
                        this.startPage = 1;
                        this.endPage = 5;
                    } else if (this.current + 2 >= this.totalPages) {
                        this.startPage = this.totalPages - 4;
                        this.endPage = this.totalPages;
                    } else {
                        this.startPage = this.current - 2;
                        this.endPage = this.current + 2;
                    }
                }
                this.pages = Array.from(Array((this.endPage + 1) - this.startPage).keys()).map(i => ({ id: (this.startPage+i), name: 'Item ' + (this.startPage+i) }));
            })
        },
        del_leave:function (id){
            this.$http.post(this.$store.state.api_url+'delete_leave',{id}).then(function(data){
                var response = data.body;
                if(response.status === true){
                    this.get_leaves(this.page)
                }
            })
        }
    },
    mounted() {
        this.current_year = new Date().getFullYear()
        this.filter = new Date().getFullYear()
        this.year_filter = this.filter
        this.set_year_dd()
        this.get_leaves(this.page)
        this.$store.commit("change_page", "holidays");
        if(this.$store.state.user_detail.user_role != 'admin' && this.$store.state.user_detail.user_role != 'super_admin'){
            this.$router.push({name:'Home'})
        }
    },
}
</script>