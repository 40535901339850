import Vue from 'vue'
import Vuex from 'vuex'
var commonHelper = require("../../node_app/src/commonHelper");
import axios from "axios";
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    project: {},
    scrumgroup: {},
    scrumgrouppagetitle: "",
    task: {},
    sprint: {},
    page: '',
    user_detail: {},
    api_url: commonHelper.getBaseUrl() + '/api/',            //https://teams.waioz.com/api/
    chat: {},
    status: '',
    group_chat: {},
    isLoading: true,
    boards: []
  },
  getters: {
    full_name: state => {
      return state.user_detail.full_name + "'s"
    },
    project_name: state => {
      if (state.project != undefined) {
        return state.project.name
      }
      else {
        return ''
      }
    },
    project_color: state => {
      if (state.project != undefined) {
        return state.project.color
      }
      else {
        return ''
      }
    },
    project_details: state => {
      if (state.project != undefined) {
        return state.project
      }
      else {
        return ''
      }
    },
    project_id: state => {
      if (state.project != undefined) {
        return state.project._id
      }
      else {
        return ''
      }
    },
    chat_details: state => {
      return state.chat
    },
    isLoading: state => state.isLoading,
    allBoards: state => state.boards,
  },
  mutations: {
    change_page(state, payload) {
      state.page = payload
    },
    set_group_page_title(state, payload) {
      state.scrumgrouppagetitle = payload
    },
    set_sprint_page_title(state, payload) {
      state.sprintpagetitle = payload
    },
    set_user_detail(state, payload) {
      state.user_detail = payload
    },
    set_project_detail(state, payload) {
      state.project = payload
    },
    set_chat_details(state, payload) {
      state.chat = payload
    },
    set_status(state, payload) {
      state.status = payload
    },
    set_group_chat(state, payload) {
      state.group_chat = payload
    },
    // Set Initial Data
    SET_INITIAL_DATA(state, payload) {
      if (payload.length === 1 && state.boards.length > 0) {
        if (payload[0].items.length == 0) {
          return alert('Fully Loaded')
        }
        state.boards.forEach((val) => {
          if (val.name === payload[0].name) {
            payload[0].items.forEach((data) => val.items.push(data));
          }
        })
      } else {
        state.boards = payload
      }
    },

    // Set Loading State
    SET_LOADING_STATE(state, payload) {
      state.isLoading = payload
    },

    // Reorder TaskBoad Lists
    REORDER_TASKLISTS(state, payload) {
      const board = state.boards.find(b => b.id == payload.boardId)
      Vue.set(board, "lists", payload.lists)
    },

    // Reorder Task List Items
    REORDER_TASKLIST_ITEMS(state, payload) {
      const listIdx = state.boards.findIndex(l => l.name == payload.listName)
      Vue.set(state.boards[listIdx], "items", payload.items)
    },

  },
  actions: {
    async fetchData({ commit }, payload) {
      commit("SET_LOADING_STATE", true)
      return axios.post(this.state.api_url + 'alltaskstatus', payload).then(res => {
        commit("SET_INITIAL_DATA", res.data)
        commit("SET_LOADING_STATE", false)
      })

    },

    async reorderTaskLists({ commit }, payload) {
      commit("REORDER_TASKLISTS", payload)
    },

    async reorderTaskListItems({ commit }, payload) {
      commit("REORDER_TASKLIST_ITEMS", payload)
      return axios.post(this.state.api_url + 'updatetaskstatus', payload)
        .then(res => {
          console.log(res.data.message)
        })
        .catch(err => {
          if (err.response.status === 404) {
            console.log(err.response.data.message);
          } else {
            console.log('Internal Server Error');
          }
        })
    },
  }
})  
