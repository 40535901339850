var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 px-0 pt-7"},[_c('div',{staticClass:"toolbar d-flex flex-column row col-12 m-0 p-0"},[(
        _vm.$store.state.user_detail.user_role == 'admin' ||
        _vm.$store.state.user_detail.user_role == 'super_admin' ||
        _vm.$store.state.user_detail._id == this.$store.state.project.manager
      )?_c('div',{staticClass:"left_children col-12 p-0 px-md-3 col-md-6"}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('ScoreCard',{attrs:{"set_status":_vm.filter_status,"projectId":[_vm.projectId],"user_filter":_vm.filter_by_assignee,"scorecard_filter":_vm.scorecard_filter}}),_c('div',{staticClass:"filtered_items"},[(_vm.searchtask !== '' && typeof _vm.searchtask !== 'undefined')?_c('div',{staticClass:"filter_chip"},[_vm._v(" Task Name: "),_c('b',[_vm._v(_vm._s(_vm.searchtask))]),_c('font-awesome-icon',{staticClass:"ml-2 clear-filter",attrs:{"icon":"times","size":"1x"},on:{"click":function($event){return _vm.remove_filter('searchtask', _vm.searchtask)}}})],1):_vm._e(),(_vm.ticket_no !== '' && typeof _vm.ticket_no !== 'undefined')?_c('div',{staticClass:"filter_chip"},[_vm._v(" Ticket Number: "),_c('b',[_vm._v(_vm._s(_vm.ticket_no))]),_c('font-awesome-icon',{staticClass:"ml-2 clear-filter",attrs:{"icon":"times","size":"1x"},on:{"click":function($event){return _vm.remove_filter('ticket_no', _vm.ticket_no)}}})],1):_vm._e(),(_vm.selected_members.length > 0)?_c('div',{staticClass:"d-flex align-items-center"},_vm._l((_vm.selected_members),function(assignee,index){return _c('div',{key:index},[(_vm.isFiltered_assignee(assignee._id))?_c('div',{staticClass:"filter_chip"},[_vm._v(" assignee: "),_c('b',[_vm._v(_vm._s(assignee.full_name))]),_c('font-awesome-icon',{staticClass:"ml-2 clear-filter",attrs:{"icon":"times","size":"1x"},on:{"click":function($event){return _vm.remove_filter('filter_by_assignee', assignee._id)}}})],1):_vm._e()])}),0):_vm._e(),(
            _vm.selected_status.length > 0 &&
            typeof _vm.selected_status !== 'undefined'
          )?_c('div',{staticClass:"d-flex align-items-center"},_vm._l((_vm.selected_status),function(status,index){return _c('div',{key:index,staticClass:"filter_chip"},[_vm._v(" Status: "),_c('b',[_vm._v(_vm._s(status.name))]),_c('font-awesome-icon',{staticClass:"ml-2 clear-filter",attrs:{"icon":"times","size":"1x"},on:{"click":function($event){return _vm.remove_filter('selected_status', status)}}})],1)}),0):_vm._e(),(_vm.start_date !== '' && typeof _vm.start_date !== 'undefined')?_c('div',{staticClass:"filter_chip"},[_vm._v(" Due Date: "),_c('b',[_vm._v(_vm._s(_vm.start_date)+" - "+_vm._s(_vm.end_date))]),_c('font-awesome-icon',{staticClass:"ml-2 clear-filter",attrs:{"icon":"times","size":"1x"},on:{"click":function($event){return _vm.remove_filter('due_date', _vm.start_date)}}})],1):_vm._e()])],1)]),(_vm.is_loading)?_c('span',{staticClass:"m-auto"},[_c('font-awesome-icon',{attrs:{"icon":"spinner","size":"sm","spin":""}})],1):_vm._e(),(!_vm.is_loading)?_c('div',{staticClass:"board-page"},[_c('BoardPage',{attrs:{"filter_by_assignee":_vm.filter_by_assignee,"start_date":_vm.start_date,"end_date":_vm.end_date,"selected_status":_vm.selected_status}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }