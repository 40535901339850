<template>
  <div class="home col-12">
    <ScoreCardForAllTaskReport />
    <div class="filtered_items">

      <div
        v-if="ticket_no !== '' && typeof ticket_no !== 'undefined'"
        class="filter_chip"
      >
        Ticket Number: <b>{{ ticket_no }}</b>
        <font-awesome-icon
          class="ml-2 clear-filter"
          @click="remove_filter('ticket_no', ticket_no)"
          icon="times"
          size="1x"
        ></font-awesome-icon>
      </div>
      <div v-if="project_list.length > 0" class="d-flex align-items-center">
        <div v-for="(project, index) in project_list" :key="index">
          <div v-if="isFiltered(project._id)" class="filter_chip">
            Project:
            <b>{{ project.name }}</b>
            <font-awesome-icon
              @click="remove_filter('filter_by_project', project._id)"
              class="ml-2 clear-filter"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
      <div
        v-if="filtered_assignees.length > 0"
        class="d-flex align-items-center"
      >
        <div v-for="(assignee, index) in filtered_assignees" :key="index">
          <div v-if="isFiltered_assignee(assignee._id)" class="filter_chip">
            Assigned to:
            <b>{{ assignee.full_name }}</b>
            <font-awesome-icon
              @click="remove_filter('filter_by_assignee', assignee._id)"
              class="ml-2 clear-filter"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
        </div>
      </div>

    </div>
    <div class="table1 row m-0 mt-0">

      <div class="tasklist row w-100 mx-0">
        <div class="taskform col-12" id="no-more-tables">
          <span class="m-auto" v-if="is_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
          <table
            class="table table-hover table_design table-striped"
            v-if="!is_loading"
          >
            <thead>
              <tr class="p-0 m-0 position-relative">

                <th
                  v-if="visible_columns.includes('ticket_no')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_ticket_no"
                >
                  Ticket Number
                </th>
                <th
                  v-if="visible_columns.includes('title')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_tasks_name"
                >
                  Task name
                </th>
                <th
                  v-if="visible_columns.includes('assigned_to')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_assigned_to"
                >
                  Assigned to
                </th>

                <th
                  v-if="visible_columns.includes('project_id')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_project_name"
                >
                  Project
                </th>
                <th
                  v-if="visible_columns.includes('status')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_updated_at"
                >
                  Status
                </th>
                <th
                  v-if="visible_columns.includes('created_at')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_created_at"
                >
                  Created Date
                </th>

                <th
                  v-if="visible_columns.includes('updated_at')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_updated_at"
                >
                  Modified at
                </th>
                <th
                  v-if="visible_columns.includes('sprint')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_sprint"
                >
                  Sprint
                </th>
                <th
                  v-if="visible_columns.includes('labels')"
                  class="semi_bold_font text-center grey-text font-weight-normal border-0 font-italic all_labels"
                >
                  Labels
                </th>
                <th
                  v-if="visible_columns.includes('due_date')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_due_date"
                >
                  Due Date
                </th>
                <th
                  v-if="visible_columns.includes('description')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_description"
                >
                  Description
                </th>

                <th
                  v-if="visible_columns.includes('project_code')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_project_code"
                >
                  Project Code
                </th>

                <th
                  v-if="visible_columns.includes('estimated_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_estimated_hours"
                >
                  Estimated Hours
                </th>

                <th
                  v-if="visible_columns.includes('actual_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_actual_hours"
                >
                  Actual Hours
                </th>                
                <th
                  v-if="visible_columns.includes('record_status')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_updated_at"
                >
                  Record Status
                </th>

              </tr>
            </thead>
            <tbody v-if="!is_task_success">
              <td colspan="5">
                <ContentLoader>
                  <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
                </ContentLoader>
              </td>
            </tbody>
            <tbody class="table-body" v-if="is_task_success">
              <tr
                class="p-0 position-relative rounded m-0 table-body-row"
                v-for="item in all_tasks"
                :key="item._id"
                @click="goto_task(item.ticket_number)"
              >
                <td
                  v-if="visible_columns.includes('ticket_no')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_ticket_no"
                >
                  {{ item.ticket_no }}
                </td>
                <td
                  v-if="visible_columns.includes('title')"
                  class="all_tasks_name"
                  data-title="Task"
                >
                  <span class="font-8 semi_bold_font">{{
                    item.ticket_number
                  }}</span>
                  <br />{{ item.title
                  }}<span v-if="item.title == null"> - </span>
                </td>
                <td
                  v-if="visible_columns.includes('assigned_to')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_assigned_to"
                >
                  {{
                    item.assigned_to_detail !== null &&
                    typeof item.assigned_to_detail !== "undefined"
                      ? item.assigned_to_detail.full_name
                      : ""
                  }}
                </td>
                <td
                  v-if="visible_columns.includes('project_id')"
                  class="all_project_name"
                  data-title="Project"
                >
                  {{
                    item.project_id !== null &&
                    typeof item.project_id !== "undefined"
                      ? item.project_id.name
                      : "-"
                  }}
                </td>
                <td
                  v-if="visible_columns.includes('status')"
                  class="all_updated_at"
                  data-title="status"
                >
                  <span
                    :style="{
                      background: get_color(item.status.toLowerCase()),
                    }"
                    class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                    >{{ status_props[item.status].name }}</span
                  >
                </td>

                <td
                  v-if="visible_columns.includes('created_at')"
                  class="all_updated_at fa-sm-custom"
                  data-title="Updated At"
                >
                  {{ format_date(item.created_at) }}
                </td>

                <td
                  v-if="visible_columns.includes('updated_at')"
                  class="all_updated_at fa-sm-custom"
                  data-title="Updated At"
                >
                  {{ format_date(item.updated_at) }}
                </td>

                <td v-if="visible_columns.includes('sprint')"
                    class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_sprint">
                    <span v-if="typeof item.sprint === 'undefined' || item.sprint === null"></span>
                    <span v-else-if="item.sprint.length === 0"></span>
                    <span v-else-if="Array.isArray(item.sprint)">
                        {{ item.sprint.map((data, index) => {
                            return data;
                        }).join(", ") }}
                    </span>
                    <span v-else></span>
                </td>
                <td
                  v-if="visible_columns.includes('labels')"
                  class="semi_bold_font grey-text d-flex flex-column font-weight-normal border-0 font-italic all_labels"
                >
                  <span
                    v-for="(label, index) in item.labels"
                    :key="index"
                    class="status_bg ml-1 text-black mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                    >{{ label }},</span
                  >
                </td>
                <td
                  v-if="visible_columns.includes('due_date')"
                  class="all_due_date fa-sm-custom due_date"
                  data-title="Due Date"
                >
                  {{ format_date(item.due_date) }}
                </td>

                <td
                  v-if="visible_columns.includes('description')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_description"
                >
                  <span v-html="item.description"></span>
                </td>
                <td
                  v-if="visible_columns.includes('project_code')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_project_code"
                >
                  {{ item.project_code }}
                </td>
                <td
                  v-if="visible_columns.includes('estimated_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_estimated_hours"
                >
                  {{ item.estimated_hours }}
                </td>
                <td
                  v-if="visible_columns.includes('actual_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_actual_hours"
                >
                  {{ item.actual_hours }}
                </td>
                <td
                  v-if="visible_columns.includes('record_status')"
                  class="all_updated_at"
                  data-title="status"
                >
                  <span
                    :class="item.record_status === 'active' ? 'pot' : 'fot'"
                    class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                    >{{ item.record_status }}</span
                  >
                </td>
              </tr>

              <tr v-if="all_tasks.length == 0">
                <td colspan="5">No Tasks here!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-3 row m-0 col-12" v-if="is_task_success">
        <ul
          class="pagination pagination_design ml-auto d-flex list-type-none"
          v-if="Taskpages && Taskpages.length > 1"
        >
          <li
            class="page-item"
            v-bind:class="{ disabled: current_task_page === 1 }"
          >
            <a class="page-link" @click="get_all_tasks(1)">
              <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
            </a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current_task_page === 1 }"
          >
            <a class="page-link" @click="get_all_tasks(current_task_page - 1)">
              <font-awesome-icon icon="angle-left"> </font-awesome-icon>
            </a>
          </li>
          <li
            class="page-item"
            v-for="item in Taskpages"
            :key="item.id"
            v-bind:class="{ active: current_task_page === item.id }"
          >
            <a class="page-link" @click="get_all_tasks(item.id)">{{
              item.id
            }}</a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current_task_page === totalTaskPages }"
          >
            <a class="page-link" @click="get_all_tasks(current_task_page + 1)">
              <font-awesome-icon icon="angle-right"> </font-awesome-icon>
            </a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current_task_page === totalTaskPages }"
          >
            <a class="page-link" @click="get_all_tasks(totalTaskPages)">
              <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import moment from "moment";
import ScoreCardForAllTaskReport from "../components/ScoreCardForAllTaskReport.vue";
import { StatusProps } from "../types/statusTypes";

export default {
  components: { ContentLoader, moment, ScoreCardForAllTaskReport },
  name: "Home",
  data() {
    return {
      taskpage: 1,
      is_task_success: false,
      all_tasks: [],
      current_task_page: 1,
      totalTaskDocs: 0,
      totalTaskPages: 0,
      startTaskPage: 1,
      endTaskPage: 1,
      Taskpages: [],
      project_list: [],
      status_props: StatusProps,
      filter_project: "", //Filter v-model for project... Yet to work on
      filter_by_project: [],
      visible_columns: [],
      is_loading: false,
      filter_status: "", //Filter v-model for status... Yet to work on,
      member_options: [],
      filter_name: "",
      filter_by_assignee: [],
      filtered_assignees: [],
      start_date: "",
      end_date: "",
      ticket_no: "",
      scoreCard_data: [],
      selected_status: [],
      filtered_sprints: [],
      checkedRow: [],
    };
  },
  created() {
    this.$eventHub.$on("search_all_task_report", this.get_all_tasks);
    this.$eventHub.$on("all_task_report_table", this.refresh_table);
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.refresh_table();
    this.get_all_tasks(this.taskpage);
    this.get_project_list();
    this.get_local_storage_filter();
  },
  methods: {
    goto_task(ticket_no) {
      this.$router.push({ path: '/taskproxy/' + ticket_no });
    },
    format_date_str(dateStr) {
      const parsedDate = moment(dateStr, "DD/MM/YYYY");
      const formattedDate = parsedDate.format("YYYY-MM-DD");

      return moment(formattedDate).isValid() ? formattedDate : "";
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].color;
      } else return "#000";
    },
    get_local_storage_filter() {
      let existing_filters = localStorage.getItem("allTasksReport_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          start_date: this.start_date,
          end_date: this.end_date,
          selected_status: this.selected_status,
          filtered_sprints: this.filtered_sprints,
          filter_by_project: this.filter_by_project,
          filtered_assignees: this.filtered_assignees,
          filter_by_assignee: this.filter_by_assignee,
          allTask_report_visible_columns: this.visible_columns,
          ticket_no: this.ticket_no,
        };
        localStorage.setItem(
          "allTasksReport_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);

        this.start_date = existing_filters.start_date || "";
        this.end_date = existing_filters.end_date || "";
        this.selected_status = existing_filters.selected_status || [];
        this.filtered_sprints = existing_filters.filtered_sprints || [];
        this.filter_by_project = existing_filters.filter_by_project || [];
        this.filter_project = existing_filters.filter_project;
        this.visible_columns = existing_filters.allTask_report_visible_columns;
        this.ticket_no = existing_filters.ticket_no || "";
        this.filtered_assignees = existing_filters.filtered_assignees || [];
        this.filter_by_assignee = existing_filters.filter_by_assignee || [];
      }
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasksReport_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );
    },
    reset_filters() {
      const existing_filters = {
        start_date: this.start_date,
        end_date: this.end_date,
        selected_status: this.selected_status,
        filtered_sprints: this.filtered_sprints,
        filter_by_project: this.filter_by_project,
        filter_project: this.filter_project,
        allTask_report_visible_columns: this.visible_columns,
      };

      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );
    },
    get_score: function (status) {
      const status_data = this.scoreCard_data.find(
        (item) => item._id === status
      );
      if (typeof status_data !== "undefined") return status_data.count;
      else return 0;
    },
    refresh_table: function () {
      let existing_filters = localStorage.getItem("allTasksReport_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          allTask_report_visible_columns: this.visible_columns,
        };
        localStorage.setItem(
          "allTasksReport_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);

        this.visible_columns = existing_filters.allTask_report_visible_columns || [];
      }
    },
    get_all_tasks: function (page) {
      this.is_loading = true;
      const filters = JSON.parse(localStorage.getItem("allTasksReport_filters"));
      const sort = localStorage.getItem("all_task_report_sort");
      let params = {
        pageno: page || 1,
        size: 10,
        user_filter: filters.filter_by_assignee,
        project_filter: filters.filter_by_project,
        start_filter:
          filters.start_date !== ""
            ? this.format_date_str(filters.start_date)
            : "",
        end_filter:
          filters.end_date !== "" ? this.format_date_str(filters.end_date) : "",
        ticket_no: filters.ticket_no,
      };
      if (filters.selected_status) {
        params = {
          ...params,
          set_status:
            filters.selected_status.length > 0
              ? filters.selected_status.map((status) => status.value)
              : [],
        };
      }
      if (filters.filtered_sprints) {
        params = {
          ...params,
          set_sprint:
            filters.filtered_sprints.length > 0
              ? filters.filtered_sprints.map((sprint) => sprint.sprint_name)
              : [],
        };
      }
      if (sort) {
        switch (sort) {
          case "Created High to Low":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: 1,
            };
            break;
          case "Created Low to High":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: -1,
            };
            break;
          case "DueDate High to Low":
            params = {
              ...params,
              sortBy: "due_date",
              sortOrder: 1,
            };
            break;
          case "DueDate Low to High":
            params = {
              ...params,
              sortBy: "due_date",
              sortOrder: -1,
            };
            break;
          default:
            break;
        }
      } else {
        params = {
          ...params,
          sortBy: "created_at",
          sortOrder: -1,
        };
      }
      this.$http
        .get(this.$store.state.api_url + "alltasks_admin", { params })
        .then(function (response) {
          this.$eventHub.$emit("refresh_alltask_report_card");
          this.is_loading = false;
          this.is_task_success = true;
          this.all_tasks = response.body.data.docs;
          this.current_task_page = response.body.data.page;
          this.totalTaskDocs = response.body.data.totalDocs;
          this.totalTaskPages = response.body.data.totalPages;
          if (this.totalTaskPages <= 5) {
            this.startTaskPage = 1;
            this.endTaskPage = this.totalTaskPages;
          } else {
            if (this.current_task_page <= 3) {
              this.startTaskPage = 1;
              this.endTaskPage = 5;
            } else if (this.current_task_page + 2 >= this.totalTaskPages) {
              this.startTaskPage = this.totalTaskPages - 4;
              this.endTaskPage = this.totalTaskPages;
            } else {
              this.startTaskPage = this.current_task_page - 2;
              this.endTaskPage = this.current_task_page + 2;
            }
          }
          this.scoreCard_data = response.body.scoreCard || [];

          this.Taskpages = Array.from(
            Array(this.endTaskPage + 1 - this.startTaskPage).keys()
          ).map((i) => ({
            id: this.startTaskPage + i,
            name: "Item " + (this.startTaskPage + i),
          }));
        });
      this.is_loading = false;
      this.get_local_storage_filter();
    },
    get_project_list: function () {
      this.$http
        .post(this.$store.state.api_url + "projectchat", {})
        .then(function (response) {
          this.project_list = response.body.data;
        });
    },
    get_userlist: function () {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers")
        .then(function (response) {
          this.member_options = response.body.data;
        });
    },
    apply_project_filter(value) {
      this.filter_by_project = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_project.push(element);
        }
      } else {
        this.filter_by_project = [];
      }
      this.get_all_tasks(this.taskpage);
    },
    apply_status_filter(value) {
      if (value != null) {
        this.filter_status = value;
      } else {
        this.filter_status = "";
      }
      this.get_all_tasks(this.taskpage);
    },
    apply_assignee_filter(value) {
      this.filter_by_assignee = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_assignee.push(element);
        }
      } else {
        this.filter_by_assignee = [];
      }
      this.get_all_tasks(this.taskpage);
    },
    isFiltered(id) {
      return this.filter_by_project.includes(id) ? true : false;
    },

    isFiltered_assignee(id) {
      return this.filter_by_assignee.includes(id) ? true : false;
    },
    remove_filter(name, value) {
      switch (name) {
        case "filter_by_project":
          let new_projects = this.filter_by_project.filter(
            (project) => project !== value
          );
          let new_projects_list = this.filter_project.filter(
            (project) => project._id !== value
          );
          this.filter_project = new_projects_list;
          this.filter_by_project = new_projects;
          this.update_local_storage_filters(
            "filter_project",
            new_projects_list
          );
          this.update_local_storage_filters(name, new_projects);
          this.get_all_tasks(this.taskpage);
          break;
        case "filter_by_assignee":
          let new_assignees = this.filter_by_assignee.filter(
            (assignee) => assignee !== value
          );
          let new_assignees_list = this.filtered_assignees.filter(
            (assignee) => assignee._id !== value
          );
          this.filtered_assignees = new_assignees_list;
          this.filter_by_assignee = new_assignees;
          this.update_local_storage_filters(
            "filtered_assignees",
            new_assignees_list
          );
          this.update_local_storage_filters(name, new_assignees);
          this.get_all_tasks(this.taskpage);
          break;
        default:
          break;
      }
      this.$eventHub.$emit("refresh_allTaskReport");
    },
  },
  mounted() {
    this.get_project_list();
    this.get_userlist();
    this.get_all_tasks(this.taskpage);
    this.get_local_storage_filter();
    this.$store.commit("change_page", "All Tasks Report");
  },
  watch: {
    start_date(newValue, oldValue) {
      this.start_date = newValue;
    },
    end_date(newValue, oldValue) {
      this.end_date = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    filtered_sprints(newValue, oldValue) {
      this.filtered_sprints = newValue;
    },
    filter_by_project(newValue, oldValue) {
      this.filter_by_project = newValue;
    },
    filter_task(newValue, oldValue) {
      this.filter_task = newValue;
    },
    filtered_assignees(newValue, oldValue) {
      this.filtered_assignees = newValue;
    },
    filter_by_assignee(newValue, oldValue) {
      this.filter_by_assignee = newValue;
    },
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style>
@media (min-width: 700px) {
  .table.table_design {
    min-width: 100vw;
    overflow-x: auto;
  }
  .all_assigned_to,
  .all_project_code,
  .all_sprint,
  .all_estimated_hours,
  .all_actual_hours,
  .all_labels {
    width: 150px;
    max-width: 200px;
  }

  .all_ticket_no {
    width: 100px;
  }
  .all_tasks_name {
    width: 240px !important;
  }
  .all_description {
    width: 560px !important;
    overflow-wrap: break-word;
  }

  /* .all_tasks_flag {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f1f1f1;
  } */

  .all_project_name,
  .all_due_date,
  .all_created_at {
    width: 120px !important;
  }

  .all_updated_at {
    width: 124px !important;
  }
  .flag_status {
    width: 60px;
  }
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.filtered_items {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.filter_chip {
  display: flex;
  background-color: #e6e6e6;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.close-filter,
.clear-filter {
  cursor: pointer;
}
.paginator {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.page-item {
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: rgb(54, 88, 143) !important;
  color: #ffffff;
}
.img-small {
  width: 30px;
  height: 30px;
}
.img-high-small {
  width: 40px;
  height: 40px;
  z-index: 1;
}
</style>
