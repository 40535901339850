<template>
  <div class="no-data-found">
    <h1>No Ticket Found</h1>
    <p>Sorry, we couldn't find the ticket you were looking for.</p>
    <button @click="goBack" class="btn btn-primary">Go Back</button>
  </div>
</template>

<script>
export default {
  name: 'NoDataFound',
  methods: {
    goBack() {
      this.$router.go(-2);
    }
  }
};
</script>

<style scoped>
.no-data-found {
  text-align: center;
  margin-top: 50px;
}

.no-data-found button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}
</style>
