<template>
  <div class="d-flex justify-content-center align-items-center" style="min-height: 100vh;">
    <span v-if="loading">
      <font-awesome-icon icon="spinner" size="lg" spin></font-awesome-icon>
    </span>
    <div v-else>
      <!-- Your existing content here -->
    </div>
  </div>
</template>

<script>

export default {
  name: "TaskRouter",
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$http.get(this.$store.state.api_url + "task_id/" + this.$route.params.ticket_no)
      .then((res) => {
        if(res.body.data != null) {
             localStorage.setItem("showing_task_data_id", res.body.data.id);
        localStorage.setItem("showing_task_data_ticket_id", this.$route.params.ticket_no);
        this.$router.replace({ path: '/viewtask/' + this.$route.params.ticket_no });
        } else {
            this.$router.replace({ path: '/nodatafound/' });
        }
      })
      .catch((err) => {
        console.log(err); 
      });
  }
};
</script>