<template>
<div class="fullpage text_logo bg-grey h-sm-100">
    <div class="col-md-12 p-0 h-100">
        <div class="d-flex justify-content-end p-10 align-items-center h-100">
         <div class="modal-container radius_xs_0 z-depth-xs-0 p-5 position-relative bg-white row mx-0 align-items-center h-100">
            <div class="col-lg-5 col-md-7 col-sm-7 col-xs-10 text-center m-auto">
                <h1 class="text-center mb-4">Log in</h1>
                <div class="input-group">
                    <label for="email" class="grey-text  p-0 text-left">Email Address</label>
                    <div class="col-12 p-0">
                        <input type="email" placeholder="name@mail.com" id="email" v-model="userinfo.emailid" class="p-3 w-100 outline-none border-1 rounded mb-3 font-8"/>
                    </div>
                </div>
                <div class="input-group">
                    <label for="password" class="grey-text p-0 text-left">Password</label>
                    <div class="col-12 p-0">
                        <input :type="passwordFieldType" placeholder="Password" id="password" v-model="userinfo.password" @keyup.enter="login" class="p-3 w-100 outline-none border-1 rounded mb-0 font-8"/>
                        <font-awesome-icon :icon="eyeIcon" size="1x" class="mr-3 pointer position-absolute t-0 b-0 r-0 my-auto primary-text-color" @click="showPassword"></font-awesome-icon>
                    </div>
                </div>
                <div class="text-left mt-5">
                    <button v-bind:class="{ disabled: is_btn_loading }" class="d-flex align-items-center z-depth-0 justify-content-center btn primary-bg outline-none pointer w-100 font-1 p-3 rounded text-white bold_font" type="submit" @click.prevent="login">Log In
                            <span v-bind:class="{ angle_loader: is_btn_loading }" class="d-inline-flex ml-2"><font-awesome-icon icon="angle-right" size="1x" class="ml-2"></font-awesome-icon>
                            <font-awesome-icon icon="angle-right" size="1x" class=""></font-awesome-icon></span>
                    </button>
                </div>
                <br/> <br/>
                <div class="col-12 p-0">
                    <a v-b-modal.modal-center href="javascript:void(0)" class="mb-3 outline-none primary-text-color bold_font">Forgot password?</a>
                    <b-modal ok-only hide-header-close id="modal-center" centered title="Uh oh!">
                        <p class="my-4">Please contact your admin!</p>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'Signin',
    data () {
        return {
            userinfo: {},
            loggedInUser: '',
            passwordFieldType: 'password',
            eyeIcon: 'eye',
            is_btn_loading: false
        }
    },
    methods: {
        showPassword() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            this.eyeIcon = this.passwordFieldType === 'password' ? 'eye' : 'eye-slash'
        },
        login:function(){
            if(this.userinfo.emailid == undefined) {
                this.makeToast("Enter Email ID")
            }
            else if (!this.validateEmail(this.userinfo.emailid)) {
                this.makeToast("Please Enter Valid Email ID")
            }
            else if(this.userinfo.password == undefined) {
                this.makeToast("Enter Password")
            }
            else {
                this.is_btn_loading = true;
                this.$http.post(this.$store.state.api_url+'login/',this.userinfo).then(function(data){
                    var response = data.body;
                    let userinfo = response.data;
                    this.is_btn_loading = false;
                    if(response.status === true){
                        localStorage.setItem("userinfo", JSON.stringify(userinfo))
                        if(localStorage.getItem('userinfo')){
                            this.$store.commit("set_user_detail", JSON.parse(localStorage.getItem('userinfo')));
                            this.$socket.emit('user_connect',{user_id: this.$store.state.user_detail._id, socket_id: this.$socket.id}) 
                        }
                        this.$notification.requestPermission()
                            // .then(console.log)
                        this.$router.push('/');
                    }else{
                        this.makeToast("Enter valid credentials")
                    }
                })
            }
        },
        makeToast(msg) {
            this.$bvToast.toast(msg, {
                title: 'Invalid Credentials',
                variant: 'danger',
                autoHideDelay: 3000,
            })
        },
        validateEmail(email) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
    }
}
</script>

<style scoped>

.modal-container{
    border-radius: 100px 0 0 100px;
    box-shadow: 0 20px 30px 0 rgba(36,50,66,.14);
    width: 100%;
    max-width: calc(100% - 6em);
}

</style>
