<template>
  <div class="home col-12">

     <!-- <ScoreCard
      :set_status="status_list"
      :set_status_name="status_name_list"
      :scorecard_filter="scorecard_filter"
      :tabs_list="tabs"
      :set_tab="handleTabClick"
    /> -->

    <div class="filtered_items" style="margin-bottom: 10px;">
      <div
      v-if="sprint_name_in_filter_shower != '' && typeof sprint_name_in_filter_shower != 'undefined'"
      class="filter_chip"
     >
      Sprint Name: <b>{{ sprint_name_in_filter_shower }}</b>
      <font-awesome-icon
        class="ml-2 clear-filter"
        @click="remove_filter('name_filter', sprint_name_in_filter_shower)"
        icon="times"
        size="1x"
      ></font-awesome-icon>
    </div>
    <div
      v-if="start_date_in_filter_shower != '' && typeof start_date_in_filter_shower != 'undefined'"
      class="filter_chip"
    >
      Start Date: <b>{{ new Date(start_date_in_filter_shower).toISOString().split('T')[0] }}</b>
      <font-awesome-icon
        @click="remove_filter('start_date_filter', start_date_in_filter_shower)"
        class="ml-2 clear-filter"
        icon="times"
        size="1x"
      ></font-awesome-icon>
    </div>
    <div
      v-if="end_date_in_filter_shower != '' && typeof end_date_in_filter_shower != 'undefined'"
      class="filter_chip"
    >
      End Date: <b>{{ new Date(end_date_in_filter_shower).toISOString().split('T')[0] }}</b>
      <font-awesome-icon
        @click="remove_filter('end_date_filter', end_date_in_filter_shower)"
        class="ml-2 clear-filter"
        icon="times"
        size="1x"
      ></font-awesome-icon>
    </div>
     
    </div>

    
    <div class="mx-0">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" v-for="(tab, index) in tabs" :key="index" :style="{ cursor: 'pointer' }">
          <a class="nav-link" :class="{ 'active': activeTab === tab.id }" @click="handleTabClick(tab.id);scorecard_filter(status_list[index], status_name_list[index])" style="font-weight: bold;" data-toggle="tab" role="tab">
            {{ tab.name }}
          </a>
        </li>
      </ul>
        <div class="tab-content mt-2">
        <div v-for="(tab, index) in tabs" :key="index" class="tab-pane" :class="{ 'active': activeTab === tab.id }" :id="tab.id" role="tabpanel">

     <div class="table1 row m-0 mt-0">
      

      <div class="sprintlist row w-100 mx-0">
        <div class="sprintform col-12" id="no-more-tables">
          <span class="m-auto" v-if="is_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
          <table
            class="table table-hover table_design table-striped"
            v-if="!is_loading"
          >
            <thead>
              <tr class="p-0 m-0 position-relative">

                <th
                  v-if="visible_columns.includes('sprint_name')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_sprint_name"
                >
                  Sprint Name
                </th>
                <th
                  v-if="visible_columns.includes('start_date')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_start_date"
                >
                  Start Date
                </th>
                <th
                  v-if="visible_columns.includes('end_date')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_end_date"
                >
                 End Date 
                </th>
                <th
                  v-if="visible_columns.includes('sprint_status')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_sprint_status"
                >
                  Sprint Status
                </th>
                <th
                  v-if="visible_columns.includes('status')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_status"
                >
                  Status
                </th>
                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_action_button"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody v-if="!is_sprint_list_success">
              <td colspan="5">
                <ContentLoader>
                  <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
                </ContentLoader>
              </td>
            </tbody>
            <tbody class="table-body" v-if="is_sprint_list_success">
              <tr
                class="p-0 position-relative rounded m-0 table-body-row"
                v-for="item in all_sprints"
                :key="item._id"
                style="cursor: default;"
              >
                
                <td
                  v-if="visible_columns.includes('sprint_name')"
                  class="all_sprints_name"
                  data-title="sprint"
                >
                  <span class="font-8 semi_bold_font">{{
                    item.sprint_name
                  }}</span><span v-if="item.sprint_name == null"> - </span>
                </td>
                
                <td
                  v-if="visible_columns.includes('start_date')"
                  class="all_start_date fa-sm-custom"
                  data-title="start_date"
                >
                  {{ format_date(item.start_date) }}
                </td>

                <td
                  v-if="visible_columns.includes('end_date')"
                  class="all_end_date fa-sm-custom"
                  data-title="end_date"
                >
                  {{ format_date(item.end_date) }}
                </td>

                <td
                  v-if="visible_columns.includes('sprint_status')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_sprint_status"
                >
                  {{ item.sprint_status }}
                </td>
                <td
                  v-if="visible_columns.includes('status')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_status"
                >
                  <span v-html="item.status"></span>
                </td>
                <td
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_action_button"
                >

                <font-awesome-icon icon="eye" style="cursor: pointer;" class="mr-2" title="View" @click="view_item(item);"></font-awesome-icon>
                <font-awesome-icon icon="edit" v-if="$store.state.user_detail.user_role == 'admin' ||
            $store.state.user_detail.user_role == 'super_admin'" style="cursor: pointer;" class="mx-2" title="Edit" @click="goto_sprint(item)"></font-awesome-icon>
                <font-awesome-icon icon="file-alt" style="cursor: pointer;" class="mx-2" title="Report" @click="report_item(item)"></font-awesome-icon>
          
                </td>
              </tr>

              <tr v-if="all_sprints.length == 0">
                <td colspan="5">No sprints here!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-3 row m-0 col-12" v-if="is_sprint_list_success">
        <ul
          class="pagination pagination_design ml-auto d-flex list-type-none"
          v-if="Sprintpages && Sprintpages.length > 1"
        >
          <li
            class="page-item"
            v-bind:class="{ disabled: current_sprint_page === 1 }"
          >
            <a class="page-link" @click="get_all_sprints(1)">
              <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
            </a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current_sprint_page === 1 }"
          >
            <a class="page-link" @click="get_all_sprints(current_sprint_page - 1)">
              <font-awesome-icon icon="angle-left"> </font-awesome-icon>
            </a>
          </li>
          <li
            class="page-item"
            v-for="item in Sprintpages"
            :key="item.id"
            v-bind:class="{ active: current_sprint_page === item.id }"
          >
            <a class="page-link" @click="get_all_sprints(item.id)">{{
              item.id
            }}</a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current_sprint_page === totalSprintPages }"
          >
            <a class="page-link" @click="get_all_sprints(current_sprint_page + 1)">
              <font-awesome-icon icon="angle-right"> </font-awesome-icon>
            </a>
          </li>
          <li
            class="page-item"
            v-bind:class="{ disabled: current_sprint_page === totalSprintPages }"
          >
            <a class="page-link" @click="get_all_sprints(totalSprintPages)">
              <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import moment from "moment";
import ScoreCard from "../../components/ScoreCardForSprints.vue";
import { sprintStatusProps } from "../../types/sprintStatusTypes";

export default {
  components: { ContentLoader, moment, ScoreCard },
  name: "SprintList",
  data() {
    return {
      sprintpage: 1,
      is_sprint_list_success: false,
      all_sprints: [],
      current_sprint_page: 1,
      totalSprintDocs: 0,
      totalSprintPages: 0,
      startSprintPage: 1,
      endSprintPage: 1,
      Sprintpages: [],
      status_props: sprintStatusProps,
      status_list: ["Open", "In Progress", "Completed", "Active", "In Active"],
      status_name_list: ["sprint_status", "sprint_status", "sprint_status", "status", "status"],
      visible_columns: [],
      is_loading: false,
      filter_status: "", 
      filter_name: "",
      start_date: "",
      end_date: "",
      sprint_name: "",
      sprint_status: "",
      status: "",
      searchsprint: "",
      scoreCard_data: [],
      selected_status: [],
      sprint_name_in_filter_shower: "",
      start_date_in_filter_shower: "",
      end_date_in_filter_shower: "",
      activeTab: 'tab1', 
      tabs: [
      { id: 'tab1', name: 'Open' },
      { id: 'tab2', name: 'In - Progress' },
      { id: 'tab3', name: 'Completed' },
      { id: 'tab4', name: 'Active' },
      { id: 'tab5', name: 'In - Active' }
    ]
    };
  },
  created() {
    this.$eventHub.$on("search_sprint", this.get_all_sprints);
    this.$eventHub.$on("all_sprint_table", this.refresh_table);
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.refresh_table();
    this.get_all_sprints(this.sprintpage);
    this.get_local_storage_filter();
  },
  methods: {
    goto_sprint(item) {
      if(this.$store.state.user_detail.user_role == "admin" || this.$store.state.user_detail.user_role == "super_admin") {
        this.$router.push({  name: 'AddSprint',
                            params: { id: item.id, data: item }});
      }
    },
    update_local_storage_filters_only_for_sprint(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "allTasks_filters",
        JSON.stringify(existing_filters)
      );
    },
    update_local_storage_filters_only_for_task_report(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasksReport_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );
    },
    view_item(item) {
      this.update_local_storage_filters_only_for_sprint("filter_by_sprint", [item._id]);
      this.update_local_storage_filters_only_for_sprint("filtered_sprints", [item]);
      this.$router.push({  name: 'AllTasks' });
    },
    report_item(item) {
      this.update_local_storage_filters_only_for_task_report("filter_by_sprint", [item._id]);
      this.update_local_storage_filters_only_for_task_report("filtered_sprints", [item]);
      this.$router.push({  name: 'AllTasksReport' });
    },
    format_date_str(dateStr) {
      const parsedDate = moment(dateStr, "DD/MM/YYYY");
      const formattedDate = parsedDate.format("YYYY-MM-DD");

      return moment(formattedDate).isValid() ? formattedDate : "";
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].color;
      } else return "#000";
    },
    get_local_storage_filter() {
      let existing_filters = localStorage.getItem("sprints_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          start_date: this.start_date,
          end_date: this.end_date,
          sprint_name: this.sprint_name,
          sprint_status: this.sprint_status,
          status: this.status,
          searchsprint: this.searchsprint,
          selected_status: this.selected_status,
          allSprint_visible_columns: this.visible_columns,
        };
        localStorage.setItem(
          "sprints_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);

        this.start_date = existing_filters.start_date || "";
        this.end_date = existing_filters.end_date || "";
        this.sprint_name = existing_filters.sprint_name || "",
        this.sprint_status = existing_filters.sprint_status || "";
        this.status = existing_filters.status || "";
        this.searchsprint = existing_filters.searchsprint || "";
        this.selected_status = existing_filters.selected_status || [];
       
        this.filter_sprint = existing_filters.searchsprint;
        this.visible_columns = existing_filters.allSprint_visible_columns;
        this.sprint_name_in_filter_shower = JSON.parse(localStorage.getItem("sprint_filters")).name_filter || "";
        this.start_date_in_filter_shower = JSON.parse(localStorage.getItem("sprint_filters")).start_date_filter || "";
        this.end_date_in_filter_shower = JSON.parse(localStorage.getItem("sprint_filters")).end_date_filter || "";
       
      }
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("sprint_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "sprint_filters",
        JSON.stringify(existing_filters)
      );
    },
    reset_filters() {
      const existing_filters = {
        start_date: this.start_date,
        end_date: this.end_date,
        searchsprint: this.searchsprint,
        selected_status: this.selected_status,
       
        allSprint_visible_columns: this.visible_columns,
      };

      localStorage.setItem(
        "sprints_filters",
        JSON.stringify(existing_filters)
      );
    },
    scorecard_filter: function (status, status_name) {
      this.reset_filters();
      this.selected_status = status;
      this.update_local_storage_filters("selected_status", 
        { name: this.get_status_name(status.toLowerCase()), value: status, status_field_name: status_name },
    );
      this.get_all_sprints(this.sprintpage);
      this.$eventHub.$emit("refresh_allSprint");
    },

    get_score: function (status) {
      const status_data = this.scoreCard_data.find(
        (item) => item._id === status
      );
      if (typeof status_data !== "undefined") return status_data.count;
      else return 0;
    },
    refresh_table: function () {
      let existing_filters = localStorage.getItem("sprints_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          allSprint_visible_columns: this.visible_columns,
        };
        localStorage.setItem(
          "sprints_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);

        this.visible_columns = existing_filters.allSprint_visible_columns || [];
      }
    },
    get_all_sprints: function (page) {
      this.is_loading = true;
      const filters = JSON.parse(localStorage.getItem("sprint_filters"));
      const sort = localStorage.getItem("sprint_sort");
      let params = {
        pageno: page || 1,
        size: 10,
        
        search_sprint: filters.searchsprint,
        sprint_name: filters.name_filter,
        start_date_filter:
          filters.start_date_filter != ""
            ? filters.start_date_filter
            : "",
        end_date_filter:
          filters.end_date_filter != "" ? filters.end_date_filter : "",
          selected_status: filters.selected_status == undefined || filters.selected_status == null ? {
                name: "open",
                value: "Open",
                status_field_name: "sprint_status"
            } : filters.selected_status,
          selected_tab_data: filters.selected_status,
      
      };
      if (sort) {
        switch (sort) {
          case "Created High to Low":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: 1,
            };
            break;
          case "Created Low to High":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: -1,
            };
            break;
          default:
            break;
        }
      } else {
        params = {
          ...params,
          sortBy: "created_at",
          sortOrder: -1,
        };
      }
      this.$http
        .get(this.$store.state.api_url + "allsprints_admin", { params })
        .then(function (response) {
          this.is_loading = false;
          this.is_sprint_list_success = true;
          this.all_sprints = response.body.data.docs;
          this.current_sprint_page = response.body.data.page;
          this.totalSprintDocs = response.body.data.totalDocs;
          this.totalSprintPages = response.body.data.totalPages;
          if (this.totalSprintPages <= 5) {
            this.startSprintPage = 1;
            this.endSprintPage = this.totalSprintPages;
          } else {
            if (this.current_sprint_page <= 3) {
              this.startSprintPage = 1;
              this.endSprintpage = 5;
            } else if (this.current_sprint_page + 2 >= this.totalSprintPages) {
              this.startSprintPage = this.totalSprintPages - 4;
              this.endSprintPage = this.totalSprintPages;
            } else {
              this.startSprintPage = this.current_sprint_page - 2;
              this.endSprintPage = this.current_sprint_page + 2;
            }
          }
          this.scoreCard_data = response.body.scoreCard || [];

          this.Sprintpages = Array.from(
            Array(this.endSprintPage + 1 - this.startSprintPage).keys()
          ).map((i) => ({
            id: this.startSprintPage + i,
            name: "Item " + (this.startSprintPage + i),
          }));
        });
      this.is_loading = false;
      this.get_local_storage_filter();
    },
    
    apply_project_filter(value) {

      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
        
        }
      } else {
      
      }
      this.get_all_sprints(this.sprintpage);
    },
    apply_status_filter(value) {
      if (value != null) {
        this.filter_status = value;
      } else {
        this.filter_status = "";
      }
      this.get_all_sprints(this.sprintpage);
    },
    
    remove_filter(name, value) {
      switch (name) {
        case "name_filter":
          this.searchsprint = "";
          this.sprint_name_in_filter_shower = "";
          this.update_local_storage_filters(name, "");
          this.get_all_sprints(this.sprintpage);
          break;

        
        case "start_date_filter":
          this.start_date = "";
          this.start_date_in_filter_shower = "";
          this.update_local_storage_filters("start_date_filter", "");
          this.get_all_sprints(this.sprintpage);
          break;
          case "end_date_filter":
          this.end_date = "";
          this.end_date_in_filter_shower = "";
          this.update_local_storage_filters("end_date_filter", "");
          this.get_all_sprints(this.sprintpage);
          break;
        

        case "selected_status":
          let new_status = this.selected_status.filter(
            (status) => status.value !== value.value
          );
          this.selected_status = new_status;
          this.update_local_storage_filters(name, new_status);
          this.get_all_sprints(this.sprintpage);
          break;
        
          break;
        default:
          break;
      }
      this.$eventHub.$emit("refresh_allSprint");
    },
    handleTabClick(tabId) {
      this.activeTab = tabId;
    },
  },
  mounted() {
    this.update_local_storage_filters("selected_status", { name: "open", value: "Open", status_field_name: "sprint_status" },
    );   
    this.get_all_sprints(this.sprintpage);
    this.get_local_storage_filter();
    this.$store.commit("change_page", "All Sprints");
  },
  watch: {
    start_date(newValue, oldValue) {
      this.start_date = newValue;
    },
    end_date(newValue, oldValue) {
      this.end_date = newValue;
    },
    searchsprint(newValue, oldValue) {
      this.searchsprint = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    
    filter_sprint(newValue, oldValue) {
      this.filter_sprint = newValue;
    },
    
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style>
@media (min-width: 700px) {
  .table.table_design {
    min-width: 100%;
    overflow-x: auto;
  }
  .all_assigned_to,
  .all_project_code,
  .all_sprint,
  .all_action_button,
  .all_labels {
    width: 150px;
    max-width: 200px;
  }

  .all_ticket_no {
    width: 100px;
  }
  .all_sprints_name {
    width: 240px !important;
  }

  .flag_status {
    width: 60px;
  }
}
.scorecard-container.row {
  margin-left: 8px !important;
}
.scorecard {
  display: flex;
  cursor: pointer;
  color: white !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  font-size: 24px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #ebebeb;
}
.score_count {
  font-size: 20px;
}
.new {
  background-color: #007bff !important;
}


.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.filtered_items {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.filter_chip {
  display: flex;
  background-color: #e6e6e6;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.close-filter,
.clear-filter {
  cursor: pointer;
}
.paginator {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.page-item {
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: rgb(54, 88, 143) !important;
  color: #ffffff;
}

</style>
