<template>
    <div class="col-12">
        <div class="col-12 px-0 py-3 row m-0">
            <!-- <h5 class="mt-4">
                <span v-if="is_edit_page">Update Employee</span>
                <span v-if="!is_edit_page">Add Employee</span>
            </h5> -->
            <div class="col-md-3 mb-3" v-if="is_edit_page && (this.$store.state.user_detail._id == employee._id)">
                <label class="position-relative">
                    <img :src="this.$store.state.user_detail.profile_photo" class="w-100 rounded h-200 o-cover" />
                    <div class="text-center my-4 hide_input m-auto">
                        <label for="images" class="p-0 position-absolute r-0 t-0 text-center p-2 img-small bg-white rounded-circle d-flex justify-content-center m-1 align-items-center">
                            <font-awesome-icon icon="pencil-alt" size="sm" /> 
                        </label>
                        <div class="col-12 p-0">
                            <input type="file" accept="image/png, image/jpeg, image/jpg" id="images" ref="files" @change="uploadimage" />
                        </div>
                    </div>
                </label>
            </div>
            <div class="col-md-9 row p-0 form-table mx-auto">
                <!-- <div class="col-12 mb-3">
                    <label for="employeename" class="light-grey-text p-0 text-left">Employee Name</label>
                    <div class="col-12 p-0 row m-0">
                        <input type="text" placeholder="Full name" id="employeename" v-model="employee.full_name" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"/>
                    </div>
                </div> -->
                <div class="row m-0 col-12 p-0">
                    <div class="col-md-6 mb-3">
                        <label for="employeename" class="light-grey-text p-0 text-left">Employee Name</label>
                        <div class="col-12 p-0 row m-0">
                            <input type="text" placeholder="Full name" id="employeename" v-model="employee.full_name" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"/>
                        </div>
                    </div>
                    <div v-if="(this.$store.state.user_detail.user_role == 'super_admin' || this.$store.state.user_detail.user_role == 'admin') && (this.$store.state.user_detail._id != employee._id)" class="col-md-6 mb-3">
                        <label for="role" class="light-grey-text p-0 text-left">Role</label>
                        <div class="col-12 p-0">
                            <v-select class="v-select-design" :no-drop="false" :searchable="true" :multiple="false" v-model="employee.user_role" :options="role_options" placeholder="Select user role" >
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="employeeemail" class="light-grey-text p-0 text-left">Email ID</label>
                        <div class="col-12 p-0 row m-0">
                            <input type="email" v-bind:class="{ disabled: this.$store.state.user_detail.user_role != 'admin' && this.$store.state.user_detail.user_role != 'super_admin' }" placeholder="xyz@gmail.com" id="employeeemail" v-model="employee.emailid" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"/>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="employeemobile" class="light-grey-text p-0 text-left">Mobile Number</label>
                        <div class="col-12 p-0 row m-0">
                            <input type="text" maxlength="10" pattern="[0-9] {10}" placeholder="Mobile number" id="employeemobile" v-model="employee.mobile_no" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"/>
                        </div>
                    </div>
                </div>
                <!-- <div class="row m-0 col-12 p-0"> -->
                    <div class="col-md-6 mb-3" v-if="employee.user_role != 'client'">
                        <label for="dob" class="light-grey-text p-0 text-left">Date of Birth</label>
                        <div class="col-12 p-0">
                            <Datepicker :use-utc=true :bootstrap-styling="true" :open-date="simpledate" :disabled-dates = state.disabledDates id="dob" placeholder="DD MM YYYY"  v-model="employee.user_dob" class="datepicker_design" />
                        </div>
                    </div>
                    <div v-if="is_edit_page == true" class="col-md-6 mb-3">
                        <label for="employeepassword" class="light-grey-text p-0 text-left">Password</label>
                        <div class="col-12 p-0" style="display:inline-flex">
                            <input :type="passwordFieldType" placeholder="Enter a strong password" id="employeepassword" v-model="employee.password" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"  />
                            <font-awesome-icon :icon="eyeIcon" size="1x" class="mr-3 pointer position-absolute t-0 b-0 r-0 my-auto primary-text-color" @click="switchVisibility"></font-awesome-icon>
                        </div>
                    </div>
                <!-- </div> -->
                <!-- <div class="row m-0"> -->
                    <div v-if="(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin') && (this.$store.state.user_detail._id != employee._id || this.$store.state.user_detail.user_role == 'super_admin') && (employee.user_role != 'client')" class="col-md-6 mb-3">
                        <label for="doj" class="light-grey-text p-0 text-left">Date of Joining</label>
                        <div class="col-12 p-0">
                            <Datepicker :use-utc=true :bootstrap-styling="true" id="doj" placeholder="DD MM YYYY" v-model="employee.user_doj" class="datepicker_design" />
                        </div>
                    </div>
                    <div v-if="(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin') && (this.$store.state.user_detail._id != employee._id || this.$store.state.user_detail.user_role == 'super_admin') && (employee.user_role != 'client')" class="col-md-6 mb-3">
                        <label for="designation" class="light-grey-text p-0 text-left">Designation</label>
                        <div class="col-12 p-0">
                            <v-select class="v-select-design" :no-drop="false" :searchable="true" :multiple="false" v-model="employee.user_designation" :options="options" placeholder="Select a designation" >
                            </v-select>
                        </div>
                    </div>
                <!-- </div> -->
                <!-- <div class="row m-0"> -->
                    <!-- <div v-if="(this.$store.state.user_detail.user_role == 'super_admin' || this.$store.state.user_detail.user_role == 'admin') && (this.$store.state.user_detail._id != employee._id)" class="col-md-6 mb-3">
                        <label for="role" class="light-grey-text p-0 text-left">Role</label>
                        <div class="col-12 p-0">
                            <v-select class="v-select-design" :no-drop="false" :searchable="true" :multiple="false" v-model="employee.user_role" :options="role_options" placeholder="Select user role" >
                            </v-select>
                        </div>
                    </div> -->
                    <div v-if="(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin') && (this.$store.state.user_detail._id != employee._id || this.$store.state.user_detail.user_role == 'super_admin') && (employee.user_role != 'client')" class="col-md-6 mb-3">
                        <label class="light-grey-text p-0 text-left">Weekend selection</label>
                        <div class="col-12 p-0">
                            <v-select class="v-select-design multiple" :no-drop="false" :searchable="true" :multiple="true" v-model="employee.weekend_days" label="name" :options="weekend_options" :reduce="weekend_options => weekend_options.value" :selectable="() => selected.length < 2" placeholder="Select two days" >
                            </v-select>
                        </div>
                    </div>
                    <div v-if="(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin')  && (this.$store.state.user_detail._id != employee._id || this.$store.state.user_detail.user_role == 'super_admin') && (employee.user_role != 'client')" class="col-md-6">
                        <label for="employeeleaves" class="light-grey-text p-0 text-left">Holidays per year</label>
                        <div class="col-12 p-0 mb-2">
                            <input type="text" maxlength="2" pattern="[0-9] {10}" placeholder="Number of Leaves" id="employeeleaves" v-model="employee.leaves" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"/>
                        </div>
                    </div>
                <!-- </div> -->
                <div class="text-center my-4 col-12 pt-4 z-depth-top">
                    <button class="btn primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded text-white bold_font" type="submit" v-bind:class="{ disabled: is_btn_loading }" @click="newuser">Submit
                        <span class="ml-2" v-if="is_btn_loading">
                            <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
                        </span>
                    </button>
                    <button style="margin-left:10px" v-if="is_edit_page" class="btn border rounded py-2 px-4" type="submit" @click="cancel">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'AddUser',
    components: {
        Datepicker
    },
    data () {
        return {
            // selected: '',
            weekend_days: [],
            date: '',
            simpledate: '',
            employee: {},
            is_edit_page: false,
            passwordFieldType: 'password',
            options: [
                'Developer','Tester','Designer', 'BDE', 'Sales'
            ],
            role_options: [],
            is_btn_loading: false,
            weekend_options: [ 
                {
                    name: "Sunday",
                    value: 0
                },
                {
                    name: "Monday",
                    value: 1
                },
                {
                    name: "Tuesday",
                    value: 2
                },
                {
                    name: "Wednesday",
                    value: 3
                },
                {
                    name: "Thursday",
                    value: 4
                },
                {
                    name: "Friday",
                    value: 5
                },
                {
                    name: "Saturday",
                    value: 6
                },
            ],
            state: {
                disabledDates: {
                    to: '', // Disable all dates up to specific date
                    from: '', // Disable all dates after specific date
                    // days: [6, 0], // Disable Saturday's and Sunday's
                    // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
                },
                joiningDate: {
                    to: ''
                }
            },
            files: '',
            eyeIcon: 'eye'
        }
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            this.eyeIcon = this.passwordFieldType === 'password' ? 'eye' : 'eye-slash'
        },
        makeToast(variant=null) {
            this.$root.$bvToast.toast(`New employee added`, {
                title: 'Success',
                variant: variant,
                autoHideDelay: 3000,
            })
        },
        newuser:function() {
            var err = ''
            if(this.employee.full_name == '' || typeof this.employee.full_name == "undefined" ){
                err = 'Please fill name'
            }else if (this.employee.emailid == '' || typeof this.employee.emailid == "undefined" ){
                err = 'Please fill Email ID'
            }else if (this.employee.mobile_no == '' || typeof this.employee.mobile_no == "undefined" ){
                err = 'Please fill mobile number'
            }
            else if ((this.employee.user_dob == '' || typeof this.employee.user_dob == "undefined") && this.employee.user_role != 'client' )  {
                err = 'Please select date of birth'
            }
            else if ((this.employee.user_doj == '' || typeof this.employee.user_doj == "undefined") && this.employee.user_role != 'client' ){
                err = 'Please select date of joining'
            }
            else if ((this.employee.user_designation == '' || typeof this.employee.user_designation == "undefined") && this.employee.user_role != 'client' ){
                err = 'Please select the designation of user '
            }
            else if (this.employee.user_role == '' || typeof this.employee.user_role == "undefined" ){
                err = 'Please select the role of user'
            }
            if(err == ''){
                this.is_btn_loading = true;
                this.employee.type=this.is_edit_page
                this.$http.post(this.$store.state.api_url+'adduser',this.employee)
                .then(function(data){
                    this.is_btn_loading = false;
                    var response = data.body;
                    if(response.status === true){
                        if(!this.is_edit_page){
                            this.makeToast('success')
                            this.$router.push({name:'Userlist'})
                        }else{
                            if(this.$store.state.user_detail._id == response.data._id){
                                this.$store.commit("set_user_detail", response.data);
                            }
                            this.$bvToast.toast(`Details updated`, {
                            title: 'Success',
                            autoHideDelay: 5000,
                            variant: 'success'
                            })
                            this.$router.push({name:'Home'})
                        }
                    }else{
                        this.$bvToast.toast(`User already exists`, {
                            title: 'Error',
                            autoHideDelay: 3000,
                            variant: 'danger'
                        })
                    }
                })
            }else{
                this.$bvToast.toast(err, {
                    title: 'Error',
                    autoHideDelay: 3000,
                    variant: 'danger'
                })
            }
        },
        cancel:function(){
            this.$router.push({name:'Userlist'})
        },
        datesetting: function(days, months, years){
            var date = new Date(); 
            date.setDate(date.getDate() - days);
            date.setMonth(date.getMonth() - months);
            date.setFullYear(date.getFullYear() - years);
            this.simpledate = date
            this.state.disabledDates.from = this.simpledate
            this.state.joiningDate.to = this.simpledate
        },
        uploadimage: function() {
            this.files = this.$refs.files.files;
            let formData = new FormData();
            formData.append("employee_id", this.employee._id)
            for(var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                if (file.name.split('.').pop() == 'jpeg' || file.name.split('.').pop() == 'jpg' || file.name.split('.').pop() == 'JPG' || file.name.split('.').pop() == 'png' ){
                    formData.append('file', file)
                }
                else {
                    this.$bvToast.toast("Photo format not supported", {
                        title: 'Error',
                        autoHideDelay: 3000,
                        variant: 'danger'
                    })
                }
            }
            this.$http.post(this.$store.state.api_url+'add_profile_photo',formData,{headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then(function(response){
                if(this.$store.state.user_detail._id == response.body.data.users._id){
                    localStorage.setItem("userinfo", JSON.stringify(response.body.data.users))
                    this.$store.commit("set_user_detail", response.body.data.users);
                }
                this.$bvToast.toast(response.body.message, {
                    title: 'Success',
                    autoHideDelay: 5000,
                    variant: 'success'
                })
            })
        }
    },
    created() {
        this.$store.commit("change_page", "adduser");
        this.selected = ''
        if(typeof this.$route.params._id!="undefined")
        {
            this.is_edit_page = true
            this.employee=this.$route.params

        }
        else{
            console.log(this.employee.weekend_days)
            this.employee.weekend_days = [6,0]
            this.employee.leaves = 15
        }
        if(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin' || this.$store.state.user_detail._id == this.$route.params._id )
        {

        }else
        {
            this.$router.push({name:'Home'})
        }
    },
    mounted() {
        this.datesetting(0, 0, 18)
        if(this.$store.state.user_detail.user_role == 'super_admin'){
            this.role_options = ['admin', 'user', 'client']
        }
        else if(this.$store.state.user_detail.user_role == 'admin'){
            this.role_options = ['user', 'client']
        }

    }
}
</script>

<style>
.form-control[readonly]{
        background-color: #ffffff !important;
}
#dob{
    background-color: white;
}

.form-control:disabled, .form-control[readonly]{
    background-color: white
} 

.heading{
    text-align: left;
    padding: 0px 15px;
    font-size: 20px;    
}

input{
    font-size: 16px;
    border-radius: 3px;
    display: block;
    margin-bottom: 8px !important;
    border: 2px;
    border-style: solid;
    border-color: #e0e6e8;
}
.newsub{
    margin: 20px 0px 0px 0px;
}

/* .cancel{
    background-color: rgb(248, 151, 151) !important;
} */

.btn:focus{
    outline: none !important;
}

/* .cancel:hover{
    background-color: rgb(243, 113, 113) !important;
} */
.light-grey-text {
    color: #a5a5a5;
}
</style>