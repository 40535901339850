<template id="myTasks-filter">
  <perfect-scrollbar>
    <div class="sidenav-pkg bg-grey">
      <div class="sidebar mx-2 text-left p-0" id="lgmenu">
        <div class="bar-menus">
          <ul class="m-0 p-2 text-left no-bullet">
            <li
              class="sidemenu mt-1 d-flex justify-content-between align-items-center"
            >
              <h3 class="semi_bold_font w-100 mt-1 mb-4">Filters</h3>
              <font-awesome-icon
                @click="closeFilters()"
                class="cursor-pointer mt-0 mb-4"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Task Name</label>
              <input
                autofocus
                class="form-control w-100 p-2 h-auto rounded m-0"
                placeholder="Search..."
                v-model="filter_task"
                @keyup="task_search(filter_task)"
                value="filter_task"
              />
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Ticket Number</label>
              <input
                autofocus
                class="form-control w-100 p-2 h-auto rounded m-0"
                placeholder="Search..."
                v-model="ticket_no"
                @keyup="number_search(ticket_no)"
                value="ticket_no"
              />
              <!-- v-model="filter_task"
          @keyup="task_search(filter_task)"
          value="filter_task" -->
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font font-8 w-100">Due date </label>
              <v-date-picker
                class="inline-block h-full"
                :masks="masks"
                v-model="date"
                is-range
                :columns="1"
                :max-date="maxDate"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <CalendarInput
                    :isClearable="true"
                    :value="dateFormatVS(inputValue.start, inputValue.end)"
                    @clickInput="togglePopover()"
                    @clear="clearDate"
                  />
                </template>
                <!-- <template slot="header-left-button"><IconLeft /></template>
                <template slot="header-right-button"><IconRight /></template> -->
              </v-date-picker>
            </li>

            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Project</label>
              <v-select
                @input="project_filter(filter_project)"
                class="v-select-design w-100 multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="filter_project"
                :options="project_list"
                placeholder="Select Project"
                label="name"
              >
              </v-select>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Sprint</label>
              <v-select
                @input="sprint_filter(filtered_sprints)"
                class="v-select-design w-100 multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="filtered_sprints"
                :options="sprint_options"
                placeholder="Sprint Names"
                label="sprint_name"
              >
              </v-select>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Status</label>
              <v-select
                @input="status_filter(selected_status)"
                class="w-100 v-select-design multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="selected_status"
                :options="filter_status"
                placeholder="Select Status"
                label="name"
              >
              </v-select>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-4 filter_sidebar_footer">
      <button
        class="btn bg-danger border border-danger btn-primary"
        @click="clear_local_storage_filters"
      >
        Clear
      </button>
      <button class="btn btn-primary ml-2 position-sticky" @click="get_tasks">
        Submit
      </button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import Vue from "vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import CalendarInput from "../CalendarInput.vue";
import VCalendar from "v-calendar";
import moment from "moment-timezone";
import { StatusProps } from "../../types/statusTypes";
Vue.use(VCalendar);
export default {
  name: "MyTasksFilter",
  components: {
    PerfectScrollbar,
    CalendarInput,
    moment,
  },
  props: ["closeFilters"],
  data() {
    return {
      hidemenu: true,
      toggle: false,
      start_date: "",
      end_date: "",
      ticket_no: "",
      filter_project: [],
      filtered_sprints: [],
      filter_by_sprint: [],
      filter_task: "",
      filter_by_project: [],
      sprint_options: [],
      searchtask: "",
      filter_status: [
        { name: StatusProps["new"].name, value: "new" },
        { name: StatusProps["todo"].name, value: "todo" },
        { name: StatusProps["on_hold"].name, value: "on_hold" },
        { name: StatusProps["in_progress"].name, value: "in_progress" },
        { name: StatusProps["dev_completed"].name, value: "dev_completed" },
        { name: StatusProps["completed"].name, value: "Completed" },
      ],
      selected_status: "",
      project_list: [],
      sprint_options: [],
      user_in: "",
      date: { start: "", end: "" },
      masks: {
        title: "MMMM YYYY",
        weekdays: "WW",
        navMonths: "MMM",
        input: ["L", "DD.MM.YYY", "YYYY/MM/DD"],
        dayPopover: "WWW, MMM D, YYYY",
        data: ["L", "DD.MM.YYY", "YYYY/MM/DD"],
      },
      maxDate: new Date(),
    };
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    remove_date_filter() {
      this.date.start = "";
      this.date.end = "";
      this.update_local_storage_filters("start_date", "");
      this.update_local_storage_filters("end_date", "");
      // this.get_tasks(this.page);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    clearDate() {
      this.date = { start: "", end: "" };
      this.start_date = "";
      this.end_date = "";
      this.update_local_storage_filters("start_date", "");
      this.update_local_storage_filters("end_date", "");
    },
    dateFormatVS(start, end) {
      if (start && end) {
        this.start_date = start;
        this.end_date = end;
        this.update_local_storage_filters("start_date", start);
        this.update_local_storage_filters("end_date", end);

        return `${start} - ${end}`;
      } else {
        return "";
      }
    },
    number_search: function (ticket_number) {
      this.update_local_storage_filters("ticket_no", ticket_number);
      if (ticket_number !== null) {
        this.ticket_no = ticket_number;
      } else {
        this.$bvToast.toast(`Enter text to search`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      }
    },
    task_search: function (search_task) {
      this.update_local_storage_filters("searchtask", search_task);
      this.update_local_storage_filters("filter_task", search_task);
      if (search_task != null) {
        this.searchtask = search_task;
        this.filter_task = search_task;
        // this.get_tasks(this.page);
      } else {
        this.$bvToast.toast(`Enter text to search`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      }
    },
    date_filter: function (start_date, end_date) {
      this.start_date = moment.utc(start_date).format("YYYY-MM-DD");
      this.end_date = moment.utc(end_date).format("YYYY-MM-DD");
      this.start_date =
        this.start_date == "Invalid date" ? "" : this.start_date;
      this.end_date = this.end_date == "Invalid date" ? "" : this.end_date;
      this.update_local_storage_filters("start_date", this.start_date);
      this.update_local_storage_filters("end_date", this.end_date);
      if (this.start_date == "" || this.end_date == "") {
        this.$bvToast.toast(`Choose start and end dates`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      } else {
        // this.get_tasks(this.page);
      }
    },
    project_filter: function (filter_project) {
      this.filter_by_project = [];
      const filtered_project = [];
      if (filter_project.length > 0) {
        for (let index = 0; index < filter_project.length; index++) {
          const element = filter_project[index]._id;
          this.filter_by_project.push(element);
          filtered_project.push(filter_project[index]);
        }
      } else {
        this.filter_by_project = [];
      }
      this.update_local_storage_filters("filter_project", filtered_project);
      this.update_local_storage_filters(
        "filter_by_project",
        this.filter_by_project
      );
      // this.get_tasks(this.page);
    },
    get_tasks: function () {
      this.$eventHub.$emit("search");
      this.closeFilters();
    },
    get_sprintlist: function() {
      this.$http
        .get(this.$store.state.api_url + "activesprints/")
        .then(function (response) {
          this.sprint_options = response.body.data;
        });
    },
    get_project_list: function () {
      let params = {
        id: this.user_in._id,
      };
      this.$http
        .post(this.$store.state.api_url + "projectchat", { params })
        .then(function (response) {
          this.is_success = true;
          response = response.body;
          let project = response.data;
          this.project_list = project;
          this.update_local_storage_filters("filter_project", []);
          this.update_local_storage_filters("project_list", project);
        });
    },
    sprint_filter(value) {
      this.filter_by_sprint = [];
      this.filtered_sprints = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_sprint.push(element);
          this.filtered_sprints.push(value[index]);
        }
      } else {
        this.filter_by_sprint = [];
        this.filtered_sprints = [];
      }
      this.update_local_storage_filters(
        "filtered_sprints",
        this.filtered_sprints
      );
      this.update_local_storage_filters("filter_by_sprint", this.filter_by_sprint);
    },
    status_filter: function (status) {
      this.selected_status = status;
      this.update_local_storage_filters("selected_status", status);
      // this.get_tasks(this.page);
    },

    clear_local_storage_filters() {
      let existing_filters = JSON.parse(
        localStorage.getItem("myTasks_filters")
      );
      existing_filters = {
        ...existing_filters,
        start_date: "",
        end_date: "",
        searchtask: "",
        selected_status: [],
        filter_project: "",
        filtered_sprints: [],
        filter_by_sprint: [],
        filter_task: [],
      };

      this.date.start = "";
      this.date.end = "";
      this.start_date = "";
      this.end_date = "";
      this.searchtask = "";
      this.selected_status = [];
      this.filter_project = [];
      this.filtered_sprints = [];
      this.filter_by_sprint = [];
      this.filter_task = [];

      this.clearDate();

      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));
      this.$eventHub.$emit("search");
      this.get_tasks();
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("myTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));
    },
    get_local_storage_data() {
      let existing_filters = localStorage.getItem("myTasks_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          start_date: this.date.start,
          end_date: this.start.end,
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filter_project: this.filter_project,
          filtered_sprints: this.filtered_sprints,
          filter_by_sprint: this.filter_by_sprint,
          filter_task: this.searchtask,
          filter_by_project: this.filter_by_project,
          project_list: this.project_list,
          sprint_options: this.sprint_options,
          ticket_no: this.ticket_no,
        };
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.date.start = existing_filters.start_date || "";
        this.date.end = existing_filters.end_date || "";
        this.start_date = existing_filters.start_date || "";
        this.end_date = existing_filters.end_date || "";
        this.searchtask = existing_filters.searchtask || "";
        this.ticket_no = existing_filters.ticket_no || "";
        this.selected_status = existing_filters.selected_status || [];
        this.filter_by_project = existing_filters.filter_by_project;
        this.filter_project = existing_filters.filter_project;
        this.filtered_sprints = existing_filters.filtered_sprints;
        this.filter_by_sprint = existing_filters.filter_by_sprint;
        this.filter_task = existing_filters.searchtask;
        existing_filters = {
          ...existing_filters,
          start_date: this.date.start,
          end_date: this.date.end,
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filter_project: this.filter_project,
          filtered_sprints: this.filtered_sprints,
          filter_by_sprint: this.filter_by_sprint,
          filter_task: this.searchtask,
          filter_by_project: this.filter_by_project,
          project_list: this.project_list,
          sprint_options: this.sprint_options,
        };
      }

      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));
    },
  },
  created() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.$eventHub.$on("refresh_mytask", this.get_local_storage_data);
    this.get_local_storage_data();
  },
  mounted() {
    this.get_project_list();
    this.get_sprintlist();
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.get_local_storage_data();
  },
  watch: {
    start_date(newValue, oldValue) {
      this.date.start = newValue;
      if (newValue === "") {
        this.clearDate();
      }
    },
    end_date(newValue, oldValue) {
      this.date.end = newValue;
      if (newValue === "") {
        this.clearDate();
      }
    },
    searchtask(newValue, oldValue) {
      this.searchtask = newValue;
    },

    ticket_no(newValue, oldValue) {
      this.ticket_no = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    filter_by_project(newValue, oldValue) {
      this.filter_by_project = newValue;
    },
    filter_task(newValue, oldValue) {
      this.filter_task = newValue;
    },
    filtered_sprints(newValue, oldValue) {
      this.filtered_sprints = newValue;
    },
    filter_by_sprint(newValue, oldValue) {
      this.filter_by_sprint = newValue;
    },
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style scoped>
.chat-menus {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.cursor-pointer {
  cursor: pointer;
}
.chat_icon svg {
  margin-right: 4px;
  margin-left: 0px !important;
}

.filter_sidebar_header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 16px;
  z-index: 1000;
}

.filter_sidebar_footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: -16px;
}
.green_status_icon {
  color: rgb(106, 238, 30);
}

.vs__dropdown-menu {
  max-height: 100px !important;
}

.icons {
  margin-right: 10px;
}

.hdr-img {
  height: 50px;
  width: 50px;
}

.pms {
  font-size: 10px;
  color: #9a9494;
  margin: 0px;
  text-align: left !important;
}

.leftcorner {
  text-align: left;
  background-color: rgb(240, 241, 243);
  color: rgba(53, 53, 53);
  z-index: 99 !important;
  top: 0;
}
.input-block {
  width: 100% !important;
}
.list {
  border: none;
  background-color: transparent;
  align-content: center;
  /* margin-top: 10px; */
}

.list:focus {
  outline: none;
}

svg {
  align-content: left;
  color: rgb(0, 0, 0);
  width: 20px;
}
.sidebar {
  color: rgba(53, 53, 53);
  height: 100vh;
}

.no-bullet {
  list-style: none;
}

h4 {
  font-size: 1.8em;
  margin: 5px 5px 10px 5px;
}

hr {
  margin: 1rem 0.5rem;
  border: 2px;
  border-top: 1px solid rgb(241, 237, 237);
}

@media (max-width: 992px) {
  .header {
    display: contents;
  }
}
.h-120 {
  height: 120px;
}
</style>
