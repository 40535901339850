<template>
    <div class="col-12 p-4">
        <!-- <h5 class="mt-4">
            <span v-if="is_edit_page">Update Leave</span>
            <span v-if="!is_edit_page">Add Leave</span>
        </h5> -->
        <div class="col-md-7 row p-0 form-table mx-auto">
            <div class="mb-3 col-12">
                <label for="holidayname" class="light-grey-text p-0 text-left">Holiday name</label>
                <div class="col-12 p-0">
                    <input type="text" placeholder="Leave for" id="holidayname" v-model="holiday.leave_name" class="form-control px-3 py-3 h-auto rounded m-0 grey-text"/>
                </div>
            </div>
            <div class="mb-3 col-12">
                <label for="dol" class="light-grey-text p-0 text-left">Leave Date</label>
                <div class="col-12 p-0">
                    <Datepicker class="datepicker_design" :use-utc=true :bootstrap-styling="true"  id="dol" placeholder="DD MM YYYY"  v-model="holiday.leave_date" /> <!-- :disabled-dates = state.disabledDates -->
                </div>
            </div>
            <div class="text-center my-4 col-12 pt-4 z-depth-top">
                <button class="btn primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded text-white bold_font" type="submit" v-bind:class="{ disabled: is_btn_loading }" @click="newholiday">Submit
                    <span class="ml-2" v-if="is_btn_loading">
                        <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
                    </span>
                </button>
                <button style="margin-left:10px" class="btn border rounded py-2 px-4" type="submit" @click="cancel">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'AddLeave',
    components: {
        Datepicker
    },
    data () {
        return {
            holiday: {},
            is_edit_page: false,
            state: {
                disabledDates: {
                    to: '', // Disable all dates up to specific date
                    from: '', // Disable all dates after specific date
                    // days: [6, 0], // Disable Saturday's and Sunday's
                    // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
                },
            },
            is_btn_loading: false
        }
    },
    methods: {
        makeToast(variant=null) {
            this.$root.$bvToast.toast(`New Leave added`, {
                title: 'Success',
                variant: variant,
                autoHideDelay: 3000,
            })
        },
        newholiday:function() {
            var err = ''
            if(this.holiday.leave_name == '' || typeof this.holiday.leave_name == "undefined" ){
                err = 'Please fill holiday name'
            }else if (this.holiday.leave_date == '' || typeof this.holiday.leave_date == "undefined" ){
                err = 'Please select leave date'
            }
            if(err == ''){
                var year = this.holiday.leave_date.getFullYear()
                this.holiday.year = year
                this.is_btn_loading = true;
                this.holiday.type=this.is_edit_page
                console.log(this.holiday)
                this.$http.post(this.$store.state.api_url+'addholiday',this.holiday).then(function(data){
                    this.is_btn_loading = false;
                    var response = data.body;
                    if(response.status === true){
                        if(!this.is_edit_page){
                            this.makeToast('success')
                            this.$router.push({name:'Holidays'})
                        }else{
                            this.$bvToast.toast(`Leave details updated`, {
                            title: 'Success',
                            autoHideDelay: 5000,
                            variant: 'success'
                            })
                            this.$router.push({name:'Holidays'})
                        }
                    }else{
                        this.$bvToast.toast(`Leave date already exists`, {
                            title: 'Error',
                            autoHideDelay: 3000,
                            variant: 'danger'
                        })
                    }
                })
            }else{
                this.$bvToast.toast(err, {
                    title: 'Error',
                    autoHideDelay: 3000,
                    variant: 'danger'
                })
            }
        },
        cancel:function(){
            this.$router.push({name:'Holidays'})
        },
    },
    created() {
        this.$store.commit("change_page", "addleave");
        if(typeof this.$route.params._id!="undefined")
        {
            this.is_edit_page = true
            this.holiday=this.$route.params
        }
        if(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin'){
            
        }else{
            this.$router.push({name:'Home'})
        }
    },
    mounted() {
        // this.datesetting(0, 0, 18)
    }
}
</script>