<template>
  <div class="home col-12">
    
    <div class="filtered_items">
      <div
        v-if="searchtask !== '' && typeof searchtask !== 'undefined'"
        class="filter_chip"
      >
        Task Name: <b>{{ searchtask }}</b>
        <font-awesome-icon
          class="ml-2 clear-filter"
          @click="remove_filter('searchtask', searchtask)"
          icon="times"
          size="1x"
        ></font-awesome-icon>
      </div>
      <div
        v-if="ticket_no !== '' && typeof ticket_no !== 'undefined'"
        class="filter_chip"
      >
        Ticket Number: <b>{{ ticket_no }}</b>
        <font-awesome-icon
          class="ml-2 clear-filter"
          @click="remove_filter('ticket_no', ticket_no)"
          icon="times"
          size="1x"
        ></font-awesome-icon>
      </div>
      <div
        v-if="filtered_assignees.length > 0"
        class="d-flex align-items-center"
      >
        <div v-for="(assignee, index) in filtered_assignees" :key="index">
          <div v-if="isFiltered_assignee(assignee._id)" class="filter_chip">
            Assigned to:
            <b>{{ assignee.full_name }}</b>
            <font-awesome-icon
              @click="remove_filter('filter_by_assignee', assignee._id)"
              class="ml-2 clear-filter"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
      <div
        v-if="
          selected_status.length > 0 && typeof selected_status !== 'undefined'
        "
        class="d-flex align-items-center"
      >
        <div
          v-for="(status, index) in selected_status"
          :key="index"
          class="filter_chip"
        >
          Status:
          <b>{{ status.name }}</b>
          <font-awesome-icon
            @click="remove_filter('selected_status', status)"
            class="ml-2 clear-filter"
            icon="times"
            size="1x"
          ></font-awesome-icon>
        </div>
      </div>
    </div>

     <div class="container p-2" style="border: 2px solid #E6E6E6;">
    <div class="row">
    <div class="col-md-6">
    </div>
    <div class="col-md-6">
    <v-select
    @input="apply_sprint_filter(filtered_sprints)"
    class="v-select-design w-100 multiple filter_select"
    :searchable="true"
    :multiple="true"
    v-model="filtered_sprints"
    :options="sprint_options"
    placeholder="Select Sprints"
    label="sprint_name"
  >
  </v-select>
    </div>
    </div>
    </div>


    <div class="container">
    <div class="row">
    <div class="col-md-6" style="border: 2px solid #E6E6E6;">
    <div class="table1 row m-0 mt-0">
      <div class="tasklist row w-100 mx-0 table-custom-height">
        <div class="taskform col-12" id="no-more-tables">
          <span class="m-auto" v-if="is_backlog_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
          <table
            class="table table-hover table_design table-striped backlogs-table"
            v-if="!is_backlog_loading"
          >
            <thead>
              <tr class="p-0 m-0 position-relative">

                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_ticket_no"
                >
                  Ticket Number
                </th>
                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_backlogs_name"
                >
                  Task name
                </th>
                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_assigned_to"
                >
                  Assigned to
                </th>
              </tr>
            </thead>
            <tbody v-if="!is_backlog_success">
              <td colspan="5">
                <ContentLoader>
                  <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
                </ContentLoader>
              </td>
            </tbody>
            <tbody class="table-body" v-if="is_backlog_success">
            <draggable v-model="all_backlogs" :group="{ name: 'tasksTables', pull: true, put: true }"  :disabled="filtered_sprints.length == 0" style="width: 430px;" @start="onDragStartOfBackLogs" @end="onDragEndOfBackLogs">
              <tr
                class="p-0 position-relative rounded m-0 table-body-row"
                v-for="item in all_backlogs"
                :key="item._id"
              >
                <td
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_ticket_no"
                  @click="goto_task(item.ticket_number)"
                >
                  {{ item.ticket_no }}
                </td>
                <td
                  class="all_backlogs_name"
                  data-title="Task"
                  @click="goto_task(item.ticket_number)"
                >
                  <span class="font-8 semi_bold_font">{{
                    item.ticket_number
                  }}</span>
                  <br />{{ item.title
                  }}<span v-if="item.title == null"> - </span>
                </td>
                <td
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_assigned_to"
                >
                  {{
                    item.assigned_to_detail !== null &&
                    typeof item.assigned_to_detail !== "undefined"
                      ? item.assigned_to_detail.full_name
                      : ""
                  }}
                </td>
              </tr>
              </draggable>

              <tr v-if="all_backlogs.length == 0">
                <td colspan="5">No Tasks here!</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-center ml-auto" v-if="is_backlog_success">
        <button v-if="hasMoreBacklogs" @click="loadMoreBacklogs" class="btn btn-primary m-2">Load More</button>
      </div>
    </div>
    </div>
    <div class="col-md-6" style="border: 2px solid #E6E6E6;">
     <div class="table1 row m-0 mt-0">
      <div class="tasklist row w-100 mx-0 table-custom-height">
        <div class="taskform col-12" id="no-more-tables">
          <span class="m-auto" v-if="is_sprinted_backlog_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
          <table
            class="table table-hover table_design table-striped sprinted-backlogs-table"
            v-if="!is_sprinted_backlog_loading"
          >
            <thead>
              <tr class="p-0 m-0 position-relative">

                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_ticket_no"
                >
                  Ticket Number
                </th>
                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic sprinted_backlogs_name"
                >
                  Task name
                </th>
                <th
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_assigned_to"
                >
                  Assigned to
                </th>
              </tr>
            </thead>
            <tbody v-if="!is_backlog_success">
              <td colspan="5">
                <ContentLoader>
                  <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                  <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
                </ContentLoader>
              </td>
            </tbody>
            <tbody class="table-body" v-if="is_backlog_success">
            <draggable v-model="sprinted_backlogs" :group="{ name: 'tasksTables', pull: true, put: true }"  :disabled="filtered_sprints.length == 0" style="width: 430px;" @start="onDragStartOfSprintsBackLogs" @end="onDragEndOfSprintsBackLogs">
              <tr
                class="p-0 position-relative rounded m-0 table-body-row"
                v-for="item in sprinted_backlogs"
                :key="item._id"
              >
                <td
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_ticket_no"
                  @click="goto_task(item.ticket_number)"
                >
                  {{ item.ticket_no }}
                </td>
                <td
                  class="sprinted_backlogs_name"
                  data-title="Task"
                  @click="goto_task(item.ticket_number)"
                >
                  <span class="font-8 semi_bold_font">{{
                    item.ticket_number
                  }}</span>
                  <br />{{ item.title
                  }}<span v-if="item.title == null"> - </span>
                </td>
                <td
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic all_assigned_to"
                >
                  {{
                    item.assigned_to_detail !== null &&
                    typeof item.assigned_to_detail !== "undefined"
                      ? item.assigned_to_detail.full_name
                      : ""
                  }}
                </td>
              </tr>
              </draggable>

              <tr v-if="filtered_sprints.length != 0 && sprinted_backlogs.length == 0">
                <td colspan="5">No Tasks here!</td>
              </tr>

              <tr v-if="filtered_sprints.length == 0">
                <td colspan="5">Select atleast one sprint name!</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-center ml-auto" v-if="is_backlog_success">
         <button v-if="hasMoreSprintedBacklogs && filtered_sprints.length != 0" @click="loadMoreSprintedBacklogs" class="btn btn-primary m-2">Load More</button>
      </div>
    </div>
    </div>
    </div>
    </div>


  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import draggable from 'vuedraggable';
import moment from "moment";

export default {
  components: { ContentLoader, moment, draggable, },
  name: "Home",
  data() {
    return {
      backlogpage: 1,
      sprintbacklogpage: 1,
      is_backlog_success: false,
      all_backlogs: [],
      sprinted_backlogs: [],
      current_backlog_page: 1,
      current_sprinted_backlog_page: 1,
      totalBackLogDocs: 0,
      totalSprintedBackLogDocs: 0,
      totalBackLogPages: 0,
      totalSprintedBackLogPages: 0,
      filter_project: "", //Filter v-model for project... Yet to work on
      is_backlog_loading: false,
      is_sprinted_backlog_loading: false,
      filter_status: "", //Filter v-model for status... Yet to work on,
      member_options: [],
      filter_name: "",
      filter_by_assignee: [],
      filtered_assignees: [],
      searchtask: "",
      filter_by_sprint: [],
      sprint_options: [],
      filtered_sprints: [],
      ticket_no: "",
      hasMoreBacklogs: true,
      hasMoreSprintedBacklogs: true,
      selected_status: [],
    };
  },
  created() {
    this.$eventHub.$on("search_all_task", this.get_all_backlogs);
    this.$eventHub.$on("remove_old_backlogs", this.remove_old_backlogs_data);
    this.$eventHub.$on("backlogs_table", this.refresh_table);
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.refresh_table();
    this.get_all_backlogs(this.backlogpage);
   // this.get_sprinted_backlogs(this.sprintbacklogpage);
    this.get_local_storage_filter();
  },
  methods: {
    onDragStartOfBackLogs(event) {
    },
    onDragEndOfBackLogs(event) {
      if (event.from === event.to && event.oldIndex === event.newIndex) {
        console.log('Item returned to original position in the same normal table');
        // Handle your logic here, such as resetting data or displaying a message
      } else {
         this.$http.post(this.$store.state.api_url + "modifytasksprint", { taskId: event.item._underlying_vm_.id, sprintData: this.filtered_sprints, action: "add" }).then(function (data) {});
      }
    },
    onDragStartOfSprintsBackLogs(event) {
    },
    onDragEndOfSprintsBackLogs(event) {
      if (event.from === event.to && event.oldIndex === event.newIndex) {
        console.log('Item returned to original position in the same sprinted table');
        // Handle your logic here, such as resetting data or displaying a message
      } else {
         this.$http.post(this.$store.state.api_url + "modifytasksprint", { taskId: event.item._underlying_vm_.id, sprintData: [], action: "remove" }).then(function (data) {});
      }
    },
    goto_task(ticket_no) {
      this.$router.push({ path: '/taskproxy/' + ticket_no });
    },
    get_local_storage_filter() {
      let existing_filters = localStorage.getItem("BackLogs_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filtered_assignees: this.filtered_assignees,
          filter_by_sprint: this.filter_by_sprint,
           filtered_sprints: this.filtered_sprints,
          filter_by_assignee: this.filter_by_assignee,
          ticket_no: this.ticket_no,
        };
        localStorage.setItem(
          "BackLogs_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.searchtask = existing_filters.searchtask || "";
        this.selected_status = existing_filters.selected_status || [];
        this.filter_project = existing_filters.filter_project;
        this.filter_task = existing_filters.searchtask;
        this.ticket_no = existing_filters.ticket_no || "";
        this.filtered_assignees = existing_filters.filtered_assignees || [];
        this.filter_by_assignee = existing_filters.filter_by_assignee || [];
        this.filter_by_sprint = existing_filters.filter_by_sprint || [];
        this.filtered_sprints = existing_filters.filtered_sprints || [];
      }
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("BackLogs_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "BackLogs_filters",
        JSON.stringify(existing_filters)
      );
    },
    reset_filters() {
      const existing_filters = {
        searchtask: this.searchtask,
        selected_status: this.selected_status,
        filter_project: this.filter_project,
      };

      localStorage.setItem(
        "BackLogs_filters",
        JSON.stringify(existing_filters)
      );
    },
    refresh_table: function () {
      let existing_filters = localStorage.getItem("BackLogs_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        localStorage.setItem(
          "BackLogs_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);
      }
    },
    remove_old_backlogs_data: function() {
      this.all_backlogs = [];
    },
    get_all_backlogs: function (page) {
      this.is_backlog_loading = true;
      const filters = JSON.parse(localStorage.getItem("backLogs_filters"));
    
      let params = {
        pageno: page || 1,
        size: 50,
        user_filter: filters.filter_by_assignee,
        search_task: filters.searchtask,
        ticket_no: filters.ticket_no,
      };
      if (filters.selected_status) {
        params = {
          ...params,
          set_status:
            filters.selected_status.length > 0
              ? filters.selected_status.map((status) => status.value)
              : [],
        };
      }
      params = {
          ...params,
          sortBy: "created_at",
          sortOrder: -1,
        };
      this.$http
        .get(this.$store.state.api_url + "backlogs", { params })
        .then(function (response) {
          this.is_backlog_loading = false;
          this.is_backlog_success = true;
          this.all_backlogs = [...this.all_backlogs, ...response.body.data.docs];
          this.current_backlog_page = response.body.data.page;
          this.totalBackLogDocs = response.body.data.totalDocs;
          this.totalBackLogPages = response.body.data.totalPages;
          this.hasMoreBacklogs = this.current_backlog_page < this.totalBackLogPages;

        });
      this.is_backlog_loading = false;
      this.get_local_storage_filter();
    },
    get_sprinted_backlogs: function (page) {
      this.is_sprinted_backlog_loading = true;
      const filters = JSON.parse(localStorage.getItem("BackLogs_filters"));
      let params = {
        pageno: page || 1,
        size: 50,
        sprint_filter: filters.filtered_sprints,
      };
      params = {
          ...params,
          sortBy: "created_at",
          sortOrder: -1,
        };

      this.$http
        .get(this.$store.state.api_url + "sprinted_backlogs", { params })
        .then(function (response) {
          this.is_sprinted_backlog_loading = false;
          this.is_backlog_success = true;
          this.sprinted_backlogs = response.body.data.docs;
          this.current_sprinted_backlog_page = response.body.data.page;
          this.totalSprintedBackLogDocs = response.body.data.totalDocs;
          this.totalSprintedBackLogPages = response.body.data.totalPages;
          this.hasMoreSprintedBacklogs = this.current_sprinted_backlog_page < this.totalSprintedBackLogPages;
        });
      this.is_sprinted_backlog_loading = false;
      this.get_local_storage_filter();
    },
    get_userlist: function () {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers")
        .then(function (response) {
          this.member_options = response.body.data;
        });
    },
     get_sprintlist: function() {
      this.$http
        .get(this.$store.state.api_url + "activesprints/")
        .then(function (response) {
          this.sprint_options = response.body.data;
        });
    },
    apply_status_filter(value) {
      if (value != null) {
        this.filter_status = value;
      } else {
        this.filter_status = "";
      }
      this.get_all_backlogs(this.backlogpage);
    },
    apply_assignee_filter(value) {
      this.filter_by_assignee = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_assignee.push(element);
        }
      } else {
        this.filter_by_assignee = [];
      }
      this.get_all_backlogs(this.backlogpage);
    },
    apply_sprint_filter(value) {
      this.filter_by_sprint = [];
      this.filtered_sprints = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_sprint.push(element);
          this.filtered_sprints.push(value[index]);
        }
      } else {
        this.filter_by_sprint = [];
        this.filtered_sprints = [];
        this.sprinted_backlogs = [];
      }
      this.update_local_storage_filters(
        "filtered_sprints",
        this.filtered_sprints
      );
      this.update_local_storage_filters("filter_by_sprint", this.filter_by_sprint);
      this.get_sprinted_backlogs(this.sprintbacklogpage);
    },
    isFiltered_assignee(id) {
      return this.filter_by_assignee.includes(id) ? true : false;
    },
    remove_filter(name, value) {
      switch (name) {
        case "searchtask":
          this.searchtask = "";
          this.update_local_storage_filters(name, "");
          this.get_all_backlogs(this.backlogpage);
          break;

        case "ticket_no":
          this.ticket_no = "";
          this.update_local_storage_filters(name, "");
          this.get_all_backlogs(this.backlogpage);
          break;
        case "selected_status":
          let new_status = this.selected_status.filter(
            (status) => status.value !== value.value
          );
          this.selected_status = new_status;
          this.update_local_storage_filters(name, new_status);
          this.get_all_backlogs(this.backlogpage);
          break;
        case "filter_by_assignee":
          let new_assignees = this.filter_by_assignee.filter(
            (assignee) => assignee !== value
          );
          let new_assignees_list = this.filtered_assignees.filter(
            (assignee) => assignee._id !== value
          );
          this.filtered_assignees = new_assignees_list;
          this.filter_by_assignee = new_assignees;
          this.update_local_storage_filters(
            "filtered_assignees",
            new_assignees_list
          );
          this.update_local_storage_filters(name, new_assignees);
          this.get_all_backlogs(this.backlogpage);
          break;
        default:
          break;
      }
      this.$eventHub.$emit("refresh_backLogs");
    },
    loadMoreBacklogs() {
      if (this.hasMoreBacklogs) {
        this.current_backlog_page += 1;
        this.get_all_backlogs(this.current_backlog_page);
      }
    },
    loadMoreSprintedBacklogs() {
      if (this.hasMoreSprintedBacklogs) {
        this.current_sprinted_backlog_page += 1;
        this.get_sprinted_backlogs(this.current_sprinted_backlog_page);
      }
    },
  },
  mounted() {
    this.update_local_storage_filters("filter_by_sprint", []);
    this.update_local_storage_filters("filtered_sprints", []);
    this.get_userlist();
    this.get_sprintlist();
    this.get_all_backlogs(this.backlogpage);
    //this.get_sprinted_backlogs(this.sprintbacklogpage);
    this.get_local_storage_filter();
    this.$store.commit("change_page", "All Tasks");
  },
  watch: {
    searchtask(newValue, oldValue) {
      this.searchtask = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    filter_task(newValue, oldValue) {
      this.filter_task = newValue;
    },
    filtered_assignees(newValue, oldValue) {
      this.filtered_assignees = newValue;
    },
    filter_by_sprint(newValue, oldValue) {
      this.filter_by_sprint = newValue;
    },
    filtered_sprints(newValue, oldValue) {
      this.filtered_sprints = newValue;
    },
    filter_by_assignee(newValue, oldValue) {
      this.filter_by_assignee = newValue;
    },
  },
};
</script>

<style>
@media (min-width: 700px) {
  .table.table_design {
    min-width: 5vw;
    overflow-x: auto;
  }
  .all_assigned_to,
  .all_project_code {
    width: 150px;
    max-width: 200px;
  }

  .all_ticket_no {
    width: 100px;
  }
  .all_backlogs_name, .sprinted_backlogs_name {
    width: 180px !important;
  }


  .all_project_name {
    width: 120px !important;
  }
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.filtered_items {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.filter_chip {
  display: flex;
  background-color: #e6e6e6;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.close-filter,
.clear-filter {
  cursor: pointer;
}
.paginator {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.page-item {
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: rgb(54, 88, 143) !important;
  color: #ffffff;
}
.img-small {
  width: 30px;
  height: 30px;
}
.img-high-small {
  width: 40px;
  height: 40px;
  z-index: 1;
}
.table-custom-height {
  height: 65vh;
  overflow-y: auto;
}
</style>
