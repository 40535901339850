<template id="scoreCard">
  <div class="row scorecard-container">
    <div
      v-for="(item, index) in set_status"
      :key="index"
      class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2"
      :style="{ background: get_color(item) }"
      @click="scorecard_filter(item, set_status_name[index]);set_tab(tabs_list[index].id)"
    >
      <h5>{{ get_status_name(item) }}</h5>
      <h2>{{ get_score(item) }}</h2>
    </div>
  </div>
</template>

<script>

//  @click="scorecard_filter(item, set_status_name[index])" - for scorecard

import { sprintStatusProps } from "../types/sprintStatusTypes";
export default {
  name: "ScoreCardForSprints",

  props: {
    
    set_status: {
      type: Array,
      default: () => [],
    },
    set_status_name: {
      type: Array,
      default: () => [],
    },
    tabs_list: {
      type: Array,
      default: () => [],
    },
    
    scorecard_filter: {
      type: Function,
      required: true,
      validator: function (value) {
        // Validate the prop value
        return typeof value === "function";
      },
    },
    set_tab: {
      type: Function,
      required: true,
      validator: function (value) {
        // Validate the prop value
        return typeof value === "function";
      },
    },
  },
  data() {
    return {
      scoreCard_data: [],
      status_props: sprintStatusProps,
    };
  },
  methods: {
    get_score(status) {
        if(this.scoreCard_data.length != 0) {
         return this.scoreCard_data.reduce((total, item) => {
        if (item.status == status || item.sprint_status == status) {
            return total + item.count;
        }
        return total;
    }, 0);
        }
    },

    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] != "undefined") {
        return this.status_props[status].color;
      } else return "#F2F2F2";
    },
    get_scorecard_data() {
      let params = {
        
         set_status: this.set_status || [],
      };

      if (
        typeof this.$props.set_status === "string" &&
        this.$props.set_status !== ""
      ) {
        params.set_status = [this.$props.set_status];
      }

      this.$http
        .get(this.$store.state.api_url + "scorecardforsprint", { params })
        .then(function (response) {
          this.scoreCard_data = response.body.scoreCard_data || [];
        });
    },
  },
  mounted() {
    this.get_scorecard_data();
  },
  watch: {},
};
</script>

<style scoped>
.scorecard-container.row {
  margin-left: 8px !important;
}
.scorecard {
  display: flex;
  cursor: pointer;
  color: white !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  font-size: 24px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #ebebeb;
}
.score_count {
  font-size: 20px;
}
</style>
