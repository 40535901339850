<template>
  <div class="home col-12">
    <div class="col-12 row m-0">
      <div class="col-12 p-0 pb-3">
        <ContentLoader class="" height="210" v-if="!is_chat_success">
          <circle cx="19" cy="25" r="16" />
          <rect x="39" y="12" rx="5" ry="5" width="45%" height="10" />
          <rect x="40" y="29" rx="5" ry="5" width="45%" height="10" />
          <circle cx="380" cy="71" r="16" />
          <rect x="179" y="76" rx="5" ry="5" width="45%" height="10" />
          <rect x="179" y="58" rx="5" ry="5" width="45%" height="10" />
          <circle cx="21" cy="117" r="16" />
          <rect x="45" y="104" rx="5" ry="5" width="45%" height="10" />
          <rect x="45" y="122" rx="5" ry="5" width="45%" height="10" />
          <circle cx="380" cy="161" r="16" />
          <rect x="179" y="168" rx="5" ry="5" width="45%" height="10" />
          <rect x="179" y="148" rx="5" ry="5" width="45%" height="10" />
        </ContentLoader>
        <perfect-scrollbar ref="ps">
          <div
            class="chat_messages col-12 p-0 bg-white"
            v-if="is_chat_success"
            id="container"
          >
            <ul class="px-0 pb-5 list-type-none" v-if="messages.length > 0">
              <li class="px-3" v-for="message in messages" :key="message._id">
                <span
                  class="
                    sticky-top
                    bg-white
                    fa-sm-custom
                    grey-text
                    py-1
                    px-4
                    mx-auto
                    align-items-center
                    justify-content-center
                    d-flex
                  "
                  v-if="message.date != ''"
                  ><span class="bg-white py-1 px-4 rounded-pill z-depth-1">{{
                    message.date
                  }}</span></span
                >
                <span v-for="msg in message.msg" :key="msg._id">
                  <div
                    class="chat_line pb-3"
                    v-if="msg.sender == signed_in_user._id"
                  >
                    <img
                      :src="msg.sent_by_name.profile_photo"
                      class="
                        img-small
                        position-absolute
                        r-0
                        mr-2
                        t-0
                        rounded-circle
                        ml-auto
                      "
                    />
                    <span class="pr-5 d-block">
                      <p
                        class="
                          m-0
                          chat_name
                          text-right
                          semi_bold_font
                          text-black
                        "
                      >
                        You
                      </p>
                      <div
                        v-if="!msg.image_name"
                        class="ml-auto chat_para mb-1 p-2 rounded"
                        v-html="msg.message"
                      ></div>
                      <div
                        v-if="msg.image_name"
                        class="
                          ml-auto
                          chat_para
                          mb-1
                          bg-transparent
                          position-relative
                        "
                      >
                        <a
                          class="
                            bg-white
                            img-too-small
                            m-2
                            position-absolute
                            r-0
                            rounded-circle
                            no_white
                            t-0
                            align-items-center
                            d-flex
                            justify-content-center
                            z-depth-top
                          "
                          href="javascript:void(0)"
                          @click="download_img(msg.image_url, msg.image_name)"
                        >
                          <font-awesome-icon
                            icon="download"
                            class="mb-0 primary-text-color fa-sm-custom"
                            size="lg"
                          ></font-awesome-icon>
                        </a>
                        <img
                          :src="msg.image_url"
                          class="h-150 pointer rounded"
                          @click="showmodal(msg.image_url)"
                        />
                      </div>
                      <p class="time_display text-right mb-0 text-black">
                        {{ msg.show_message_time }}
                      </p>
                    </span>
                  </div>
                  <div
                    class="text-left chat_line pb-3"
                    v-if="msg.sender != signed_in_user._id"
                  >
                    <img
                      :src="msg.sent_by_name.profile_photo"
                      class="
                        img-small
                        position-absolute
                        l-0
                        ml-2
                        t-0
                        rounded-circle
                      "
                    />
                    <span class="pl-5 d-block">
                      <p class="m-0 chat_name semi_bold_font text-black">
                        {{ msg.sent_by_name.full_name }}
                      </p>
                      <div
                        v-if="!msg.image_name"
                        class="
                          mr-auto
                          chat_para
                          mb-1
                          bg-dark
                          text-white
                          p-2
                          rounded
                        "
                        v-html="msg.message"
                      ></div>
                      <div
                        v-if="msg.image_name"
                        class="
                          mr-auto
                          chat_para
                          mb-1
                          bg-transparent
                          position-relative
                        "
                      >
                        <a
                          class="
                            bg-white
                            img-too-small
                            m-2
                            position-absolute
                            r-0
                            rounded-circle
                            no_white
                            t-0
                            align-items-center
                            d-flex
                            justify-content-center
                            z-depth-top
                          "
                          href="javascript:void(0)"
                          @click="download_img(msg.image_url, msg.image_name)"
                        >
                          <font-awesome-icon
                            icon="download"
                            class="mb-0 primary-text-color fa-sm-custom"
                            size="lg"
                          ></font-awesome-icon>
                        </a>
                        <img
                          :src="msg.image_url"
                          class="h-150 pointer rounded"
                          @click="showmodal(msg.image_url)"
                        />
                      </div>
                      <p class="time_display mb-0">
                        {{ msg.show_message_time }}
                      </p>
                    </span>
                  </div>
                </span>
                <b-modal
                  size="xl"
                  id="modal-image"
                  class="modal_div"
                  v-model="modalShow"
                >
                  <img :src="display_image" class="pop_up_image" />
                </b-modal>
              </li>
              <!-- <li v-if="typing">{{typing}}...</li> -->
            </ul>
            <div
              class="
                col-12
                p-3
                text-center
                row
                m-0
                align-items-center
                justify-content-center
              "
              v-if="messages.length == 0"
            >
              <font-awesome-icon
                icon="comment-dots"
                class="grey-text fa-3x mb-3"
                size="lg"
              ></font-awesome-icon>
              <h3 class="bold_font w-100">No chat messages</h3>
              <p class="grey-text">
                Start conversing to see your messages here
              </p>
            </div>
          </div>
        </perfect-scrollbar>
        <div class="chat_box w-100" id="footer">
          <!-- <input v-model="message" @keydown="texttyping('$event')" @keyup.enter="send_message()" placeholder="Type something..." /> -->
          <quill-editor
            id="editor"
            class="
              zindex-high
              textarea_design
              chat
              bg-white
              position-absolute
              r-0
              l-0
              b-0
              w-100
              overflow-hidden
              pb-1
            "
            v-model="message"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)"
          >
            <div id="toolbar" slot="toolbar">
              <button class="ql-bold border rounded mr-1">Bold</button>
              <button class="ql-italic border rounded mr-1">Italic</button>
              <button
                class="border rounded outline-none"
                onclick="document.getElementById('fileInput').click();"
              >
                <font-awesome-icon
                  icon="paperclip"
                  class="text-black"
                  size="1x"
                ></font-awesome-icon>
              </button>
              <input
                id="fileInput"
                style="display: none"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                multiple
                ref="files"
                @change="uploadimage"
              />
            </div>
          </quill-editor>
          <button
            class="
              py-2
              px-3
              w-auto
              semi_bold_font
              zindex-high
              primary-bg
              text-white text-center
              btn
              position-absolute
              b-0
              r-0
              z-index-2
            "
            @click="send_message"
          >
            <font-awesome-icon
              icon="paper-plane"
              class="text-white"
              size="sm"
            ></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="chat_page col-12">
    </div> -->
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { ContentLoader } from "vue-content-loader";
import Compress from "compress.js";
import LightBox from "vue-image-lightbox";

export default {
  name: "ChatPage",
  components: {
    PerfectScrollbar,
    ContentLoader,
    Compress,
    LightBox,
  },
  data() {
    return {
      chat_details: "",
      messages: [],
      to: "",
      message: "",
      signed_in_user: "",
      typing: false,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      is_chat_success: false,
      send_image: [],
      display_image: "",
      modalShow: false,
    };
  },
  methods: {
    showmodal: function (url) {
      this.modalShow = !this.modalShow;
      this.display_image = url;
    },
    forceFileDownload(response, image_name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", image_name); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    download_img(image, name) {
      this.$http({
        method: "get",
        url: image,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, name);
        })
        .catch(() => console.log("error occured"));
    },
    onEditorBlur(editor) {},
    onEditorFocus(editor) {},
    onEditorReady(editor) {},
    onEditorChange(editor) {},
    uploadimage: function (evt) {
      this.message = "";
      let selected = evt.target.files;
      let accepted_files = [];
      let declined_files = [];
      for (var i = 0; i < selected.length; i++) {
        let file = selected[i];
        if (
          file.name.split(".").pop() == "jpeg" ||
          file.name.split(".").pop() == "jpg" ||
          file.name.split(".").pop() == "JPG" ||
          file.name.split(".").pop() == "png"
        ) {
          accepted_files.push(file);
        } else {
          declined_files.push(file);
        }
      }
      const compress = new Compress();
      var files = accepted_files;
      compress
        .compress(files, {
          size: 5, // the max size in MB, defaults to 2MB
          quality: 0.8, // the quality of the image, max is 1,
          maxWidth: 720, // the max width of the output image, defaults to 1920px
          maxHeight: 640, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          let compressed_img = data;
          var img = [];
          for (var i = 0; i < compressed_img.length; i++) {
            let file = compressed_img[i];
            var image = {};
            image.name = compressed_img[i].alt;
            image.b64code = compressed_img[i].data;
            img.push(image);
          }
          this.send_image = img;
          this.send_message();
          // this.send_image.splice(0,this.send_image.length)
        });
    },
    send_message() {
      var chat_id = this.$route.params.id;
      var find = "<p><br></p>";
      var re = new RegExp(find, "g");
      this.message = this.message.replace(re, "");
      if (this.message.trim() != "") {
        var params = {
          sender: this.signed_in_user._id,
          // receiver: this.to,
          chat_id: chat_id,
          message: this.message,
          type: "message",
        };
        let response = this.$socket.emit("chat", {
          params: params,
          allmsg: this.messages,
        });
        this.message = "";
        this.scrollToEnd();
      } else if (this.send_image.length) {
        var params = {
          sender: this.signed_in_user._id,
          // receiver: this.to,
          chat_id: chat_id,
          chat_image: this.send_image,
          type: "image",
        };
        let response = this.$socket.emit("chat", {
          params: params,
          allmsg: this.messages,
        });
        this.scrollToEnd();
      } else {
        this.$bvToast.toast(`Please enter comment`, {
          title: "Error",
          variant: "warning",
          autoHideDelay: 3000,
        });
      }
    },    
    scrollToEnd: function () {
      setTimeout(() => {
        var objDiv = document.getElementById("container");
        const ps = this.$refs.ps;
        ps.$el.scrollTop = objDiv.scrollHeight;
        ps.update();
      }, 100);
    },
    texttyping: function (e) {
      console.log("FDfd");
      this.$socket.emit("typing", this.$route.params.id);
    },
  },
  watch: {
    $route(to, from) {
      this.$store.commit("change_page", "chat_page");
      this.$socket.emit("room_for_chat", this.$route.params.id);
      this.$socket.emit("fetch_chat_details", this.$route.params.id);
      this.$socket.emit("all_chat_messages", {
        session_id: this.$socket.id,
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
      });
      this.$socket.emit("read_all_notifications", {
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
        type: "message",
      });
    },
  },
  mounted() {
    document
      .getElementById("editor")
      .addEventListener("input", this.texttyping);
    this.$store.commit("change_page", "chat_page");
    this.$socket.emit("fetch_chat_details", this.$route.params.id);
  },
  created() {
    this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    // this.fetch_chat()
    this.$socket.emit("fetch_chat_details", this.$route.params.id);
    this.$socket.emit("room_for_chat", this.$route.params.id);
    this.$socket.emit("all_chat_messages", {
      session_id: this.$socket.id,
      chat_id: this.$route.params.id,
      user_id: this.signed_in_user._id,
    });
    this.$socket.emit("read_all_notifications", {
      chat_id: this.$route.params.id,
      user_id: this.signed_in_user._id,
      type: "message",
    });
  },
  sockets: {
    connect: function () {
      this.$socket.emit("room_for_chat", this.$route.params.id);
      this.$socket.emit("fetch_chat_details", this.$route.params.id); //Details about chat page
      this.$socket.emit("all_chat_messages", {
        session_id: this.$socket.id,
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
      }); //All text messages in chat window
      this.$socket.emit("read_all_notifications", {
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
        type: "message",
      });
    },
    get_chat: function (val) {
      if (this.$route.params.id == val.msg[0].chat_id) {
        this.messages.push(val);
      }
      this.scrollToEnd();
      this.send_image.splice(0, this.send_image.length);
    },
    get_all_chats: function (values) {
      this.is_chat_success = true;
      this.messages = values;
      this.scrollToEnd();
      console.log(this.messages);
    },
    typing: function (data) {
      this.typing = data;
    },
    stopTyping: function (data) {
      this.typing = false;
    },
    get_chat_details: function (data) {
      this.chat_details = data;
      this.$store.commit("set_chat_details", this.chat_details);
    },
  },
};
</script>

<style scoped>
.background {
  background-color: #f0f1f3;
}

.pop_up_image {
  width: 100%;
  object-fit: contain;
  padding: 5px;
  height: fit-content;
}

.chat_line {
  margin: 0px !important;
  font-size: 14px;
  position: relative;
}

.chat_para {
  background-color: #e9e7e7;
  max-width: calc(100% - 30%);
  font-size: 16px;
  width: fit-content;
}

.chat_name {
  font-size: 14px;
}

.time_display {
  font-size: 11px;
}

.chat_messages {
  max-height: calc(100vh - 150px);
  min-height: calc(100vh - 150px);
  word-wrap: break-word;
}

.chat_box {
  width: 99%;
}

.chat_box input {
  margin: 0px 0px 0px 4px;
  width: 99%;
}

.send_icon {
  font-size: 13px;
  position: absolute;
  bottom: 10px;
  right: 13px;
  cursor: pointer;
  color: #ffffff;
}

.color {
  position: absolute;
  right: 30px;
  bottom: 30px;
  background-color: #36588f;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  transition: 0.4s;
  cursor: pointer;
}

.color:hover .send_icon {
  transform: scale(1.5);
  transition: 0.2s;
}

.chat_box .quill-editor {
  width: 100%;
  background-color: #ffffff;
}

@media (max-width: 575px) {
  .chat_messages {
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
  }
}
</style>