<template>
  <div class="home col-12 p-0">
    <div class="col-12 row m-0 p-0 px-md-3">
      <div class="col-12 p-0 pb-2">
        <ContentLoader class="" height="190" v-if="!is_chat_success">
          <circle cx="19" cy="25" r="16" />
          <rect x="39" y="12" rx="5" ry="5" width="45%" height="10" />
          <rect x="40" y="29" rx="5" ry="5" width="45%" height="10" />
          <circle cx="380" cy="71" r="16" />
          <rect x="179" y="76" rx="5" ry="5" width="45%" height="10" />
          <rect x="179" y="58" rx="5" ry="5" width="45%" height="10" />
          <circle cx="21" cy="117" r="16" />
          <rect x="45" y="104" rx="5" ry="5" width="45%" height="10" />
          <rect x="45" y="122" rx="5" ry="5" width="45%" height="10" />
        </ContentLoader>
        <perfect-scrollbar ref="ps">
          <div
            class="chat_messages col-12 p-0 bg-white"
            id="container"
            v-if="is_chat_success"
          >
            <ul class="px-0 pb-5 list-type-none" v-if="grp_messages.length > 0">
              <li
                class="px-md-3"
                v-for="message in grp_messages"
                :key="message._id"
              >
                <span
                  class="
                    sticky-top
                    bg-white
                    fa-sm-custom
                    grey-text
                    py-1
                    px-4
                    mx-auto
                    align-items-center
                    justify-content-center
                    d-flex
                  "
                  v-if="message.date != ''"
                  ><span class="bg-white py-1 px-4 rounded-pill z-depth-1">{{
                    message.date
                  }}</span></span
                >
                <span v-for="msg in message.msg" :key="msg._id">
                  <div
                    class="chat_line pb-3 position-relative"
                    v-if="msg.sender == signed_in_user._id"
                  >
                    <img
                      :src="msg.sent_by.profile_photo"
                      class="
                        img-small
                        position-absolute
                        r-0
                        mr-2
                        t-0
                        rounded-circle
                        ml-auto
                      "
                    />
                    <span class="pr-5 d-block">
                      <p
                        class="
                          m-0
                          chat_name
                          text-right
                          semi_bold_font
                          text-black
                        "
                      >
                        {{ msg.sent_by.full_name }}
                      </p>
                      <div
                        v-if="!msg.image_name" @mouseover="mouseOver"
                        class="ml-auto chat_para mb-1 p-2 rounded"
                      >
                        <div
                          class="d-flex justify-content-end"                          
                        >
                          <button
                            class="border-0 p-0"
                            v-show="active"
                            @click="send_reply_msg(msg)"
                          >
                            <font-awesome-icon
                              icon="reply"
                              class="mb-0 primary-text-color fa-sm-custom"
                              size="1x"
                            ></font-awesome-icon>
                          </button>
                        </div>
                        <div v-html="msg.message"></div>
                      </div>
                      <div
                        v-if="msg.image_name"
                        class="
                          ml-auto
                          chat_para
                          position-relative
                          bg-transparent
                          mb-1
                          p-0
                        "
                      >
                        <a
                          class="
                            bg-white
                            img-too-small
                            no_white
                            m-2
                            position-absolute
                            r-0
                            rounded-circle
                            t-0
                            align-items-center
                            d-flex
                            justify-content-center
                            z-depth-top
                          "
                          href="javascript:void(0)"
                          @click="download_img(msg.image_url, msg.image_name)"
                        >
                          <font-awesome-icon
                            icon="download"
                            class="mb-0 primary-text-color fa-sm-custom"
                            size="lg"
                          ></font-awesome-icon>
                        </a>
                        <img
                          :src="msg.image_url"
                          class="h-150 pointer rounded"
                          @click="showmodalw(msg.image_url)"
                        />
                      </div>
                      <p class="time_display text-right mb-0 text-black">
                        {{ msg.show_message_time }}
                      </p>
                    </span>
                  </div>
                  <div
                    class="text-left chat_line pb-3"
                    v-if="msg.sender != signed_in_user._id"
                  >
                    <!-- v-if="msg.sender != signed_in_user._id" -->
                    <img
                      :src="msg.sent_by.profile_photo"
                      class="
                        img-small
                        position-absolute
                        l-0
                        ml-2
                        t-0
                        rounded-circle
                      "
                    />
                    <span class="pl-5 d-block">
                      <p class="m-0 chat_name semi_bold_font text-black">
                        {{ msg.sent_by.full_name }}
                      </p>
                      <div
                        v-if="!msg.image_name"
                        class="
                          mr-auto
                          chat_para
                          mb-1
                          bg-dark
                          text-white
                          p-2
                          rounded
                        "    
                        @mouseover="mouseOver"                    
                      >                      
                        <div
                          class="d-flex justify-content-end"                          
                        >
                          <button
                            class="border-0 p-0 bg-transparent"
                            v-show="active"
                            @click="send_reply_msg(msg)"
                          >
                            <font-awesome-icon
                              icon="reply"
                              class="mb-0 primary-text-color fa-sm-custom"
                              size="1x"
                            ></font-awesome-icon>
                          </button>
                        </div>
                        <div v-html="msg.message"></div>
                      </div>                      
                      <div
                        v-if="msg.image_name"
                        class="
                          mr-auto
                          chat_para
                          position-relative
                          mb-1
                          p-0
                          bg-transparent
                        "
                      >
                        <a
                          class="
                            bg-white
                            img-too-small
                            no_white
                            m-2
                            position-absolute
                            r-0
                            rounded-circle
                            t-0
                            align-items-center
                            d-flex
                            justify-content-center
                            z-depth-top
                          "
                          href="javascript:void(0)"
                          @click="download_img(msg.image_url, msg.image_name)"
                        >
                          <font-awesome-icon
                            icon="download"
                            class="mb-0 primary-text-color fa-sm-custom"
                            size="lg"
                          ></font-awesome-icon>
                        </a>
                        <img
                          :src="msg.image_url"
                          class="h-150 pointer rounded"
                          @click="showmodalw(msg.image_url)"
                        />
                      </div>
                      <p class="time_display mb-0">
                        {{ msg.show_message_time }}
                      </p>
                    </span>
                  </div>
                </span>
                <b-modal
                  size="xl"
                  id="modal-image"
                  class="modal_div"
                  v-model="modalShow"
                >
                  <img :src="display_image" class="pop_up_image" />
                </b-modal>
              </li>
            </ul>
            <div
              class="
                col-12
                p-3
                text-center
                row
                m-0
                align-items-center
                justify-content-center
              "
              v-if="grp_messages.length == 0"
            >
              <font-awesome-icon
                icon="comment-dots"
                class="grey-text fa-3x mb-3"
                size="lg"
              ></font-awesome-icon>
              <h3 class="bold_font w-100">No chat messages</h3>
              <p class="grey-text">
                Start conversing to see your messages here
              </p>
            </div>
          </div>
        </perfect-scrollbar>
        <div class="chat_box w-100" id="footer">
          <quill-editor
            id="editor"
            class="
              zindex-high
              textarea_design
              chat
              bg-white
              position-absolute
              r-0
              l-0
              b-0
              w-100
              overflow-hidden
              pb-1
            "
            v-model="grp_message"
            :options="editorOption"
            v-on:keydown="texttyping($event)"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)"
          >
            <div id="toolbar" slot="toolbar">
              <button class="ql-bold border rounded mr-1">Bold</button>
              <button class="ql-italic border rounded mr-1">Italic</button>
              <button class="ql-link border rounded mr-1">Link</button>
              <button
                class="border rounded outline-none"
                onclick="document.getElementById('fileInput').click();"
              >
                <font-awesome-icon
                  icon="paperclip"
                  class="text-black"
                  size="1x"
                ></font-awesome-icon>
              </button>
              <input
                id="fileInput"
                style="display: none"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                multiple
                ref="files"
                @change="uploadimage"
              />
            </div>
          </quill-editor>
          <button
            class="
              zindex-high
              py-2
              px-3
              w-auto
              semi_bold_font
              primary-bg
              text-white text-center
              btn
              position-absolute
              b-0
              r-0
              z-index-2
            "
            @click="send_group_message"
          >
            <font-awesome-icon
              icon="paper-plane"
              class="text-white"
              size="sm"
            ></font-awesome-icon>
          </button>
          <!-- <input autofocus v-model="grp_message" @keydown="texttyping('$event')" @keyup.enter="send_group_message()" placeholder="Type something..." />
                    <div class="color" @click="send_group_message()">
                        <div class="send_icon">
                            <font-awesome-icon icon="paper-plane" size="lg"> </font-awesome-icon>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { ContentLoader } from "vue-content-loader";
import Compress from "compress.js";
import LightBox from "vue-image-lightbox";

export default {
  name: "GroupChat",
  components: {
    PerfectScrollbar,
    ContentLoader,
    Compress,
    LightBox,
  },
  data() {
    let self = this;
    return {
       active: false,
      grp_chat_details: "",
      grp_messages: [],
      signed_in_user: "",
      grp_message: "",
      typing: false,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
          keyboard: {
            bindings: {
              tab: false,
              handleEnter: {
                key: 13,
                handler() {
                  console.log("enter works");
                  self.send_group_message();
                },
              },
              smartbreak: {
                key: 13,
                shiftKey: true,
                handler: function (range, context) {
                  this.quill.setSelection(range.index, "silent");
                  this.quill.insertText(range.index, "\n", "user");
                  this.quill.setSelection(range.index + 1, "silent");
                  this.quill.format("linebreak", true, "user");
                },
              },
            },
          },
        },
      },
      // is_focus: false,
      is_chat_success: false,
      send_image: [],
      display_image: "",
      modalShow: false,
    };
  },
  methods: {
    mouseOver: function () {
      this.active = !this.active;
    },
    showmodalw: function (url) {
      this.modalShow = !this.modalShow;
      this.display_image = url;
    },
    forceFileDownload(response, image_name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", image_name); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    download_img(image, name) {
      this.$http({
        method: "get",
        url: image,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, name);
        })
        .catch(() => console.log("error occured"));
    },
    onEditorBlur(editor) {},
    onEditorFocus(editor) {
      // this.is_focus = true;
    },
    onEditorReady(editor) {
      // console.log('editor ready!', editor)
      editor.focus();
    },
    onEditorChange(editor) {
      // this.is_focus = false;
    },
    uploadimage: function (evt) {
      this.grp_message = "";
      let selected = evt.target.files;
      let accepted_files = [];
      let declined_files = [];
      for (var i = 0; i < selected.length; i++) {
        let file = selected[i];
        if (
          file.name.split(".").pop() == "jpeg" ||
          file.name.split(".").pop() == "jpg" ||
          file.name.split(".").pop() == "JPG" ||
          file.name.split(".").pop() == "png"
        ) {
          accepted_files.push(file);
        } else {
          declined_files.push(file);
        }
      }
      const compress = new Compress();
      var files = accepted_files;
      compress
        .compress(files, {
          size: 5, // the max size in MB, defaults to 2MB
          quality: 0.8, // the quality of the image, max is 1,
          maxWidth: 720, // the max width of the output image, defaults to 1920px
          maxHeight: 640, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          let compressed_img = data;
          var img = [];
          for (var i = 0; i < compressed_img.length; i++) {
            let file = compressed_img[i];
            var image = {};
            image.name = compressed_img[i].alt;
            image.b64code = compressed_img[i].data;
            img.push(image);
          }
          this.send_image = img;
          this.send_group_message();
          // this.send_image.splice(0,this.send_image.length)
        });
    },
    send_group_message() {
      var group_id = this.$route.params.id;
      var find = "<p><br></p>";
      var re = new RegExp(find, "g");
      this.grp_message = this.grp_message.replace(re, "");
      if (this.grp_message.trim() != "") {
        var params = {
          sender: this.signed_in_user._id,
          group_id: group_id,
          message: this.grp_message,
          type: "message",
        };
        console.log(params);
        let response = this.$socket.emit("groupmessage", {
          params: params,
          allmsg: this.grp_messages,
        });
        this.grp_message = "";
        this.scrollToEnd();
      } else if (this.send_image.length) {
        var params = {
          sender: this.signed_in_user._id,
          group_id: group_id,
          group_image: this.send_image,
          type: "image",
        };
        console.log(params);
        let response = this.$socket.emit("groupmessage", {
          params: params,
          allmsg: this.grp_messages,
        });
        this.scrollToEnd();
      } else {
        this.$bvToast.toast(`Please enter comment`, {
          title: "Success",
          variant: "warning",
          autoHideDelay: 3000,
        });
      }
    },
    texttyping: function (e) {
      let params = {
        user_name: this.signed_in_user.full_name,
        group_id: this.$route.params.id,
      };
      this.$socket.emit("group_typing", params);
    },
    scrollToEnd: function () {
      setTimeout(() => {
        var objDiv = document.getElementById("container");
        const ps = this.$refs.ps;
        ps.$el.scrollTop = objDiv.scrollHeight;
        ps.update();
      }, 100);
    },
  },
  created() {
    this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    this.$socket.emit("room_for_group", this.$route.params.id);
    this.$socket.emit("group_chat_messages", this.$route.params.id); //All messages in group chat
    this.$socket.emit("read_all_notifications", {
      chat_id: this.$route.params.id,
      user_id: this.signed_in_user._id,
      type: "group",
    });
  },
  mounted() {
    document
      .getElementById("editor")
      .addEventListener("input", this.texttyping);
    document.getElementById("footer").focus();
    this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    this.$store.commit("change_page", "group_chat");
    this.$socket.emit("room_for_group", this.$route.params.id);
    this.$socket.emit("group_chat_messages", this.$route.params.id); //All messages in group chat
  },
  watch: {
    $route(to, from) {
      this.$store.commit("change_page", "group_chat");
      this.$socket.emit("room_for_group", this.$route.params.id);
      this.$socket.emit("group_chat_messages", this.$route.params.id); //All messages in group chat
      this.$socket.emit("read_all_notifications", {
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
        type: "group",
      });
    },
  },
  sockets: {
    new_group_text: function (val) {
      this.grp_messages.push(val);
      this.send_image.splice(0, this.send_image.length);
    },
    collect_group_text: function (values) {
      this.is_chat_success = true;
      console.log(values.group_msg);
      this.grp_messages = values.group_chats;
      this.send_image.splice(0, this.send_image.length);
      this.$store.commit("set_group_chat", values.project_detail);
    },
    group_typing: function (data) {
      this.typing = data;
    },
  },
};
</script>

<style scoped>
.pop_up_image {
  width: 100%;
  object-fit: contain;
  padding: 5px;
  height: 100%;
}

.bg-dark * {
  color: #fff !important;
}
.background {
  background-color: #f0f1f3;
}

.chat_line {
  margin: 0px !important;
  font-size: 14px;
  position: relative;
}

.chat_para {
  background-color: #e9e7e7;
  max-width: calc(100% - 30%);
  font-size: 16px;
  width: fit-content;
  min-width: 70px;
}
.chat_name {
  font-size: 14px;
}

.time_display {
  font-size: 11px;
  position: relative;
}

.chat_messages {
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
  word-wrap: break-word;
}

@media (max-width: 575px) {
  .chat_messages {
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
  }
}
</style>