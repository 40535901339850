import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import BootstrapVue from 'bootstrap-vue'
import VueResource from 'vue-resource'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VSelectize from '@isneezy/vue-selectize'
import Multiselect from 'vue-multiselect'
import VueUploadComponent from 'vue-upload-component'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueStickyKit from 'vue-stickykit'
import VueQuillEditor from 'vue-quill-editor'
import VueSocketio from 'vue-socket.io'
import { store } from './store/index'
import _ from 'lodash';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueNativeNotification from 'vue-native-notification'
Object.defineProperty(Vue.prototype, '$_', { value: _ });
import VueLazyLoad from 'vue-lazyload'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import { SidebarComponent, SidebarPlugin } from '@syncfusion/ej2-vue-navigations';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import '../src/assets/scss/custom.scss';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
library.add(fas )

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-selectize', VSelectize)
Vue.component('multiselect', Multiselect)
Vue.component('file-upload', VueUploadComponent)
Vue.component('v-select', vSelect)
Vue.component(SidebarPlugin.name, SidebarComponent); // sidebar plugin

Vue.prototype.$eventHub = new Vue();

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueResource)
Vue.use(VueStickyKit)
Vue.use(VueQuillEditor,)
Vue.use(PerfectScrollbar)

Vue.use(VueLazyLoad)

Vue.use(VueMoment, {
    moment,
})

Vue.use(VueNativeNotification, {
    // Automatic permission request before showing notification (default: true)
    requestOnNotify: true
  })

// Vue.notification.requestPermission()
// .then(console.log)
var commonHelper = require("../node_app/src/commonHelper");
Vue.use(new VueSocketio({
    debug: true,
    connection: commonHelper.getBaseUrl(),         //https://teams.waioz.com
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    options: { path: '' } //Optional options
}))

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')