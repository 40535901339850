<template id="sidebar-template">
  <perfect-scrollbar>
    <div class="sidenav-pkg bg-grey">
      <div class="sidebar text-left p-0" id="lgmenu">
        <div class="bar-details text-center px-3 pt-3">
          <img
            :src="this.$store.state.user_detail.profile_photo"
            width="30px"
            class="mb-3 w-100 mw-120 h-120 rounded-circle o-cover"
          />
          <p class="name_title bold_font">
            {{ this.$store.state.user_detail.full_name }}
          </p>
          <!-- <li class="name_title"> {{this.$store.state.user_detail.emailid}} </li> -->
        </div>
        <div class="bar-menus">
          <ul class="m-0 p-2 text-left">
            <li class="sidemenu" @click="changebin()">
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/"
                >Projects
              </router-link>
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="
                ($store.state.user_detail.user_role == 'admin' ||
                  $store.state.user_detail.user_role == 'super_admin') &&
                $store.state.user_detail.user_role != 'client'
              "
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/alltasks"
                >All Tasks
              </router-link>
            </li>
            <li class="sidemenu" @click="changebin()">
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/mytasks"
                >My Tasks</router-link
              >
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="
                $store.state.user_detail.user_role == 'admin' ||
                $store.state.user_detail.user_role == 'super_admin'
              "
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/userlist"
                >Users
              </router-link>
            </li>
             <li class="sidemenu" @click="changebin()" v-if="isScrumSideBarTabVisible">
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/scrum"
                >Scrum</router-link
              >
            </li>
            <li class="sidemenu" @click="changebin()">
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/sprint"
                >Sprint</router-link
              >
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="
                $store.state.user_detail.user_role == 'admin' ||
                $store.state.user_detail.user_role == 'super_admin'
              "
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/backlogs"
                >Back Logs
              </router-link>
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="
                $store.state.user_detail.user_role == 'admin' ||
                $store.state.user_detail.user_role == 'super_admin'
              "
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/holidays"
                >Holidays
              </router-link>
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="$store.state.user_detail.user_role != 'client'"
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                v-bind:to="{
                  name: 'AddUser',
                  params: $store.state.user_detail,
                }"
                >Edit Profile
              </router-link>
            </li>
            <li class="sidemenu position-relative" @click="changebin()">
              <router-link
                class="
                  sidebarmenu
                  grey-text
                  text-decoration-none
                  d-flex
                  align-items-center
                  p-2
                "
                v-bind:to="'/notification'"
                >Notifications<b-badge
                  v-if="notifications_count > 0"
                  variant="light"
                  class="
                    z-depth-1
                    badge
                    ml-2
                    d-flex
                    align-items-center
                    rounded-circle
                    img-too-small
                    justify-content-center
                  "
                  >{{ notifications_count }}</b-badge
                ></router-link
              >
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="
                $store.state.user_detail.user_role == 'admin' ||
                $store.state.user_detail.user_role == 'super_admin'
              "
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                to="/leavelist"
                >Leave Records
              </router-link>
            </li>
            <li
              class="sidemenu"
              @click="changebin()"
              v-if="$store.state.user_detail.user_role != 'client'"
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                v-bind:to="'/calendar'"
                >Calendar</router-link
              >
            </li>
            <!-- <li
              class="sidemenu"
              @click="changebin()"
              v-if="$store.state.user_detail.user_role != 'client'"
            >
              <router-link
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                v-bind:to="'/inbox'"
                >Inbox</router-link
              >
            </li> -->
            <li class="sidemenu">
              <a
                href="javascript:void(0)"
                class="sidebarmenu grey-text text-decoration-none d-block p-2"
                @click="clearsession"
                >Logout</a
              >
            </li>
          </ul>
        </div>
        <div v-if="($store.state.user_detail.user_role != 'client')" class="chat-menus">
              <ul class="p-2 m-0 list-type-none">
                  <li class="sidemenu logout_button" @click="changebin();"> <router-link class="sidebarmenu chat_icon grey-text text-decoration-none d-block p-2 " v-bind:to="'/group/waioz'" > #waioz </router-link> </li>
              </ul>
          </div>
        
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "Sidebar",
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      user_detail: {},
      hidemenu: true,
      isScrumSideBarTabVisible: false,
      toggle: false,
      edit_user_info: "",
      chat_modal: false,
      chat_list: "",
      group_chat_list: "",
      sender: "",
      receiver: "",
      member_options: "",
      notifications_count: 0,
    };
  },
  methods: {
    hideSideBarScrumRoute() {
      setTimeout(() => {
        if(localStorage.getItem("ScrumPageNoData") == "true") {
        this.isScrumSideBarTabVisible = false;
      } else {
        this.isScrumSideBarTabVisible = true;
      }
      }, 500);
    },
    changebin(value) {
      this.$eventHub.$emit("change-binary", this.toggle);
      this.$eventHub.$emit("hide_sidemenu");
    },
    new_chat_modal() {
      this.chat_modal = !this.chat_modal;
    },
    clearsession() {
      this.changebin();
      this.$socket.emit("logout_disconnect", {
        user_id: this.$store.state.user_detail._id,
      });
      localStorage.removeItem("userinfo");
      this.$router.push("/signin");
      this.showdd = false;
    },
    get_userlist: function () {
      let params = {
        current_user: this.user_detail._id,
      };
      this.$http
        .post(this.$store.state.api_url + "allchatusers", params)
        .then(function (response) {
          response = response.body;
          response = response.data;
          let data = response;
          this.member_options = data;
        });
    },
    new_chat: function () {
      var signed_in_user = this.user_detail;
      this.sender = signed_in_user._id;
      let params = {
        sender: this.sender,
        receiver: this.receiver._id,
      };
      this.$http
        .post(this.$store.state.api_url + "newchat", params)
        .then(function (response) {
          var request = response.body.data;
          this.chat_modal = false;
          this.receiver = "";
          if ((response.body.status = "true")) {
            this.$socket.emit("getchats", {
              session_id: this.$socket.id,
              user_chat: this.user_detail._id,
            });
            this.changebin();
            this.$router.push("/chat/" + request.chat_id);
          } else if ((response.body.message = "Chat exists")) {
            this.$socket.emit("getchats", {
              session_id: this.$socket.id,
              user_chat: this.user_detail._id,
            });
            this.changebin();
            this.$router.push("/chat/" + request.chat_id);
          }
        });
    },
    get_chat_list: function () {
      var user_id = this.user_detail;
      this.sender = user_id._id;
      let params = {
        user_chat: this.sender,
        session_id: this.$socket.id,
      };
      let response = this.$socket.emit("getchats", params);
    },
    get_group_chat: function () {
      let params = {
        id: this.user_detail._id,
      };
      this.$http
        .post(this.$store.state.api_url + "projectchat", { params })
        .then(function (response) {
          response = response.body;
          this.group_chat_list = response.data;
        });
    },
  },
  created() {
    this.user_detail = JSON.parse(localStorage.getItem("userinfo"));
    if (localStorage.getItem("userinfo")) {
      this.$store.commit(
        "set_user_detail",
        JSON.parse(localStorage.getItem("userinfo"))
      );
    }
    // this.get_userlist();
    // this.get_group_chat();
    this.$socket.emit("getchats", {
      session_id: this.$socket.id,
      user_chat: this.user_detail._id,
    });
  },
  mounted() {
    // this.get_userlist();
    if (document.body.clientWidth >= 767) {
      setTimeout(function () {
        let mainDiv = document.getElementById("extra_margin").style.marginLeft;
        if (mainDiv == "") mainDiv = "0px";
        document.getElementById("extra_margin").style.marginLeft =
          parseInt(mainDiv.split("px")[0]) + 60 + "px";
      }, 200);
    }
    // this.get_group_chat()
    this.hideSideBarScrumRoute();
  },
  sockets: {
    connect: function () {
      this.$socket.emit("getchats", {
        session_id: this.$socket.id,
        user_chat: this.user_detail._id,
      });
    },
    list_of_chats: function (val) {
      this.chat_list = val;
    },
    user_status: function (val) {
      if(this.chat_list.length)
      {
        var receiver = this.chat_list.find((o) => o.receiver === val.user_id);
        var sender = this.chat_list.find((o) => o.sender === val.user_id);
        if (receiver || sender) {
          this.$socket.emit("getchats", {
            session_id: this.$socket.id,
            user_chat: this.user_detail._id,
          });
        }
      }
    },
    notification_alert: function (data) {
      this.notifications_count += 1;
    },
    get_all_notifications_count: function (value) {
      this.notifications_count = value;
    },
  },
};
</script>

<style scoped>
.chat-menus {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.chat_icon svg {
  margin-right: 4px;
  margin-left: 0px !important;
}

.green_status_icon {
  color: rgb(106, 238, 30);
}

.vs__dropdown-menu {
  max-height: 100px !important;
}

.icons {
  margin-right: 10px;
}

.hdr-img {
  height: 50px;
  width: 50px;
}

.pms {
  font-size: 10px;
  color: #9a9494;
  margin: 0px;
  text-align: left !important;
}

.leftcorner {
  text-align: left;
  background-color: rgb(240, 241, 243);
  color: rgba(53, 53, 53);
  z-index: 99 !important;
  top: 0;
}

.list {
  border: none;
  background-color: transparent;
  align-content: center;
  /* margin-top: 10px; */
}

.list:focus {
  outline: none;
}

svg {
  align-content: left;
  color: rgb(0, 0, 0);
  width: 20px;
}
.sidebar {
  color: rgba(53, 53, 53);
  height: 100vh;
}

h4 {
  font-size: 1.8em;
  margin: 5px 5px 10px 5px;
}

hr {
  margin: 1rem 0.5rem;
  border: 2px;
  border-top: 1px solid rgb(241, 237, 237);
}

@media (max-width: 992px) {
  .header {
    display: contents;
  }
}
.h-120 {
  height: 120px;
}
</style>