<template>
    <div class="scrumlist col-12">
        <div class="table1 row m-0">
            <div class="col-12 p-0">
                <div class="d-flex row col-12 mx-0 pt-5 px-0 position-relative">
                    <div class="row position-absolute align-items-center col-md-9 mx-auto l-0 r-0 t-0">
                        <input autofocus v-model="scrum_search" class="border-1 outline-none p-3 rounded-pill w-100 bg-light mb-0" placeholder="Search..." @keyup="search_scrum(scrum_search)" />
                        <font-awesome-icon icon="search" size="sm" class="disabled grey-text mr-5 position-absolute r-0 t-0 b-0 my-auto"> </font-awesome-icon>
                        <!-- <div>
                            <b-button class="mx-1" variant="primary" size="sm"  >Search</b-button>
                        </div> -->
                    </div>
                </div>
                <div class="project_way col-12 col-md-9 mx-auto p-0 row mt-4">
                    <ContentLoader class="w-100 col-12 p-3" v-if="!is_success">
                        <rect x="0" y="10" rx="20" ry="20" width="24%" height="90%" />
                        <circle cx="20%" cy="12" r="12" />
                        <rect x="25%" y="10" rx="20" ry="20" width="24%" height="90%" />
                        <circle cx="45%" cy="12" r="12" />
                        <rect x="50%" y="10" rx="20" ry="20" width="24%" height="90%" />
                        <circle cx="70%" cy="12" r="12" />
                        <rect x="75%" y="10" rx="20" ry="20" width="24%" height="90%" />
                        <circle cx="95%" cy="12" r="12" />
                    </ContentLoader>
                    <div class="col-12 row m-0 p-0" v-if="is_success">
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 col-sm-4 col-xs-6 my-4" v-if="this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin'">
                            <div class="tilestructure">
                                <router-link class="route" to="/addscrumgroup">
                                    <div class="tilebox d-flex align-items-center justify-content-center position-relative w-100 mx-auto">
                                        <div class="add_icon">
                                            <font-awesome-icon icon="plus" size="2x"> </font-awesome-icon>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="my-5" v-if="(this.$store.state.user_detail.user_role != 'admin' && this.$store.state.user_detail.user_role != 'super_admin') && scrumgrouplist.length == 0">
                            <h5>You are not assigned to any scrums currently.</h5>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 col-sm-4 col-xs-6 py-4" v-for="item in scrumgrouplist" :key="item._id">
                            <div class="tilestructure w-100 h-200 mx-auto">
                                <span class="position-absolute t-0 r-0 rounded-circle img-high-small d-flex align-items-center justify-content-center my-2 mx-4" v-bind:style="{ 'background-color': item.color }"> 
                                    <router-link 
                                    class="route"
                                    v-bind:to="{
                                    name: 'ScrumGroup',
                                    params: item,
                                    }" v-if="isUserAdminOrSuperAdmin"><font-awesome-icon icon="pen" size="md" class="disabled text-white"></font-awesome-icon></router-link>
                                    <font-awesome-icon v-else icon="bolt" size="md" class="disabled text-white"></font-awesome-icon>
                                <!-- <router-link 
                                class="route"
                                v-bind:to="{
                                name: 'ScrumGroup',
                                params: item,
                                }" ><font-awesome-icon icon="pen" size="md" class="disabled text-white"></font-awesome-icon></router-link> -->
                                </span>
                                <router-link class="route row align-items-center m-0 tilebox mx-auto bg-light" v-bind:to="'/scrum/' + item._id" >
                                    <div class="p-3 mw-100 position-relative user_images"> <!-- v-bind:style="{ 'background-color': item.color }" -->
                                        <span class="d-inline-flex m-0 flex-row-reverse">
                                            <template v-if="item.teammembers.length > 5">
                                                <span :id="'member' + item._id" class="fa-xs d-inline-flex img-small rounded-circle align-items-center justify-content-center z-depth-1 border-white bold_font mb-1 pt-1 text-white bg-rgba">
                                                    <!-- <font-awesome-icon icon="ellipsis-v" size="xs" class="grey-text"> </font-awesome-icon> -->
                                                    {{item.teammembers.length}}
                                                </span>
                                                <b-tooltip :target="'member' + item._id" triggers="hover">
                                                    <span v-for="member in item.teammembers" :key="member._id" class="text-left">
                                                        {{member.full_name}} <br />
                                                    </span>
                                                </b-tooltip>
                                            </template>
                                            <span v-for="(member, index) in item.teammembers"  :key="index" class="position-relative" :class="{'m-0':index>=4}">
                                                <template v-if="index<5">
                                                    <img v-b-tooltip.hover :title="member.full_name" :src="member.profile_photo" class="img-small border-white mb-1 rounded-circle o-cover" />
                                                </template>
                                            </span>
                                        </span>
                                        <h3 class="bold_font grey-text m-0">{{item.name}}</h3>
                                    </div> 
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 row m-0 col-12">
                        <ul class="pagination pagination_design ml-auto d-flex list-type-none" v-if="pages && pages.length > 1">
                            <li class="page-item" v-bind:class="{disabled:current === 1}">
                                <a class="page-link"  @click="get_scrums(1)">
                                    <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
                                </a>
                            </li>
                            <li class="page-item" v-bind:class="{disabled:current === 1}">
                                <a class="page-link"  @click="get_scrums(current - 1)">
                                    <font-awesome-icon icon="angle-left"> </font-awesome-icon>
                                </a>
                            </li>
                            <li class="page-item" v-for="item in pages" :key="item.id" v-bind:class="{active:current === item.id}">
                                <a class="page-link"  @click="get_scrums(item.id)">{{item.id}}</a>
                            </li>
                            <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                                <a class="page-link"  @click="get_scrums(current + 1)">
                                    <font-awesome-icon icon="angle-right"> </font-awesome-icon>
                                </a>
                            </li>
                            <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                                <a class="page-link"  @click="get_scrums(totalPages)">
                                    <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
    components: { ContentLoader },
    name: 'ScrumList',
    data () {
        return {
            page: 1,
            taskpage: 1,
            current: 1,
            totalDocs: 0,
            totalPages: 0,
            startPage: 1,
            endPage: 1,
            pages:[],
            serial_number:'',
            scrumgrouplist:{},
            signed_in_user: '',
            scrum_search: '',
            search: '',
            is_success: false,
        }
    },
    methods: {
        notify () {
            // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
            // this.$notification.show('Hello World', {
            //     body: 'This is an example!'
            // }, {})
            const notification = {
                title: 'Your title',
                options: {
                    body: 'This is an example!'
                },
                events: {
                    onerror: function () {
                        console.log('Custom error event was called');
                    },
                    onclick: function () {
                        this.$router.push({name:'Notification'})
                    },
                    onclose: function () {
                        console.log('Custom close event was called');
                    },
                    onshow: function () {
                        console.log('Custom show event was called');
                    }
                }
            }
            this.$notification.show(notification.title, notification.options, notification.events)
            console.log('Notification ba')
        },
        addscrum:function() {
            this.$router.push({name:'addscrumgroup'})
        },
        search_scrum: function(value) {
            if(value != null || value != ""){
                this.search = value
                this.get_scrums(this.page)
                this.scrum_search = value
            }
        },
        get_scrums:function(page){
            this.signed_in_user = JSON.parse(localStorage.getItem('userinfo'));
            let params = {
                pageno : page,
                size: 7,
                id: this.signed_in_user._id,
                search_scrum: this.search
            };
            this.$http.get(this.$store.state.api_url+'allscrumsgroup',{params})
            .then(function(response){
                this.is_success = true;
                response = response.body;
                this.data= (JSON.parse(localStorage.getItem('userinfo')).user_role == "super_admin" || JSON.parse(localStorage.getItem('userinfo')).user_role == "admin") ? response.data.docs : response.data.docs.filter(doc => doc.scrummaster.id == JSON.parse(localStorage.getItem('userinfo'))._id && doc.status == "Active");
                this.serial_number = response.data.pagingCounter;
                this.current =response.data.page;
                this.totalDocs=response.data.totalDocs;
                this.totalPages=response.data.totalPages;
                this.scrumgrouplist=this.data;
                if(this.data.length == 0) {
                    localStorage.setItem("ScrumPageNoData", true);
                } else {
                    localStorage.setItem("ScrumPageNoData", false);
                }
                if(this.totalPages <= 5) 
                {
                    this.startPage = 1;
                    this.endPage = this.totalPages;
                } 
                else 
                {
                    if (this.current <= 3) {
                        this.startPage = 1;
                        this.endPage = 5;
                    } else if (this.current + 2 >= this.totalPages) {
                        this.startPage = this.totalPages - 4;
                        this.endPage = this.totalPages;
                    } else {
                        this.startPage = this.current - 2;
                        this.endPage = this.current + 2;
                    }
                }
                this.pages = Array.from(Array((this.endPage + 1) - this.startPage).keys()).map(i => ({ id: (this.startPage+i), name: 'Item ' + (this.startPage+i) }));
            })
        },
    },
    mounted() {
        this.get_scrums(this.page)
        this.$store.commit("change_page", "home");
    },
     computed: {
    isUserAdminOrSuperAdmin() {
      if (typeof localStorage !== 'undefined') {
        const userInfo = localStorage.getItem('userinfo');
        if (userInfo) {
          const userRole = JSON.parse(userInfo).user_role;
          return userRole === 'admin' || userRole === 'super_admin';
        }
      }
      return false;
    }
  }
}
</script>

<style>

@media (min-width: 700px){
    .my_task_name{
        width: 45%
    }
    .flag_status {
        width: 60px;
    }
}
.route{
    color: rgb(114, 112, 112);
}
.tilestructure a{
  transition: 0.4s;
}

.tilestructure:hover a{
  transform: translate3d(0, -5px, 0);
}

.tilebox{
    height: 180px;
    border-radius: 50px;
    border: 2px solid #d9d9d9;
}

.paginator{
    margin-left: auto;
    margin-right: auto;
    user-select: none;
}

.page-item {
    cursor: pointer;
}

.page-item.active .page-link{
    background-color: rgb(54, 88, 143) !important; 
    color: #ffffff;
}
.img-small {
    width: 30px;
    height: 30px;
}
.img-high-small {
    width: 40px;
    height: 40px;
    z-index: 1;
}
.bg-rgba {
    background: rgba(0, 0, 0, 0.5);
}
</style>