<template>
  <div class="board_fullpage">
    <!-- <div class="buttons my-2">      
        <button class="button add ml-4" @click="addColumn()">Add Column</button>
      </div> -->
    <Container
      orientation="horizontal"
      @drop="onColumnDrop($event)"
      drag-handle-selector=".column-drag-handle"
      @drag-start="dragStart"
      :drop-placeholder="upperDropPlaceholderOptions"
    >
      <Draggable v-for="(column, index) in scene.children" :key="column.id">
        <div :class="column.props.className">
          <div class="card-column-header">
            <div>
              <span class="column-drag-handle placeholder">&#x2630;</span>
              {{ column.name }}
            </div>
          </div>
          <Container
            group-name="col"
            @drop="(e) => onCardDrop(column.id, e)"
            @drag-start="(e) => log('drag start', e)"
            @drag-end="(e) => log('drag end', e)"
            :get-child-payload="getCardPayload(column.id)"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
          >
            <Draggable v-for="card in column.children" :key="card.id">
              <div :class="card.props.className" :style="card.props.style">
                <textarea
                  id="card_task"
                  name="task"
                  rows="4"
                  cols="20"
                  class="border-0 text_ar"
                  v-model="card.data"
                >
                </textarea>
              </div>
            </Draggable>
          </Container>
          <button class="button add ml-4" @click="addrow(index)">
            Add row
          </button>
        </div>
      </Draggable>
    </Container>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import Vue from "vue";
// import { applyDrag, scene, generateItems } from "../../public/helpers";

export default {
  name: "Cards",
  components: { Container, Draggable },

  data() {    
    let columnNames = ['new','todo','in_progress','Completed','rot','pot','fot'];
    let lorem =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
    return {
      inprogress_task: [
        {
          id: 1,
          name: "ganesh",
        },
      ],
      scene: {
        type: "container",
        props: {
          orientation: "horizontal",
        },
        children: this.generateStatus(7, (i) => ({
          id: `column${i}`,
          type: "container",
          name: columnNames[i],
          props: {
            orientation: "vertical",
            className: "card-container",
          },
          children: [],
        })),
      },

      isHidden: true,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      groups: [],
      flags: [],
      page: 1,
      current: 1,
      totalDocs: 0,
      totalPages: 0,
      startPage: 1,
      endPage: 1,
      pages: [],
      api_data: [],
    };
  },

  methods: {
    generateStatus(count, creator) {
      const result = [];
      for (let i = 0; i < count; i++) {
        // console.log("creater", creator(i));
        result.push(creator(i));
      }
      return result;
    },
    generateTasks(parent, inprogress_task) {
      var data = [];
      switch (parent) {
        case 0:
          data = inprogress_task;
          break;

        default:
          break;
      }
      console.log("data", this.startPage);
      const count = data.length;
      const result = [];
      for (let j = 0; j < count; j++) {
        result.push({
          type: "draggable",
          id: `${parent}${j}`,
          props: {
            className: "card",
          },
          number: data[j].id,
          data: data[j].name,
        });
      }
      return result;
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    onColumnDrop(dropResult) {
      const scene = Object.assign({}, this.scene);
      scene.children = this.applyDrag(scene.children, dropResult);
      this.scene = scene;
    },

    onCardDrop(columnId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = Object.assign({}, this.scene);
        const column = scene.children.filter((p) => p.id === columnId)[0];
        const columnIndex = scene.children.indexOf(column);

        const newColumn = Object.assign({}, column);
        console.log(newColumn.children,"new column value")                       
        newColumn.children = this.applyDrag(newColumn.children, dropResult);
        let params = {
          project_Id: this.$route.params.id,
          task_value: newColumn.children,
          status:newColumn.name
          };
           this.$http
            .post(this.$store.state.api_url + "update_board_status", { params })
             .then(function (response) {
              this.is_success = true;
             })  
        console.log(newColumn.children,"new column value")        
        scene.children.splice(columnIndex, 1, newColumn);

        this.scene = scene;
      }
    },

    getCardPayload(columnId) {
      return (index) => {
        return this.scene.children.filter((p) => p.id === columnId)[0].children[
          index
        ];
      };
    },

    dragStart() {
      console.log("drag started");
    },
    addColumn(i) {
      // this.scene.children.push(generate(this.scene.children.length + 1));
      console.log(this.scene.children.length);
      this.scene.children.push({
        id: `column-${i}`,
        type: "container",
        name: "Add new column",
        props: {
          orientation: "vertical",
          className: "card-container",
        },
        children: [],
      });
    },
    addrow(i, data) {
      this.scene.children[i].children.push({
        id: `${data.id}`,
        type: "draggable",
        props: {
          className: "card",
          style: { backgroundColor: "white" },
        },
        number: data.ticket_number,
        data: data.title,
      });
    },
    change_header(i) {
      console.log(i, "clicked");
      // this.head_click : true,
    },
    get_tasks: function () {
      let params = {
        project_Id: this.$route.params.id,
      };
      this.$http
        .get(this.$store.state.api_url + "boardtasks", { params })
        .then(function (response) {
          this.is_success = true;
          this.api_data = response.data.data;
          this.api_data.new_tasks.map((res) => {
            this.addrow(0, res);
          });
          this.api_data.todo_tasks.map((res) => {
            this.addrow(1, res);
          });
          this.api_data.completed_tasks.map((res) => {
            this.addrow(3, res);
          });
          this.api_data.fot_tasks.map((res) => {
            this.addrow(5, res);
          });
          this.api_data.inprogress_tasks.map((res) => {
            this.addrow(2, res);
          });
          this.api_data.pot_tasks.map((res) => {
            this.addrow(6, res);
          });
          this.api_data.rot_tasks.map((res) => {
            this.addrow(4, res);
          });
          // this.scene.children.children["data"] = this.api_data;
          this.data = response.data.docs;
          this.serial_number = response.data.pagingCounter;
          this.current = response.data.page;
          this.totalDocs = response.data.totalDocs;
          this.totalPages = response.data.totalPages;
          this.project_tasks = this.data;
          if (this.totalPages <= 5) {
            this.startPage = 1;
            this.endPage = this.totalPages;
          } else {
            if (this.current <= 3) {
              this.startPage = 1;
              this.endPage = 5;
            } else if (this.current + 2 >= this.totalPages) {
              this.startPage = this.totalPages - 4;
              this.endPage = this.totalPages;
            } else {
              this.startPage = this.current - 2;
              this.endPage = this.current + 2;
            }
          }
          this.pages = Array.from(
            Array(this.endPage + 1 - this.startPage).keys()
          ).map((i) => ({
            id: this.startPage + i,
            name: "Item " + (this.startPage + i),
          }));
        });
    },
    log(...params) {},
  },

  mounted() {
    this.get_tasks();
    this.scene;
    this.$store.commit("change_page", "project_board");
  },
};
</script>
<style>
.board_fullpage {
  height: calc(100vh - 154px);
  overflow-y: hidden;
}
.dndrop-container {
  height: calc(100vh - 252px);
  overflow: scroll;
}
.card-container {
  min-width: 237px;
}
</style>