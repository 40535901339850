<template>
  <div class="col-12 px-0 pt-7">
    <div class="toolbar d-flex flex-column row col-12 m-0 p-0">
      <div
        class="left_children col-12 p-0 px-md-3 col-md-6"
        v-if="
          $store.state.user_detail.user_role == 'admin' ||
          $store.state.user_detail.user_role == 'super_admin' ||
          $store.state.user_detail._id == this.$store.state.project.manager
        "
      ></div>

      <div class="d-flex flex-column">
        <div class="filtered_items">
          <div
            v-if="name_filter !== '' && typeof name_filter !== 'undefined'"
            class="filter_chip"
          >
            Task Name: <b>{{ name_filter }}</b>
            <font-awesome-icon
              class="ml-2 clear-filter"
              @click="remove_filter('name_filter', name_filter)"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
          <div v-if="selected_status !== ''" class="d-flex align-items-center">
            <div
              v-for="(status, index) in selected_status"
              :key="index"
              class="filter_chip"
            >
              Status:
              <b>{{ status }}</b>
              <font-awesome-icon
                @click="remove_filter('selected_status', status)"
                class="ml-2 clear-filter"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-primary" @click="openSprintModal({}, 'create')">
        + Add Sprint
      </button>
    </div>
    <div class="tasklist col-12 row w-100 px-md-3 p-0 mx-0">
      <div class="taskform col-12 px-md-3 p-0" id="no-more-tables">
        <span class="ml-2" v-if="is_loading">
          <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
        </span>
        <table
          v-if="!is_loading"
          class="table table-hover sprint_table_design table-striped"
        >
          <thead>
            <tr class="p-0 m-0 position-relative">
              <th
                class="project_sprints_flag text-left flag_status semi_bold_font grey-text font-weight-normal border-0 font-italic"
              >
                <input
                  type="checkbox"
                  :checked="
                    isCheckedAll || checkedRow.length === project_sprints
                  "
                  @click="(e) => onCheck(e, true, '')"
                />
              </th>

              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_sprints_name"
              >
                Name
              </th>

              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_updated_at"
              >
                Status
              </th>
              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_created_at"
              >
                Created Date
              </th>

              <th
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic project_updated_at"
              >
                Modified At
              </th>
            </tr>
          </thead>
          <tbody v-if="!is_success">
            <td colspan="5">
              <ContentLoader>
                <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
              </ContentLoader>
            </td>
          </tbody>
          <tbody class="table-body" v-if="is_success">
            <tr
              class="p-0 position-relative rounded m-0 table-body-row"
              v-for="item in project_sprints"
              :key="item._id"
              @click="openSprintModal(item, 'update')"
            >
              <td
                class="pl-3 text-left w-auto flag_status project_sprints_flag"
              >
                <input
                  type="checkbox"
                  :checked="isCheckedRow(item._id)"
                  @click="(e) => onCheck(e, false, item._id)"
                />
              </td>

              <td class="project_sprints_name" data-title="Task">
                <br />{{ item.name }}<span v-if="item.name == null"> - </span>
              </td>

              <td class="project_updated_at" data-title="status">
                <span
                  :style="{ background: get_color(item.status.toLowerCase()) }"
                  class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ status_props[item.status].name }}</span
                >
              </td>
              <td
                class="project_created_at fa-sm-custom created_at"
                data-title="created At"
              >
                {{ format_date(item.created_at) }}
              </td>
              <td
                class="project_updated_at fa-sm-custom"
                data-title="Updated At"
              >
                {{ format_date(item.updated_at) }}
              </td>
            </tr>

            <tr v-if="project_sprints.length == 0">
              <td colspan="5">No Sprints here!</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal
      size="lg"
      :title="
        sprint_modal_mode === 'create' ? 'Create Sprint' : 'Sprint Detail'
      "
      v-model="sprint_modal"
      @ok="
        (e) =>
          sprint_modal_mode === 'create'
            ? addSprintData(e)
            : updateSprintData(e)
      "
    >
      <div class="mt-2">
        <h4>Name</h4>
        <input
          type="text"
          class="form-control"
          id="sprint_name"
          v-model="sprint_modal_data.name"
          value="scrum_modal_data.name"
        />
      </div>

      <div class="mt-2">
        <h4>Status</h4>
        <v-select
          class="w-100 v-select-design"
          :searchable="true"
          v-model="sprint_modal_data.status"
          :options="status_options"
          placeholder="Select Status"
        >
        </v-select>
        <div v-if="sprint_modal_error !== ''" class="alert-danger mt-2 p-2">
          {{ sprint_modal_error }}
        </div>
      </div>
    </b-modal>
    <div class="mt-3 row m-0 col-12" v-if="is_success">
      <ul
        class="pagination pagination_design ml-auto d-flex list-type-none"
        v-if="pages && pages.length > 1"
      >
        <li class="page-item" v-bind:class="{ disabled: current === 1 }">
          <a class="page-link" @click="get_sprints(1)">
            <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
          </a>
        </li>
        <li class="page-item" v-bind:class="{ disabled: current === 1 }">
          <a class="page-link" @click="get_sprints(current - 1)">
            <font-awesome-icon icon="angle-left"> </font-awesome-icon>
          </a>
        </li>
        <li
          class="page-item"
          v-for="item in pages"
          :key="item.id"
          v-bind:class="{ active: current === item.id }"
        >
          <a class="page-link" @click="get_sprints(item.id)">{{ item.id }}</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: current === totalPages }"
        >
          <a class="page-link" @click="get_sprints(current + 1)">
            <font-awesome-icon icon="angle-right"> </font-awesome-icon>
          </a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: current === totalPages }"
        >
          <a class="page-link" @click="get_sprints(totalPages)">
            <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ContentLoader } from "vue-content-loader";
import { StatusProps } from "../types/statusTypes";
import moment from "moment";

export default {
  name: "ProjectSprints",
  components: {
    vSelect,
    moment,
    ContentLoader,
  },
  data() {
    return {
      current_delete_id: "",
      del_modal: false,
      sprint_modal: false,
      sprint_modal_mode: "create",
      sprint_modal_data: {},
      sprint_modal_error: "",
      sort_task: "",
      sort_date: "",
      status_props: StatusProps,
      status_options: ["open", "closed"],
      filter_status: [
        "new",
        "todo",
        "dev_completed",
        "on_hold",
        "in_progress",
        "completed",
        "rot",
        "pot",
        "fot",
      ],
      start_date: "",
      end_date: "",
      filter_name: "",
      filter_sprint: "", //Filter v-model for sprint... Yet to work on
      filter_by_member: "",
      filter_by_sprint: "", //Variable sent in api
      defaultSelected: [],
      projectId: this.$route.params.id,
      project: "",
      assigned_to: "",
      assignedState: null,
      task: "",
      taskState: null,
      due_date: "",
      due_dateState: null,
      submittedNames: [],
      page: 1,
      current: 1,
      totalDocs: 0,
      totalPages: 0,
      startPage: 1,
      endPage: 1,
      pages: [],
      serial_number: "",
      project_sprints: {},
      sprint_modal: false,
      teammate_modal: false,
      member_options: [],
      selected_members: [],
      teammate: [],
      sprint_options: [],
      selected_status: "",
      is_loading: false,
      is_success: false,
      filter_by_assignee: [],
      showList: false,
      showBoard: false,
      name_filter: "",
      ticket_no: "",
      scoreCard_data: [],
      isCheckedAll: false,
      checkedRow: [],
      filter_by_labels: "",
      visible_columns: ["title", "assigned_to", "created_at", "status"],
    };
  },
  created() {
    this.$eventHub.$on("search_sprint", this.get_sprints);
    this.get_sprints(1);
    this.fetchproject();
  },
  methods: {
    setActiveTab(tab) {
      localStorage.setItem("activeTab", tab);
      this.showList = tab === "list";
      this.showBoard = tab === "board";
    },
    change_board() {
      this.$router.push("/board/" + this.$route.params.id);
    },
    goto_task(id) {
      this.$router.push("/viewtask/" + id);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    del_confirmation: function (id) {
      this.del_modal = !this.del_modal;
      this.current_delete_id = id;
    },
    isFiltered(id) {
      return this.filter_by_project.includes(id) ? true : false;
    },
    openSprintModal(data, mode) {
      this.sprint_modal_data =
        mode === "create"
          ? { name: "", status: "", project_id: this.$route.params.id }
          : {
              _id: data._id,
              name: data.name,
              project_id: data.project_id._id,
              status: data.status,
            };
      this.sprint_modal_mode = mode;
      this.sprint_modal = true;
    },
    updateSprintData() {
      this.sprint_modal_error = "";
      let params = {
        name: this.sprint_modal_data.name,
        status: this.sprint_modal_data.status,
        project_id: this.sprint_modal_data.project_id,
      };
      this.$http
        .put(
          this.$store.state.api_url + "sprint/" + this.sprint_modal_data._id,
          { params }
        )
        .then(function (response) {
          this.get_sprints();
          this.sprint_modal = false;
        });
    },
    addSprintData(bvModalEvent) {
      let params = this.sprint_modal_data;
      if (params.name === "") {
        bvModalEvent.preventDefault();
        return (this.sprint_modal_error = "Please enter a valid name");
      }
      if (params.status === "") {
        bvModalEvent.preventDefault();
        return (this.sprint_modal_error = "Please select a status");
      } else {
        this.$http
          .post(this.$store.state.api_url + "addsprint/", { params })
          .then(function (response) {
            this.get_sprints();
          });
        this.sprint_modal_error = "";
        this.sprint_modal = false;
      }
    },
    isCheckedRow(id) {
      return this.isCheckedAll || this.checkedRow.includes(id) ? true : false;
    },
    onCheck(e, isAll, id) {
      e.stopPropagation();
      if (isAll) {
        if (this.isCheckedAll) {
          this.checkedRow = [];
        }
        this.isCheckedAll = !this.isCheckedAll;
        
      } else {
        let existingList = new Set(this.checkedRow);
        existingList.add(id);
        this.checkedRow = Array.from(existingList);
      }
    },
    isFiltered_assignee(id) {
      return this.filter_by_assignee.includes(id) ? true : false;
    },
    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].color;
      } else return "#000";
    },
    remove_filter(name, value) {
      switch (name) {
        case "name_filter":
          this.name_filter = "";
          this.update_local_storage_filters(name, "");
          this.get_sprints(this.page);
          break;
        case "selected_status":
          let new_status = this.selected_status.filter(
            (status) => status !== value
          );
          this.selected_status = new_status;
          this.update_local_storage_filters(name, new_status);
          this.get_sprints(this.page);
          break;
        default:
          break;
      }
      this.$eventHub.$emit("refresh_sprint");
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(localStorage.getItem("sprint_filters"));
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem("sprint_filters", JSON.stringify(existing_filters));
    },
    reset_filters() {
      const existing_filters = {
        name_filter: this.name_filter,
        start_date_filter: this.start_date_filter,
        end_date_filter: this.end_date_filter
      };

      localStorage.setItem("sprint_filters", JSON.stringify(existing_filters));
    },

    get_sprints: function () {
      this.is_loading = true;
      const filters = JSON.parse(localStorage.getItem("sprint_filters"));
      let params = {
        pageno: this.current,
        size: 100,
      };
      if(filters.name_filter !== "" && typeof filters.name_filter !== "undefined" && filters.name_filter !== null){
        params["name"] = filters.name_filter;
      }
      if(filters.start_date_filter !== "" && typeof filters.start_date_filter !== "undefined" && filters.start_date_filter !== null){
        params["start_date"] = filters.start_date_filter;
      }
      if(filters.end_date_filter !== "" && typeof filters.end_date_filter !== "undefined" && filters.end_date_filter !== null){
        params["end_date"] = filters.end_date_filter;
      }
      
      this.$http
        .get(this.$store.state.api_url + "sprints/" + this.projectId, {
          params,
        })
        .then(function (response) {
          this.is_loading = false;
          response = response.body;
          this.data = response.data.docs;
          this.serial_number = response.data.pagingCounter;
          this.current = response.data.page;
          this.totalDocs = response.data.totalDocs;
          this.totalPages = response.data.totalPages;
          this.scoreCard_data = response.scoreCard_data;
          this.project_sprints = response.data.docs;
          if (this.totalPages <= 5) {
            this.startPage = 1;
            this.endPage = this.totalPages;
          } else {
            if (this.current <= 3) {
              this.startPage = 1;
              this.endPage = 5;
            } else if (this.current + 2 >= this.totalPages) {
              this.startPage = this.totalPages - 4;
              this.endPage = this.totalPages;
            } else {
              this.startPage = this.current - 2;
              this.endPage = this.current + 2;
            }
          }
          this.pages = Array.from(
            Array(this.endPage + 1 - this.startPage).keys()
          ).map((i) => ({
            id: this.startPage + i,
            name: "Item " + (this.startPage + i),
          }));
        });
      this.is_success = true;
      this.get_local_storage_data();
    },

    add_teammate: function () {
      this.teammate = [];
      var user_name = [];
      for (var i = 0; i < this.selected_members.length; i++) {
        this.teammate.push(this.selected_members[i]._id);
        user_name[this.selected_members[i]._id] =
          this.selected_members[i].full_name;
      }
      var project_members = [];
      for (var i = 0; i < this.project.members.length; i++) {
        project_members.push(this.project.members[i]._id);
        user_name[this.project.members[i]._id] =
          this.project.members[i].full_name;
      }
      console.log(user_name);
      var removed_user = _.difference(project_members, this.teammate);
      console.log(removed_user);
      var added_user = _.difference(this.teammate, project_members);
      var added_message = "";
      var removed_message = "";
      if (added_user.length) {
        for (var i = 0; i < added_user.length; i++) {
          added_message += user_name[added_user[i]];
          if (added_user.length > 1 && added_user.length >= i) {
            added_message += ", ";
          }
        }
        added_message += " newly added in project '" + this.project.name + "'.";
      }
      if (removed_user.length) {
        for (var i = 0; i < removed_user.length; i++) {
          removed_message += user_name[removed_user[i]];
          if (removed_user.length > 1 && i < removed_user.length - 1) {
            removed_message += ", ";
          }
        }
        removed_message += " removed from project '" + this.project.name + "'.";
        console.log(removed_message);
      }
      var params = {};
      params["members"] = this.teammate;
      params["_id"] = this.project._id;
      params["type"] = true;
      params["name"] = this.project.name;
      this.$http
        .post(this.$store.state.api_url + "addproject", params)
        .then(function (data) {
          var whole = data.body;
          var data = whole.data;
          this.selected_members = data.members;
          var member_list = data.members;
          this.project.members = member_list;
          if (added_user.length) {
            for (var i = 0; i < added_user.length; i++) {
              let params = {
                user_id: added_user[i],
                project_id: data._id,
                type: "project",
                isread: "1",
                message: "You are added in " + data.name + " project.",
              };
              this.$socket.emit("new_notification", params);
            }
          }
          if (removed_user.length) {
            for (var i = 0; i < removed_user.length; i++) {
              let params = {
                user_id: removed_user[i],
                project_id: data._id,
                type: "project",
                isread: "1",
                message: "You are removed from " + data.name + " project.",
              };
              this.$socket.emit("new_notification", params);
            }
            
          }
          for (var i = 0; i < member_list.length; i++) {
            let params = {
              user_id: member_list[i],
              project_id: data._id,
              type: "project",
              isread: "1",
              message: added_message,
            };

            if (
              added_user.length &&
              added_user.indexOf(member_list[i] == "-1")
            ) {
              this.$socket.emit("new_notification", params);
            }
            params.message = removed_message;
            if (
              removed_user.length &&
              removed_user.indexOf(member_list[i] == "-1")
            ) {
              this.$socket.emit("new_notification", params);
            }
          }
        });
    },
    add_sprint: function () {
      var params = {};
      params["sprint"] = this.project.sprint;
      params["_id"] = this.project._id;
      params["type"] = true;
      params["name"] = this.project.name;
      this.$http
        .post(this.$store.state.api_url + "addproject", params)
        .then(function (res) {});
    },
    select_teammate: function () {
      this.teammate_modal = !this.teammate_modal;
    },
    makeToast(variant = null) {
      this.$root.$bvToast.toast(`New employee added`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    fetchproject() {
      let user_params = this.$store.state.user_detail._id;
      var itemId = this.$route.params.id;
      this.$http
        .post(this.$store.state.api_url + "project/" + itemId, { user_params })
        .then(async (response) => {
          response = response.body.data;
          this.project = response;
          console.log(this.project);
          this.$store.commit("set_project_detail", this.project);
          this.member_options = this.project.members;
          var obj = await this.project.members.find(
            (o) => o.id === this.$store.state.user_detail._id
          );
          if (
            this.$store.state.user_detail.user_role != "admin" &&
            this.$store.state.user_detail.user_role != "super_admin" &&
            this.$store.state.user_detail._id !=
              this.$store.state.project.manager &&
            obj == undefined
          ) {
            this.$router.push({ name: "Home" });
          }
        });
    },
    get_local_storage_data() {
      let existing_filters = localStorage.getItem("sprint_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          name_filter: this.name_filter,
          start_date_filter: this.start_date_filter,
          end_date_filter: this.end_date_filter
        };
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.name_filter = existing_filters.name_filter || "";
        this.start_date_filter = existing_filters.start_date_filter || "";
        this.end_date_filter = existing_filters.end_date_filter || "";
        existing_filters = {
          ...existing_filters,
          name_filter: this.name_filter,
          start_date_filter: this.start_date_filter,
          end_date_filter: this.end_date_filter
        };
      }

      localStorage.setItem("sprint_filters", JSON.stringify(existing_filters));
    },
  },
  mounted() {
    this.get_sprints(this.page);
    this.get_local_storage_data();
    this.$store.commit("change_page", "sprints");
    this.$socket.emit("read_project_notifications", {
      project_id: this.$route.params.id,
      type: "project",
    }); //Yet to complete
    this.$socket.emit("read_project_notifications", {
      project_id: this.$route.params.id,
      user_id: this.$store.state.user_detail._id,
      type: "project",
    });
  },
  watch: {
    name_filter(newValue, oldValue) {
      this.name_filter = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
  },
};
</script>

<style>
.table_header {
  font-weight: bold;
}

.page-item.active .page-link {
  background-color: rgb(54, 88, 143) !important;
  color: #ffffff;
}

.page-item {
  cursor: pointer;
}

.sort_btn {
  width: 30px;
  border: solid 1px #ffffff;
  background-color: transparent;
  border-radius: 4px;
  color: rgb(114, 112, 112);
}

.filt svg {
  right: -12px;
  position: relative;
  top: 1px;
}

.filt .dropdown-menu {
  min-width: 9rem;
  font-size: 14px;
}

.filt .dropdown-item {
  padding: 0.5em 1em;
}

.btn-dd {
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
  border-radius: 4px;
}

.btn-dd:focus {
  outline: none !important;
}

.btn-dd:hover {
  background-color: transparent !important;
  border-color: transparent;
}

.btn-dd:active {
  background-color: transparent !important;
  border-color: transparent;
}

.tasklink {
  color: rgb(0, 0, 0);
  margin-left: 0px;
  margin-right: 0px;
}

.tasklink:hover {
  color: black;
  text-decoration: none;
}

.edit_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: rgb(114, 112, 112);
}

.del_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  color: rgb(114, 112, 112);
}

.view_btn {
  width: 60px;
  border: solid 1px rgb(179, 177, 177);
  background-color: transparent;
  border-radius: 4px;
  color: rgb(114, 112, 112);
}

.toolbar {
  display: flex;
  border-bottom: 1px solid gainsboro;
}

.scorecard-container.row {
  margin-left: 8px !important;
}
.scorecard {
  display: flex;
  cursor: pointer;
  color: white !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  font-size: 24px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #ebebeb;
}
.score_count {
  font-size: 20px;
}
.dev_completed {
  background-color: #a3ff0e !important;
}

.in_progress {
  background-color: #ff980e !important;
}
.new {
  background-color: #007bff !important;
}
.todo {
  background-color: #00d5d9 !important;
}

.rot {
  background-color: #612f12 !important;
}

.pot {
  background-color: #018a01 !important;
}

.completed {
  background-color: #6606bd !important;
}
.fot {
  background-color: #f13636 !important;
}
.released {
  background-color: #ff01f2 !important;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.filtered_items {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.filter_chip {
  display: flex;
  background-color: #e6e6e6;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.close-filter,
.clear-filter {
  cursor: pointer;
}
.left_children {
  margin-right: auto;
  display: flex;
  font-size: 14px;
}

.right_children {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-left: auto !important;
}

#buton:hover {
  background-color: #dae0e5 !important;
}

.sort_filter {
  display: flex;
}

.tasklist {
  margin: 20px 10px;
  display: block;
  width: fit-content;
  overflow-y: auto;
}

.table-body:hover {
  cursor: pointer;
}

.router_links {
  color: #484848;
}

.router_links:hover {
  color: #484848;
  text-decoration: none;
}

.project {
  display: flex;
  padding-top: 20px;
}

#pjt:focus {
  outline: none;
}

#pjt {
  color: white;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

@media (orientation: portrait) {
  .toolbar {
    margin-top: 20px;
  }
}

@media (min-width: 700px) {
  .tasklink {
    margin-left: 1rem;
  }

  .addtask {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .add_teammate {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media (max-width: 700px) {
  #buton {
    margin-bottom: 3px;
  }

  .addtask {
    border-radius: 20px;
  }

  .dd_select {
    border-radius: 20px;
  }

  .add_teammate {
    border-radius: 20px;
  }

  .tasklink {
    margin-left: 1.7rem;
  }

  #right {
    display: inline-flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .filt {
    display: inline-flex;
  }

  .filt svg {
    right: -7px;
    position: relative;
    top: 8px;
  }

  .sorter svg {
    right: 2px;
    position: relative;
    top: 4px;
  }

  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td:not(.mf-footer),
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
    height: auto;
  }
  #no-more-tables td:not(.mf-footer) {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 8px;
    left: 5%;
    width: 45%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    /* font-family: 'Poppins-Regular'; */
  }
  .data_table_design tfoot tr {
    display: flex;
    justify-content: center;
  }
  .data_table_design tfoot tr {
    display: block;
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

@media (min-width: 700px) {
  .table.sprint_table_design {
    width: 100% !important;
    overflow-x: auto;
  }

  .project_sprint,
  .project_labels {
    width: 80px;
    max-width: 200px;
  }

  .project_sprints_name {
    width: 80px !important;
  }

  .project_updated_at {
    max-width: 100px !important;
  }
}
.btn-secondary {
  outline: none !important;
  box-shadow: none !important;
}
input.vs__search {
  margin-top: 12px;
}
</style>
