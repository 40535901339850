export const sprintStatusProps = {
    open: {
        key: "open",
        short_name: "Open",
    },
    in_progress: {
        key: "in_progress",
        short_name: "In Progress",
    },
    completed: {
        key: "completed",
        short_name: "Completed",
    },
    active: {
        key: "active",
        short_name: "Active",
    },
    in_progress: {
        key: "in_active",
        short_name: "In Active",
    },
    ["Open"]: {
        name: "Open",
        color: "#007bff"
    },
    ["In Progress"]: {
        name: "In Progress",
        color: "#ff980e"
    },
    ["Completed"]: {
        name: "Completed",
        color: "#ff98ee"
    },
    ["Active"]: {
        name: "Active",
        color: "#af7bff"
    },
    ["In Active"]: {
        name: "In Active",
        color: "#ffe80e"
    }
};
