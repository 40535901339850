<template>
  <div class="newform col-12 p-0">
    <div class="newsub col-12 p-0">
      <div class="col-12 row mx-0">
        <div class="col-lg-8">
          <div class="input-group col-12 mb-4">
            <label for="projectname" class="grey-text col-12 p-0 text-left fa-15x">Project Name</label>
            <div class="col-12 p-0">
                <input type="text" v-model="project.name" placeholder="Project Name" id="projectname"  class="form-control px-3 py-3 h-auto rounded m-0"/>
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="description" class="grey-text col-12 p-0 text-left fa-15x">Project Description</label>
            <div class="col-12 p-0">
                <!-- <input type="text" v-model="project.description" placeholder="A brief description" id="description" class="form-control"/> -->
                <quill-editor class="textarea_design position-relative overflow-hidden pb-1" v-model="project.description" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)">
                    <div id="toolbar" slot="toolbar">
                        <button class="ql-bold border rounded mr-1">Bold</button>
                        <button class="ql-italic border rounded">Italic</button>
                    </div>
                </quill-editor>
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="projectmanager" class="grey-text col-12 p-0 text-left fa-15x">Project Manager</label>
            <div class="col-12 p-0 mb-2">
              <v-select class="v-select-design" :searchable="true" :multiple="false" v-model="project.project_manager" :options="options" placeholder="Select Project Manager" label="full_name" >
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="input-group col-12 mb-4">
            <label for="doj" class="grey-text col-12 p-0 text-left fa-15x">Project Start Date</label>
            <div class="col-12 p-0">
              <Datepicker class="datepicker_design" :bootstrap-styling="true" id="dob" placeholder="DD MM YYYY"  v-model="project.start_date" />
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="clientname" class="grey-text col-12 p-0 text-left fa-15x">Client Name</label>
            <div class="col-12 p-0">
              <input type="text" v-model="project.client_name" placeholder="Full name" id="clientname" class="form-control px-3 py-3 h-auto rounded m-0"/>
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="clientcountry" class="grey-text col-12 p-0 text-left fa-15x">Client Country</label>
            <div class="col-12 p-0">
              <input type="text" v-model="project.client_country" placeholder="Country" id="clientcountry" class="form-control px-3 py-3 h-auto rounded m-0"/>
            </div>
          </div>
          <div class="input-group col-12 mb-4">
            <label for="clientmobile" class="grey-text col-12 p-0 text-left fa-15x">Client Mobile No.</label>
            <div class="col-12 p-0">
              <input type="text" v-model="project.client_mobile" placeholder="Mobile Number" id="clientmobile" class="form-control px-3 py-3 h-auto rounded m-0"/>
            </div>
          </div>
        </div>
        <div class="text-center my-4 col-12 pt-4 z-depth-top">
          <button class="primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded-pill text-white bold_font" type="submit" v-bind:class="{ disabled: is_btn_loading }"  @click="newproject">Add Project
            <span class="ml-2" v-if="is_btn_loading">
              <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
            </span>
          </button>
          <button class="btn border rounded-pill py-2 px-4 ml-3" v-if="edit_page" type="button" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

export default {
    name: 'AddProject',
    components: {
        Datepicker,
        vSelect
    },
    data () {
        return {
          state: {
              disabledDates: {
                  to: new Date( ), // Disable all dates up to specific date
                  // from: new Date(2020, 18, 2), // Disable all dates after specific date
                  // days: [6, 0], // Disable Saturday's and Sunday's
                  // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
              }
          },
          project: {},
          edit_page: false,
          is_btn_loading: false,
          options: [],
          editorOption: {
          modules: {
            toolbar: '#toolbar'
              }
          }
        }
    },
    methods: {
        onEditorBlur(editor) {
            
        },
        onEditorFocus(editor) {
            
        },
        onEditorReady(editor) {
            
        },
        makeToast(variant=null) {
            this.$root.$bvToast.toast(`Project updated`, {
                title: 'Success',
                variant: variant,
                autoHideDelay: 3000,
            })
        },
        newproject:function() {
          var err = ''
          if(this.project.name == '' || typeof this.project.name == "undefined" ){
              err = 'Please fill title'
          }else if (this.project.description == '' || typeof this.project.description == "undefined" ){
              err = 'Please fill project description'
          }else if (this.project.project_manager == '' || typeof this.project.project_manager == "undefined" ){
              err = 'Please select a project manager'
          }
          else if (this.project.start_date == '' || typeof this.project.start_date == "undefined" ){
              err = 'Please select start date of project'
          }
          else if (this.project.client_name == '' || typeof this.project.client_name == "undefined" ){
              err = 'Please fill name of client'
          }
          else if (this.project.client_country == '' || typeof this.project.client_country == "undefined" ){
              err = 'Please fill country of client '
          }
          else if (this.project.client_mobile == '' || typeof this.project.client_mobile == "undefined" ){
              err = 'Please fill mobile number of client'
          }
          if(err == ''){
            this.is_btn_loading = true;
            this.project.manager = this.project.project_manager._id
            this.project.type=this.edit_page
            if(!this.edit_page) {
              this.project.members = this.project.project_manager._id
            }
            this.$http.post(this.$store.state.api_url+'addproject',this.project).then(function(data){
                this.is_btn_loading = false;
                var response = data.body;
                if(response.status === true){
                    if(!this.edit_page){
                        var details = response.data
                        // this.$socket.emit('new_notification',{user_id: details.project_manager._id, message: 'You are added as Project Manager for ' + details.name})
                        this.makeToast('success')
                        this.$router.push({name: 'Home'})
                    }else{
                        // this.makeToast('success')
                        this.$root.$bvToast.toast(`Project details updated`, {
                            title: 'Success',
                            autoHideDelay: 5000,
                            variant: 'success'
                        })
                        this.$router.push({name: 'Home'})
                    }
                }else{
                    this.$bvToast.toast(`Project exists already`, {
                        title: 'Error',
                        autoHideDelay: 3000,
                        variant: 'danger'
                    })
                }
          })
          }else{
            this.$bvToast.toast(err, {
                  title: 'Error',
                  autoHideDelay: 3000,
                  variant: 'danger'
              })
          }
        },
        cancel:function(){
            this.$router.push({name: 'Home'})
        },
        get_userlist:function(){
            this.$http.post(this.$store.state.api_url+'allprojectusers',{search: ''})
            .then(function(response){
                response = response.body;
                response = response.data
                let data = response
                this.options = data;
            })
        },
    },
    mounted() {
      this.get_userlist();
      this.$store.commit("change_page", "Managing Projects")
      if(this.$store.state.user_detail.user_role == 'admin' || this.$store.state.user_detail.user_role == 'super_admin' || this.$store.state.user_detail._id == this.$store.state.project.manager){}
      else {
        this.$router.push({name:'Home'})
      }
    },
    created(){
        if(typeof this.$route.params._id!="undefined")
        {
            this.edit_page = true
            this.project=this.$route.params
        }
    }
}
</script>

<style>

.ql-toolbar.ql-snow{
  padding: 0.375rem 0.75rem !important;
}

.ql-container{
  margin-bottom: 8px !important;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow{
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

/* .cancel{
    background-color: rgb(248, 151, 151) !important;
}

.cancel:hover{
    background-color: rgb(243, 113, 113) !important;
} */

h2{
  margin-bottom: 20px;
  text-align: left;
}

.newform{
  height: 100vh;
}
input{
  font-size: 16px;
  border-radius: 3px;
  display: block;
  margin-bottom: 8px ;
  border: 2px;
  border-style: solid;
  border-color: #e0e6e8;
}

.vs__dropdown-menu{
  max-height: 200px !important;
}

.vs__dropdown-toggle{
  padding: 0px !important;
}

.vs__search{
  font-size: 1rem !important;
  color: #495057
}

.vs__search:focus{
  color: #495057
}

.vs__selected-options{
  font-size: 1rem !important;
}

.vs__selected{
      margin: 2px !important;
}

.vs__actions{
  padding: 2px 6px 0 3px;
}

.newsub{
  height: 100vh;
  margin: 20px 0px;
  float: right;
}

.vs__clear{
  margin-bottom: 4px !important;
}

</style>