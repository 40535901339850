<template>
  <div class="task_view col-12 p-0 px-md-3">
    <div class="row col-12 p-0 px-md-3 mx-0">
      <div class="col-12 row mx-0 align-items-center mb-0">
        <ContentLoader class="w-100" height="60" v-if="!this.is_task_success">
          <rect x="0" y="20" rx="0" ry="0" width="50%" height="10" />
          <rect x="0" y="40" rx="0" ry="0" width="25%" height="10" />
          <rect x="80%" y="30" rx="0" ry="0" width="20%" height="10" />
        </ContentLoader>
        <b-breadcrumb
          class="bg-transparent font-8 p-0 mb-0 mt-3"
          :items="items"
        ></b-breadcrumb>
        <span
          class="row m-0 w-100 align-items-center"
          v-if="this.is_task_success"
        >
          <h2 class="mx-0 my-3 col-10 p-0">
            <span class="h6"
              >{{ task.ticket_number || "-" }}
              <router-link
                class="grey-text d-inline-flex text-decoration-none py-0 px-2 fa-sm-custom"
                v-bind:to="'/task/' + task._id"
                v-b-tooltip.hover
                title="Edit"
                v-if="
                  this.$store.state.user_detail.user_role == 'admin' ||
                  this.$store.state.user_detail.user_role == 'super_admin' ||
                  this.$store.state.user_detail._id ==
                    this.task.project_id.manager
                "
              >
                <font-awesome-icon
                  icon="edit"
                  size="1x"
                  class="font-italic grey-text"
                ></font-awesome-icon>
              </router-link>
              <div
                @click="del_confirmation(task._id)"
                v-b-tooltip.hover
                title="Delete"
                class="grey-text d-inline-flex text-decoration-none py-0 px-2 fa-sm-custom"
                v-if="
                  this.$store.state.user_detail.user_role == 'admin' ||
                  this.$store.state.user_detail.user_role == 'super_admin' ||
                  this.$store.state.user_detail._id ==
                    this.task.project_id.manager
                "
              >
                <font-awesome-icon
                  icon="trash"
                  size="1x"
                  class="font-italic grey-text"
                ></font-awesome-icon>
              </div>
            </span>
            <br />{{ task.title }}
          </h2>
          <span
            class="status_bg ml-auto text-white rounded fa-sm-custom semi_bold_font"
            :style="{ background: get_color(task.status.toLowerCase()) }"
            >{{ get_status_name(task.status.toLowerCase()) }}</span
          >
        </span>
        <b-modal
          hide-header-close
          v-model="del_modal"
          @ok="del_task()"
          centered
          title="Delete Task"
        >
          <p class="my-4">Are you sure to delete this task?</p>
        </b-modal>
      </div>
      <div class="col-lg-8 col-md-8 col-xs-12 mx-0 mb-3 detail">
        <ContentLoader
          viewBox="0 0 778 600"
          width="778"
          height="600"
          v-if="!this.is_task_success"
        >
          <rect x="0" y="29" rx="0" ry="0" width="30%" height="25" />
          <rect x="0" y="75" rx="0" ry="0" width="80%" height="25" />
          <rect x="0" y="200" rx="0" ry="0" width="30%" height="25" />
          <rect x="0" y="245" rx="0" ry="0" width="80%" height="25" />
          <rect x="0" y="380" rx="0" ry="0" width="30%" height="25" />
          <rect x="0" y="425" rx="0" ry="0" width="80%" height="25" />
        </ContentLoader>
        <div class="task_info" v-if="this.is_task_success">
          <div class="text-left mb-3">
            <div class="task_desc">
              <p class="grey-text font-8 semi_bold_font mb-2">Description</p>
              <perfect-scrollbar
                ><p class="grey-text h6 mh-180">
                  <span v-html="task.description"></span></p
              ></perfect-scrollbar>
            </div>
          </div>
        </div>
        <div class="chatbox">
          <b-tabs content-class="mt-3">
            <b-tab active>
              <template v-slot:title>
                <span class="font-8 semi_bold_font">Comments</span>
              </template>
              <ContentLoader
                class="w-100 py-3"
                viewBox="0 0 446 250"
                height="250"
                v-if="!is_success"
              >
                <circle cx="19" cy="25" r="16" />
                <rect x="39" y="12" rx="5" ry="5" width="45%" height="10" />
                <rect x="40" y="29" rx="5" ry="5" width="45%" height="10" />
                <circle cx="380" cy="71" r="16" />
                <rect x="179" y="76" rx="5" ry="5" width="45%" height="10" />
                <rect x="179" y="58" rx="5" ry="5" width="45%" height="10" />
                <circle cx="21" cy="117" r="16" />
                <rect x="45" y="104" rx="5" ry="5" width="45%" height="10" />
                <rect x="45" y="122" rx="5" ry="5" width="45%" height="10" />
                <circle cx="380" cy="161" r="16" />
                <rect x="179" y="168" rx="5" ry="5" width="45%" height="10" />
                <rect x="179" y="148" rx="5" ry="5" width="45%" height="10" />
                <circle cx="23" cy="215" r="16" />
                <rect x="51" y="220" rx="5" ry="5" width="45%" height="10" />
                <rect x="51" y="200" rx="5" ry="5" width="45%" height="10" />
              </ContentLoader>
              <perfect-scrollbar ref="ps">
                <div
                  class="chat_messages col-12 p-0 bg-white"
                  v-if="is_success"
                  id="container"
                >
                  <ul
                    class="px-0 pb-5 list-type-none"
                    v-if="messages.length > 0"
                  >
                    <li class="" v-for="message in messages" :key="message._id">
                      <!-- <span class="sticky-top bg-white py-1 px-4 mx-auto align-items-center justify-content-center d-flex" v-if="message.date != ''"><span class="bg-white fa-sm-custom grey-text py-1 px-4 rounded-pill z-depth-1">{{message.date}}</span></span> -->
                      <span v-for="msg in message.msg" :key="msg._id">
                        <div
                          class="comment_line pb-3"
                          v-if="msg.sent_by === signed_in_user._id"
                        >
                          <img
                            :src="msg.sent_by_info.profile_photo"
                            class="img-small position-absolute l-0 ml-2 t-0 rounded-circle"
                          />
                          <span class="pl-5 d-block">
                            <p
                              class="m-0 comment_name semi_bold_font text-black"
                            >
                              {{
                                msg.sent_by_info !== null
                                  ? msg.sent_by_info.full_name
                                  : ""
                              }}
                            </p>
                            <div
                              v-if="!msg.image_name"
                              class="comment_para mb-1 rounded"
                              v-html="msg.comment"
                            ></div>
                            <div
                              v-if="msg.image_name"
                              class="comment_para mb-1 text-white rounded"
                            >
                              <img
                                :src="msg.image_url"
                                class="images"
                                @click="showmodal(msg.image_url)"
                              />
                            </div>
                            <p
                              class="time_display fa-sm-custom mb-0"
                              v-if="is_today"
                            >
                              {{ msg.show_comment_time }}
                            </p>
                            <p
                              class="time_display fa-sm-custom mb-0"
                              v-if="!is_today"
                            >
                              {{ custom_msg_date(msg.msg_date) }}
                            </p>
                          </span>
                        </div>
                        <div
                          class="text-left comment_line pb-3"
                          v-if="msg.sent_by != signed_in_user._id"
                        >
                          <img
                            :src="msg.sent_by_info.profile_photo"
                            class="img-small position-absolute l-0 ml-2 t-0 rounded-circle"
                          />
                          <span class="pl-5 d-block">
                            <p
                              class="m-0 comment_name semi_bold_font text-black"
                            >
                              {{
                                msg.sent_by_info !== null
                                  ? msg.sent_by_info.full_name
                                  : ""
                              }}
                            </p>
                            <div
                              v-if="!msg.image_name"
                              class="comment_para mb-1 rounded"
                              v-html="msg.comment"
                            ></div>
                            <div
                              v-if="msg.image_name"
                              class="comment_para mb-1 rounded"
                            >
                              <img
                                :src="msg.image_url"
                                class="images"
                                @click="showmodal(msg.image_url)"
                              />
                            </div>
                            <p
                              class="time_display fa-sm-custom mb-0"
                              v-if="is_today"
                            >
                              {{ msg.show_comment_time }}
                            </p>
                            <p
                              class="time_display fa-sm-custom mb-0"
                              v-if="!is_today"
                            >
                              {{ custom_msg_date(msg.msg_date) }}
                            </p>
                          </span>
                        </div>
                      </span>
                      <b-modal
                        size="xl"
                        id="modal-1"
                        class="modal_div"
                        v-model="modalShow"
                      >
                        <img :src="display_image" class="pop_up_image" />
                      </b-modal>
                    </li>
                  </ul>
                  <div
                    class="col-12 p-3 text-center row m-0 align-items-center justify-content-center"
                    v-if="messages.length == 0"
                  >
                    <!-- <font-awesome-icon icon="comment-dots" class="grey-text fa-3x mb-3" size="lg"></font-awesome-icon> -->
                    <h6 class="bold_font w-100">No comments</h6>
                    <!-- <p class="grey-text">Start conversing to see your comments here</p> -->
                  </div>
                </div>
              </perfect-scrollbar>
              <div class="p-0 my-3 col-12 w-100">
                <quill-editor
                  class="textarea_design chat bg-white position-absolute r-0 l-0 b-0 w-100 overflow-hidden pb-1"
                  v-model="comment"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                >
                  <div id="toolbar" slot="toolbar">
                    <button class="ql-bold border rounded mr-1">Bold</button>
                    <button class="ql-italic border rounded mr-1">
                      Italic
                    </button>
                    <button
                      class="border rounded outline-none"
                      onclick="document.getElementById('fileInput').click();"
                    >
                      <font-awesome-icon
                        icon="paperclip"
                        class="text-black"
                        size="1x"
                      ></font-awesome-icon>
                    </button>
                    <input
                      id="fileInput"
                      style="display: none"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      ref="files"
                      @change="uploadimage"
                    />
                  </div>
                </quill-editor>
                <button
                  class="p-2 w-auto semi_bold_font primary-bg text-white text-center btn position-absolute b-0 r-0 z-index-2"
                  @click="sendComment"
                >
                  <!-- <font-awesome-icon icon="paper-plane" class="text-white" size="sm"></font-awesome-icon> -->
                  Comment
                </button>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="font-8 semi_bold_font">History</span>
              </template>
              <div class="col-12 row m-0 p-0">
                <perfect-scrollbar class="w-100">
                  <div class="mh-180 col-12 p-0" v-if="task_history.length > 0">
                    <div
                      class=""
                      v-for="history in task_history"
                      :key="history._id"
                    >
                      <p class="mb-3 h6">
                        {{ history.message }}
                        <span class="font-8 ml-2">{{
                          custom_msg_date(history.updated_at)
                        }}</span>
                      </p>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-lg-4 ml-auto col-md-4 mx-0 mb-3 col-xs-12 chat_page">
        <div v-if="this.task.status != 'Completed'" class="text-left mb-4">
          <div
            v-if="
              this.$store.state.user_detail.user_role == 'admin' ||
              this.$store.state.user_detail.user_role == 'super_admin' ||
              this.$store.state.user_detail._id ==
                this.task.project_id.manager ||
              this.$store.state.user_detail._id == this.task.assigned_to
            "
          >
            <div class="input-group mt-1 mb-4">
              <label for="status" class="h6 semi_bold_font mb-2">Status</label>
              <div class="col-12 p-0">
                <select
                  class="form-control"
                  v-model="task.status"
                  @change="change_status($event)"
                >
                  <option
                    v-for="option in status"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="input-group mt-1 mb-4">
              <label for="actual_hours" class="h6 semi_bold_font mb-2">Actual Hours</label>
              <div class="col-12 p-0">
                    <input
                    type="number"
                    placeholder="HH.MM"
                    id="actual_hours"
                    v-model="task.actual_hours"
                    @focus="save_prev_actual_hours_value(task.actual_hours)"
                    @blur="change_actual_hours($event)"
                    class="form-control px-3 py-1.1 h-auto rounded m-0"
                  />
              </div>
            </div>
          </div>
        </div>
        <div class="text-left mb-4">
          <p class="font-8 semi_bold_font mb-1">Assignee</p>
          <p class="grey-text row m-0 align-items-center">
            <img
              :src="
                task.assigned_to_detail
                  ? task.assigned_to_detail.profile_photo
                  : ''
              "
              class="img-too-small rounded-circle mr-1"
            />
            <span>
              <!-- {{
              task.assigned_to_detail !== null
                ? task.assigned_to_detail.full_name
                : ""
            }} -->
            </span>
          </p>
        </div>
        <div class="text-left mb-4">
          <p class="font-8 semi_bold_font mb-1">Due Date</p>
          <p class="grey-text">{{ format_date(task.due_date) }}</p>
        </div>
        <div class="text-left mb-4">
          <p class="font-8 semi_bold_font mb-1">Current Status</p>
          <p :style="{ color: get_color(task.status.toLowerCase()) }">
            {{ get_status_name(task.status.toLowerCase()) }}
          </p>
        </div>
        <div v-if="task_images.length" class="task_images text-left mb-4">
          <p class="font-8 semi_bold_font mb-2">Reference Images</p>
          <span class="row m-0">
            <label
              class="col-3 p-0 mr-2"
              v-for="(task_image, index) in task_images"
              :key="task_image._id"
            >
              <img
                :src="task_image.image_url"
                class="images pointer rounded p-0 w-100 h-100x o-cover"
                @click="openGallery(index)"
              />
            </label>
            <!-- <ul>
                            <li v-for="(image, index) in media" :key="index" style="display: inline-block" >
                                <img v-lazy="image.src || image.thumb" style="height: 100px" @click="openGallery(index)">
                            </li>
                        </ul> -->
            <LightBox
              ref="lightbox"
              :showCaption="true"
              :showLightBox="false"
              :media="media"
            ></LightBox>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import moment from "moment-timezone";
import { ContentLoader } from "vue-content-loader";
import Compress from "compress.js";
import LightBox from "vue-image-lightbox";
import { StatusProps } from "../types/statusTypes";

export default {
  name: "TaskView",
  components: {
    moment,
    PerfectScrollbar,
    ContentLoader,
    Compress,
    LightBox,
  },
  data() {
    return {
      items: [],
      signed_in_user: "",
      comment: "",
      room_id: "",
      got_comments: "",
      del_modal: false,
      status_props: StatusProps,
      status: [
        { text: StatusProps["new"].name, value: "new" },
        { text: StatusProps["todo"].name, value: "todo" },
        { text: StatusProps["on_hold"].name, value: "on_hold" },
        { text: StatusProps["in_progress"].name, value: "in_progress" },
        { text: StatusProps["dev_completed"].name, value: "dev_completed" },
        { text: StatusProps["rot"].name, value: "rot" },
        { text: StatusProps["pot"].name, value: "pot" },
        { text: StatusProps["fot"].name, value: "fot" },
        { text: StatusProps["completed"].name, value: "completed" },
        { text: StatusProps["released"].name, value: "released" },
        { text: StatusProps["closed"].name, value: "closed" },
      ],
      messages: [],
      editorOption: {
        placeholder: "Post your comment here...",
        modules: {
          toolbar: "#toolbar",
        },
      },
      task: {},
      task_images: "",
      modalShow: false,
      is_success: false,
      is_task_success: false,
      media: [],
      display_image: "",
      current_date: "",
      is_today: false,
      task_history: [],
    };
  },
  methods: {
    openGallery(index) {
      this.topFunction();
      this.$refs.lightbox.showImage(index);
    },
    comment_gallery: function () {
      this.$refs.commentbox.showImage();
    },
    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].color;
      } else return "#000";
    },
    uploadimage: function (evt) {
      let selected = evt.target.files;
      let accepted_files = [];
      let declined_files = [];
      for (var i = 0; i < selected.length; i++) {
        let file = selected[i];
        if (
          file.name.split(".").pop() == "jpeg" ||
          file.name.split(".").pop() == "jpg" ||
          file.name.split(".").pop() == "JPG" ||
          file.name.split(".").pop() == "png"
        ) {
          accepted_files.push(file);
        } else {
          declined_files.push(file);
        }
      }
      const compress = new Compress();
      var files = accepted_files;
      compress
        .compress(files, {
          size: 5, // the max size in MB, defaults to 2MB
          quality: 0.8, // the quality of the image, max is 1,
          maxWidth: 720, // the max width of the output image, defaults to 1920px
          maxHeight: 640, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          let compressed_img = data;
          var img = [];
          for (var i = 0; i < compressed_img.length; i++) {
            let file = compressed_img[i];
            var image = {};
            image.name = compressed_img[i].alt;
            image.b64code = compressed_img[i].data;
            img.push(image);
          }
          var user_id = this.signed_in_user;
          var room_id = localStorage.getItem("showing_task_data_id");
          var params = {
            sent_by: user_id["_id"],
            room_id: room_id,
            comment_img: img,
            project_id: this.task.project_id._id,
          };
          let response = this.$socket.emit("comment_img", {
            params: params,
            allmsg: this.messages,
          });
          this.scrollToEnd();
        });
    },
    del_confirmation: function (id) {
      this.del_modal = !this.del_modal;
      this.current_delete_id = id;
    },
    del_task: function () {
      this.$http
        .post(this.$store.state.api_url + "delete_task", {
          _id: this.current_delete_id,
        })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.get_tasks(this.page);
          }
        });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    custom_msg_date(value) {
      if (value == this.current_date) {
        this.is_today = true;
      } else {
        return moment(String(value)).format("L");
      }
    },
    showmodal: function (url) {
      this.modalShow = !this.modalShow;
      this.display_image = url;
    },
    makeToast(variant = null) {
      this.$bvToast.toast(`Task status updated`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    makeToast_ActualHours(variant = null) {
      this.$bvToast.toast(`Task actual hours updated`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    change_status(event) {
      this.task.status = event.target.value;
      // let params =''
      // params.status = status
      // params._id = localStorage.getItem("showing_task_data_id")
      // params.manager = this.task.project_id.manager
      // console.log(params)
      let response = this.$socket.emit("change_status", this.task);
      // this.$http.post(this.$store.state.api_url+'change-status', this.task)
      // .then(function(response){
      this.makeToast("success");
      // this.gettask();
      // })
    },
    save_prev_actual_hours_value(cur_act_hours) {
      if(cur_act_hours != "") {
        localStorage.setItem("Current_Actual_Hours", cur_act_hours);
      }
    },
    change_actual_hours(event) {
      this.task.actual_hours = event.target.value;
      if(event.target.value < 0) {
        this.task.actual_hours = localStorage.getItem("Current_Actual_Hours");
      }
      if(event.target.value != "" && event.target.value != localStorage.getItem("Current_Actual_Hours") && event.target.value >= 0) {
        // let params =''
      // params.status = status
      // params._id = localStorage.getItem("showing_task_data_id")
      // params.manager = this.task.project_id.manager
      // console.log(params)
      let response = this.$socket.emit("change_actual_hours", this.task);
      // this.$http.post(this.$store.state.api_url+'change-status', this.task)
      // .then(function(response){
      this.makeToast_ActualHours("success");
      // this.gettask();
      // }) 
      }
      if(event.target.value == "") {
        this.task.actual_hours = localStorage.getItem("Current_Actual_Hours");
      }
    },
    gettask() {
      if(this.$route.params.id != localStorage.getItem("showing_task_data_ticket_id")) {
        this.$router.push({ path: '/taskproxy/' + this.$route.params.id });
      }

      var itemId = localStorage.getItem("showing_task_data_id");
      this.$http
        .get(this.$store.state.api_url + "task/" + itemId)
        .then(async (response) => {
          this.is_task_success = true;
          response = response.body.data;
          this.task = response;
          this.items = [
            { text: "Home", to: "/" },
            {
              text: this.response.name,
              to: "/project/list/" + this.task.project_id._id,
            },
            { text: this.task.title, to: "" },
          ];
          this.$store.commit("set_project_detail", this.task.project_id);
          this.task_images = response.task_images;
          this.media.splice(0, this.media.length);
          for (var i = 0; i < this.task_images.length; i++) {
            let img = {};
            img.thumb = this.task_images[i].image_url;
            img.src = this.task_images[i].image_url;
            img.caption = this.task_images[i].image_name;
            this.media.push(img);
          }
          var obj = await this.task.project_id.members.find(
            (o) => o.id === this.$store.state.user_detail._id
          );
          if (
            this.$store.state.user_detail.user_role != "admin" &&
            this.$store.state.user_detail.user_role != "super_admin" &&
            this.$store.state.user_detail._id != this.task.project_id.manager &&
            obj == undefined
          ) {
            this.$router.push({ name: "Home" });
          }
        });
      this.$http
        .get(this.$store.state.api_url + "get_task_history/" + itemId)
        .then(async (response) => {
          this.task_history = response.body.data;
        });
    },
    onEditorBlur(editor) {},
    onEditorFocus(editor) {},
    onEditorReady(editor) {},
    sendComment() {
      var user_id = this.signed_in_user;
      var room_id = localStorage.getItem("showing_task_data_id");
      var find = "<p><br></p>";
      var re = new RegExp(find, "g");
      this.comment = this.comment.replace(re, "");
      if (this.comment.trim() != "") {
        var params = {
          sent_by: user_id["_id"],
          room_id: room_id,
          comment: this.comment,
          project_id: this.task.project_id._id,
        };
        let response = this.$socket.emit("comment", {
          params: params,
          allmsg: this.messages,
        });
        this.comment = "";
        this.scrollToEnd();
      } else {
        this.$bvToast.toast(`Please enter comment`, {
          title: "Success",
          variant: "warning",
          autoHideDelay: 3000,
        });
      }
    },
    scrollToEnd: function () {
      setTimeout(() => {
        var objDiv = document.getElementById("container");
        const ps = this.$refs.ps;
        ps.$el.scrollTop = objDiv.scrollHeight;
        ps.update();
      }, 100);
    },
    topFunction: function () {
      var objDiv = document.getElementById("extra_margin");
      objDiv.scrollTop = 0;
      // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    this.$socket.emit("room_for_user", localStorage.getItem("showing_task_data_id"));
    this.scrollToEnd();
    this.$socket.emit("all_comments", {
      session_id: this.$socket.id,
      task_id: localStorage.getItem("showing_task_data_id"),
    });
  },
  mounted() {
    this.gettask();
    this.current_date = moment().format("ll");
    this.$store.commit("change_page", "project");
    this.$socket.emit("room_for_user", localStorage.getItem("showing_task_data_id"));
  },
  sockets: {
    connect: function () {
      this.$socket.emit("room_for_user", localStorage.getItem("showing_task_data_id"));
      this.$socket.emit("all_comments", {
        session_id: this.$socket.id,
        task_id: localStorage.getItem("showing_task_data_id"),
      });
    },
    get_comment: function (val) {
      this.messages.push(val);
    },
    get_all_comments: function (values) {
      this.messages = values;
      this.scrollToEnd();
      this.is_success = true;
    },
    task_update: function () {
      // console.log('Task status updated')
      this.gettask();
    },
  },
};
</script>

<style>
.pop_up_image {
  width: 100%;
  object-fit: contain;
  padding: 5px;
  height: fit-content;
}

.comment_para {
  max-width: calc(100% - 30%);
  font-size: 16px;
  width: fit-content;
}

.comment_para > p {
  margin-bottom: 0px !important;
}

.comment_line {
  margin: 0px !important;
  font-size: 14px;
  position: relative;
}

.chat_messages {
  max-height: calc(100vh - 350px);
  min-height: 150px;
  word-wrap: break-word;
}

.task_desc {
  margin: auto;
}

.task_view {
  font-size: 18px;
}

.detail {
  margin-left: 0px;
}

.grey {
  background: #484848;
}
.mh-180 {
  max-height: calc(100vh - 530px);
}
</style>
