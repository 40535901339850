<template>
  <div class="item">
    <div
      class="card tasklist-item"
      :class="{ 'load-more-card': loadButton }"
      @click="handleItemClick"
    >
      <div class="card-body" v-if="!loadButton">
        <div class="text-dark">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <b>{{ item.ticket_number }}</b>
            <img
              v-b-tooltip.hover
              :title="
                item.assigned_to_detail && item.assigned_to_detail.full_name
              "
              :src="
                item.assigned_to_detail && item.assigned_to_detail.profile_photo
              "
              class="img-small rounded-circle mr-2"
            />
          </div>
          <div class="mt-2">{{ item.title }}</div>
        </div>
      </div>
      <div
        v-if="loadButton"
        class="text-center font-weight-bold disable-select button"
      >
        <span><b>Load More</b></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "list"],
  computed: {
    loadButton() {
      return (
        this.list.items.indexOf(this.item) === this.list.items.length - 1 &&
        this.list.items.length % 50 === 0
      );
    },
  },
  methods: {
    handleItemClick() {
      if (!this.loadButton) {
        // Handle item click logic
        console.log("task item clicked");
      } else {
        this.handleLoadMore();
      }
    },
    handleLoadMore() {
      let project_Id = this.$route.params.id;
      let payload = {
        project_Id,
        names: [this.list.name],
        limit: 50,
        skip: this.list.items.length,
      };
      this.$store.dispatch("fetchData", payload);
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-body {
  cursor: pointer;
}

.load-more-card {
  border: 1px solid green;
  padding: 2px;
  background-color: rgb(255, 253, 253);
  cursor: pointer;
  font-size: medium;
}

.item {
  margin-bottom: 12px;
  overflow-y: auto;
}

.due-date {
  color: red;
}

.hide {
  display: none;
}
</style>
