<template>
  <div class="input-block">
    <input
      placeholder="Select Date"
      class="date-input"
      @click="$emit('clickInput')"
      :value="value"
      readonly
    />
    <ClearButton v-if="isClearable" @clear="$emit('clear')" />
  </div>
</template>

<script>
import ClearButton from "@/components/ClearButton";
export default {
  name: "CalendarInput",
  components: { ClearButton },
  props: {
    value: {
      type: String,
      default: "",
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(newVal, oldVal) {
      // Do something when myProp changes
      this.value = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.input-block {
  display: flex;
  align-items: center;
  position: relative;
  width: 275px;
  .date-input {
    width: 65%;
    padding: 11px 16px;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 6px;
    text-align: start;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>
