<template>
  <div class="col-12 p-4">
    <div class="col-md-7 row p-0 form-table mx-auto">
      <div class="mb-3 col-12">
        <label for="reason" class="light-grey-text p-0 text-left">Reason</label>
        <div class="col-12 p-0">
          <input
            type="text"
            placeholder="Leave for"
            id="reason"
            v-model="leave.reason"
            class="form-control px-3 py-3 h-auto rounded m-0 grey-text"
          />
        </div>
      </div>
      <div class="mb-3 col-12">
        <label for="sol" class="light-grey-text p-0 text-left">From</label>
        <div class="col-12 p-0">
          <Datepicker
            class="datepicker_design"
            :use-utc="true"
            :bootstrap-styling="true"
            id="sol"
            placeholder="DD MM YYYY"
            v-model="leave.leave_start"
            @changedMonth="changedMonth"
            :disabled-dates="state.disabledDates"
            :highlighted="state.highlighted"
          />
        </div>
      </div>
      <div class="mb-3 col-12">
        <label for="eol" class="light-grey-text p-0 text-left">To</label>
        <div class="col-12 p-0">
          <Datepicker
            class="datepicker_design"
            :use-utc="true"
            :bootstrap-styling="true"
            id="eol"
            placeholder="DD MM YYYY"
            v-model="leave.leave_end"
            @changedMonth="changedMonth"
            :disabled-dates="state.disabledDates"
            :highlighted="state.highlighted"
          />
        </div>
      </div>
      <div class="text-center my-4 col-12 pt-4 z-depth-top">
        <button
          class="btn primary-btn btn primary-bg outline-none pointer font-1 py-2 px-4 rounded text-white bold_font"
          type="submit"
          v-bind:class="{ disabled: is_btn_loading }"
          @click="leave_apply"
        >
          Submit
          <span class="ml-2" v-if="is_btn_loading">
            <font-awesome-icon
              icon="spinner"
              size="sm"
              spin
            ></font-awesome-icon>
          </span>
        </button>
        <button
          style="margin-left: 10px"
          class="btn border rounded py-2 px-4"
          type="submit"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";

export default {
  name: "ApplyLeave",
  components: {
    Datepicker,
    moment,
  },
  data() {
    return {
      leave: {},
      // is_edit_page: false,
      user_detail: "",
      state: {
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: new Date(2020, 18, 2), // Disable all dates after specific date
          days: [], // Disable Saturday's and Sunday's
          // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
        },
        highlighted: {
          dates: [],
          includeDisabled: true,
        },
      },
      is_btn_loading: false,
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$root.$bvToast.toast(`New Leave added`, {
        title: "Success",
        variant: variant,
        autoHideDelay: 3000,
      });
    },
    user_details: function () {
      this.state.disabledDates.days = this.user_detail.weekend_days;
    },
    customFormatter(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    changedMonth: function (month) {
      this.state.highlighted.dates.length = 0;
      var start_date = moment.utc(month).startOf("month").format("YYYY-MM-DD");
      var end_date = moment.utc(month).endOf("month").format("YYYY-MM-DD");
      let params = {
        start: start_date,
        end: end_date,
      };
      this.$http
        .post(this.$store.state.api_url + "month_holidays", params)
        .then(function (data) {
          var gov_leave = data.body.data;
          for (var i = 0; i < gov_leave.length; i++) {
            var date = new Date(gov_leave[i].leave_date);
            this.state.highlighted.dates.push(date);
          }
        });
    },
    leave_apply: function () {
      console.log(this.leave);
      var err = "";
      if (this.leave.reason == "" || typeof this.leave.reason == "undefined") {
        err = "Please enter the reason";
      } else if (
        this.leave.leave_start == "" ||
        typeof this.leave.leave_start == "undefined"
      ) {
        err = "Please select leave starting date";
      } else if (
        this.leave.leave_end == "" ||
        typeof this.leave.leave_end == "undefined"
      ) {
        err = "Please select leave ending date";
      } else if (this.leave.leave_start > this.leave.leave_end) {
        err = "From date must be before the selected To date";
      }
      if (err == "") {
        this.is_btn_loading = true;
        this.leave.user = this.user_detail._id;
        this.leave.user_name = this.user_detail.full_name;
        this.$http
          .post(this.$store.state.api_url + "applyleave", this.leave)
          .then(function (data) {
            this.is_btn_loading = false;
            var response = data.body;
            if (response.status === true) {
              // this.makeToast('success')
              this.$bvToast.toast(`Leave details updated`, {
                title: "Success",
                autoHideDelay: 5000,
                variant: "success",
              });
              // let params = {
              //     user_id: added_user[i],
              //     project_id: data._id,
              //     type: 'leave',
              //     isread: '1',
              //     message: this.user_detail.full_name + ' applied for leave.'
              // }
              // this.$socket.emit('new_notification',params)
              this.$router.push({ name: "Calendar" });
            } else {
              this.$root.$bvToast.toast(`Leave date already exists`, {
                title: "Error",
                autoHideDelay: 3000,
                variant: "danger",
              });
            }
          });
      } else {
        this.$bvToast.toast(err, {
          title: "Error",
          autoHideDelay: 3000,
          variant: "danger",
        });
      }
    },
    cancel: function () {
      this.$router.push({ name: "Calendar" });
    },
    restrict_month: function () {
      var current_date = new Date();
      var limited_date = new Date(
        current_date.setMonth(current_date.getMonth() + 3)
      );
      // console.log(limited_date)
      this.state.disabledDates.from = limited_date;
      console.log(this.state.disabledDates.from);
    },
  },
  created() {
    this.$store.commit("change_page", "applyleave");
    // if(typeof this.$route.params._id!="undefined")
    // {
    //     this.is_edit_page = true
    //     this.holiday=this.$route.params
    // }
  },
  mounted() {
    this.$store.commit("change_page", "apply_leave");
    this.user_detail = JSON.parse(localStorage.getItem("userinfo"));
    this.user_details();
    this.changedMonth();
    this.restrict_month();
  },
};
</script>
