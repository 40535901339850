<template id="allTasksReport-filter">
  <perfect-scrollbar>
    <div class="sidenav-pkg bg-grey">
      <div class="sidebar mx-2 text-left p-0" id="lgmenu">
        <div class="bar-menus">
          <ul class="m-0 p-2 text-left no-bullet">
            <li
              class="sidemenu mt-1 d-flex justify-content-between align-items-center"
            >
              <h3 class="semi_bold_font w-100 mt-1 mb-4">Filters</h3>
              <font-awesome-icon
                @click="closeFilters()"
                class="cursor-pointer mt-0 mb-4"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Assignee</label>
              <v-select
                @input="apply_assignee_filter(filtered_assignees)"
                class="v-select-design w-100 multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="filtered_assignees"
                :options="member_options"
                placeholder="Team members"
                label="full_name"
              >
              </v-select>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Project</label>
              <v-select
                @input="project_filter(filter_project)"
                class="v-select-design w-100 multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="filter_project"
                :options="project_list"
                placeholder="Select Project"
                label="name"
              >
              </v-select>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-4 filter_sidebar_footer">
      <button
        class="btn bg-danger border border-danger btn-primary"
        @click="clear_local_storage_filters"
      >
        Clear
      </button>
      <button class="btn btn-primary ml-2 position-sticky" @click="get_tasks">
        Submit
      </button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import Vue from "vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import CalendarInput from "../CalendarInput.vue";
import VCalendar from "v-calendar";
import moment from "moment-timezone";
import { StatusProps } from "../../types/statusTypes";
Vue.use(VCalendar);
export default {
  name: "AllTasksReportFilters",
  components: {
    PerfectScrollbar,
    CalendarInput,
    moment,
  },
  props: ["closeFilters"],
  data() {
    return {
      hidemenu: true,
      toggle: false,
      filter_project: [],
      filter_by_project: [],
      project_list: [],
      user_in: "",
      member_options: [],
      sprint_options: [],
      filter_name: "",
      filter_by_assignee: [],
      filter_by_sprint: [],
      filtered_assignees: [],
    };
  },
  methods: {
    apply_assignee_filter(value) {
      this.filter_by_assignee = [];
      this.filtered_assignees = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_assignee.push(element);
          this.filtered_assignees.push(value[index]);
        }
      } else {
        this.filter_by_assignee = [];
        this.filtered_assignees = [];
      }
      this.update_local_storage_filters(
        "filtered_assignees",
        this.filtered_assignees
      );
      this.update_local_storage_filters(
        "filter_by_assignee",
        this.filter_by_assignee
      );
    },
    project_filter: function (filter_project) {
      this.filter_by_project = [];
      const filtered_project = [];
      if (filter_project.length > 0) {
        for (let index = 0; index < filter_project.length; index++) {
          const element = filter_project[index]._id;
          this.filter_by_project.push(element);
          filtered_project.push(filter_project[index]);
        }
      } else {
        this.filter_by_project = [];
      }
      this.update_local_storage_filters("filter_project", filtered_project);
      this.update_local_storage_filters(
        "filter_by_project",
        this.filter_by_project
      );
      // this.get_tasks(this.page);
    },
    get_tasks: function () {
      this.$eventHub.$emit("search_all_task_report");
      this.closeFilters();
    },
    get_userlist: function () {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers")
        .then(function (response) {
          this.member_options = response.body.data;
        });
    },
    get_sprintlist: function() {
      this.$http
        .get(this.$store.state.api_url + "activesprints/")
        .then(function (response) {
          this.sprint_options = response.body.data;
        });
    },
    get_project_list: function () {
      let params = {
        id: this.user_in._id,
      };
      this.$http
        .post(this.$store.state.api_url + "projectchat", { params })
        .then(function (response) {
          this.is_success = true;
          response = response.body;
          let project = response.data;
          this.project_list = project;
          this.update_local_storage_filters("filter_project", []);
          this.update_local_storage_filters("project_list", project);
        });
    },
    clear_local_storage_filters() {
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasksReport_filters")
      );
      existing_filters = {
        ...existing_filters,
        filter_project: "",
        filtered_assignees: [],
        filter_by_assignee: [],
        filter_by_project: [],
        filter_by_sprint: [],
      };

      this.filter_project = [];

      this.filtered_assignees = [];
      this.filter_by_assignee = [];
      this.filter_by_sprint = [];

      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );
      this.$eventHub.$emit("search");
      this.get_tasks();
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("allTasksReport_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );
    },
    get_local_storage_data() {
      let existing_filters = localStorage.getItem("allTasksReport_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          filter_project: this.filter_project,
          filter_by_project: this.filter_by_project,
          project_list: this.project_list,
          filter_by_assignee: this.filter_by_assignee,
          filter_by_sprint: this.filter_by_sprint,
          filtered_assignees: this.filtered_assignees,
        };
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.filter_by_project = existing_filters.filter_by_project;
        this.filter_project = existing_filters.filter_project;
        this.filter_by_assignee = existing_filters.filter_by_assignee || [];
        this.filter_by_sprint = existing_filters.filter_by_sprint || [];
        this.filtered_assignees = existing_filters.filtered_assignees || [];
        existing_filters = {
          ...existing_filters,
          filter_project: this.filter_project,
          filter_by_project: this.filter_by_project,
          project_list: this.project_list,
          filter_by_assignee: this.filter_by_assignee,
          filter_by_sprint: this.filter_by_sprint,
          filtered_assignees: this.filtered_assignees,
        };
      }

      localStorage.setItem(
        "allTasksReport_filters",
        JSON.stringify(existing_filters)
      );
    },
  },
  created() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));

    this.$eventHub.$on("refresh_allTaskReport", this.get_local_storage_data);
    this.get_local_storage_data();
  },
  mounted() {
    this.get_project_list();
    this.get_userlist();
    this.get_sprintlist();
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.get_local_storage_data();
  },
  watch: {
    filter_by_project(newValue, oldValue) {
      this.filter_by_project = newValue;
    },
    filter_by_assignee(newValue, oldValue) {
      this.filter_by_assignee = newValue;
    },
    filter_by_sprint(newValue, oldValue) {
      this.filter_by_sprint = newValue;
    },
    filtered_assignees(newValue, oldValue) {
      this.filtered_assignees = newValue;
    },
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style scoped>
.chat-menus {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.cursor-pointer {
  cursor: pointer;
}
.chat_icon svg {
  margin-right: 4px;
  margin-left: 0px !important;
}

.filter_sidebar_header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 16px;
  z-index: 1000;
}

.filter_sidebar_footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: -16px;
}
.green_status_icon {
  color: rgb(106, 238, 30);
}

.vs__dropdown-menu {
  max-height: 100px !important;
}

.icons {
  margin-right: 10px;
}

.hdr-img {
  height: 50px;
  width: 50px;
}

.pms {
  font-size: 10px;
  color: #9a9494;
  margin: 0px;
  text-align: left !important;
}

.leftcorner {
  text-align: left;
  background-color: rgb(240, 241, 243);
  color: rgba(53, 53, 53);
  z-index: 99 !important;
  top: 0;
}
.input-block {
  width: 100% !important;
}
.list {
  border: none;
  background-color: transparent;
  align-content: center;
  /* margin-top: 10px; */
}

.list:focus {
  outline: none;
}

svg {
  align-content: left;
  color: rgb(0, 0, 0);
  width: 20px;
}
.sidebar {
  color: rgba(53, 53, 53);
  height: 100vh;
}

.no-bullet {
  list-style: none;
}

h4 {
  font-size: 1.8em;
  margin: 5px 5px 10px 5px;
}

hr {
  margin: 1rem 0.5rem;
  border: 2px;
  border-top: 1px solid rgb(241, 237, 237);
}

@media (max-width: 992px) {
  .header {
    display: contents;
  }
}
.h-120 {
  height: 120px;
}
</style>
