<template>
  <div style="position: absolute; top: 10px; right: 10px" ref="dropdown">
    <div class="dot-icon" @click="toggleDeletePopup(list.name)">
      <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
    </div>
    <div v-if="showPopup" class="popup-content" ref="popupContent">
      <label class="content-item" @click="handleRemoveStatus(deleteName)"
        >Delete</label
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["list", "handleRemoveStatus"],
  data() {
    return {
      showPopup: false,
      deleteName: "",
    };
  },
  methods: {
    toggleDeletePopup(status_name) {
      this.showPopup = !this.showPopup;
      if (this.showPopup) {
        this.addClickOutsideListener();
      } else {
        this.removeClickOutsideListener();
      }
      this.deleteName = status_name;
    },
    addClickOutsideListener() {
      document.addEventListener("click", this.handleDocumentClick);
    },
    removeClickOutsideListener() {
      document.removeEventListener("click", this.handleDocumentClick);
    },
    handleDocumentClick(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.hideDeletePopup();
      }
    },
    hideDeletePopup() {
      this.showPopup = false;
      this.removeClickOutsideListener();
    },
  },
  beforeDestroy() {
    this.removeClickOutsideListener();
  },
};
</script>

<style scoped>
.dot-icon {
  cursor: pointer;
}
.content-item {
  width: 80px;
  padding: 5px;
  font-size: 16px;
  color: #010101;
  cursor: pointer;
  font-weight: 600;
  background-color: whitesmoke;
}

.popup-content {
  position: absolute;
  top: -8px;
  right: -25px;
  z-index: 100;
  background-color: #f0f0f0;
}

.content-item:hover {
  background-color: rgb(255, 128, 128);
  margin-bottom: -2px;
}
</style>
