export const StatusProps = {
  new: {
    name: "New",

    key: "new",

    color: "#007bff",

    short_name: "New",
  },
  in_progress: {
    name: "Inprogress",

    key: "in_progress",

    color: "#ff980e",

    short_name: "Inprogress",
  },
  on_hold: {
    name: "OnHold",

    key: "on_hold",

    color: "#ff98ee",

    short_name: "OnHold",
  },
  rot: {
    name: "Ready On Test",

    key: "rot",

    color: "#612f12",

    short_name: "ROT",
  },
  dev_completed: {
    name: "Dev Completed",

    key: "dev_completed",

    color: "#91B35B",

    short_name: "Dev Completed",
  },
  completed: {
    name: "Completed",

    key: "completed",

    color: "#a3ff00",

    short_name: "Completed",
  },
  Incomplete: {
    name: "In Complete",

    key: "in_complete",

    color: "#a3ea00",

    short_name: "In Complete",
  },
  todo: {
    name: "To Do",

    key: "todo",

    color: "#00d5d9",

    short_name: "To Do",
  },
  pot: {
    name: "Pass On Test",

    key: "pot",

    color: "#018a01",

    short_name: "POT",
  },
  released: {
    name: "Released",

    key: "released",

    color: "#ff01f2",

    short_name: "released",
  },
  open: {
    name: "open",

    key: "open",

    color: "#ffa1f2",

    short_name: "open",
  },
  rod: {
    name: "Ready On DevServer",
    key: "rod",
    color: "#fba1f2",
    short_name: "rod",
  },
  fot: {
    name: "Fail On Test",
    key: "fot",
    color: "#f13636",
    short_name: "fot",
  },
  closed: {
    name: "Closed",
    key: "closed",
    color: "#ff3636",
    short_name: "closed",
  },
  hold: {
    name: "Hold",
    key: "hold",
    color: "#fbd309",
    short_name: "hold",
  },
  aft: {
    name: "Awaiting For Testers",
    key: "aft",
    color: "#ffcf0e",
    short_name: "aft",
  },
  afb: {
    name: "Awaiting For BA",
    key: "aft",
    color: "#ff0e5a",
    short_name: "aft",
  },
  archived: {
    name: "Archived",
    key: "archived",
    color: "#ff0e5a",
    short_name: "archived",
  },
};
