<template id="sprint-filter">
  <perfect-scrollbar>
    <div class="sidenav-pkg bg-grey">
      <div class="sidebar mx-2 text-left p-0" id="lgmenu">
        <div class="bar-menus">
          <ul class="m-0 p-2 text-left no-bullet">
            <li
              class="sidemenu mt-1 d-flex justify-content-between align-items-center"
            >
              <h3 class="semi_bold_font w-100 mt-1 mb-4">Filters</h3>
              <font-awesome-icon
                @click="closeFilters()"
                class="cursor-pointer mt-0 mb-4"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Sprint Name</label>
              <input
                autofocus
                class="form-control w-100 p-2 h-auto rounded m-0"
                placeholder="Search..."
                v-model="name_filter"
                @keyup="filter_by_name(name_filter)"
                value="name_filter"
              />
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font font-8 w-100">Start date </label>
              <Datepicker
              class="form-control w-100 p-0 h-0 rounded m-0 datepicker-input"
              :use-utc="true"
              :bootstrap-styling="true"
              id="start_date"
              placeholder="DD MM YYYY"
              @change="filter_by_start_date(start_date_filter)"
              v-model="start_date_filter"
              :style="{ height: '33px', border: 'none', outline: 'none', borderRadius: '8px', padding: '5px' }"
            />
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font font-8 w-100">End date </label>
              <Datepicker
              class="form-control w-100 p-0 h-0 rounded m-0 datepicker-input"
              :use-utc="true"
              :bootstrap-styling="true"
              id="end_date"
              placeholder="DD MM YYYY"
              @change="filter_by_end_date(end_date_filter)"
              v-model="end_date_filter"
              :style="{ height: '33px', border: 'none', outline: 'none', borderRadius: '8px', padding: '5px' }"
            />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-4 filter_sidebar_footer">
      <button
        class="btn bg-danger border border-danger btn-primary"
        @click="clear_local_storage_filters"
      >
        Clear
      </button>
      <button class="btn btn-primary ml-2 position-sticky" @click="get_all_sprints">
        Submit
      </button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import Vue from "vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";

export default {
  name: "SprintFilters",
  components: {
    PerfectScrollbar,
    Datepicker
  },
  props: ["closeFilters"],
  data() {
    return {
      name_filter: "",
      start_date_filter: "",
      end_date_filter: ""
    };
  },
  methods: {
    filter_by_name: function (value) {
      this.name_filter = value;
      this.update_local_storage_filters("name_filter", value);
    },
    filter_by_start_date: function (value) {
      this.start_date_filter = value;
      this.update_local_storage_filters("start_date_filter", value);
    },
    filter_by_end_date: function (value) {
      this.end_date_filter = value;
      this.update_local_storage_filters("end_date_filter", value);
    },

    clear_local_storage_filters() {
      let existing_filters = JSON.parse(localStorage.getItem("sprint_filters"));
      

      existing_filters = {
        ...existing_filters,
        name_filter: "",
        start_date_filter: "",
          end_date_filter: "",
      };


      this.name_filter = "";
      this.start_date_filter = "";
      this.end_date_filter = "";

      localStorage.setItem("sprint_filters", JSON.stringify(existing_filters));
      this.get_all_sprints();
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(localStorage.getItem("sprint_filters"));
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem("sprint_filters", JSON.stringify(existing_filters));
    },
    get_all_sprints: function () {
      this.filter_by_start_date(this.start_date_filter);
      this.filter_by_end_date(this.end_date_filter);
      this.$eventHub.$emit("search_sprint");
      this.closeFilters();
    },
    get_local_storage_data() {
      let existing_filters = localStorage.getItem("sprint_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          name_filter: this.name_filter,
          start_date_filter: this.start_date_filter,
          end_date_filter: this.end_date_filter,
        };
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.name_filter = existing_filters.name_filter || "";
        this.start_date_filter = existing_filters.start_date_filter || "";
        this.end_date_filter = existing_filters.end_date_filter || "";
        existing_filters = {
          ...existing_filters,
          name_filter: this.name_filter,
          start_date_filter: this.start_date_filter,
          end_date_filter: this.end_date_filter,
        };
      }

      localStorage.setItem("sprint_filters", JSON.stringify(existing_filters));
    },
  },
  created() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));

    this.$eventHub.$on("refresh_sprint", this.get_local_storage_data);
    this.get_local_storage_data();
  },
  mounted() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.get_local_storage_data();
  },
  watch: {
    name_filter(newValue, oldValue) {
      this.name_filter = newValue;
    },
    start_date_filter(newValue, oldValue) {
      this.start_date_filter = newValue;
    },
    end_date_filter(newValue, oldValue) {
      this.end_date_filter = newValue;
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.filter_sidebar_footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: -16px;
}
.vs__dropdown-menu {
  max-height: 100px !important;
}

.icons {
  margin-right: 10px;
}

.input-block {
  width: 100% !important;
}
.list {
  border: none;
  background-color: transparent;
  align-content: center;
  /* margin-top: 10px; */
}

.list:focus {
  outline: none;
}

svg {
  align-content: left;
  color: rgb(0, 0, 0);
  width: 20px;
}
.sidebar {
  color: rgba(53, 53, 53);
  height: 100vh;
}

.no-bullet {
  list-style: none;
}

h4 {
  font-size: 1.8em;
  margin: 5px 5px 10px 5px;
}

hr {
  margin: 1rem 0.5rem;
  border: 2px;
  border-top: 1px solid rgb(241, 237, 237);
}

@media (max-width: 992px) {
  .header {
    display: contents;
  }
}
.h-120 {
  height: 120px;
}
</style>
