<template>
    <div class="col-12">
        <div class="leave_button col-12 p-0 px-md-3 row m-0 align-items-center">
            <div class="mt-3">
                <h6 class="text-left"> Remaining Leaves : {{this.remaining_leaves}} </h6>
            </div>
            <button v-b-tooltip.hover title="Apply Leave" class="btn primary-bg d-flex align-items-center ml-auto justify-content-center rounded-circle img-high-small text-white" @click="applyleave">
                <font-awesome-icon icon="plus" size="md" /> 
            </button>
        </div>
        <div class="show_calendar col-12 pt-4 mb-4">
            <Datepicker :inline="true" :format="customFormatter" :bootstrap-styling="true" @changedMonth="changedMonth" :clear-button="false" :disabled-dates = state.disabledDates  :highlighted= state.highlighted />
        </div>
        <div class="tasklist row w-100 mx-0">
            <div class="taskform col-12" id="no-more-tables">
                <table class="table table-striped text-left table_design">
                <thead class="table_header">
                    <tr class="p-0">
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic ">Leave For</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic ">Leave Date</th>
                        <th class="semi_bold_font grey-text font-weight-normal border-0 font-italic ">Action</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr class="p-0" v-for="item in leave" :key="item.doc._id">
                        <td data-title="Name" class="">{{item.doc.reason}}</td>
                        <td data-title="Leave Date" class="">{{item.doc.display_date}}</td>
                        <td data-title="Action" class="">
                            <div v-if="today_date <= item.doc.date_of_leave">
                                <div style="display:inline-flex" class="bg-white px-2 py-1 rounded">
                                    <a href="javascript:void(0)" class="grey-text d-inline-block text-decoration-none py-0 px-2 fa-sm-custom" @click="del_confirmation(item.doc.group_id)">Delete</a>
                                </div>
                            </div>
                        </td>
                        <b-modal v-model="del_modal" @ok="del_leave()" >
                            <p class="my-4">Are you sure to cancel?</p>
                        </b-modal>
                    </tr>
                    <tr v-if="leave.length == 0">
                        <td colspan="3">No leaves</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="mt-5 paginator">
                <ul style="display:flex" v-if="pages && pages.length > 1">
                    <li class="page-item" v-bind:class="{disabled:current === 1}">
                        <a class="page-link"  @click="get_leaves(1)">
                            <span aria-hidden="true">|&lt; </span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled:current === 1}">
                        <a class="page-link"  @click="get_leaves(current - 1)"> &lt;
                        </a>
                    </li>
                    <li class="page-item" v-for="item in pages" :key="item.id" v-bind:class="{active:current === item.id}">
                        <a class="page-link"  @click="get_leaves(item.id)">{{item.id}}</a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                        <a class="page-link"  @click="get_leaves(current + 1)">&gt;</a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled:current === totalPages}">
                        <a class="page-link"  @click="get_leaves(totalPages)">
                            <span aria-hidden="true"> &gt;| </span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment-timezone'
export default {
    name: 'Calendar',
    components: {
        Datepicker,
        moment
    },
    data () {
        return {
            user_detail: '',
            leave: [],
            del_modal: false,
            page: 1,
            current: 1,
            totalDocs: 0,
            totalPages: 0,
            startPage: 1,
            endPage: 1,
            pages:[],
            serial_number:'',
            current_delete_id: '',
            today_date: '',
            remaining_leaves: '',
            state: {
                disabledDates: {
                    //   to: new Date(), // Disable all dates up to specific date
                    // from: new Date(2020, 18, 2), // Disable all dates after specific date
                    days: [], // Disable Saturday's and Sunday's
                    // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
                },
                highlighted: {
                    dates: [],
                    includeDisabled: true
                }
            },
            filter_by_user: []
        }
    },
    methods: {
        del_confirmation:function(id){
            this.del_modal = !this.del_modal
            this.current_delete_id = id
        },
        user_details: function(){
            this.state.disabledDates.days = this.user_detail.weekend_days
        },
        customFormatter(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        changedMonth:function(month){
            this.state.highlighted.dates.length = 0
            var start_date = moment.utc(month).startOf('month').format('YYYY-MM-DD')
            var end_date = moment.utc(month).endOf('month').format("YYYY-MM-DD")
            let params = {
                start : start_date,
                end : end_date,
                user_id: this.user_detail._id
            }
            this.$http.post(this.$store.state.api_url+'month_holidays',params)
            .then(function(data){
                var data = data.body.data
                var gov_leave = data.received_holidays
                var applied_leave = data.received_leaves
                this.remaining_leaves = data.remaining_days
                for(var i=0; i<gov_leave.length;i++){
                    var date = new Date(gov_leave[i].leave_date)
                    this.state.highlighted.dates.push(date)
                }
                for(var i=0; i<applied_leave.length;i++){
                    var date = new Date(applied_leave[i].date_of_leave)
                    this.state.highlighted.dates.push(date)
                }
                this.get_leaves(this.page)
            })
        },
        applyleave: function(){
            this.$router.push({name:'ApplyLeave'})
        },
        get_leaves: function(page){
            this.filter_by_user.push(this.user_detail._id)
            let params = {
                pageno : page,
                size: 10,
                user_id: this.filter_by_user,
                request_for: "user",
                start_filter: null,
                end_filter: null
            }
            this.$http.post(this.$store.state.api_url+'listleaves',params)
            .then(function(data){
                this.data = data.body.data
                this.leave = this.data.docs
            })
        },
        del_leave:function (){
            var id = this.current_delete_id
            this.$http.post(this.$store.state.api_url+'cancel_leave',{id}).then(function(data){
                var response = data.body;
                if(response.status === true){
                    this.changedMonth()
                    this.get_leaves(this.page)
                }
            })
        }
    },
    mounted() {
        this.$store.commit("change_page", "Calendar")
        this.user_detail = JSON.parse(localStorage.getItem('userinfo'));
        this.user_details()
        this.changedMonth()
        this.get_leaves(this.page)
        this.today_date = moment.utc().format()
    }
}
</script>

<style>
.show_calendar .vdp-datepicker .vdp-datepicker__calendar {
    width: 100% !important;
    margin: 0 auto;
}
.show_calendar .vdp-datepicker .vdp-datepicker__calendar .cell {
    height: 100px;
    line-height: 100px;
}

.vdp-datepicker__calendar .cell.highlighted {
  background: #0b1369 !important;
  color: #fff;
}

</style>