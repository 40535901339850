<template id="backLogs-filter">
  <perfect-scrollbar>
    <div class="sidenav-pkg bg-grey">
      <div class="sidebar mx-2 text-left p-0" id="lgmenu">
        <div class="bar-menus">
          <ul class="m-0 p-2 text-left no-bullet">
            <li
              class="sidemenu mt-1 d-flex justify-content-between align-items-center"
            >
              <h3 class="semi_bold_font w-100 mt-1 mb-4">Filters</h3>
              <font-awesome-icon
                @click="closeFilters()"
                class="cursor-pointer mt-0 mb-4"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Task Name</label>
              <input
                autofocus
                class="form-control w-100 p-2 h-auto rounded m-0"
                placeholder="Search..."
                v-model="filter_task"
                @keyup="task_search(filter_task)"
                value="filter_task"
              />
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Ticket Number</label>
              <input
                autofocus
                class="form-control w-100 p-2 h-auto rounded m-0"
                placeholder="Search..."
                v-model="ticket_no"
                @keyup="number_search(ticket_no)"
                value="ticket_no"
              />
              <!-- v-model="filter_task"
          @keyup="task_search(filter_task)"
          value="filter_task" -->
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Assignee</label>
              <v-select
                @input="apply_assignee_filter(filtered_assignees)"
                class="v-select-design w-100 multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="filtered_assignees"
                :options="member_options"
                placeholder="Team members"
                label="full_name"
              >
              </v-select>
            </li>
            <li class="sidemenu mt-4">
              <label class="semi_bold_font w-100 font-8">Status</label>
              <v-select
                @input="status_filter(selected_status)"
                class="w-100 v-select-design multiple filter_select"
                :searchable="true"
                :multiple="true"
                v-model="selected_status"
                :options="filter_status"
                placeholder="Select Status"
                label="name"
              >
              </v-select>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-4 filter_sidebar_footer">
      <button
        class="btn bg-danger border border-danger btn-primary"
        @click="clear_local_storage_filters"
      >
        Clear
      </button>
      <button class="btn btn-primary ml-2 position-sticky" @click="get_tasks">
        Submit
      </button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import Vue from "vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import moment from "moment-timezone";
import { StatusProps } from "../../types/statusTypes";

export default {
  name: "BackLogsFilters",
  components: {
    PerfectScrollbar,
    moment,
  },
  props: ["closeFilters"],
  data() {
    return {
      hidemenu: true,
      toggle: false,
      ticket_no: "",
      filter_task: "",
      searchtask: "",
      filter_status: [
        { name: StatusProps["new"].name, value: "new" },
        { name: StatusProps["todo"].name, value: "todo" },
        { name: StatusProps["on_hold"].name, value: "on_hold" },
        { name: StatusProps["in_progress"].name, value: "in_progress" },
        { name: StatusProps["dev_completed"].name, value: "dev_completed" },
        { name: StatusProps["completed"].name, value: "Completed" },
      ],
      selected_status: [],
      user_in: "",
      member_options: [],
      filter_name: "",
      filter_by_assignee: [],
      filtered_assignees: [],
    };
  },
  methods: {
    number_search: function (ticket_number) {
      this.update_local_storage_filters("ticket_no", ticket_number);
      if (ticket_number !== null) {
        this.ticket_no = ticket_number;
      } else {
        this.$bvToast.toast(`Enter text to search`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      }
    },
    task_search: function (search_task) {
      this.update_local_storage_filters("searchtask", search_task);
      this.update_local_storage_filters("filter_task", search_task);
      if (search_task != null) {
        this.searchtask = search_task;
        this.filter_task = search_task;
        // this.get_tasks(this.page);
      } else {
        this.$bvToast.toast(`Enter text to search`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      }
    },
    apply_assignee_filter(value) {
      this.filter_by_assignee = [];
      this.filtered_assignees = [];
      if (value.length > 0) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index]._id;
          this.filter_by_assignee.push(element);
          this.filtered_assignees.push(value[index]);
        }
      } else {
        this.filter_by_assignee = [];
        this.filtered_assignees = [];
      }
      this.update_local_storage_filters(
        "filtered_assignees",
        this.filtered_assignees
      );
      this.update_local_storage_filters(
        "filter_by_assignee",
        this.filter_by_assignee
      );
    },
    get_tasks: function () {
      this.$eventHub.$emit("search_all_task");
      this.$eventHub.$emit("remove_old_backlogs");
      this.closeFilters();
    },
    get_userlist: function () {
      this.$http
        .post(this.$store.state.api_url + "allprojectusers")
        .then(function (response) {
          this.member_options = response.body.data;
        });
    },
    status_filter: function (status) {
      this.selected_status = status;
      this.update_local_storage_filters("selected_status", status);
      // this.get_tasks(this.page);
    },

    clear_local_storage_filters() {
      let existing_filters = JSON.parse(
        localStorage.getItem("backLogs_filters")
      );
      existing_filters = {
        ...existing_filters,
        searchtask: "",
        selected_status: [],
        filter_task: [],
        filtered_assignees: [],
        filter_by_assignee: [],
        ticket_no: ""
      };

      this.searchtask = "";
      this.selected_status = [];
      this.filter_task = [];
      this.ticket_no = "";

      this.filtered_assignees = [];
      this.filter_by_assignee = [];
      localStorage.setItem(
        "backLogs_filters",
        JSON.stringify(existing_filters)
      );
      this.$eventHub.$emit("search");
      this.$eventHub.$emit("remove_old_backlogs");
      this.get_tasks();
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("backLogs_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem(
        "backLogs_filters",
        JSON.stringify(existing_filters)
      );
    },
    get_local_storage_data() {
      let existing_filters = localStorage.getItem("backLogs_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filter_task: this.searchtask,
          ticket_no: this.ticket_no,
          filter_by_assignee: this.filter_by_assignee,
          filtered_assignees: this.filtered_assignees,
        };
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.searchtask = existing_filters.searchtask || "";
        this.ticket_no = existing_filters.ticket_no || "";
        this.selected_status = existing_filters.selected_status || [];
        this.filter_task = existing_filters.searchtask;
        this.filter_by_assignee = existing_filters.filter_by_assignee || [];
        this.filtered_assignees = existing_filters.filtered_assignees || [];
        existing_filters = {
          ...existing_filters,
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filter_task: this.searchtask,
          filter_by_assignee: this.filter_by_assignee,
          filtered_assignees: this.filtered_assignees,
        };
      }

      localStorage.setItem(
        "backLogs_filters",
        JSON.stringify(existing_filters)
      );
    },
  },
  created() {
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));

    this.$eventHub.$on("refresh_backLogs", this.get_local_storage_data);
    this.get_local_storage_data();
  },
  mounted() {
    this.get_userlist();
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.get_local_storage_data();
    if (
      this.$store.state.user_detail.user_role != "admin" &&
      this.$store.state.user_detail.user_role != "super_admin"
    ) {
      this.$router.push({ name: "Home" });
    }
  },
  watch: {
    searchtask(newValue, oldValue) {
      this.searchtask = newValue;
    },

    ticket_no(newValue, oldValue) {
      this.ticket_no = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    filter_task(newValue, oldValue) {
      this.filter_task = newValue;
    },
    filter_by_assignee(newValue, oldValue) {
      this.filter_by_assignee = newValue;
    },
    filtered_assignees(newValue, oldValue) {
      this.filtered_assignees = newValue;
    },
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style scoped>
.chat-menus {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.cursor-pointer {
  cursor: pointer;
}
.chat_icon svg {
  margin-right: 4px;
  margin-left: 0px !important;
}

.filter_sidebar_header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 16px;
  z-index: 1000;
}

.filter_sidebar_footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: -16px;
}
.green_status_icon {
  color: rgb(106, 238, 30);
}

.vs__dropdown-menu {
  max-height: 100px !important;
}

.icons {
  margin-right: 10px;
}

.hdr-img {
  height: 50px;
  width: 50px;
}

.pms {
  font-size: 10px;
  color: #9a9494;
  margin: 0px;
  text-align: left !important;
}

.leftcorner {
  text-align: left;
  background-color: rgb(240, 241, 243);
  color: rgba(53, 53, 53);
  z-index: 99 !important;
  top: 0;
}
.input-block {
  width: 100% !important;
}
.list {
  border: none;
  background-color: transparent;
  align-content: center;
  /* margin-top: 10px; */
}

.list:focus {
  outline: none;
}

svg {
  align-content: left;
  color: rgb(0, 0, 0);
  width: 20px;
}
.sidebar {
  color: rgba(53, 53, 53);
  height: 100vh;
}

.no-bullet {
  list-style: none;
}

h4 {
  font-size: 1.8em;
  margin: 5px 5px 10px 5px;
}

hr {
  margin: 1rem 0.5rem;
  border: 2px;
  border-top: 1px solid rgb(241, 237, 237);
}

@media (max-width: 992px) {
  .header {
    display: contents;
  }
}
.h-120 {
  height: 120px;
}
</style>
