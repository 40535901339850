<template>
  <div class="col-12 px-md-3 p-0">
    <div class="tasklist row w-100 mx-0">
      <!-- <div class="row scorecard-container">
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 dev_completed"
          @click="scorcard_filter('dev_completed')"
        >
          <h5>Dev Completed</h5>
          <h2>{{ get_score("dev_completed") }}</h2>
        </div>
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 todo"
          @click="scorcard_filter('todo')"
        >
          <h5>To Do</h5>
          <h2>{{ get_score("todo") }}</h2>
        </div>
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 in_progress"
          @click="scorcard_filter('in_progress')"
        >
          <h5>In Progress</h5>
          <h2>{{ get_score("in_progress") }}</h2>
        </div>
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 new"
          @click="scorcard_filter('new')"
        >
          <h5>New</h5>
          <h2>{{ get_score("new") }}</h2>
        </div>
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 rot"
          @click="scorcard_filter('rot')"
        >
          <h5>ROT</h5>
          <h2>{{ get_score("rot") }}</h2>
        </div>
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 pot"
          @click="scorcard_filter('pot')"
        >
          <h5>POT</h5>
          <h2>{{ get_score("pot") }}</h2>
        </div>
        <div
          class="scorecard col-md-4 col-xs-6 col-lg-3 col-sm-6 col-xl-2 fot"
          @click="scorcard_filter('fot')"
        >
          <h5>FOT</h5>
          <h2>{{ get_score("fot") }}</h2>
        </div>
      </div> -->

      <ScoreCard
        :set_status="filter_status"
        :projectId="''"
        :user_filter="[user_in._id]"
        :scorecard_filter="scorecard_filter"
      />

      <div class="filtered_items">
        <div
          v-if="searchtask !== '' && typeof searchtask !== 'undefined'"
          class="filter_chip"
        >
          Task Name: <b>{{ searchtask }}</b>
          <font-awesome-icon
            class="ml-2 clear-filter"
            @click="remove_filter('searchtask', searchtask)"
            icon="times"
            size="1x"
          ></font-awesome-icon>
        </div>
        <div
          v-if="ticket_no !== '' && typeof ticket_no !== 'undefined'"
          class="filter_chip"
        >
          Ticket Number: <b>{{ ticket_no }}</b>
          <font-awesome-icon
            class="ml-2 clear-filter"
            @click="remove_filter('ticket_no', ticket_no)"
            icon="times"
            size="1x"
          ></font-awesome-icon>
        </div>
        <div v-if="project_list.length > 0" class="d-flex align-items-center">
          <div v-for="(project, index) in project_list" :key="index">
            <div v-if="isFiltered(project._id)" class="filter_chip">
              Project:
              <b>{{ project.name }}</b>
              <font-awesome-icon
                @click="remove_filter('filter_by_project', project._id)"
                class="ml-2 clear-filter"
                icon="times"
                size="1x"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div
          v-if="
            selected_status.length > 0 && typeof selected_status !== 'undefined'
          "
          class="d-flex align-items-center"
        >
          <div
            v-for="(status, index) in selected_status"
            :key="index"
            class="filter_chip"
          >
            Status:
            <b>{{ status.name }}</b>
            <font-awesome-icon
              @click="remove_filter('selected_status', status)"
              class="ml-2 clear-filter"
              icon="times"
              size="1x"
            ></font-awesome-icon>
          </div>
        </div>
        <div
        v-if="
          filtered_sprints.length > 0 && typeof filtered_sprints !== 'undefined'
        "
        class="d-flex align-items-center"
      >
        <div
          v-for="(sprint, index) in filtered_sprints"
          :key="index"
          class="filter_chip"
        >
          Sprint:
          <b>{{ sprint.sprint_name }}</b>
          <font-awesome-icon
            @click="remove_filter('filtered_sprints', sprint)"
            class="ml-2 clear-filter"
            icon="times"
            size="1x"
          ></font-awesome-icon>
        </div>
      </div>
        <div
          v-if="start_date !== '' && typeof start_date !== 'undefined'"
          class="filter_chip"
        >
          Due Date: <b>{{ start_date }} - {{ end_date }}</b>
          <font-awesome-icon
            @click="remove_filter('due_date', start_date)"
            class="ml-2 clear-filter"
            icon="times"
            size="1x"
          ></font-awesome-icon>
        </div>
      </div>
      <span class="m-auto" v-if="is_loading">
        <font-awesome-icon icon="spinner" size="sm" spin></font-awesome-icon>
      </span>
      <div
        class="taskform col-12 p-0 px-md-3"
        id="no-more-tables"
        v-if="!is_loading"
      >
        <table class="table table_design table-hover table-striped">
          <thead>
            <tr class="p-0 m-0 position-relative">
              <th
                class="my_tasks_flag text-left flag_status semi_bold_font grey-text font-weight-normal border-0 font-italic"
              >
                <input
                  type="checkbox"
                  :checked="
                    isCheckedAll || checkedRow.length === all_tasks.length
                  "
                  @click="(e) => onCheck(e, true, '')"
                />
              </th>
              <th
                v-if="visible_columns.includes('ticket_no')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_ticket_no"
              >
                Ticket Number
              </th>
              <th
                v-if="visible_columns.includes('title')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_tasks_name"
              >
                Task name
              </th>
              <th
                v-if="visible_columns.includes('assigned_to')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_assigned_to"
              >
                Assigned to
              </th>

              <th
                v-if="visible_columns.includes('project_id')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_project_name"
              >
                Project
              </th>
              <th
                v-if="visible_columns.includes('status')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_updated_at"
              >
                Status
              </th>

              <th
                v-if="visible_columns.includes('created_at')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_created_at"
              >
                Created Date
              </th>
              <th
                v-if="visible_columns.includes('updated_at')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_updated_at"
              >
                Modified At
              </th>
              <th
                v-if="visible_columns.includes('sprint')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_sprint"
              >
                Sprint
              </th>
              <th
                v-if="visible_columns.includes('labels')"
                class="semi_bold_font text-center grey-text font-weight-normal border-0 font-italic my_labels"
              >
                Labels
              </th>
              <th
                v-if="visible_columns.includes('due_date')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_due_date"
              >
                Due Date
              </th>

              <th
                v-if="visible_columns.includes('description')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_description"
              >
                Description
              </th>
              <th
                v-if="visible_columns.includes('project_code')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_project_code"
              >
                Project Code
              </th>
              <th
                  v-if="visible_columns.includes('estimated_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_estimated_hours"
                >
                  Estimated Hours
                </th>

                <th
                  v-if="visible_columns.includes('actual_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_actual_hours"
                >
                  Actual Hours
                </th>
              <th
                v-if="visible_columns.includes('record_status')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_updated_at"
              >
                Record Status
              </th>
            </tr>
          </thead>
          <tbody v-if="!is_success">
            <td colspan="7">
              <ContentLoader>
                <rect x="0" y="10" rx="6" ry="6" width="100%" height="23" />
                <rect x="0" y="40" rx="6" ry="6" width="100%" height="23" />
                <rect x="0" y="70" rx="6" ry="6" width="100%" height="23" />
              </ContentLoader>
            </td>
          </tbody>
          <tbody class="table-body" v-if="is_success">
            <tr
              class="p-0 position-relative rounded m-0 table-body-row"
              v-for="item in all_tasks"
              :key="item._id"
              @click="navigateToDetails(item.ticket_number)"
            >
              <td class="my_tasks_flag text-left w-auto flag_status">
                <input
                  type="checkbox"
                  :checked="isCheckedRow(item._id)"
                  @click="(e) => onCheck(e, false, item._id)"
                />
                <!-- <img
                  src="../assets/completed.png"
                  class="img-small"
                  v-if="item.status == 'Completed'"
                />
                <img
                  src="../assets/pending.png"
                  class="img-small"
                  v-if="item.status == 'Incomplete'"
                />
                <img
                  src="../assets/processing.png"
                  class="img-small"
                  v-if="item.status == 'In Progress'"
                />
                <img
                  src="../assets/out for checking.png"
                  class="img-small"
                  v-if="item.status === 'Out for Testing'"
                /> -->
                <!-- <span class="img-small d-flex align-items-center justify-content-center bg-white rounded-circle"><font-awesome-icon icon="caret-up" size="lg" class="font-italic status_text" :class="item.status"></font-awesome-icon></span> -->
              </td>

              <td
                v-if="visible_columns.includes('ticket_no')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_ticket_no"
              >
                {{ item.ticket_no }}
              </td>
              <td
                v-if="visible_columns.includes('title')"
                class="my_tasks_name"
                data-title="Task"
              >
                <span class="font-8 semi_bold_font">{{
                  item.ticket_number
                }}</span>
                <br />{{ item.title }}<span v-if="item.title == null"> - </span>
              </td>
              <td
                v-if="visible_columns.includes('assigned_to')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_assigned_to"
              >
                {{ item.assigned_to_detail.full_name }}
              </td>
              <td
                v-if="visible_columns.includes('project_id')"
                class="my_project_name"
                data-title="Project"
              >
                {{ item.project_id ? item.project_id.name : "-" }}
              </td>
              <td
                v-if="visible_columns.includes('status')"
                class="my_updated_at"
                data-title="status"
              >
              
                <span
                  :style="{ background: get_color(item.status.toLowerCase()) }"
                  class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ status_props[item.status].name }}</span
                >
              </td>
              <td
                v-if="visible_columns.includes('created_at')"
                class="my_created_at fa-sm-custom created_at"
                data-title="created At"
              >
                {{ format_date(item.created_at) }}
              </td>
              <td
                v-if="visible_columns.includes('updated_at')"
                class="my_updated_at fa-sm-custom"
                data-title="Updated At"
              >
                {{ format_date(item.updated_at) }}
              </td>
              
                <td v-if="visible_columns.includes('sprint')"
                    class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_sprint">
                    <span v-if="typeof item.sprint === 'undefined' || item.sprint === null"></span>
                    <span v-else-if="item.sprint.length === 0"></span>
                    <span v-else-if="Array.isArray(item.sprint)">
                        {{ item.sprint.map((data, index) => {
                            return data;
                        }).join(", ") }}
                    </span>
                    <span v-else></span>
                </td>
              <td
                v-if="visible_columns.includes('labels')"
                class="semi_bold_font grey-text d-flex flex-column font-weight-normal border-0 font-italic my_labels"
              >
                <span
                  v-for="(label, index) in item.labels"
                  :key="index"
                  class="status_bg ml-1 text-black mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ label }},</span
                >
              </td>
              <td
                v-if="visible_columns.includes('due_date')"
                class="my_due_date fa-sm-custom due_date"
                data-title="Due Date"
              >
                {{ format_date(item.due_date) }}
              </td>

              <td
                v-if="visible_columns.includes('description')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_description"
              >
                <span v-html="item.description"></span>
              </td>

              <td
                v-if="visible_columns.includes('project_code')"
                class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_project_code"
              >
                {{ item.project_code }}
              </td>
              <td
                  v-if="visible_columns.includes('estimated_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_estimated_hours"
                >
                  {{ item.estimated_hours }}
              </td>
              <td
                  v-if="visible_columns.includes('actual_hours')"
                  class="semi_bold_font grey-text font-weight-normal border-0 font-italic my_actual_hours"
                >
                  {{ item.actual_hours }}
              </td>
              <td
                v-if="visible_columns.includes('record_status')"
                class="my_updated_at"
                data-title="status"
              >
                <span
                  :class="item.record_status === 'active' ? 'pot' : 'fot'"
                  class="status_bg text-white mx-auto mw-100x rounded fa-sm-custom semi_bold_font"
                  >{{ item.record_status }}</span
                >
              </td>
            </tr>

            <tr v-if="all_tasks.length == 0">
              <td colspan="6">No Tasks here!</td>
            </tr>
            <b-modal hide-header-close v-model="del_modal" @ok="del_task()">
              <p class="my-4">Are you sure to delete this task?</p>
            </b-modal>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-3 row m-0 col-12">
      <ul
        class="pagination pagination_design ml-auto d-flex list-type-none"
        v-if="pages && pages.length > 1"
      >
        <li class="page-item" v-bind:class="{ disabled: current === 1 }">
          <a class="page-link" @click="get_tasks(1)">
            <font-awesome-icon icon="angle-double-left"> </font-awesome-icon>
          </a>
        </li>
        <li class="page-item" v-bind:class="{ disabled: current === 1 }">
          <a class="page-link" @click="get_tasks(current - 1)">
            <font-awesome-icon icon="angle-left"> </font-awesome-icon>
          </a>
        </li>
        <li
          class="page-item"
          v-for="item in pages"
          :key="item.id"
          v-bind:class="{ active: current === item.id }"
        >
          <a class="page-link" @click="get_tasks(item.id)">{{ item.id }}</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: current === totalPages }"
        >
          <a class="page-link" @click="get_tasks(current + 1)">
            <font-awesome-icon icon="angle-right"> </font-awesome-icon>
          </a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: current === totalPages }"
        >
          <a class="page-link" @click="get_tasks(totalPages)">
            <font-awesome-icon icon="angle-double-right"> </font-awesome-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { ContentLoader } from "vue-content-loader";
import ScoreCard from "./ScoreCard.vue";
import { StatusProps } from "../types/statusTypes";

export default {
  name: "MyTasks",
  components: {
    moment,
    ContentLoader,
    ScoreCard,
  },
  data() {
    return {
      del_modal: false,
      current_delete_id: "",
      showstatus: false,
      status_props: StatusProps,
      page: 1,
      current: 1,
      totalDocs: 0,
      totalPages: 0,
      startPage: 1,
      endPage: 1,
      pages: [],
      is_loading: false,
      visible_columns: ["title", "project_id", "due_date", "status"],
      serial_number: "",
      all_tasks: {},
      start_date: "",
      end_date: "",
      filter_project: [], //Filter v-model for sprint... Yet to work on
      filter_task: "", //FIlter v-model for search task .. Going on
      filter_by_project: [], //Variable sent in api
      searchtask: "",
      isCheckedAll: false,
      checkedRow: [],
      filter_status: [
        "new",
        "dev_completed",
        "todo",
        "on_hold",
        "in_progress",
        "completed",
      ],
      selected_status: [],
      filtered_sprints: [],
      project_list: "",
      user_in: "",
      is_success: false,
      localStorageData: JSON.parse(localStorage.getItem("myTasks_filters")),
      scoreCard_data: [],
      ticket_no: "",
    };
  },
  created() {
    this.$eventHub.$on("search", this.get_tasks);
    this.$eventHub.$on("my_task_table", this.refresh_table);
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    this.refresh_table();
    this.get_tasks(this.page);
    this.get_project_list();
  },
  mounted() {
    const style = document.createElement("style");
    style.textContent = `
      .vm--modal {
        height: 40vh !important;
        border-radius: 20px;
        overflow: visible !important;
        padding: 16px;
        position: fixed;
        top: 50%;
        right: -100%;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
      }
      .v--modal.v--modal-show {
        transform: translateX(-50%);
      }
    `;
    document.head.appendChild(style);
    this.user_in = JSON.parse(localStorage.getItem("userinfo"));
    window.addEventListener("storage", this.handleLocalStorageChange);

    // Initialize localStorageData once component is mounted
    this.localStorageData =
      JSON.parse(localStorage.getItem("myTasks_filters")) || {};

    let existing_filters = localStorage.getItem("myTasks_filters");
    if (
      typeof existing_filters === "undefined" ||
      !existing_filters ||
      existing_filters === null ||
      existing_filters.trim() === ""
    ) {
      existing_filters = {
        start_date: this.start_date,
        end_date: this.end_date,
        searchtask: this.searchtask,
        selected_status: this.selected_status,
        filtered_sprints: this.filtered_sprints,
        filter_by_project: this.filter_by_project,
        mytask_visible_columns: this.visible_columns,
        ticket_no: this.ticket_no,
      };
      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));
    } else {
      existing_filters = JSON.parse(existing_filters);
      this.start_date = existing_filters.start_date || "";
      this.end_date = existing_filters.end_date || "";
      this.searchtask = existing_filters.searchtask || "";
      this.selected_status = existing_filters.selected_status || [];
      this.filtered_sprints = existing_filters.filtered_sprints || [];
      this.filter_by_project = existing_filters.filter_by_project || [];
      this.filter_project = existing_filters.filter_project;
      this.filter_task = existing_filters.searchtask;
      this.visible_columns = existing_filters.mytask_visible_columns;
      this.ticket_no = existing_filters.ticket_no || "";
    }
    this.get_tasks(this.page);
    this.get_project_list();

    this.$store.commit("change_page", "Tasks");
  },
  beforeDestroy() {
    this.$eventHub.$off("search", this.get_tasks);
  },
  methods: {
    isCheckedRow(id) {
      return this.isCheckedAll || this.checkedRow.includes(id) ? true : false;
    },
    onCheck(e, isAll, id) {
      e.stopPropagation();
      if (isAll) {
        if (this.isCheckedAll) {
          this.checkedRow = [];
        }
        this.isCheckedAll = !this.isCheckedAll;
        // let existingList = new Set(this.checkedRow);
        // existingList.delete(id)
        // this.checkedRow = Array.from(existingList);
      } else {
        let existingList = new Set(this.checkedRow);
        existingList.add(id);
        this.checkedRow = Array.from(existingList);
      }
    },

    navigateToDetails(ticket_no) {
      this.$router.push("/taskproxy/" + ticket_no);
    },
    remove_date_filter() {
      this.start_date = "";
      this.end_date = "";
      this.update_local_storage_filters("start_date", "");
      this.update_local_storage_filters("end_date", "");
      // this.get_tasks(this.page);
    },
    get_status_name(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].name;
      } else return status;
    },
    get_color(status) {
      if (typeof this.status_props[status] !== "undefined") {
        return this.status_props[status].color;
      } else return "#000";
    },
    format_date_str(dateStr) {
      const parsedDate = moment(dateStr, "DD/MM/YYYY");
      const formattedDate = parsedDate.format("YYYY-MM-DD");

      return moment(formattedDate).isValid() ? formattedDate : "";
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
    del_confirmation: function (id) {
      this.del_modal = !this.del_modal;
      this.current_delete_id = id;
    },
    customFormatter(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    task_search: function (search_task) {
      this.update_local_storage_filters("searchtask", search_task);
      this.update_local_storage_filters("filter_task", search_task);
      if (search_task != null) {
        this.searchtask = search_task;
        // this.get_tasks(this.page);
      } else {
        this.$bvToast.toast(`Enter text to search`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      }
    },
    date_filter: function (start_date, end_date) {
      this.start_date = moment.utc(start_date).format("YYYY-MM-DD");
      this.end_date = moment.utc(end_date).format("YYYY-MM-DD");
      this.start_date =
        this.start_date == "Invalid date" ? "" : this.start_date;
      this.end_date = this.end_date == "Invalid date" ? "" : this.end_date;
      this.update_local_storage_filters("start_date", this.start_date);
      this.update_local_storage_filters("end_date", this.end_date);
      if (this.start_date == "" || this.end_date == "") {
        this.$bvToast.toast(`Choose start and end dates`, {
          title: "Oops!",
          variant: "danger",
          autoHideDelay: 500,
          appendToast: false,
        });
      } else {
        // this.get_tasks(this.page);
      }
    },
    project_filter: function (filter_project) {
      this.filter_by_project = [];
      if (filter_project.length > 0) {
        for (let index = 0; index < filter_project.length; index++) {
          const element = filter_project[index]._id;
          this.filter_by_project.push(element);
        }
      } else {
        this.filter_by_project = [];
      }

      this.update_local_storage_filters("filter_project", filter_project);
      // this.get_tasks(this.page);
    },
    onClose() {
      this.showstatus = false;
    },
    handleLocalStorageChange(event) {
      if (event.key === "myTasks_filters") {
        // this.localStorageData = event.newValue;
      }
    },
    del_task: function () {
      this.$http
        .post(this.$store.state.api_url + "delete_task", {
          _id: this.current_delete_id,
        })
        .then(function (data) {
          var response = data.body;
          if (response.status === true) {
            this.get_tasks(this.page);
          }
        });
    },
    get_project_list: function () {
      let params = {
        id: this.user_in._id,
      };
      this.$http
        .post(this.$store.state.api_url + "projectchat", { params })
        .then(function (response) {
          this.is_success = true;
          response = response.body;
          let project = response.data;
          this.project_list = project;
        });
    },
    refresh_table: function () {
      let existing_filters = localStorage.getItem("myTasks_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          mytask_visible_columns: this.visible_columns,
        };
        localStorage.setItem(
          "myTasks_filters",
          JSON.stringify(existing_filters)
        );
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.visible_columns = existing_filters.mytask_visible_columns || [];
      }
    },
    get_tasks: function (page) {
      this.is_loading = true;
      let existing_filters = localStorage.getItem("myTasks_filters");
      if (
        typeof existing_filters === "undefined" ||
        !existing_filters ||
        existing_filters === null ||
        existing_filters.trim() === ""
      ) {
        existing_filters = {
          start_date: format_date_str(this.start_date),
          end_date: format_date_str(this.end_date),
          searchtask: this.searchtask,
          selected_status: this.selected_status,
          filtered_sprints: this.filtered_sprints,
          filter_project: this.filter_project,
          filter_task: this.searchtask,
          mytask_visible_columns: this.visible_columns,
          ticket_no: this.ticket_no,
        };
        // localStorage.setItem(
        //   "myTasks_filters",
        //   JSON.stringify(existing_filters)
        // );
      } else {
        existing_filters = JSON.parse(existing_filters);
        this.start_date = existing_filters.start_date || "";
        this.end_date = existing_filters.end_date || "";
        this.searchtask = existing_filters.searchtask || "";
        this.selected_status = existing_filters.selected_status || [];
        this.filtered_sprints = existing_filters.filtered_sprints || [];
        this.filter_by_project = existing_filters.filter_by_project || [];
        this.filter_project = existing_filters.filter_project;
        this.filter_task = existing_filters.searchtask;
        this.visible_columns = existing_filters.mytask_visible_columns || [];
        this.ticket_no = existing_filters.ticket_no || "";
      }

      const filters = JSON.parse(localStorage.getItem("myTasks_filters"));

      let params = {
        pageno: page || this.page,
        size: 10,
        project_filter: filters.filter_by_project,
        search_task: filters.searchtask,
        start_filter: filters.start_date,
        end_filter: filters.end_date,
        user_id: this.$store.state.user_detail._id,
        ticket_no: this.ticket_no,
        sort: {
          due_date: "asc",
        },
      };
      const sort = localStorage.getItem("my_task_sort");
      if (sort) {
        switch (sort) {
          case "Created High to Low":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: 1,
            };
            break;
          case "Created Low to High":
            params = {
              ...params,
              sortBy: "created_at",
              sortOrder: -1,
            };
            break;
          case "DueDate High to Low":
            params = {
              ...params,
              sortBy: "due_date",
              sortOrder: 1,
            };
            break;
          case "DueDate Low to High":
            params = {
              ...params,
              sortBy: "due_date",
              sortOrder: -1,
            };
            break;
          default:
            break;
        }
      } else {
        params = {
          ...params,
          sortBy: "created_at",
          sortOrder: -1,
        };
      }
      if (filters.selected_status) {
        params = {
          ...params,
          set_status:
            filters.selected_status.length > 0
              ? filters.selected_status.map((status) => status.value)
              : [],
        };
      }
      if (filters.filtered_sprints) {
        params = {
          ...params,
          set_sprint:
            filters.filtered_sprints.length > 0
              ? filters.filtered_sprints.map((sprint) => sprint.sprint_name)
              : [],
        };
      }
      this.$http
        .post(this.$store.state.api_url + "mytasks", { params })
        .then(function (response) {
          this.is_loading = false;
          response = response.body;
          this.data = response.data.docs;
          this.serial_number = response.data.pagingCounter;
          this.current = response.data.page;
          this.totalDocs = response.data.totalDocs;
          this.totalPages = response.data.totalPages;
          this.all_tasks = this.data;
          if (this.totalPages <= 5) {
            this.startPage = 1;
            this.endPage = this.totalPages;
          } else {
            if (this.current <= 3) {
              this.startPage = 1;
              this.endPage = 5;
            } else if (this.current + 2 >= this.totalPages) {
              this.startPage = this.totalPages - 4;
              this.endPage = this.totalPages;
            } else {
              this.startPage = this.current - 2;
              this.endPage = this.current + 2;
            }
          }
          this.scoreCard_data = response.scoreCard || [];
          this.pages = Array.from(
            Array(this.endPage + 1 - this.startPage).keys()
          ).map((i) => ({
            id: this.startPage + i,
            name: "Item " + (this.startPage + i),
          }));
        });
      this.is_loading = false;
    },
    scorecard_filter: function (status) {
      this.reset_filters();
      this.selected_status = status;
      this.update_local_storage_filters("selected_status", [
        { name: this.get_status_name(status.toLowerCase()), value: status },
      ]);
      this.get_tasks(this.page);
      this.$eventHub.$emit("refresh_mytask");
    },
    status_filter: function (status) {
      this.selected_status = status;
      this.update_local_storage_filters("selected_status", status);
      // this.get_tasks(this.page);
    },
    get_score: function (status) {
      const status_data = this.scoreCard_data.find(
        (item) => item._id === status
      );
      if (typeof status_data !== "undefined") return status_data.count;
      else return 0;
    },
    update_local_storage_filters(key, value) {
      let existing_filters = JSON.parse(
        localStorage.getItem("myTasks_filters")
      );
      if (!existing_filters) {
        existing_filters = {
          [key]: value,
        };
      } else {
        existing_filters = { ...existing_filters, [key]: value };
      }
      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));
    },
    reset_filters() {
      const existing_filters = {
        start_date: this.start_date,
        end_date: this.end_date,
        searchtask: this.searchtask,
        selected_status: this.selected_status,
        filtered_sprints: this.filtered_sprints,
        filter_by_project: this.filter_by_project,
        filter_project: this.filter_project,
        mytask_visible_columns: this.visible_columns,
      };

      localStorage.setItem("myTasks_filters", JSON.stringify(existing_filters));
    },
    isFiltered(id) {
      return this.filter_by_project.includes(id) ? true : false;
    },
    remove_filter(name, value) {
      switch (name) {
        case "searchtask":
          this.searchtask = "";
          this.update_local_storage_filters(name, "");
          this.get_tasks(this.page);
          break;

        case "ticket_no":
          this.ticket_no = "";
          this.update_local_storage_filters(name, "");
          this.get_tasks(this.page);
          break;
        case "due_date":
          this.start_date = "";
          this.end_date = "";
          this.update_local_storage_filters("start_date", "");
          this.update_local_storage_filters("end_date", "");
          this.get_tasks(this.page);
          break;
        case "filter_by_project":
          let new_projects = this.filter_by_project.filter(
            (project) => project !== value
          );
          let new_projects_list = this.filter_project.filter(
            (project) => project._id !== value
          );
          this.filter_project = new_projects_list;
          this.filter_by_project = new_projects;
          this.update_local_storage_filters(
            "filter_project",
            new_projects_list
          );
          this.update_local_storage_filters(name, new_projects);
          this.get_tasks(this.page);
          break;

        case "selected_status":
          let new_status = this.selected_status.filter(
            (status) => status.value !== value.value
          );
          this.selected_status = new_status;
          this.update_local_storage_filters(name, new_status);
          this.get_tasks(this.page);
          break;

          case "filtered_sprints":
          let new_sprint = this.filtered_sprints.filter(
            (sprint) => sprint.sprint_name !== value.sprint_name
          );
          this.filtered_sprints = new_sprint;
          this.update_local_storage_filters(name, new_sprint);
          this.get_tasks(this.taskpage);
          break;
        default:
          break;
      }
      this.$eventHub.$emit("refresh_mytask");
    },
  },
  watch: {
    start_date(newValue, oldValue) {
      this.start_date = newValue;
    },
    end_date(newValue, oldValue) {
      this.end_date = newValue;
    },
    searchtask(newValue, oldValue) {
      this.searchtask = newValue;
    },
    selected_status(newValue, oldValue) {
      this.selected_status = newValue;
    },
    filtered_sprints(newValue, oldValue) {
      this.filtered_sprints = newValue;
    },
    filter_by_project(newValue, oldValue) {
      this.filter_by_project = newValue;
    },
    filter_task(newValue, oldValue) {
      this.filter_task = newValue;
    },
    visible_columns(newValue, oldValue) {
      this.visible_columns = newValue;
    },
  },
};
</script>

<style scoped>
@media (max-width: 700px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td:not(.mf-footer),
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute !important;
    top: -9999px;
    left: -9999px;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
    height: auto;
  }
  #no-more-tables td:not(.mf-footer) {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 8px;
    left: 5%;
    width: 45%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    /* font-family: 'Poppins-Regular'; */
  }
  .data_table_design tfoot tr {
    display: flex;
    justify-content: center;
  }
  .data_table_design tfoot tr {
    display: block;
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }

  .tasklink {
    margin-left: 1.7rem;
  }
}

@media (min-width: 700px) {
  .my_assigned_to,
  .my_project_code,
  .my_estimated_hours,
  .my_actual_hours,
  .my_sprint,
  .my_labels {
    width: 150px;
    max-width: 200px;
  }
  .due_date {
    width: 120px !important;
  }

  table.table_design .my_action {
    width: 8%;
  }
}

.my_ticket_no {
  width: 100px !important;
}

.my_tasks_name {
  width: 320px !important;
}
.my_description {
  width: 560px !important;
  overflow-wrap: break-word;
}

.taskform {
  overflow-x: auto !important;
}
.table.table_design {
  min-width: 100vw;
  overflow-x: auto;
}
.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.filtered_items {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.filter_chip {
  display: flex;
  background-color: #e6e6e6;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.table-body-row {
  cursor: pointer;
}
.close-filter,
.clear-filter {
  cursor: pointer;
}
.paginator {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.tasklist {
  margin: 20px 10px;
  display: block;
  width: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
}

.statuslist input {
  width: 100%;
}

/* .my_tasks_flag {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f1f1f1;
} */

.my_project_name,
.my_due_date,
.my_created_at {
  width: 120px !important;
}

.my_updated_at {
  width: 124px !important;
}

.scorecard-container.row {
  margin-left: 8px !important;
}
.scorecard {
  display: flex;
  cursor: pointer;
  color: white !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 8px;
  font-size: 24px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 8px;
  background-color: #ebebeb;
}

.score_count {
  font-size: 20px;
}
.sts_select .dropdown-menu {
  padding: 2px !important;
}

.dev_completed {
  background-color: #a3ff0e !important;
}

.in_progress {
  background-color: #ff980e !important;
}
.new {
  background-color: #007bff !important;
}
.todo {
  background-color: #00d5d9 !important;
}

.rot {
  background-color: #612f12 !important;
}

.pot {
  background-color: #018a01 !important;
}

.completed {
  background-color: #6606bd !important;
}
.fot {
  background-color: #f13636 !important;
}
.released {
  background-color: #ff01f2 !important;
}

/* table{
  margin: 0px auto;
}

.table-wrap {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

table th, table tr {
  text-align: left;
}

table thead {
  background: #f2f2f2;
}

table tr td {
  padding: 10px;
  width: fit-content;
  height: auto;
  margin-top: 10px;
  text-align: center;
  margin: auto;
}

table tr:nth-child(odd) {
  background: #f2f2f2;
}
table tr:nth-child(1) {
  background: #4d7ef7;
  color: #fff;
} */
</style>
