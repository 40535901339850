<template id="sidebar-template">
  <div class="row m-0 border">
    <div class="col-md-4">
      <div
        v-if="$store.state.user_detail.user_role != 'client'"
        class="chat-menus p-2"
      >
        <!-- <ul class="p-0 m-0 list-type-none">
          <li class="sidemenu logout_button">
            <a
              href="javascript:void(0)"
              class="grey-text text-decoration-none d-block p-2"
              @click="new_chat_modal()"
            >
              New Chat
              <font-awesome-icon
                icon="plus-circle"
                size="1x"
                class="icons"
                style="margin-left: auto"
              ></font-awesome-icon
            ></a>
          </li>
          <div v-show="chat_modal">
            <v-select
              class="v-select-design chat"
              :searchable="true"
              :multiple="false"
              v-model="receiver"
              :options="member_options"
              @input="new_chat"
              placeholder="Select to chat"
              label="full_name"
            >
            </v-select>
          </div>
        </ul> -->
        <ul
          class="p-0 m-0 list-type-none"
          v-for="item in chat_list"
          :key="item._id"
        >
          <li
            v-if="$store.state.user_detail._id == item.sender"
            class="sidemenu logout_button"
            @click="changebin()"
          >
            <router-link
              class="sidebarmenu grey-text text-decoration-none d-block p-2 chat_icon text-truncate"
              v-bind:to="'/chat/' + item.chat_id"
            >
              <font-awesome-icon
                icon="circle"
                v-bind:class="{
                  green_status_icon: item.sent_to_info.status == 'online',
                }"
                size="xs"
              ></font-awesome-icon>
              {{ item.sent_to_info.full_name }}
            </router-link>
          </li>
          <li
            v-if="$store.state.user_detail._id == item.receiver"
            class="sidemenu logout_button"
            @click="changebin()"
          >
            <router-link
              class="sidebarmenu grey-text text-decoration-none d-block p-2 chat_icon text-truncate"
              v-bind:to="'/chat/' + item.chat_id"
            >
              <font-awesome-icon
                icon="circle"
                v-bind:class="{
                  green_status_icon: item.sent_by_info.status == 'online',
                }"
                size="xs"
              ></font-awesome-icon
              >{{ item.sent_by_info.full_name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-8">
      <div class="home col-12">
        <div class="col-12 row m-0">
          <div class="col-12 p-0 pb-3">
            <ContentLoader class="" height="210" v-if="!is_chat_success">
              <circle cx="19" cy="25" r="16" />
              <rect x="39" y="12" rx="5" ry="5" width="45%" height="10" />
              <rect x="40" y="29" rx="5" ry="5" width="45%" height="10" />
              <circle cx="380" cy="71" r="16" />
              <rect x="179" y="76" rx="5" ry="5" width="45%" height="10" />
              <rect x="179" y="58" rx="5" ry="5" width="45%" height="10" />
              <circle cx="21" cy="117" r="16" />
              <rect x="45" y="104" rx="5" ry="5" width="45%" height="10" />
              <rect x="45" y="122" rx="5" ry="5" width="45%" height="10" />
              <circle cx="380" cy="161" r="16" />
              <rect x="179" y="168" rx="5" ry="5" width="45%" height="10" />
              <rect x="179" y="148" rx="5" ry="5" width="45%" height="10" />
            </ContentLoader>
            <perfect-scrollbar ref="ps">
              <div
                class="chat_messages col-12 p-0 bg-white"
                v-if="is_chat_success"
                id="container"
              >
                <ul class="px-0 pb-5 list-type-none" v-if="messages.length > 0">
                  <li
                    class="px-3"
                    v-for="message in messages"
                    :key="message._id"
                  >
                    <span
                      class="sticky-top bg-white fa-sm-custom grey-text py-1 px-4 mx-auto align-items-center justify-content-center d-flex"
                      v-if="message.date != ''"
                      ><span
                        class="bg-white py-1 px-4 rounded-pill z-depth-1"
                        >{{ message.date }}</span
                      ></span
                    >
                    <span v-for="msg in message.msg" :key="msg._id">
                      <div
                        class="chat_line pb-3"
                        v-if="msg.sender == signed_in_user._id"
                      >
                        <img
                          :src="msg.sent_by_name.profile_photo"
                          class="img-small position-absolute r-0 mr-2 t-0 rounded-circle ml-auto"
                        />
                        <span class="pr-5 d-block">
                          <p
                            class="m-0 chat_name text-right semi_bold_font text-black"
                          >
                            You
                          </p>
                          <div
                            v-if="!msg.image_name"
                            class="ml-auto chat_para mb-1 p-2 rounded"
                            v-html="msg.message"
                          ></div>
                          <div
                            v-if="msg.image_name"
                            class="ml-auto chat_para mb-1 bg-transparent position-relative"
                          >
                            <a
                              class="bg-white img-too-small m-2 position-absolute r-0 rounded-circle no_white t-0 align-items-center d-flex justify-content-center z-depth-top"
                              href="javascript:void(0)"
                              @click="
                                download_img(msg.image_url, msg.image_name)
                              "
                            >
                              <font-awesome-icon
                                icon="download"
                                class="mb-0 primary-text-color fa-sm-custom"
                                size="lg"
                              ></font-awesome-icon>
                            </a>
                            <img
                              :src="msg.image_url"
                              class="h-150 pointer rounded"
                              @click="showmodal(msg.image_url)"
                            />
                          </div>
                          <p class="time_display text-right mb-0 text-black">
                            {{ msg.show_message_time }}
                          </p>
                        </span>
                      </div>
                      <div
                        class="text-left chat_line pb-3"
                        v-if="msg.sender != signed_in_user._id"
                      >
                        <img
                          :src="msg.sent_by_name.profile_photo"
                          class="img-small position-absolute l-0 ml-2 t-0 rounded-circle"
                        />
                        <span class="pl-5 d-block">
                          <p class="m-0 chat_name semi_bold_font text-black">
                            {{ msg.sent_by_name.full_name }}
                          </p>
                          <div
                            v-if="!msg.image_name"
                            class="mr-auto chat_para mb-1 bg-dark text-white p-2 rounded"
                            v-html="msg.message"
                          ></div>
                          <div
                            v-if="msg.image_name"
                            class="mr-auto chat_para mb-1 bg-transparent position-relative"
                          >
                            <a
                              class="bg-white img-too-small m-2 position-absolute r-0 rounded-circle no_white t-0 align-items-center d-flex justify-content-center z-depth-top"
                              href="javascript:void(0)"
                              @click="
                                download_img(msg.image_url, msg.image_name)
                              "
                            >
                              <font-awesome-icon
                                icon="download"
                                class="mb-0 primary-text-color fa-sm-custom"
                                size="lg"
                              ></font-awesome-icon>
                            </a>
                            <img
                              :src="msg.image_url"
                              class="h-150 pointer rounded"
                              @click="showmodal(msg.image_url)"
                            />
                          </div>
                          <p class="time_display mb-0">
                            {{ msg.show_message_time }}
                          </p>
                        </span>
                      </div>
                    </span>
                    <b-modal
                      size="xl"
                      id="modal-image"
                      class="modal_div"
                      v-model="modalShow"
                    >
                      <img :src="display_image" class="pop_up_image" />
                    </b-modal>
                  </li>
                  <!-- <li v-if="typing">{{typing}}...</li> -->
                </ul>
                <div
                  class="col-12 p-3 text-center row m-0 align-items-center justify-content-center"
                  v-if="messages.length == 0"
                >
                  <font-awesome-icon
                    icon="comment-dots"
                    class="grey-text fa-3x mb-3"
                    size="lg"
                  ></font-awesome-icon>
                  <h3 class="bold_font w-100">No chat messages</h3>
                  <p class="grey-text">
                    Start conversing to see your messages here
                  </p>
                </div>
              </div>
            </perfect-scrollbar>
            <div class="chat_box w-100" id="footer">
              <!-- <input v-model="message" @keydown="texttyping('$event')" @keyup.enter="send_message()" placeholder="Type something..." /> -->
              <quill-editor
                id="editor"
                class="zindex-high textarea_design chat bg-white position-absolute r-0 l-0 b-0 w-100 overflow-hidden pb-1"
                ref="myQuillEditor"
                v-model="message"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)"
                @keyup.enter="send_message"
                @change="onEditorChange($event)"
              >
                <div id="toolbar" slot="toolbar">
                  <button class="ql-bold border rounded mr-1">Bold</button>
                  <button class="ql-italic border rounded mr-1">Italic</button>
                  <button class="ql-link border rounded mr-1">link</button>
                  <button
                    class="border rounded outline-none"
                    onclick="document.getElementById('fileInput').click();"
                  >
                    <font-awesome-icon
                      icon="paperclip"
                      class="text-black"
                      size="1x"
                    ></font-awesome-icon>
                  </button>
                  <input
                    id="fileInput"
                    style="display: none"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                    ref="files"
                    @change="uploadimage"
                  />
                  <button @click="toogleDialogEmoji">😃</button>
                </div>
              </quill-editor>
              <button
                class="py-2 px-3 w-auto semi_bold_font zindex-high primary-bg text-white text-center btn position-absolute b-0 r-0 z-index-2"
                @click="send_message"
              >
                <font-awesome-icon
                  icon="paper-plane"
                  class="text-white"
                  size="sm"
                ></font-awesome-icon>
              </button>
            </div>
            <div :hidden="dialogHidden" class="emoji_pos">
              <VEmojiPicker
                :pack="emojisNative"
                labelSearch="Search"
                @select="onSelectEmoji"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Quill from "quill";
import { ContentLoader } from "vue-content-loader";
import Compress from "compress.js";
import LightBox from "vue-image-lightbox";
import VEmojiPicker from "v-emoji-picker";
import packEmoji from "v-emoji-picker/data/emojis.js";

export default {
  name: "Sidebar",
  components: {
    PerfectScrollbar,
    ContentLoader,
    Compress,
    LightBox,
    VEmojiPicker,
  },
  data() {
    let self = this;
    return {
      dialogHidden: true,
      user_detail: {},
      hidemenu: true,
      toggle: false,
      edit_user_info: "",
      chat_modal: false,
      chat_list: "",
      group_chat_list: "",
      sender: "",
      receiver: "",
      member_options: "",
      notifications_count: 0,
      chat_details: "",
      messages: [],
      to: "",
      message: "",
      signed_in_user: "",
      typing: false,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
          keyboard: {
            bindings: {
              tab: false,
              handleEnter: {
                key: 13,
                handler() {
                  self.send_message();
                },
              },
              smartbreak: {
                key: 13,
                shiftKey: true,
                handler: function (range, context) {
                  this.quill.setSelection(range.index, "silent");
                  this.quill.insertText(range.index, "\n", "user");
                  this.quill.setSelection(range.index + 1, "silent");
                  this.quill.format("linebreak", true, "user");
                },
              },
            },
          },
        },
      },
      is_chat_success: false,
      send_image: [],
      display_image: "",
      modalShow: false,
    };
  },
  methods: {
    toogleDialogEmoji() {
      this.dialogHidden = !this.dialogHidden;
    },
    onSelectEmoji(dataEmoji) {
      let lastIndex = this.editor.getText().length - 1;
      this.editor.insertText(lastIndex, dataEmoji.emoji);
      this.toogleDialogEmoji();
    },
    changebin(value) {
      this.$eventHub.$emit("change-binary", this.toggle);
      this.$eventHub.$emit("hide_sidemenu");
    },
    new_chat_modal() {
      this.chat_modal = !this.chat_modal;
    },
    clearsession() {
      this.changebin();
      this.$socket.emit("logout_disconnect", {
        user_id: this.$store.state.user_detail._id,
      });
      localStorage.removeItem("userinfo");
      this.$router.push("/signin");
      this.showdd = false;
    },
    get_userlist: function () {
      let params = {
        current_user: this.user_detail._id,
      };
      this.$http
        .post(this.$store.state.api_url + "allchatusers", params)
        .then(function (response) {
          response = response.body;
          response = response.data;
          let data = response;
          this.member_options = data;
        });
    },
    new_chat: function () {
      var signed_in_user = this.user_detail;
      this.sender = signed_in_user._id;
      let params = {
        sender: this.sender,
        receiver: this.receiver._id,
      };
      this.$http
        .post(this.$store.state.api_url + "newchat", params)
        .then(function (response) {
          var request = response.body.data;
          this.chat_modal = false;
          this.receiver = "";
          if ((response.body.status = "true")) {
            this.$socket.emit("getchats", {
              session_id: this.$socket.id,
              user_chat: this.user_detail._id,
            });
            this.changebin();
            this.$router.push("/chat/" + request.chat_id);
          } else if ((response.body.message = "Chat exists")) {
            this.$socket.emit("getchats", {
              session_id: this.$socket.id,
              user_chat: this.user_detail._id,
            });
            this.changebin();
            this.$router.push("/chat/" + request.chat_id);
          }
        });
    },
    get_chat_list: function () {
      var user_id = this.user_detail;
      this.sender = user_id._id;
      let params = {
        user_chat: this.sender,
        session_id: this.$socket.id,
      };
      let response = this.$socket.emit("getchats", params);
    },
    get_group_chat: function () {
      let params = {
        id: this.user_detail._id,
      };
      this.$http
        .post(this.$store.state.api_url + "projectchat", { params })
        .then(function (response) {
          response = response.body;
          this.group_chat_list = response.data;
        });
    },
    showmodal: function (url) {
      this.modalShow = !this.modalShow;
      this.display_image = url;
    },
    forceFileDownload(response, image_name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", image_name); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    download_img(image, name) {
      this.$http({
        method: "get",
        url: image,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, name);
        })
        .catch(() => console.log("error occured"));
    },
    onEditorBlur(editor) {},
    onEditorFocus(editor) {},
    onEditorReady(editor) {},
    onEditorChange(editor) {},

    uploadimage: function (evt) {
      this.message = "";
      let selected = evt.target.files;
      let accepted_files = [];
      let declined_files = [];
      for (var i = 0; i < selected.length; i++) {
        let file = selected[i];
        if (
          file.name.split(".").pop() == "jpeg" ||
          file.name.split(".").pop() == "jpg" ||
          file.name.split(".").pop() == "JPG" ||
          file.name.split(".").pop() == "png"
        ) {
          accepted_files.push(file);
        } else {
          declined_files.push(file);
        }
      }
      const compress = new Compress();
      var files = accepted_files;
      compress
        .compress(files, {
          size: 5, // the max size in MB, defaults to 2MB
          quality: 0.8, // the quality of the image, max is 1,
          maxWidth: 720, // the max width of the output image, defaults to 1920px
          maxHeight: 640, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          let compressed_img = data;
          var img = [];
          for (var i = 0; i < compressed_img.length; i++) {
            let file = compressed_img[i];
            var image = {};
            image.name = compressed_img[i].alt;
            image.b64code = compressed_img[i].data;
            img.push(image);
          }
          this.send_image = img;
          this.send_message();
          // this.send_image.splice(0,this.send_image.length)
        });
    },
    send_message: function () {
      var chat_id = this.$route.params.id;
      var find = "<p><br></p>";
      var re = new RegExp(find, "g");
      this.message = this.message.replace(re, "");
      if (this.message.trim() != "") {
        var params = {
          sender: this.signed_in_user._id,
          // receiver: this.to,
          chat_id: chat_id,
          message: this.message,
          type: "message",
        };
        let response = this.$socket.emit("chat", {
          params: params,
          allmsg: this.messages,
        });
        this.message = "";
        this.scrollToEnd();
      } else if (this.send_image.length) {
        var params = {
          sender: this.signed_in_user._id,
          // receiver: this.to,
          chat_id: chat_id,
          chat_image: this.send_image,
          type: "image",
        };
        let response = this.$socket.emit("chat", {
          params: params,
          allmsg: this.messages,
        });
        this.scrollToEnd();
      } else {
        this.$bvToast.toast(`Please enter comment`, {
          title: "Error",
          variant: "warning",
          autoHideDelay: 3000,
        });
      }
    },
    scrollToEnd: function () {
      setTimeout(() => {
        var objDiv = document.getElementById("container");
        const ps = this.$refs.ps;
        ps.$el.scrollTop = objDiv.scrollHeight;
        ps.update();
      }, 100);
    },
    texttyping: function (e) {
      // console.log("FDfd");
      this.$socket.emit("typing", this.$route.params.id);
    },
  },
  watch: {
    $route(to, from) {
      this.$store.commit("change_page", "chat_page");
      this.$socket.emit("room_for_chat", this.$route.params.id);
      this.$socket.emit("fetch_chat_details", this.$route.params.id);
      this.$socket.emit("all_chat_messages", {
        session_id: this.$socket.id,
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
      });
      this.$socket.emit("read_all_notifications", {
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
        type: "message",
      });
    },
  },
  created() {
    this.user_detail = JSON.parse(localStorage.getItem("userinfo"));
    if (localStorage.getItem("userinfo")) {
      this.$store.commit(
        "set_user_detail",
        JSON.parse(localStorage.getItem("userinfo"))
      );
    }
    this.get_chat_list();
    this.get_userlist();
    this.get_group_chat();
    this.$socket.emit("getchats", {
      session_id: this.$socket.id,
      user_chat: this.user_detail._id,
    });
    this.signed_in_user = JSON.parse(localStorage.getItem("userinfo"));
    // this.fetch_chat()
    this.$socket.emit("fetch_chat_details", this.$route.params.id);
    this.$socket.emit("room_for_chat", this.$route.params.id);
    this.$socket.emit("all_chat_messages", {
      session_id: this.$socket.id,
      chat_id: this.$route.params.id,
      user_id: this.signed_in_user._id,
    });
    this.$socket.emit("read_all_notifications", {
      chat_id: this.$route.params.id,
      user_id: this.signed_in_user._id,
      type: "message",
    });
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
    this.get_userlist();
    if (document.body.clientWidth >= 767) {
      setTimeout(function () {
        let mainDiv = document.getElementById("extra_margin").style.marginLeft;
        if (mainDiv == "") mainDiv = "0px";
        document.getElementById("extra_margin").style.marginLeft =
          parseInt(mainDiv.split("px")[0]) + 0 + "px";
      }, 200);
    }
  },
  computed: {
    emojisNative() {
      return packEmoji;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  sockets: {
    connect: function () {
      this.$socket.emit("getchats", {
        session_id: this.$socket.id,
        user_chat: this.user_detail._id,
      });
      this.$socket.emit("room_for_chat", this.$route.params.id);
      this.$socket.emit("fetch_chat_details", this.$route.params.id); //Details about chat page
      this.$socket.emit("all_chat_messages", {
        session_id: this.$socket.id,
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
      }); //All text messages in chat window
      this.$socket.emit("read_all_notifications", {
        chat_id: this.$route.params.id,
        user_id: this.signed_in_user._id,
        type: "message",
      });
    },
    list_of_chats: function (val) {
      this.chat_list = val;
    },
    user_status: function (val) {
      var receiver = this.chat_list.find((o) => o.receiver === val.user_id);
      var sender = this.chat_list.find((o) => o.sender === val.user_id);
      if (receiver || sender) {
        this.$socket.emit("getchats", {
          session_id: this.$socket.id,
          user_chat: this.user_detail._id,
        });
      }
    },
    notification_alert: function (data) {
      this.notifications_count += 1;
    },
    get_all_notifications_count: function (value) {
      this.notifications_count = value;
    },
    get_chat: function (val) {
      if (this.$route.params.id == val.msg[0].chat_id) {
        this.messages.push(val);
      }
      this.scrollToEnd();
      this.send_image.splice(0, this.send_image.length);
    },
    get_all_chats: function (values) {
      this.is_chat_success = true;
      this.messages = values;
      this.scrollToEnd();
      // console.log(this.messages);
    },
    typing: function (data) {
      this.typing = data;
    },
    stopTyping: function (data) {
      this.typing = false;
    },
    get_chat_details: function (data) {
      this.chat_details = data;
      this.$store.commit("set_chat_details", this.chat_details);
    },
  },
};
</script>

<style scoped>
.chat-menus {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  height: calc(100vh - 170px) !important;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.chat_icon svg {
  margin-right: 4px;
  margin-left: 0px !important;
}

.green_status_icon {
  color: rgb(106, 238, 30);
}

.vs__dropdown-menu {
  max-height: 100px !important;
}

.icons {
  margin-right: 10px;
}

.hdr-img {
  height: 50px;
  width: 50px;
}

.pms {
  font-size: 10px;
  color: #9a9494;
  margin: 0px;
  text-align: left !important;
}

.leftcorner {
  text-align: left;
  background-color: rgb(240, 241, 243);
  color: rgba(53, 53, 53);
  z-index: 99 !important;
  top: 0;
}

.list {
  border: none;
  background-color: transparent;
  align-content: center;
  /* margin-top: 10px; */
}

.list:focus {
  outline: none;
}

svg {
  align-content: left;
  color: rgb(0, 0, 0);
  width: 20px;
}
.sidebar {
  color: rgba(53, 53, 53);
  height: 100vh;
}

h4 {
  font-size: 1.8em;
  margin: 5px 5px 10px 5px;
}

hr {
  margin: 1rem 0.5rem;
  border: 2px;
  border-top: 1px solid rgb(241, 237, 237);
}

@media (max-width: 992px) {
  .header {
    display: contents;
  }
}
.h-120 {
  height: 120px;
}
.background {
  background-color: #f0f1f3;
}

.pop_up_image {
  width: 100%;
  object-fit: contain;
  padding: 5px;
  height: fit-content;
}

.chat_line {
  margin: 0px !important;
  font-size: 14px;
  position: relative;
}

.chat_para {
  background-color: #e9e7e7;
  max-width: calc(100% - 30%);
  font-size: 16px;
  width: fit-content;
}

.chat_name {
  font-size: 14px;
}

.time_display {
  font-size: 11px;
}

.chat_messages {
  max-height: calc(100vh - 170px) !important;
  min-height: calc(100vh - 200px);
  word-wrap: break-word;
}

.chat_box {
  width: 99%;
  height: auto;
}

.chat_box input {
  margin: 0px 0px 0px 4px;
  width: 99%;
}

.send_icon {
  font-size: 13px;
  position: absolute;
  bottom: 10px;
  right: 13px;
  cursor: pointer;
  color: #ffffff;
}

.color {
  position: absolute;
  right: 30px;
  bottom: 30px;
  background-color: #36588f;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  transition: 0.4s;
  cursor: pointer;
}

.color:hover .send_icon {
  transform: scale(1.5);
  transition: 0.2s;
}

.chat_box .quill-editor {
  width: 100%;
  background-color: #ffffff;
}
.box_shadow {
  box-shadow: 0px 0px 5px;
}
.b_right {
  box-shadow: 6px 0 5px -6px #888;
}
.emoji_pos {
  position: absolute;
  top: 0;
}
@media (max-width: 575px) {
  .chat_messages {
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
  }
}
</style>
